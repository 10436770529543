import React from 'react';
import { Table, Tabs } from 'antd';
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import LayoutWrapper from '../../../components/layout/Layout';
import "./style.css";
import { NumberFormat } from '../../../hook/NumberFormat';

const { TabPane } = Tabs;

const tabLabels = [
    { analis: 1, tab: "Biokimyoviy Tahlil" },
    { analis: 2, tab: "Umumiy Tahlil" },
    { analis: 3, tab: "Eritrotsitlar" },
    { analis: 4, tab: "Siydik Tahlili" },
    { analis: 5, tab: "Chokmas Tahlili" },
];

const AdditionalTreatments = () => {
    const { data: allDoctors } = useGetAllDoctorsQuery();
    const doctorType = localStorage.getItem("doctorID");

    const doctor = allDoctors?.data?.find(i => i.idNumber === doctorType);


    const groupedData = tabLabels.map(({ analis, tab }) => {
        const data = doctor?.extraTreatment?.filter(item => item.analis == analis) || [];
        return { analis, tab, data };
    }).filter(group => group.data.length > 0);



    const columnsByAnalis = {
        1: [
            {
                title: 'Nomi', dataIndex: 'name', key: 'name',

            },
            {
                title: 'Norma', dataIndex: 'norma', key: 'norma',

            },
            {
                title: 'siBirlik', dataIndex: 'siBirlik', key: 'siBirlik',
            },
            {
                title: 'Narxi', dataIndex: 'price', key: 'price', render: (text, record) => <span>{NumberFormat(text)}</span>
            },
        ],
        2: [
            {
                title: 'Nomi', dataIndex: 'name', key: 'name',
            },
            {
                title: 'Norma', dataIndex: 'norma', key: 'norma',
            },
            {
                title: 'siBirlik', dataIndex: 'siBirlik', key: 'siBirlik',
            },
            {
                title: 'Narxi', dataIndex: 'price', key: 'price', render: (text, record) => <span>{NumberFormat(text)}</span>
            },

        ],
        3: [
            {
                title: 'Nomi', dataIndex: 'name', key: 'name',
            },
            {
                title: 'Narxi', dataIndex: 'price', key: 'price', render: (text, record) => <span>{NumberFormat(text)}</span>
            },

        ],
        4: [
            {
                title: 'Nomi', dataIndex: 'name', key: 'name',
            },
            {
                title: 'Norma', dataIndex: 'norma', key: 'norma',
            },
            {
                title: 'siBirlik', dataIndex: 'siBirlik', key: 'siBirlik'
            },
            {
                title: 'Narxi', dataIndex: 'price', key: 'price', render: (text, record) => <span>{NumberFormat(text)}</span>
            },

        ],
        5: [
            {
                title: 'Nomi', dataIndex: 'name', key: 'name',
            },
            {
                title: 'Norma', dataIndex: 'norma', key: 'norma',
            },
            {
                title: 'siBirlik', dataIndex: 'siBirlik', key: 'siBirlik',
            },
            {
                title: 'Narxi', dataIndex: 'price', key: 'price', render: (text, record) => <span>{NumberFormat(text)}</span>
            },

        ],
    };

    return (
        <LayoutWrapper>
            <div className="container">
                <Tabs defaultActiveKey="1"  >
                    {groupedData.map(({ analis, tab, data }) => (
                        <TabPane tab={tab} key={analis}>
                            <Table
                                dataSource={data}
                                columns={columnsByAnalis[analis]}
                                rowKey="_id"
                                pagination={false}
                                size="small"
                            />
                        </TabPane>
                    ))}
                </Tabs>


            </div>
        </LayoutWrapper>
    );
};

export default AdditionalTreatments;

