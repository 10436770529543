import React from "react";
import moment from "moment";
import './style.css';

// PrintCheckList komponenti
const PrintCheckList = React.forwardRef(({ analysisGroups, formData, singleStory, doctorData }, ref) => {
    let story = singleStory?.innerData?.singleStory || [];

    // Tug'ilgan sanani "YYYY-MM-DD" formatidan "DD.MM.YYYY" formatiga o'zgartirish
    const formatToDDMMYYYY = (dateString) => {
        return moment(dateString).format("DD.MM.YYYY");
    };

    // Tug'ilgan sana va bugungi sanani formatlash
    const formattedBirthday = formatToDDMMYYYY(story.birthday);
    const formattedDate = formatToDDMMYYYY(moment().format("YYYY-MM-DD"));

    return (
        <div ref={ref} className="report-container">
            <header className="report-header">
                <div className="header-left">
                    <h1>IMPULS MED CENTR</h1>
                </div>
                <div className="header-right">
                    <h2>Лаборатория</h2>
                </div>
            </header>
            <section className="report-info">
                <div className="info-left">
                    <p>Бемор: {story.clientFullname}</p>
                    <p>Туғилган сана: {formattedBirthday}</p>
                    <p>Амбулатор: ____</p>
                </div>
                <div className="info-right">
                    <p>Сана: {formattedDate}</p>
                    <p>Палата № ______</p>
                </div>
            </section>
            <section className="report-lab">
                <p>Лаборант: {doctorData?.firstName} {doctorData?.lastName}</p>
            </section>

            {Object.entries(analysisGroups).map(([tabTitle, data], index) =>
                data.length ? (
                    <div key={index} className="checklist-section">
                        <h3>{tabTitle}</h3>
                        <table className="report-table">
                            {data.some(item => item.name || formData["result" + item.key] || item.norma || item.siBirlik) ? (
                                <>
                                    <thead>
                                        <tr>
                                            <th>№</th>
                                            <th>Параметрлар</th>
                                            <th>Натижа</th>
                                            <th>Норма</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data.map((item, inx) => (
                                            (item.name || formData["result" + item.key] || item.norma || item.siBirlik) ? (
                                                <tr key={inx}>
                                                    <td>{inx + 1}</td> {/* Tartib raqami */}
                                                    <td>{item.name}</td>
                                                    <td>{formData["result" + item.key]}</td>
                                                    <td>{item.norma} {item.siBirlik}</td>
                                                </tr>
                                            ) : null
                                        ))}
                                    </tbody>
                                </>
                            ) : null}
                        </table>
                    </div>
                ) : null
            )}
        </div>
    );
});

export default PrintCheckList;




