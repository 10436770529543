import React from "react";
import { Tabs } from "antd";
import LayoutWrapper from "../layout/Layout";

import { useGetAllDoctorsQuery } from "../../redux/doctorApi";
import { useGetAllNursesQuery } from "../../redux/nursesApi";
import { useGetNusresDayQuery } from "../../redux/nusresDayliApi";
import { useGetSalaryQuery } from "../../redux/salaryApi";
import VidomostBlankDoctors from "./vidomostBlannkDoctors";
import VidomostBlankAdmins from "./vidomostBlannkAdmins";
import VidomostBlankNurses from "./vidomostBlannkNurses";

const MainVidomost = () => {
  const { data: allDoctors } = useGetAllDoctorsQuery();
  const { data: nursesData } = useGetAllNursesQuery();
  const { data: data } = useGetNusresDayQuery();
  const { data: GetSalary } = useGetSalaryQuery();

  const doctors = allDoctors?.data || [];
  const salary = GetSalary?.innerData || [];
  const nurses = nursesData?.innerData || [];

  const DoctorsData = doctors?.filter((i) => i.userType === "doctor");
  const AdminsData = doctors?.filter(
    (i) =>
      i.userType === "reception" ||
      i.userType === "calCenter" ||
      i.userType === "buhgalter"
  );
  return (
    <LayoutWrapper>
      <Tabs>
        <Tabs.TabPane
          defaultActiveKey="0"
          tab="Doktorlar oylik hisoboti"
          key={0}
        >
          <VidomostBlankDoctors
            data={data}
            doctors={DoctorsData}
            nurses={nurses}
            salary={salary}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          defaultActiveKey="1"
          tab="Adminlar oylik hisoboti"
          key={1}
        >
          <VidomostBlankAdmins
            data={data}
            doctors={AdminsData}
            nurses={nurses}
            salary={salary}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          defaultActiveKey="2"
          tab="Hamshiralar oylik hisoboti"
          key={2}
        >
          <VidomostBlankNurses
            data={data}
            doctors={doctors}
            nurses={nurses}
            salary={salary}
          />
        </Tabs.TabPane>
      </Tabs>
    </LayoutWrapper>
  );
};

export default MainVidomost;
