import React from 'react'
import LayoutWrapper from '../../../components/layout/Layout';

const PatentsCalCenter = () => {
    return (
        <LayoutWrapper>
            <div>
                <h1>Patents</h1>
            </div>
        </LayoutWrapper>

    )
}

export default PatentsCalCenter;
