import { api } from './api'; // import the existing api object

export const extendedApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllFizioterapiyaPrices: builder.query({
            query: (doctorId) => `/fizio/${doctorId}/fizioterapiyaPrices`,
            providesTags: ["GetDoctors"],
        }),
        addFizioterapiyaPrice: builder.mutation({
            query: ({ doctorId, priceData }) => ({
                url: `/fizio/${doctorId}/fizioterapiyaPrices`,
                method: 'POST',
                body: priceData,
            }),
            invalidatesTags: ["GetDoctors"],
        }),
        updateFizioterapiyaPrice: builder.mutation({
            query: ({ doctorId, priceId, updatedPriceData }) => ({
                url: `/fizio/${doctorId}/fizioterapiyaPrices/${priceId}`,
                method: 'PUT',
                body: updatedPriceData,
            }),
            invalidatesTags: ["GetDoctors"],
        }),
        deleteFizioterapiyaPrice: builder.mutation({
            query: ({ doctorId, priceId }) => ({
                url: `/fizio/${doctorId}/fizioterapiyaPrices/${priceId}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["GetDoctors", "GetReports"],
        }),
    }),
});

export const {
    useGetAllFizioterapiyaPricesQuery,
    useAddFizioterapiyaPriceMutation,
    useUpdateFizioterapiyaPriceMutation,
    useDeleteFizioterapiyaPriceMutation
} = extendedApi;
