import React from "react";
import { Tabs } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import LayoutWrapper from "../../../../components/layout/Layout";
import Reception from "../reseption/AddReception";
import AddNurses from "../nurses/AddNurses";
import "../style.css";
import AddDoctors from "../doctors/AddDoctors";

const AddMain = () => {

  return (
    <LayoutWrapper>
      <h3 className="text-center">Ishga qabul qilish</h3>
      <Tabs>
        <Tabs.TabPane
          tab={<div className="iconPlu">Doktor {<PlusOutlined />}</div>}
          key={0}
        >
          <AddDoctors />
        </Tabs.TabPane>

        <Tabs.TabPane tab={<div className="iconPlu">Administrator {<PlusOutlined />}</div>} key={1}
        >
          <Reception />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <div className="iconPlu">
              Hamshira {<PlusOutlined className="iconPlu" />}
            </div>
          }
          key={2}
        >
          <AddNurses />
        </Tabs.TabPane>
      </Tabs>
    </LayoutWrapper>
  );
};

export default AddMain;



