import React from 'react'
import "./style.css";
import { Spin } from 'antd';
import { GiMoneyStack } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import LogoMedme from "../../../../assets/img/logo.png";


const ReportsLoading = () => {
    return (
        <div className="WalletSNG">
            <div className="headerWallet">
                <div className="header-summary">
                    <div className="summary-text">Bir kunlik tushim</div>
                    <div className="summary-balance">
                        <Spin size="small" />
                    </div>
                </div>

                <div className="imgBoxProfile">
                    <Spin size="small" style={{ color: '#fff' }} />
                    <div>Loading... </div>
                    <p>Loading...</p>
                </div>
            </div>
            <div className="contentWallet">
                <div className="cardWallet">
                    <div className="upper-row">
                        <div className="card-item">
                            <Spin size="small" />
                        </div>
                        <div className="card-item">
                            <Spin size="small" />
                        </div>
                    </div>
                    <div className="lower-row">
                        <div className="icon-item">
                            <div className="icon"> <Spin size="small" /></div>
                            <div className="icon-text">
                                <FaUsers /> Bugun
                            </div>
                        </div>
                        <div className="icon-item">
                            <div className="icon"> <Spin size="small" /></div>
                            <div className="icon-text">
                                <FaUsers />{" "}
                                oy
                            </div>
                        </div>
                        <div className="icon-item">
                            <div className="icon"> <Spin size="small" /></div>
                            <div className="icon-text">
                                <GiMoneyStack /> Bir oylik tushim
                            </div>
                        </div>
                        <div className="icon-item">
                            <div className="icon">
                                <Spin size="small" />
                            </div>
                            <div className="icon-text">
                                <GiMoneyStack /> Qabul
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="drawer">
                <img width={200} src={LogoMedme} alt="" />
            </div>
        </div>
    )
}

export default ReportsLoading;

// const App = () => (



// );
// export default App;


// .content {
//   padding: 50px;
//   background: rgba(0, 0, 0, 0.05);
//   border-radius: 4px;
// }

