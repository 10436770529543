import { api } from "./api";

export const roomStories = api.injectEndpoints({
  endpoints: (builder) => ({
    getRoomStories: builder.query({
      query: () => `/roomStories/getRoomStories`,
      providesTags: ["GetRoomsStories"],
    }),

    createRoomStories: builder.mutation({
      query: (body) => ({
        url: "roomStories/createRoomStories",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetRoomsStories"],
    }),
    updateRoomStories: builder.mutation({
      query: ({ id, body }) => ({
        url: `/roomStories/updateRoomStories/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetRoomsStories", "GetRooms"],
    }),

    updateRoomPayment: builder.mutation({
      query: (body) => ({
        url: '/roomstories/update-room-payment',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['GetRoomsStories'], // So'nggi so'rovlarni qayta yuklash uchun
    }),

    updatePaidDays: builder.mutation({
      query: ({ _id, newPaidDays }) => ({
        url: '/roomstories/update-paid-days',
        method: 'POST',
        body: { _id, newPaidDays },
      }),
      invalidatesTags: ["GetRoomsStories", "GetRooms"],

    }),
  }),
});

export const {
  useGetRoomStoriesQuery,
  useCreateRoomStoriesMutation,
  useUpdateRoomStoriesMutation,
  useUpdateRoomPaymentMutation,
  useUpdatePaidDaysMutation
} = roomStories;
