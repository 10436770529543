import React, { useEffect, useState } from 'react';
import { Progress, Switch } from 'antd';
import './style.css'


const AgeStatistic = ({ year, month, manthByNomber, twoColors1, twoColors2, data }) => {
    const [ageGroups, setAgeGroups] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [maleAgeGroups, setMaleAgeGroups] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [femaleAgeGroups, setFemaleAgeGroups] = useState([0, 0, 0, 0, 0, 0, 0]);
    const [show, setShow] = useState(false);
    const [maleUsers, setMaleUsers] = useState([]);
    const [femaleUsers, setFemaleUsers] = useState([]);

    const calculateAgeGroups = (users) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();

        const newAgeGroups = [0, 0, 0, 0, 0, 0, 0];
        users?.forEach((user) => {
            const userYear = new Date(user.birthday).getFullYear();
            const age = currentYear - userYear;
            if (age >= 1 && age < 4) newAgeGroups[0]++;
            if (age >= 5 && age < 14) newAgeGroups[1]++;
            else if (age >= 15 && age < 24) newAgeGroups[2]++;
            else if (age >= 25 && age < 34) newAgeGroups[3]++;
            else if (age >= 35 && age < 44) newAgeGroups[4]++;
            else if (age >= 45 && age < 54) newAgeGroups[5]++;
            else if (age >= 55 && age < 64) newAgeGroups[6]++;
            else newAgeGroups[7]++;
        });
        return newAgeGroups;
    };

    useEffect(() => {
        const newAgeGroups = calculateAgeGroups(data);
        setAgeGroups(newAgeGroups);

        const males = data.filter(user => user.gender === 'male');
        const females = data.filter(user => user.gender === 'female');

        const maleAgeGroups = calculateAgeGroups(males);
        setMaleAgeGroups(maleAgeGroups);
        setMaleUsers(males);

        const femaleAgeGroups = calculateAgeGroups(females);
        setFemaleAgeGroups(femaleAgeGroups);
        setFemaleUsers(females);
    }, []);

    const calculatePercentage = (count, total) => {
        return ((count / total) * 100).toFixed(2);
    };


    const ageRanges = [
        '-1-4-',
        '-5-15-',
        '-15-24-',
        '-25-34-',
        '-35-44-',
        '-45-54-',
        '-55-64-',
        '-65+-'
    ];
    const ageRangesAll = [
        '1-4',
        '5-15',
        '15-24',
        '25-34',
        '35-44',
        '45-54',
        '55-64',
        '65+'
    ];

    const onChange = (checked) => {
        setShow(checked);
    };
    return (
        <div className="statikk">
            <h4 style={{ textAlign: "center", color: twoColors1 }}>Bemorlarning jinsi va yoshi guruhlari bo'yicha</h4>
            <p style={{ textAlign: "center", color: twoColors1, lineHeight: '5px' }}>{`${year}-yil ${manthByNomber}-${month}`} holatiga ko‘ra</p>
            <div style={{ padding: " 0 10px" }}>
                <Switch onChange={onChange} checked={show} /> {show ? '' : <a style={{ color: "#1f646d" }}>Umumiy statistika</a>}
            </div>
            {show ?
                <div className="genderLine">
                    <p style={{ color: twoColors1, textAlign: 'center' }}>Umumiy statistika</p>
                    <div className="statistika_Yosh">
                        <div className="genderLine_Age_rigth">
                            {maleAgeGroups.map((count, index) => (
                                <div key={index}>
                                    {ageRangesAll[index]}
                                </div>
                            ))}
                        </div>
                        <div style={{ padding: '5px 15px 15px 0' }} className="genderLine">
                            {ageGroups.map((count, index) => (
                                <div key={index}>
                                    <Progress percent={parseFloat(calculatePercentage(count, data.length))} strokeColor={twoColors1} />
                                </div>
                            ))}
                        </div>

                    </div>
                </div>
                :
                <div className="statistika_Yosh">
                    <div style={{ padding: '5px 0px 15px 15px' }} className="genderLine">
                        <p style={{ color: twoColors1 }}>Erkaklar</p>
                        {maleAgeGroups.map((count, index) => (
                            <div key={index} style={{ direction: 'rtl', color: twoColors1 }}>
                                <Progress strokeLinecap="butt"
                                    percent={parseFloat(calculatePercentage(count, maleUsers.length))}
                                    strokeColor={twoColors1}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="genderLine_Age">
                        <p>Yosh</p>
                        {maleAgeGroups.map((count, index) => (
                            <div key={index}>
                                {ageRanges[index]}
                            </div>
                        ))}
                    </div>
                    <div style={{ padding: '5px 15px 15px 0' }} className="genderLine">
                        <p style={{ color: twoColors2 }}>Ayollar</p>
                        {femaleAgeGroups.map((count, index) => (
                            <div key={index}>
                                <Progress strokeLinecap="butt" percent={parseFloat(calculatePercentage(count, femaleUsers.length))} strokeColor={twoColors2} />
                            </div>
                        ))}
                    </div>
                </div>
            }
        </div>
    );
};

export default AgeStatistic;

