import React, { useRef, useEffect, useState } from "react";
import { Button, Tabs, message, Popover } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined, PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useForm, Controller, useWatch } from "react-hook-form";
import LayoutWrapper from "../../../components/layout/Layout";
import {
  useGetStoryByIdQuery,
  useUpdateStoryMutation,
} from "../../../redux/clientStores";
import {
  useGetDoctorByIdNumberQuery,
  useUpdateDoctorSalaryMutation,
} from "../../../redux/doctorApi";
import {
  useUpdateClientMutation,
  useGetUsersQuery,
} from "../../../redux/clientApi";
import PrintCheckList from "./PrintCheckList"; // Import PrintCheckList component
import "./style.css";

const BioKimyoviy = () => {
  const [totalPrice, setTotalPrice] = useState(0);
  const [updateClient] = useUpdateClientMutation();
  const { data: allClients } = useGetUsersQuery();
  const clients = allClients?.data || [];
  const navigate = useNavigate();
  const mainform = useRef();
  const printRef = useRef(); // Ref for PrintCheckList component
  const id = useParams()._id;
  const { data: singleStory } = useGetStoryByIdQuery(id);
  let story = singleStory?.innerData?.singleStory?.extraTreatment || [];
  const [popoverVisible, setPopoverVisible] = useState(false);
  useEffect(() => {
    const total = story.reduce((acc, cur) => acc + (cur.price || 0), 0);
    setTotalPrice(total);
  }, [story]);

  // Function to check if any `extraTreatment` array is empty
  const hasEmptyExtraTreatment = singleStory?.innerData?.some((i) =>
    i.singleStory?.extraTreatment?.length === 0
  ) || false;

  console.log(hasEmptyExtraTreatment);

  const analysisGroups = {
    "Qonning biokimyoviy taxlili": story.filter((i) => i.analis === "1"),
    "Qonning umumiy taxlili": story.filter((i) => i.analis === "2"),
    "Eritrotsitlar morfologiyasi": story.filter((i) => i.analis === "3"),
    "Siydik taxlili": story.filter((i) => i.analis === "4"),
    "Chokma taxlili": story.filter((i) => i.analis === "5"),
  };

  const [updateStory] = useUpdateStoryMutation();
  const [updateDoctorSalary] = useUpdateDoctorSalaryMutation();
  const doctorID = localStorage.getItem("doctorID");
  const { data: { data: doctorData } = {} } = useGetDoctorByIdNumberQuery(
    doctorID || ""
  );

  const { control, handleSubmit } = useForm({
    defaultValues: {
      extraTreatment: story.reduce((acc, cur) => {
        acc["result" + cur.key] = cur.result || "";
        return acc;
      }, {}),
    },
  });

  const watchedFormData = useWatch({ control }); // Watch form data in real-time

  const onSubmit = (formData) => {
    let dataStory = { ...singleStory.innerData.singleStory };

    dataStory.extraTreatment = story.map((item) => ({
      ...item,
      result: formData["result" + item.key],
    }));

    dataStory.paySumm = +totalPrice;
    dataStory.view = true;

    updateStory({ id: dataStory._id, body: dataStory })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    if (!dataStory.debtor) {
      updateDoctorSalary({
        salary: dataStory.paySumm,
        doctor_id: dataStory.doctorIdNumber,
      });
    }

    let user = { ...clients.find((u) => u._id === dataStory.clientID) };
    user.totalPrice = +user.totalPrice + +dataStory.paySumm;

    updateClient({ id: user._id, body: user })
      .then((res) => {
        if (res?.data?.success) {
          message.success("Qaradorlikka o'tkazildi!");
        }
      })
      .catch((err) => console.log("err", err));

    navigate("/appointments");
  };

  const handleOk = () => {
    setPopoverVisible(false);
    handleSubmit(onSubmit)(); // Formani yuborish uchun chaqiramiz
  };

  const handleCancel = () => {
    setPopoverVisible(false);
  };
  const popoverContent = (
    <div>
      <p>Natijalarni chop etib berdingizmi?</p>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <Button type="primary" size="small" onClick={handleOk}>
          HA
        </Button>
        <Button size="small" onClick={handleCancel}>
          YO‘Q
        </Button>
      </div>
    </div>
  );

  const operations = (
    <div style={{ display: "flex", alignItems: "center", gap: "7px" }}>
      <Popover
        content={popoverContent}
        trigger="click"
        open={popoverVisible}
        onOpenChange={setPopoverVisible}
      >
        <Button
          shape="round"
          icon={<CheckOutlined />}
          className="BtnPrimary"
          type="primary"
        />
      </Popover>
      <ReactToPrint
        trigger={() => (
          <Button
            shape="round"
            icon={<PrinterOutlined />}
            className="BtnPrimary"
            type="default"
          >
            Print
          </Button>
        )}
        content={() => printRef.current}
      />
    </div>
  );

  console.log(analysisGroups);
  return (
    <LayoutWrapper>
      <div>
        <Tabs className="BioKimyoviy" tabBarExtraContent={operations}>
          {Object.entries(analysisGroups).map(([tabTitle, data], index) =>
            data.length ? (
              <Tabs.TabPane tab={tabTitle} key={index}>
                <form
                  ref={mainform}
                  id="mainForm"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <table className="tableAnalis">
                    <thead>
                      <tr>
                        <th>Ko'rsatkich</th>
                        <th>Natija</th>
                        <th>Norma</th>
                        <th>SI birlik</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, inx) => (
                        <tr key={inx}>
                          <td>{item.name}</td>
                          <td>
                            <Controller
                              name={"result" + item.key}
                              control={control}
                              render={({ field }) => (
                                <input
                                  {...field}
                                  placeholder="Yozing..."
                                  className="resInp"
                                  type="text"
                                />
                              )}
                            />
                          </td>
                          <td>{item.norma}</td>
                          <td>{item.siBirlik}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </form>
              </Tabs.TabPane>
            ) : null
          )}
        </Tabs>
        <div style={{ display: "none" }}>
          <PrintCheckList
            ref={printRef} // PrintCheckList componentiga ref uzatish
            analysisGroups={analysisGroups} // Analysis groups ni uzatish
            formData={watchedFormData} // Real-time form data ni uzatish
            doctorData={doctorData}
            singleStory={singleStory}
          />
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default BioKimyoviy;
