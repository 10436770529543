import React, { useEffect } from "react";
import "./style.css";
import { useForm } from "react-hook-form";
import { message } from "antd";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import HeartLine from "../../../assets/heartLogo.png";
import LayoutWrapper from "../../layout/Layout";
import { useGetOneUserQuery } from "../../../redux/clientApi";
import { useCreateAmbulatorMutation } from "../../../redux/ambulatorAPI";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";

const Ambulator = () => {
  const history = useNavigate();
  const { idNumber } = useParams();
  const { data: client } = useGetOneUserQuery(idNumber);
  const availableData = client?.data;
  const [createAmbulator] = useCreateAmbulatorMutation();

  const mapDataToFormFields = (data) => ({
    UserIdNumber: data.idNumber,
    fullName:
      capitalizeFirstLetter(data.lastname) +
      " " +
      capitalizeFirstLetter(data.firstname),
    birthDay: data.year.split("-")[2],
    birthMonth: data.year.split("-")[1],
    birthYear: data.year.split("-")[0],
    gender: data.gender,
    city: data?.address?.district,
    village: data.village,
    street: data?.address?.quarter,
    house: data.house,
    room: data.room,
    phone: data.phone,
    permanentAddress: data.permanentAddress,
    temporaryAddress: data.temporaryAddress,
    workPlace: data.workPlace,
    registrationDate: data.registrationDate,
    deregistrationReason: data.deregistrationReason,
    dispensary: data.dispensary,
    specificInstitution: data.specificInstitution,
    otherInstitution: data.otherInstitution,
    oilaviy_ahvoli: data.oilaviy_ahvoli,
    complaints: data.complaints,
    ovqat_hazm_qilish: data.ovqat_hazm_qilish,
    tashxis: data.tashxis,
    hozirgi_kasallik: data.hozirgi_kasallik,
  });
  const { register, handleSubmit, setValue, reset } = useForm();

  useEffect(() => {
    if (availableData) {
      const mappedData = mapDataToFormFields(availableData);
      Object.keys(mappedData)?.forEach((key) => {
        setValue(key, mappedData[key]);
      });
    }
  }, [availableData, setValue]);

  const handleGoBack = () => {
    history(-1);
    history("/", { replace: true });
  };

  const onSubmit = (formData) => {
    createAmbulator(formData)
      .then((res) => {
        if (res.data.success) {
          message.success(res?.data?.msg);
          handleGoBack();
          return reset();
        }
        message.warning(res?.data?.msg);
      })
      .catch((er) => console.log(er));
  };

  return (
    <LayoutWrapper>
      <div className="form-container_Medical">
        <div className="header_MedicalForm">
          <div className="header_Medical">
            <p>ЎЗБЕКИСТОН РЕСПУБЛИКАСИ</p>
            <p>СОҒЛИҚНИ САҚЛАШ ВАЗИРЛИГИ</p>
            <p>Кардиология илмий амалий тиббиёт</p>
            <p>маркази Наманган филиали</p>
          </div>
          <div className="center-header">
            <img width={80} src={HeartLine} alt="" />
            <h2>
              RIKIATM NF <br />
              <i style={{ fontSize: "14px", color: "#6a6a6a" }}>CLINICS</i>{" "}
            </h2>
          </div>
          <div className="header_Medical">
            <p>Ўзбекистон Республикаси</p>
            <p>Соғлиқни сақлаш вазирининг</p>
            <p>2020 йил 31 декабрдаги № 363-сонли</p>
            <p>буюрғи билан тасдиқланган</p>
            <p>025-рақамли тиббий хужжат шакли</p>
          </div>
        </div>
        <h2 className="title">АМБУЛАТОР ТИББИЙ КАРТА №__</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            className="form-group_Medical"
          >
            <label>1. Фамилия. Исми:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("fullName")}
            />
          </div>
          <div className="form-group_Medical">
            <label>2. Туғилган сана кун:</label>
            <input type="text" {...register("birthDay")} />
            <label>ой:</label>
            <input type="text" {...register("birthMonth")} />
            <label>йил:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("birthYear")}
            />
          </div>

          <div className="form-group_Medical">
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              3. Жинси: эр
              <input type="radio" value="male" {...register("gender")} />
              аёл
              <input type="radio" value="female" {...register("gender")} />
            </div>
          </div>
          <div className="form-group_Medical">
            <label>4. Яшаш жойи, туман (шаҳар):</label>
            <input type="text" {...register("city")} />
            <label>қишлоқ:</label>
            <input type="text" {...register("village")} />
            <label>кўча:</label>
            <input type="text" {...register("street")} />
            <label>уй:</label>
            <input type="text" {...register("house")} />
            <label>хона:</label>
            <input type="text" {...register("room")} />
            <label>телефон:+998</label>
            <input style={{ flex: "1" }} type="text" {...register("phone")} />
          </div>
          <div className="form-group_Medical">
            <label>5. Доимий яшаш жойи:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("permanentAddress")}
            />
          </div>
          <div className="form-group_Medical">
            <label>
              6. Вақтинча яшайди: бошқа шаҳар, қишлоқдан келган (чизинг)
            </label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("temporaryAddress")}
            />
          </div>
          <div className="form-group_Medical">
            <label>7. Иш (ўқиш) жойи:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("workPlace")}
            />
          </div>
          <div className="form-group_Medical">
            <label>8. Рўйхатдан чиққан сана:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("registrationDate")}
            />
          </div>
          <div className="form-group_Medical">
            <label>9. Рўйхатдан чиқиш сабаби кўрсатилсин:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("deregistrationReason")}
            />
          </div>
          <div className="form-group_Medical">
            <label>10. Диспансеризация:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("dispensary")}
            />
          </div>
          <div className="form-group_Medical">
            <label>
              10.1 Айнан шу муассасада: (шифокорлик хуқуқ № ва хуқуқ номи):
            </label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("specificInstitution")}
            />
          </div>
          <div className="form-group_Medical">
            <label>10.2 Бошқа муассасада (ташкилот номи):</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("otherInstitution")}
            />
          </div>
          <h2 className="title_bottom">Муҳим белгилар</h2>
          <div className="form-group_Medical">
            <label>Қон гуруҳи:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("bloodGroup")}
            />
            <label>Резус мансублик:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("rhesusFactor")}
            />
          </div>

          <div className="form-group_Medical">
            <label>Беморнинг шикоятлари:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("complaints")}
            />
          </div>
          <div className="form-group_Medical">
            <label>Anamnesis morbi (хозирги касаллик тафсилоти):</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("anamnesisMorbi")}
            />
          </div>
          <div className="form-group_Medical">
            <label>
              Тавсия этилган текширишлар ва консультацияларнинг натижалар:
            </label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("consultationResults")}
            />
          </div>
          <div className="form-group_Medical">
            <label>Якуний ташхис:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("finalDiagnosis")}
            />
          </div>
          <div className="form-group_Medical">
            <label>ТАВСИЯЛАР:</label>
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("recommendations")}
            />
          </div>
          <div className="status-presents">
            <h1>STATUS PRESENTS:</h1>
            <div>
              Умумий ахволи: қониқарли{" "}
              <input
                {...register("umumiy_ahvoli")}
                type="radio"
                value="qoniqarli"
              />
              , ўртача{" "}
              <input
                {...register("umumiy_ahvoli")}
                type="radio"
                value="ortacha"
              />
              , оғир{" "}
              <input {...register("umumiy_ahvoli")} type="radio" value="ogir" />{" "}
              (белгиланг)
            </div>
            <div>
              Тана харорати{" "}
              <input
                style={{ width: "120px" }}
                type="text"
                {...register("bodyTemperature")}
              />{" "}
              С; бўйи (см){" "}
              <input style={{ width: "120px" }} {...register("height")} />{" "}
              танасининг вазни (кг){" "}
              <input
                style={{ flex: "2" }}
                type="text"
                {...register("weight")}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexWrap: "wrap",
              }}
            >
              Тери ва кўринадиган шиллик қаватларининг холати ( соғлом
              <input
                {...register("skinCondition")}
                type="radio"
                value="соғлом"
              />
              , ранги оқарган
              <input
                {...register("skinCondition")}
                type="radio"
                value="ранги оқарган"
              />
              , сарғимтир
              <input
                {...register("skinCondition")}
                type="radio"
                value="сарғимтир"
              />
              , цианоз
              <input
                {...register("skinCondition")}
                type="radio"
                value="цианоз"
              />
              , акрацианоз
              <input
                {...register("skinCondition")}
                type="radio"
                value="акрацианоз"
              />
              , қуруқ
              <input
                {...register("skinCondition")}
                type="radio"
                value="қуруқ"
              />
              , нам
              <input {...register("skinCondition")} type="radio" value="нам" />
              , ҳар хил тошмалар
              <input
                {...register("skinCondition")}
                type="radio"
                value="ҳар хил тошмалар"
              />
              ) (белгиланг).
            </div>
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Сўяк-мушак тизими деформацияланмаган, деформацияланган:
            </label>{" "}
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("boneDeformation")}
            />
          </div>
          <div className="form-group_Medical">
            <label> Периферик лимфа тугунлар холати: катталашган:</label>{" "}
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("lymphNodes")}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            className="form-group_Medical"
          >
            Oёқларда шиш бор
            <input {...register("swelling")} type="radio" value="бор" />
            , симметрик
            <input {...register("swelling")} type="radio" value="симметрик" />
            , ассимметрик
            <input {...register("swelling")} type="radio" value="ассимметрик" />
            , йўқ
            <input {...register("swelling")} type="radio" value="йўқ" />
            (белгиланг).
          </div>

          <h2 className="status-presents_title">НАФАС ОЛИШ ТИЗИМИ</h2>
          <div className="form-group_Medical">
            <label></label> Нафас равон, юза, дақиқасига{" "}
            <input
              style={{ width: "30%" }}
              type="text"
              {...register("breathRate")}
            />{" "}
            марта
          </div>
          <div className="form-group_Medical">
            <label></label> Ўпкада везикуляр, суст везикуляр, дагал нафас,
            қуруқ, нам хириллаш{" "}
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("lungSounds")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Қўкрак қафаси оғриксиз, оғрик бор:{" "}
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("chestPain")}
            />
          </div>
          <h2 className="status-presents_title">ЮРАК-ҚОН ТОМИР ТИЗИМИ</h2>
          <div className="form-group_Medical">
            <label></label> Артериал қон босими (мм.сим.уст) ўнг қўл{" "}
            <input type="text" {...register("rightArmBP")} /> чап қўл{" "}
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("leftArmBP")}
            />
          </div>

          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            className="form-group_Medical"
          >
            <label>Пульс дақиқасига</label>
            <input type="text" {...register("pulseRate")} /> марта, текис
            <input {...register("pulseQuality")} type="radio" value="текис" />
            , нотекис
            <input {...register("pulseQuality")} type="radio" value="нотекис" />
            (белгиланг)
            <input
              style={{ flex: "1" }}
              type="text"
              {...register("pulseAdditionalInfo")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> ЮЎС дақиқасига{" "}
            <input type="text" {...register("heartRate")} /> марта, текис,
            нотекис
          </div>
          <h3 className="status-presents_title">ПЕРКУСИЯ:</h3>
          <div className="form-group_Medical">
            <label></label> Чегараси: чап-5-қовурғалар орасида
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("percussionBoundary1")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> чап қовурғалар-сўл{" "}
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("percussionBoundary2")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> ТЎҚ ШОВҚИН
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("dullSound")}
            />
          </div>
          <h3 className="status-presents_title">АУСКУЛТАЦИЯ:</h3>
          <div className="form-group_Medical">
            <label></label> Юрак шовқинлари йўқ, бор:{" "}
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("heartMurmurs")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Оёқларда шиш бор:{" "}
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("legSwelling")}
            />
          </div>
          <h2 className="status-presents_title">ХАЗМ ҚИЛИШ ТИЗИМИ</h2>
          <div className="form-group_Medical">
            <label></label> Ошқозон-яланг, оғриқсиз, оғриқ бор:{" "}
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("stomachPain")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Ўт пуфаги-яланг, оғриқсиз, оғриқ бор:{" "}
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("gallBladderPain")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Буйрак-оғриқсиз, оғриқ бор:{" "}
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("kidneyPain")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Физиологик фаолият нормал:{" "}
            <input
              type="text"
              style={{ flex: "1" }}
              {...register("physiologicalActivity")}
            />
          </div>

          <div className="status-presents">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <b style={{ fontSize: "16px" }}>АУСКУЛЬТАЦИЯ:</b> Юрак товушлари
              бўйикроқ
              <input
                {...register("auscultation")}
                type="radio"
                value="бўйикроқ"
              />
              , бўғик
              <input {...register("auscultation")} type="radio" value="бўғик" />
              , аник
              <input {...register("auscultation")} type="radio" value="аник" />
              , текис
              <input {...register("auscultation")} type="radio" value="текис" />
              , нотекис
              <input
                {...register("auscultation")}
                type="radio"
                value="нотекис"
              />
              (белгиланг).
            </div>
          </div>
          <div className="form-group_Medical">
            Юрак шовқинлари:{" "}
            <input
              {...register("yurak_shovqinlari")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>

          <div className="form-group_Medical">
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              Аортада II тон акценти бор
              <input
                {...register("aorta_ton_akcenti")}
                type="radio"
                name="aorta_ton_akcenti"
                value="bor"
              />
              йўқ
              <input
                {...register("aorta_ton_akcenti")}
                type="radio"
                name="aorta_ton_akcenti"
                value="yo'q"
              />
            </div>
          </div>

          <h3 className="status-presents_title">ОВҚАТ ХАЗМ ҚИЛИШ ТИЗИМИ</h3>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Тил -</b> тоза{" "}
            <input {...register("til_toza")} type="radio" />, нам{" "}
            <input {...register("til_nam")} type="radio" />, қуруқ{" "}
            <input {...register("til_quruq")} type="radio" />, оқ{" "}
            <input {...register("til_oq")} type="radio" />, сариқ{" "}
            <input {...register("til_sariq")} type="radio" />, қараш босган
            <input {...register("til_qarash")} type="radio" /> (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Томоқ -</b> қизарган
            <input {...register("tomoq_qizargan")} type="radio" />, қизармаган
            <input {...register("tomoq_qizarmagan")} type="radio" />, тоза
            <input {...register("tomoq_toza")} type="radio" />, қараш босган
            <input {...register("tomoq_qara")} type="radio" /> (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Муртак безлари -</b> катталашмаган
            <input
              {...register("fingerprintStatus")}
              type="radio"
              value="notDeveloped"
            />
            , катталашган
            <input
              {...register("fingerprintStatus")}
              type="radio"
              value="developed"
            />
            (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Қорин -</b> юмшок
            <input {...register("qorin_yumshoq")} type="radio" />, қоттик
            <input {...register("qorin_qattiq")} type="radio" />, оғрикли
            <input {...register("qorin_ogriqli")} type="radio" />, оғриксиз
            <input {...register("qorin_ogriqsiz")} type="radio" />, шишган
            <input {...register("qorin_shishgan")} type="radio" />.
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Жигар -</b> катталашмаган
            <input {...register("jigar_kattalashmagan")} type="radio" />,
            катталашган
            <input {...register("jigar_kattalashgan")} type="radio" /> (+{" "}
            <input
              {...register("jigar_sm")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                width: "70px",
              }}
              type="text"
            />{" "}
            см).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Талок -</b> катталашмаган
            <input {...register("talok_kattalashmagan")} type="radio" />,
            катталашган
            <input {...register("talok_kattalashgan")} type="radio" /> (+{" "}
            <input
              {...register("talok_sm")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                width: "70px",
              }}
              type="text"
            />{" "}
            см) (белгиланг). <span>Қабзият бор</span>
            <input {...register("constipation")} type="radio" value="present" />
            , йўқ
            <input {...register("constipation")} type="radio" value="absent" />
            (белгиланг)..
          </div>
          <div>
            <input
              {...register("ovqat_hazm_qilish")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                width: "100%",
              }}
              type="text"
            />
          </div>

          <h3 className="status-presents_title">СИЙДИК АЖРАТИШ ТИЗИМИ</h3>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Диурез -</b> равон
            <input {...register("urination")} type="radio" value="normal" />
            , эркин
            <input {...register("urination")} type="radio" value="frequent" />
            , оғрикли
            <input {...register("urination")} type="radio" value="painful" />
            , оғриксиз
            <input {...register("urination")} type="radio" value="painFree" />
            (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            Микдори - камайган
            <input {...register("diurez_kamaygan")} type="radio" />, ўзгармаган
            <input {...register("diurez_ozgarmagan")} type="radio" />{" "}
            (белгиланг)
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            Туртки белгиси - мусбат
            <input {...register("appendix")} type="radio" value="positive" />
            , манфий
            <input {...register("appendix")} type="radio" value="negative" />
            , ўнг
            <input {...register("appendix")} type="radio" value="right" />
            , чап томонлама
            <input {...register("appendix")} type="radio" value="leftAligned" />
            (белгиланг).
          </div>
          <div className="form-group_Medical">
            <b style={{ fontSize: "16px" }}>ЭКГ хулосаси:</b>{" "}
            <input
              {...register("ekg_xulosasi")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                flex: "1",
              }}
              type="text"
            />
          </div>
          <br />
          <div className="form-group_Medical">
            <label htmlFor="">
              Беморнинг шикоятлари, касаллик тарихи, ҳаёт тарзи, умумий ахволи
              ва объектив текширувлар асосидаги
            </label>{" "}
          </div>
          <div className="form-group_Medical">
            <b style={{ fontSize: "16px" }}>ТАШХИС:</b>{" "}
            <input
              {...register("tashxis")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                flex: "1",
              }}
              type="text"
            />
          </div>

          <div className="status-presents">
            <div>Anamnesis vitae: беморнинг кечирган касалликлари:</div>
            <div>
              <input
                {...register("anamnesis_vitae_kasal")}
                style={{ width: "100%" }}
                type="text"
              />
            </div>
            <div>
              Эпид. анамнез: доимий яшаш, иш, ўқишда юқумли касалликлар (қорин
              тифи, паратиф, сальмонеллёз,
              <br /> дизентерия бошқа ўткир ичак инфекциялари, вирусли гепатит,
              тери-таносил касалликлари) билан оғриган беморлар билан <br />{" "}
              муносабатда бўлганми (касалликнинг максимал инкубацион даврини
              ҳисобга олган ҳолда)?
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                бор
                <input
                  {...register("epid_anamnez_aorta_bor")}
                  type="radio"
                  name="epid_anamnez_aorta"
                  value="bor"
                />
                йўқ
                <input
                  {...register("epid_anamnez_aorta_yoq")}
                  type="radio"
                  name="epid_anamnez_aorta"
                  value="yo'q"
                />
                (белгиланг).{" "}
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
                className="form-group_Medical"
              >
                Мулоқотда бўлган вақти: дан:
                <input {...register("epid_anamnez_dan")} type="text" />
                гача:
                <input {...register("epid_anamnez_gacha")} type="text" />
              </div>
            </div>

            <div>
              Ҳақиқий (ҳозирги) касаллик бошланишидан 1 ҳафта - 2 ой олдин аҳоли
              пунктидан четда бўлганми? <br />{" "}
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                (бор
                <input
                  {...register("hozirgi_kasallik_bor")}
                  type="radio"
                  name="hozirgi_kasallik"
                  value="bor"
                />
                йўқ
                <input
                  {...register("hozirgi_kasallik_yoq")}
                  type="radio"
                  name="hozirgi_kasallik"
                  value="yo'q"
                />
                ) (белгиланг). Қаерда бўлган ва қачон қайтиб келган:{" "}
                <input
                  {...register("hozirgi_kasallik_qayer")}
                  style={{ width: "100%" }}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form-group_Medical">
            <label htmlFor="">Қандай юқумли касалликлар билан оғриган?</label>{" "}
            <input
              {...register("yuqumli_kasalliklar")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="status-presents">
            <div>Сўнгги 6 ой ичида:</div>
          </div>
          <div className="status-presents">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                flexWrap: "wrap",
              }}
            >
              Стационар ёки амбулатор даволанишда бўлганми? (ҳа{" "}
              <input
                {...register("stasionar_ha")}
                type="radio"
                name="stasionar"
                value="bor"
              />
              , йўқ
              <input
                {...register("stasionar_yoq")}
                type="radio"
                name="stasionar"
                value="yo'q"
              />
              ); гемотрансфузия (қон ва унинг компонентлари) ўтказилганми? (ҳа{" "}
              <input
                {...register("gemotransfuziya_ha")}
                type="radio"
                name="gemotransfuziya"
                value="bor"
              />
              , йўқ
              <input
                {...register("gemotransfuziya_yoq")}
                type="radio"
                name="gemotransfuziya"
                value="yo'q"
              />
              ); беморга жарроҳлик муолажалари ўтказилганми? (ҳа{" "}
              <input
                {...register("jarroh_ha")}
                type="radio"
                name="jarroh"
                value="bor"
              />
              , йўқ
              <input
                {...register("jarroh_yoq")}
                type="radio"
                name="jarroh"
                value="yo'q"
              />
              ).
            </div>
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Парентерал муолажалар (жумладан, игна билан даволаш) қабул
              қилганми?
            </label>{" "}
            <input
              {...register("parental_muolajalar")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                flexWrap: "wrap",
              }}
            >
              {" "}
              Маъиший хизмат турларидан фойдаланганми (маникюр
              <input
                {...register("maxs_manikyur")}
                type="radio"
                name="maxs_xizmat"
                value="manikyur"
              />
              , педикюр
              <input
                {...register("maxs_pedikyur")}
                type="radio"
                name="maxs_xizmat"
                value="pedikyur"
              />
              , пирсинг
              <input
                {...register("maxs_pirsing")}
                type="radio"
                name="maxs_xizmat"
                value="pirsing"
              />
              , татуаж ва б.
              <input
                {...register("maxs_tatuaj")}
                type="radio"
                name="maxs_xizmat"
                value="tatuaj"
              />
              ) қачон ва қаерда (манзил)?
            </label>{" "}
            <input
              {...register("maxs_manzil")}
              style={{ width: "100%" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label> Ирсий мойиллик:</label>{" "}
            <input
              {...register("irsy_moyillik")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Anamnesis vitae (беморнинг ҳаёт тарихи, кесирган касалликлари)
            </label>{" "}
            <input
              {...register("anamnesis_hayot")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Салбий одатлари: чекади:
              <input
                {...register("salbiy_chekadi_yil")}
                style={{ width: "70px" }}
                type="text"
              />{" "}
              йил, кунига{" "}
              <input
                {...register("salbiy_chekadi_kun")}
                style={{ width: "70px" }}
                type="text"
              />{" "}
              та, чекмайди (вақти кўрсатилсин){" "}
              <input
                {...register("salbiy_chekadi_vaqt")}
                style={{ flex: "1" }}
                type="text"
              />
            </label>
          </div>
          <div className="status-presents">
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              Алкогол ичимликлар истеъмоли: (мунтазам
              <input
                {...register("alkogol_muntazam")}
                type="radio"
                name="alkogol_istemoli"
                value="bor"
              />
              баъзида
              <input
                {...register("alkogol_bazida")}
                type="radio"
                name="alkogol_istemoli"
                value="yo'q"
              />
              истеъмол қилмайди
              <input
                {...register("alkogol_istemol_qilmaydi")}
                type="radio"
                name="alkogol_istemoli"
                value="yo'q"
              />
              ) (белгиланг).
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              Оилавий аҳволи: (турмушга чиққан,
              <input
                {...register("oilaviy_ahvoli_turmush")}
                type="radio"
                name="oilaviy_ahvoli"
                value="bor"
              />
              турмушга чиқмаган,
              <input
                {...register("oilaviy_ahvoli_turmush_yoq")}
                type="radio"
                name="oilaviy_ahvoli"
                value="yo'q"
              />
              уйланган,
              <input
                {...register("oilaviy_ahvoli_uylangan")}
                type="radio"
                name="oilaviy_ahvoli"
                value="yo'q"
              />
              бўйдок,
              <input
                {...register("oilaviy_ahvoli_boydok")}
                type="radio"
                name="oilaviy_ahvoli"
                value="yo'q"
              />
              ) (белгиланг).
            </div>
          </div>
          <div className="form-group_Medical">
            <label> Ҳайз кўриш даври, ҳомиладорлиги</label>{" "}
            <input
              {...register("hayz_korish")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label> Охирги 6 ой ичида қабул қилган муолажалари</label>{" "}
            <input
              {...register("oxirgi_6oy_muolajalar")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label> Дори-дармон ва озиқ-овқатга аллергияси</label>{" "}
            <input
              {...register("doridarmon_allergiya")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Охирги 1 ой ичида тана ҳарорати кўтарилган, кўтарилмаган (тегига
              чизилсин)
            </label>{" "}
            <input
              {...register("oxirgi_1oy_harorat")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>

          <button className="ambulatorSubmitBtn" type="submit">
            Submit
          </button>
        </form>

        <button className="ambulatorGoBackBtn" onClick={handleGoBack}>
          <FaArrowLeftLong />
        </button>
      </div>
    </LayoutWrapper>
  );
};

export default Ambulator;
