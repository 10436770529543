// api.js
import { api } from "./api";

// Define an API using reduxjs/toolkit/query
export const clinicPayApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Endpoint for fetching clinic balance
    getClinic: builder.query({
      query: () => "clinics/getData",
      providesTags: ["GetClinics"],
    }),
    // Endpoint for fetching clinic balance
    updateClinic: builder.query({
      query: (id) => `clinics/clinicsPut/${id}`,
      providesTags: ["GetClinics"],
    }),
    // Endpoint for fetching payments data
    deleteClinic: builder.query({
      query: (id) => `clinics/${id}`,
      providesTags: ["GetClinics"],
    }),
    getSingleClinicInfo: builder.query({
      query: (id) => `clinics/${id}`,
      providesTags: ["GetClinics"],
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useGetClinicQuery,
  useDeleteClinicQuery,
  useUpdateClinicQuery,
  useGetSingleClinicInfoQuery,
} = clinicPayApi;
