import React from 'react'
import { useParams } from "react-router-dom";
import LayoutWrapper from '../../../components/layout/Layout'
import GetPatients from '../../admin/singlePage/getPatients/GetPatients'

const GetSinglePage = () => {
    const { _id, doctorType } = useParams();

    return (
        <LayoutWrapper>
            <GetPatients doctorTypes={doctorType} id={_id} />
        </LayoutWrapper>
    )
}

export default GetSinglePage