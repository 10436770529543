import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IoEyeOutline } from "react-icons/io5";
import {
  SearchOutlined,
  LeftOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { FaUsers } from "react-icons/fa";
import { Button, Table, DatePicker } from "antd";
import moment from "moment"; // moment.js kutubxonasini import qilamiz
import { useGetStoriesQuery } from "../../../../redux/clientStores";
import imgNoData from "../../../../assets/nodata.png";
import { PhoneNumberFormat } from "../../../../hook/NumberFormat";
import "./style.css";
import "dayjs/locale/zh-cn";

const GetPatients = ({ doctorTypes, id }) => {
  const { _id } = useParams();
  const history = useNavigate();
  const [query, setQuery] = useState("");
  const { data: stories } = useGetStoriesQuery();
  const [filteredValue, setFilteredValue] = useState(null);
  const dataStories = stories?.innerData || [];
  const clientsForDoctorId = dataStories.filter(
    (i) => i.doctorIdNumber === _id && i.view
  );

  const handleGoBack = () => {
    history(-1);
    history("/", { replace: true });
  };

  const columns = [
    {
      title: "Sana",
      dataIndex: "day",
      filterDropdown: ({ dataIndex, confirm }) => (
        <DatePicker
          style={{ width: 200 }}
          format="DD.MM.YYYY"
          value={filteredValue ? moment(filteredValue, "DD.MM.YYYY") : null}
          onChange={(date) => {
            confirm();
            const formattedDate = date.format("D.M.YYYY");
            setFilteredValue(formattedDate);
          }}
        />
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      onFilter: (value, record) =>
        record.firstname?.toLowerCase().includes(value?.toLowerCase()),
      render: (text, record) => record.day,
    },
    {
      title: "Bemor",
      dataIndex: "clientFullname",
    },
    {
      title: "Telefon",
      dataIndex: "clientPhone",
      render: (text, record) => (
        <span>{PhoneNumberFormat(record.clientPhone)}</span>
      ),
    },
    {
      ...(doctorTypes === "analis" && doctorTypes === "fizioterapiya" ? (
        {
          title: "Status",
          dataIndex: "patientStatus",
        }
      ) : (
        <></>
      )),
    },
    {
      title: "Tarix",
      dataIndex: "action",
      render: (text, record) => (
        <Link to={`/poteintsSlinglePage/${record?.id._id}`}>
          <Button
            style={{
              background: "transparent",
              fontSize: "18px",
              border: "none",
              padding: "0px 10px",
              boxShadow: "none",
            }}
          >
            <IoEyeOutline style={{ marginBottom: "10px" }} />
          </Button>
        </Link>
      ),
    },
  ];

  const data =
    clientsForDoctorId
      ?.filter((asd) => asd.clientFullname?.toLowerCase().includes(query))
      ?.map((item, i) => ({
        key: i + 1,
        day: item.day,
        clientFullname: item.clientFullname,
        clientPhone: item.clientPhone,
        patientStatus: item.patientStatus,
        id: item,
      })) || [];

  const filterDay = data?.filter((asd) => {
    // Formatni "DD.MM.YYYY" formatga tekshiramiz
    const formattedDate1 = moment(asd?.day, "DD.MM.YYYY");
    if (
      formattedDate1.isValid() &&
      formattedDate1.format("DD.MM.YYYY") === filteredValue
    ) {
      return true;
    }
    // Formatni "D.M.YYYY" formatga tekshiramiz
    const formattedDate2 = moment(asd?.day, "D.M.YYYY");
    if (
      formattedDate2.isValid() &&
      formattedDate2.format("D.M.YYYY") === filteredValue
    ) {
      return true;
    }
    return false;
  });

  return (
    <div className="Search-Box">
      <div className="searchingBox">
        <Link onClick={handleGoBack}>
          <h4>
            <LeftOutlined />
            Medme
          </h4>
        </Link>
        {data && data.length >= 1 && (
          <div className="search">
            <div>
              <FaUsers />-{data.length}
            </div>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value.toLowerCase())}
              type="text"
              placeholder="Izlash..."
            />
            <SearchOutlined />
          </div>
        )}
      </div>

      {data && data.length === 0 ? (
        <div className="NoData">
          <div className="NoDataImg">
            <img src={imgNoData} alt="No Data" />
          </div>
        </div>
      ) : (
        <Table
          loading={!data}
          bordered={true}
          size="small"
          dataSource={filteredValue ? filterDay : data}
          columns={columns}
          pagination={false} // Set your desired page size here
          rowKey={"_id"}
        />
      )}
    </div>
  );
};

export default GetPatients;
