import { api } from "./api";

export const dailyReportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDailyReport: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `/dailyReport?startDate=${startDate}&endDate=${endDate}`,
        method: "POST",
      }),
    }),

    getDailyCost: builder.query({
      query: ({ startDate, endDate }) => ({
        url: `/dailyCost?startDate=${startDate}&endDate=${endDate}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetDailyReportQuery, useGetDailyCostQuery } = dailyReportApi;
