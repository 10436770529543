import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import './style.css'

function Home() {


  return (
    <div className="homeBoxCont">
      <div className="home">
        <div className="home__navbar">
          <div className="home__logo">
            <img src={logo} alt="" />
          </div>
          <div className="nav__btns nav__btnsShow">
            <Link to={`/login`}>Tizimga kirish</Link>
          </div>
        </div>
        <h1 className="home__title">
          <span>HAYOTLAR</span>
          <span>SIFATINI </span>
          <span>O'ZGARTIRISHGA</span>
          <span>HARAKAT</span>
          <span>QILAMIZ!</span>
        </h1>
        <div className="nav__btns nav__btnsHide">
          <Link to={`/login`}>Tizimga kirish</Link>
        </div>
      </div>

    </div>
  );
}

export default Home;
