import { api } from "./api";

export const BotApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllBot: builder.query({
      query: () => `/bot/clinicbots`,
      providesTags: ["GetBot"],
    }),

    addBot: builder.mutation({
      query: (body) => ({
        url: `/bot/clinicbots`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetBot"],
    }),

    updateBot: builder.mutation({
      query: ({ id, body }) => ({
        url: `/bot/clinicbots/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetBot"],
    }),

    deleteBot: builder.mutation({
      query: (id) => ({
        url: `/bot/clinicbots/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetBot"],
    }),

  }),
});


export const {
  useAddBotMutation,
  useDeleteBotMutation,
  useUpdateBotMutation,
  useGetAllBotQuery
} = BotApi;



