import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  volume: parseFloat(localStorage.getItem('volume')) || 1.0,
  isMuted: JSON.parse(localStorage.getItem('isMuted')) || false,
};

const audioSlice = createSlice({
  name: 'audio',
  initialState,
  reducers: {
    setVolume: (state, action) => {
      state.volume = action.payload;
      localStorage.setItem('volume', action.payload);
    },
    toggleMute: (state) => {
      state.isMuted = !state.isMuted;
      localStorage.setItem('isMuted', state.isMuted);
    },
  },
});

export const { setVolume, toggleMute } = audioSlice.actions;
export default audioSlice.reducer;
