import React, { useEffect, useState } from "react";
import "./style.css";
import { Button, message, TimePicker, Modal } from "antd";
import { Controller, useForm } from "react-hook-form";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { MdGeneratingTokens } from "react-icons/md";
import { TbNumber } from "react-icons/tb";
import { FaImage } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import moment from "moment";
import { SiNamecheap } from "react-icons/si";
import { DeleteOutlined } from "@ant-design/icons";
import { IoLocationOutline } from "react-icons/io5";
import LayoutWrapper from "../../../components/layout/Layout";
import LogoMedme from "../../../assets/img/telegramBot.png";
import {
  useDeleteBotMutation,
  useGetAllBotQuery,
  useAddBotMutation,
} from "../../../redux/telegraf";

const Setting = () => {
  const { control, register, handleSubmit, setValue, reset } = useForm();
  const [deleteBot] = useDeleteBotMutation();
  const [addBot] = useAddBotMutation();
  const { data: dateInfo } = useGetAllBotQuery();
  const clinicId = localStorage.getItem("doctorMongoId");
  const [workStartTime, setWorkStartTime] = useState(null);
  const [workEndTime, setWorkEndTime] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDays, setSelectedDays] = useState([]);
  const [inputErrors, setInputErrors] = useState({});
  const [phone, setPhone] = useState('');
  const days = ["Du", "Se", "Ch", "Pa", "Ju", "Sh", "Ya", "7-kun"];

  // Use state to store the info data
  const [info, setInfo] = useState([]);

  useEffect(() => {
    if (dateInfo) {
      const filteredInfo = dateInfo?.filter((i) => i.clinicId === clinicId);
      if (filteredInfo.length > 0) {
        setInfo(filteredInfo);
      }
    }
  }, [dateInfo, clinicId]);

  useEffect(() => {
    if (Array.isArray(info) && info.length > 0) {
      const botData = info[0];
      if (Object.keys(botData).length > 0) {
        Object.entries(botData).reduce((acc, [key, value]) => {
          setValue(key, value);

          return acc;
        }, {});
        setPhone(botData.phone);
        setWorkStartTime(moment(botData.workStartTime, "HH:mm"));
        setWorkEndTime(moment(botData.workEndTime, "HH:mm"));
        if (botData.weeks) {
          setSelectedDays(
            botData.weeks.includes("7-kun") ? days.slice(0, 7) : botData.weeks
          );
        }
      }
    }
  }, [info, setValue]);

  const handleDelete = async () => {
    try {
      const botId = info && info.length > 0 ? info[0]._id : null;

      if (!botId) {
        message.error("Bot ID mavjud emas.");
        return;
      }
      const response = await deleteBot(botId).unwrap();
      if (response) {
        message.success("Bot muvaffaqiyatli o'chirildi.");
        reset();
      }
    } catch (error) {
      console.error("Error deleting Telegraf URL:", error);
    }
  };

  const handleButtonClick = (day) => {
    if (day === "7-kun") {
      setSelectedDays([]);
    } else {
      setSelectedDays((prevSelectedDays) => {
        const newSelectedDays = prevSelectedDays.includes(day)
          ? prevSelectedDays.filter((d) => d !== day)
          : [...prevSelectedDays, day];

        // Toggle '7-kun' based on other selections
        if (newSelectedDays.length === 7) {
          return [...newSelectedDays, "7-kun"];
        } else {
          return newSelectedDays.filter((d) => d !== "7-kun");
        }
      });
    }
  };

  const onFinish = async (values) => {
    const newInputErrors = {};
    if (!values.name) newInputErrors.name = true;
    if (!values.botToken) newInputErrors.botToken = true;
    if (!values.clinicId) newInputErrors.clinicId = true;
    if (!values.clinicImageUrl) newInputErrors.clinicImageUrl = true;
    if (!values.address) newInputErrors.address = true;
    if (!values.phone) newInputErrors.phone = true;
    if (!values.gmail) newInputErrors.gmail = true;
    setInputErrors(newInputErrors);

    if (Object.keys(newInputErrors).length > 0) {
      message.error("Iltimos, barcha maydonlarni to'ldiring.");
      return;
    }

    values.workStartTime = workStartTime ? workStartTime.format("HH:mm") : "";
    values.workEndTime = workEndTime ? workEndTime.format("HH:mm") : "";

    // Remove '7-kun' from selectedDays before sending to the server
    values.weeks = selectedDays.filter((day) => day !== "7-kun");
    values.phone = phone;

    try {
      const response = await addBot(values);
      if (response) {
        message.success("Bot muvaffaqiyatli ishga tushdi.");
      }
    } catch (err) {
      console.error("Telegraf yaratishda xatolik:", err);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    await handleDelete();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const disabledMinutes = (hour) => {
    if (hour === workStartTime?.hour()) {
      return [...Array(workStartTime?.minute()).keys()];
    } else if (hour === workEndTime?.hour()) {
      return [...Array(60 - workEndTime?.minute() - 1).keys()].map(
        (minute) => minute + workEndTime?.minute() + 1
      );
    } else {
      return [];
    }
  };

  return (
    <LayoutWrapper>
      <div className="Setting">
        <form onSubmit={handleSubmit(onFinish)} className="SettingBox">
          <h3>Telegram API larini kiriting!</h3>
          <div style={{ width: "100%", display: "flex", gap: "6px" }}>
            <div className="form-group">
              <div className={`input-wrapper ${inputErrors.name ? "input-error" : ""}`}>
                <SiNamecheap />
                <input placeholder="Shifoxona nomi..." {...register("name")} />
              </div>
            </div>
            <div style={{ height: "35px" }} className="form-group">
              <div className={`input-wrapper ${inputErrors.phone ? "input-error" : ""}`} style={{ display: "flex", alignItems: "center" }}>
                <TfiHeadphoneAlt />
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <PhoneInput
                      defaultCountry="uz"
                      value={phone}
                      onChange={(value) => {
                        setPhone(value);
                        field.onChange(value);
                      }}
                      inputStyle={{ width: "100%" }}
                      className="PhoneInput"
                      placeholder="+998 xx xxx-xx-xx"
                    />
                  )}
                />
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className={`input-wrapper ${inputErrors.botToken ? "input-error" : ""}`}>
              <MdGeneratingTokens />
              <input placeholder="Bot token..." {...register("botToken")} />
            </div>
          </div>

          <div style={{ width: "100%", display: "flex", gap: "6px" }}>
            <div className="form-group">
              <div className={`input-wrapper ${inputErrors.clinicId ? "input-error" : ""}`}>
                <TbNumber />
                <input placeholder="Shifoxona ID..." defaultValue={clinicId || ""} {...register("clinicId")} />
              </div>
            </div>

            <div className="form-group">
              <div className={`input-wrapper ${inputErrors.gmail ? "input-error" : ""}`}>
                <BiLogoGmail />
                <input placeholder="Medme@gmail.com" {...register("gmail")} />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className={`input-wrapper ${inputErrors.clinicImageUrl ? "input-error" : ""}`}>
              <FaImage />
              <input placeholder="Shifoxona Image URL..." {...register("clinicImageUrl")} />
            </div>
          </div>

          <div className="form-group">
            <div className={`input-wrapper ${inputErrors.address ? "input-error" : ""}`}>
              <IoLocationOutline />
              <input placeholder="Manzil..." {...register("address")} />
            </div>
          </div>

          <div style={{ width: "100%", display: "flex", gap: "6px" }}>
            <div className="form-group" style={{ width: "100%" }}>
              <label>Boshlanish vaqti:</label>
              <TimePicker
                style={{ width: "100%", height: "30px" }}
                value={workStartTime}
                onChange={setWorkStartTime}
                format="HH:mm"
                showNow={false}
                minuteStep={1}
                hideDisabledOptions
                disabledTime={disabledMinutes}
              />
            </div>
            <div className="form-group" style={{ width: "100%" }}>
              <label>Tugash vaqti:</label>
              <TimePicker
                style={{ width: "100%", height: "30px" }}
                value={workEndTime}
                onChange={setWorkEndTime}
                format="HH:mm"
                showNow={false}
                minuteStep={1}
                hideDisabledOptions
                disabledTime={disabledMinutes}
              />
            </div>
          </div>


          <div
            className="form-group"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              margin: "0",
            }}
          >
            {days.map((day, index) => (
              <Button
                key={index}
                type={selectedDays.includes(day) ? "primary" : "default"}
                onClick={() => handleButtonClick(day)}
                style={{
                  width: "60px",
                  height: "30px",
                  margin: "4px",
                  fontSize: "16px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {day}
              </Button>
            ))}
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            <Button
              style={{
                marginTop: "0",
              }}
              htmlType="submit"
              className="sentBtnChat"
              type="primary"
            >
              Yuborish
            </Button>
            <Button
              onClick={showModal}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: "18px",
              }}
            >
              <DeleteOutlined />
            </Button>
          </div>
        </form>

        <Modal
          title="Botni o'chirish"
          // visible={isModalVisible}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Ha"
          cancelText="Yo'q"
        >
          <p>Botni o'chirishni xohlaysizmi?</p>
        </Modal>

        <div className="SettingBox">
          <div className="textSupportBox">
            <img width={200} src={LogoMedme} alt="" /> <br />
            Siz bemorlar uchun onlayn navbat olishi uchun Telegram botini ishga
            tushirishni istaysizmi? Botni ishga tushirish uchun quyidagi
            maydonlarni to'ldiring.
            <br />
            ————————————————————
            <br />
            Хотите запустить Telegram-бот для онлайн-очереди для пациентов?
            Заполните поля ниже, чтобы запустить бота.
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default Setting;
