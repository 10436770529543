import React, { useState, useRef } from "react";
import "./style.css";
import { PrinterOutlined, EyeOutlined } from "@ant-design/icons";
import { Table, Tabs, Popover, Button, Skeleton } from "antd";
import { Link, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useDispatch } from "react-redux";
import { useGetAmbulatorQuery } from "../../redux/ambulatorAPI";
import { useGetStoriesQuery } from "../../redux/clientStores";
import { PhoneNumberFormat } from "../../hook/NumberFormat";
import { setInfo } from "../../redux/recordList/recordList";
import RecordList from "../../components/checkLists/patientRecordList/RecordList";
import { useGetUsersQuery } from "../../redux/clientApi";
import { CountingDay } from "../../hook/ColculateDay";
import { ColculateBorthday } from "../../hook/ColculateBorthday";
import { useGetRoomStoriesQuery } from "../../redux/roomStoriesApi";
import { useGetTerapiyaQuery } from "../../redux/terapiyaApi";
import { capitalizeFirstLetter } from "../../hook/CapitalizeFirstLitter";
import LayoutWrapper from "../layout/Layout";
import ImgEr2 from "./img/patient.png";
import ImgEr1 from "./img/girl.png";

const PoteintsSlinglePage = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [countDoctors, setCountDoctors] = useState("");

  // RTK QUERIES
  let { data: datas } = useGetRoomStoriesQuery();
  let { data: allClients } = useGetUsersQuery();
  let { data: Data } = useGetTerapiyaQuery();
  let { data: stories } = useGetStoriesQuery();
  let { data: ambulator } = useGetAmbulatorQuery();

  let roomData = datas?.innerData || [];
  let clients = allClients?.data || [];
  let dataTerapiya = Data?.innerData || [];
  let dataStories = stories?.innerData || [];
  let storiesData = ambulator?.innerData || [];

  let filterStores = dataStories?.filter(
    (i) => i?._id === id && i?.view === true || i?.clientID === id
  );

  let userById = dataStories?.find((i) => i?._id === id || i?.clientID === id);

  let userInfo = clients?.find((i) => i?._id === userById?.clientID) || {};

  let storesLength = dataStories?.filter((i) => i?._id === id);
  console.log(filterStores);

  const expandedRowRender = () => {
    const columns = [
      {
        title: "Dorilar ro'yxati",
        dataIndex: "date",
        key: "date",
      },
    ];

    const data = Array.from(Object.values(filterStores), (item, i) => ({
      key: i.toString(),
      name: item?.retseptList,
    }));

    return (
      <Table
        size="small"
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const popoverContent = (chopId) => {
    if (!chopId) {
      return null; // Return null if chopId is null or undefined
    }

    const dataRes = dataTerapiya?.filter((item) => item.clientID === chopId);

    const transformedData = dataRes
      ?.map((item) => {
        const newObj = {};
        Object.entries(item).forEach(([key, value]) => {
          if (
            key !== "banka" &&
            value === true &&
            !["__v", "_id", "day", "doctorIdNumber", "clientID"].includes(key)
          ) {
            newObj[key] = capitalizeFirstLetter(key);
          } else if (key === "banka" && value > 0) {
            newObj[key] = "Banka " + value + " ta";
          }
        });

        return newObj;
      })
      .filter((obj) => Object.keys(obj).length !== 0);

    const divs = transformedData.map((item, index) => (
      <div key={index}>
        {Object.entries(item).map(([key, value]) => (
          <div key={key}>{value}</div>
        ))}
      </div>
    ));

    return (
      <div className="Popover">
        <div>
          <span>Fizioterapiya ko'riglari!</span>
          {divs}
        </div>
      </div>
    );
  };

  const Style = {
    display: "flex",
    flexDirection: "column;",
    alignItems: "center",
    fontSize: "16px",
  };

  const columns = [
    {
      title: "Sana",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Yo'naltirildi",
      dataIndex: "doctorType",
      key: "doctorType",
    },
    {
      title: "Doktor",
      dataIndex: "version",
      key: "version",
    },
    {
      title: "Status",
      dataIndex: "upgradeNum",
      key: "upgradeNum",
    },
    {
      title: "Chop etish",
      dataIndex: "chop",
      key: "chop",
      render: (text, record) => (
        <>
          {record.chop.doctorType === "Labarant" ||
            record.chop.doctorType === "Fizioterapiya" ? (
            <>
              {record.chop.doctorType === "Labarant" ? (
                <Link to={`/biochemicalAnalysis/${record.chop._id}`}>
                  <Button style={Style}>
                    <EyeOutlined />
                  </Button>
                </Link>
              ) : (
                <Popover
                  trigger="hover"
                  content={popoverContent(record.chop._id)}
                >
                  <Button style={Style}>
                    <EyeOutlined />
                  </Button>
                </Popover>
              )}
            </>
          ) : (
            <ReactToPrint
              trigger={() => (
                <Button
                  style={Style}
                  onFocus={() => dispatch(setInfo(record.chop))}
                >
                  <PrinterOutlined />
                </Button>
              )}
              content={() => componentRef.current}
            />
          )}
        </>
      ),
    },
  ];

  let data = [];

  if (filterStores) {
    data = Array.from(
      Object.values(
        filterStores?.filter((asd) => asd?.doctorType?.includes(countDoctors))
      ),
      (item, i) => ({
        key: i.toString(),
        name: item?.day,
        doctorType: item?.doctorType,
        version: item?.doctorFullname,
        upgradeNum: item.patientStatus,
        chop: item,
      })
    );
  }

  // Agar userInfo lastname xususiyati oxirgi xarifini tekshirish
  const ChackLasname = () =>
    userInfo?.lastname?.endsWith("a") ? "Ayol" : "Erkak";

  const doctorTypeCount = {};
  filterStores.forEach((appointment) => {
    const doctorType = appointment.doctorType;
    if (doctorType) {
      doctorTypeCount[doctorType] = (doctorTypeCount[doctorType] || 0) + 1;
    }
  });
  const doctorsLength = Object.keys(doctorTypeCount).map((doctorType) => ({
    doctorType: doctorType,
    count: doctorTypeCount[doctorType],
  }));

  // -------------------------

  const columnsLichen = [
    {
      title: "№ Xona/Qavati",
      dataIndex: "xona",
    },
    {
      title: "Doktor",
      dataIndex: "doctor",
      render: (doctor) => (
        <>
          <div className="singleRoom_DoctorTypr">
            {capitalizeFirstLetter(doctor?.doctorType)}
          </div>
          <div className="singleRoom_DoctorTypr">{doctor?.doctorFullname}</div>
        </>
      ),
    },
    {
      title: "№ Tel",
      dataIndex: "tel",
      render: (tel) => PhoneNumberFormat(tel),
    },
    {
      title: "Kun",
      dataIndex: "doctor",
      render: (doctor) => (
        <>
          {doctor?.endDay === "0" ? (
            <>
              <div className="singleRoom_DoctorTypr">{doctor?.startDay}</div>
              <div className="singleRoom_DoctorTypr">
                {CountingDay(doctor?.startDay)}
              </div>
            </>
          ) : (
            <>
              <div className="singleRoom_DoctorTypr">{doctor?.startDay}</div>
              <div className="singleRoom_DoctorTypr">{doctor?.endDay}</div>
            </>
          )}
        </>
      ),
    },
    {
      title: "Faol",
      dataIndex: "doctor",
      render: (doctor) => (
        <>
          <div className="center">
            <div className={doctor.active === true ? "pulse" : "pulseRed"}></div>
          </div>
        </>
      ),
    },
  ];

  let room = roomData?.filter((i) => i?.clientMongooseId === userInfo._id);

  let dataLichen = [];
  if (room) {
    dataLichen = Object.values(room).map((item, i) => ({
      key: item.clientMongooseId,
      xona: item?.roomNumber + "/" + item?.floor,
      doctor: item,
      tel: item?.clientPhone,
      startDay: item?.startDay,
      active: item?.active,
    }));
  }

  // -------------------------

  const columnsAmblator = [
    {
      title: "Bemor",
      dataIndex: "fullName",
    },

    {
      title: "Tarix",
      dataIndex: "tarix",
      render: (text, record) => (
        <Link to={`/ambulatoriya-signle/${record?._id}`}>
          <Button className="EyeOutlined" icon={<EyeOutlined />} />
        </Link>
      ),
    },
  ];

  return (
    <LayoutWrapper>
      <div className="global-styles">
        <span className="main_bg"></span>
        <div className="containerPotient">
          {/* <div className="brandLogo">
            <div>
              <img src={Med} alt="logo" width="40px" height="40px" />
              <span>Yasmina medical servis</span>
            </div>
            <button onClick={handleGoBack}>
              <CiLogout />
            </button>
          </div> */}
          <div className="mainUser">
            <div className="UserSIGN">
              <section className="userProfile">
                <div className="profile">
                  <figure>
                    <img
                      src={userInfo?.lastname?.endsWith("a") ? ImgEr1 : ImgEr2}
                      alt="profile"
                      width="250px"
                      height="250px"
                    />
                  </figure>
                </div>
              </section>

              <div className=" cardSingeUs">
                <h1 className="headingSin">Bemor haqida ma'lumot:</h1>
                <div>
                  <b>Telefon:</b>
                  <p>+998 {PhoneNumberFormat(userInfo?.phone)}</p>
                </div>

                <div>
                  <b>Manzil:</b>
                  <p>
                    {userInfo?.address?.region} {userInfo?.address?.district}{" "}
                    <br /> {userInfo?.address?.quarter}
                  </p>
                </div>

                <h1 className="headingSin">Asosiy ma'lumotlar:</h1>
                <div className="gender">
                  <b>Yoshi:</b>
                  <p>{ColculateBorthday(userInfo?.year)}</p>
                </div>
                <div className="gender">
                  <b>Jinsi:</b>
                  {ChackLasname()}
                </div>
              </div>
            </div>

            <div className="SigFooter">
              <section className="userDetails ">
                <div className="userNameSi">
                  <>
                    {data === 0 ? (
                      <Skeleton active paragraph={{ rows: 0 }} />
                    ) : (
                      <h2 className="nameSin">
                        {capitalizeFirstLetter(userInfo?.firstname)}{" "}
                        {capitalizeFirstLetter(userInfo?.lastname)}
                      </h2>
                    )}
                  </>

                  <div className="rank">
                    <b>Tashrifi - </b>
                    <br />
                    <span>{storesLength?.length}</span>
                  </div>
                </div>
                <>
                  {data === 0 ? (
                    <Skeleton active paragraph={{ rows: 0 }} />
                  ) : (
                    <div className="DocLenth">
                      {doctorsLength?.map((value, inx) => (
                        <div
                          onClick={() => setCountDoctors(value?.doctorType)}
                          key={inx}
                        >
                          {value?.doctorType}-{value?.count}
                        </div>
                      ))}
                    </div>
                  )}
                </>
              </section>
              <section className="bgColor timeline_about">
                <Tabs>
                  <Tabs.TabPane defaultActiveKey="0" tab="Tarix" key={0}>
                    {!data ? (
                      <Skeleton active />
                    ) : (
                      <Table
                        rowKey={"_id"}
                        columns={columns}
                        expandable={
                          data.doctorType === "doctor" && { expandedRowRender }
                        }
                        dataSource={data}
                        size="small"
                        pagination={false}
                        loading={!data.length}
                        bordered={true}
                      />
                    )}
                  </Tabs.TabPane>

                  <Tabs.TabPane defaultActiveKey="1" tab="Davolanish" key={1}>
                    <Table
                      columns={columnsLichen}
                      dataSource={dataLichen}
                      size="small"
                      bordered={true}
                      pagination={false}
                      rowKey={"_id"}
                    />
                  </Tabs.TabPane>
                  <Tabs.TabPane
                    defaultActiveKey="2"
                    tab="Ambulator karta"
                    key={2}
                  >
                    <Table
                      loading={!storiesData}
                      bordered={true}
                      size="small"
                      columns={columnsAmblator}
                      dataSource={storiesData}
                      pagination={false}
                      rowKey={"_id"}
                    />
                  </Tabs.TabPane>
                </Tabs>
              </section>
            </div>
          </div>
        </div>
        <div style={{ display: "none" }}>
          <RecordList componentRef={componentRef} />
        </div>
      </div>
    </LayoutWrapper>
  );
};
export default PoteintsSlinglePage;
