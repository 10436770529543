import React, { useEffect, useState } from "react";
import { Col, Table, Form, Input, Row, message, Button, Select } from "antd";
import { PhoneInput } from "react-international-phone";
import OtpInput from "react-otp-input";
import Selectt from "react-select";
import { useNavigate } from "react-router-dom";
import {
  EyeOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";
import { useCreateDoctorMutation } from "../../../../redux/doctorApi";
import { biokimyoviyTaxliliData } from "../../../../utils/analisData";
import { NumberFormat } from "../../../../hook/NumberFormat";
import "../style.css";
import axios from "../../../../api";
import { doctors } from "./DataProfessions";

const { Option, OptGroup } = Select;
const AddDoctors = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [phone, setPhone] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [address, setAddress] = useState({
    region: "",
    district: "",
    quarter: "",
  });

  const navigate = useNavigate();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [experience, setExperience] = useState("");
  const [feesPerCunsaltation, setFeesPerCunsaltation] = useState("");
  const [docORrecep] = useState("doctor");
  const [userType] = useState("doctor");
  const [salaryType, setSalaryType] = useState("");
  const [percent, setPercent] = useState("");
  const [idNumber, setIdNumber] = useState("");
  const [secondPrice, setSecondPrice] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [roomPercent, setRoomPercent] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [extraClass, setExtraClass] = useState(false);
  const [extraClass2, setExtraClass2] = useState(false);
  const [extraClass3, setExtraClass3] = useState(false);
  const [spec, setSpec] = useState({ name: "", value: "" });
  const [extraSpec, setExtraSpec] = useState({ name: "", value: "" });
  const [regularSalary, setRegularSalary] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [extraPrice, setExtraPrice] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [extraData, setExtraData] = useState([]);
  const [tableData, setTableData] = useState([]); // Yangi state
  const [extraPercent, setExtraPercent] = useState("");
  const [onceTreatmentPercent, setOnceTreatmentPercent] = useState(0);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };

  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));
  }, []);

  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };

  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  const [prices, setPrices] = useState([]);

  const [currentInput, setCurrentInput] = useState({
    name: "",
    price: "",
    extraPrice: "",
    analis: "",
    norma: "",
    siBirlik: "",
    gl: "",
    gr: "",
    ml: "",
  });

  const handleChange = (value) => {
    const selectedRow = filteredData
      .flatMap((group) => group.options)
      .find((option) => option.name === value);

    setCurrentInput((prev) => ({
      ...prev,
      price: extraPrice || "",
      name: selectedRow?.name || prev.name,
      analis: selectedRow?.analis || "",
      norma: selectedRow?.norma || "",
      siBirlik: selectedRow?.siBirlik || "",
      gl: selectedRow?.gl || "",
      gr: selectedRow?.gr || "",
      ml: selectedRow?.ml || "",
    }));

    setSelectedValue(selectedRow);
  };

  const handleAdd = () => {
    setPrices([
      ...prices,
      { ...currentInput, key: prices.length, price: extraPrice },
    ]);

    setCurrentInput({
      name: "",
      price: "",
      extraPrice: "",
      analis: "",
      norma: "",
      siBirlik: "",
      gl: "",
      gr: "",
      ml: "",
    });
    setExtraPrice("");

    setSelectedValue(null);
  };

  const handleDelete = (key) => {
    setPrices(data.filter((item) => item.key !== key));
  };

  const [createDoctor] = useCreateDoctorMutation();

  const AllInfo = {
    idNumber: idNumber.toLowerCase(),
    firstName,
    lastName,
    middleName,
    phone,
    roomNumber,
    address,
    login,
    password,
    specialization: spec.name,
    experience,
    feesPerCunsaltation: +feesPerCunsaltation,
    secondPrice: +secondPrice,
    docORrecep,
    userType,
    salaryType,
    dateOfBirth,
    roomPercent: +roomPercent,
    type: spec.value,
    percent: +percent,
    salary: 0,
    extraTreatment: prices,
    extraSpecialization: tableData,
    regularSalary: +regularSalary,
    onceTreatmentPercent: +onceTreatmentPercent,
  };

  const handleFinish = async () => {
    setIsLoading(true);

    await createDoctor(AllInfo)
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          navigate("/xodimlar");
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((err) => {
        console.log("err=>>>", err);
        message.error("Failed to create doctor.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // ------------yyy-mm-dd------------
  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateRegex.test(inputValue)) {
      console.log("Valid date!");
    } else {
      console.log("Invalid date!");
    }
    setDateOfBirth(inputValue);
  };

  const handleChangeSelect = (value, option) => {
    setSpec({ name: value.label, value: value.value });
  };

  function flattenDoctors(doctors) {
    return (
      doctors?.flatMap((grp) =>
        grp?.options.map((option) => ({
          value: option.value,
          label: option.name,
        }))
      ) ?? []
    );
  }

  const columns = [
    {
      title: "Nomi",
      dataIndex: "name",
      key: "name",
    },
    ...(spec.value === "analis"
      ? [
        {
          title: "Normasi",
          dataIndex: "norma",
          key: "norma",
        },
      ]
      : []),
    {
      title: "Yangi mijozlar uchun",
      dataIndex: "price",
      key: "price",
      render: (text) => `${NumberFormat(text)} so'm`,
    },
    ...(showInput
      ? [
        {
          title: "Davolanuvchi bemorlar uchun",
          dataIndex: "extraPrice",
          key: "extraPrice",
          render: (text) => `${text} so'm`,
        },
      ]
      : []),
    {
      title: "O'chirish",
      key: "action",
      render: (text, record) => (
        <Button type="link" onClick={() => handleDelete(record.key)}>
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  const data = prices.map((price, index) => ({
    key: `price-${index}`,
    name: price.name,
    price: price.price,
    extraPrice: price.extraPrice,
    norma: price.norma,
    analis: price.analis,
  }));

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const filteredData = biokimyoviyTaxliliData?.map((group) => ({
    ...group,
    options: group.options.filter((option) =>
      option.name.toLowerCase().includes(searchValue.toLowerCase())
    ),
  }));

  const flattenDoctorsExtra = (doctors) => {
    return (
      doctors?.flatMap((grp, index) =>
        grp?.options.map((option) => ({
          key: `${option.value}-${index}`, // Noyob key hosil qilish
          value: option.value,
          label: option.name,
        }))
      ) ?? []
    );
  };

  const handleChangeExtraSelect = (selectedOption) => {
    setExtraSpec(selectedOption);
  };

  const handleAddExtra = () => {
    if (extraSpec && extraPrice) {
      const newData = {
        key: `${extraSpec.value}-${extraData.length + 1}`, // Unique key for each row
        specialization: extraSpec.label,
        price: extraPrice,
        percent: extraPercent,
      };

      const updatedData = [...extraData, newData];
      setExtraData(updatedData);

      // Update tableData state with the new row
      setTableData(updatedData);

      // Reset the form fields
      setExtraSpec(null);
      setExtraPrice("");
    }
  };

  const handleDeleteExtra = (key) => {
    const newData = extraData.filter((item) => item.key !== key);
    setExtraData(newData);

    // Update tableData state
    setTableData(newData);
  };

  const extraColumns = [
    {
      title: "Mutaxassislik",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Muolaja Narxi",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Amallar",
      key: "actions",
      render: (text, record) => (
        <Button type="link" onClick={() => handleDeleteExtra(record.key)}>
          O'chirish
        </Button>
      ),
    },
  ];

  return (
    <Form layout="vertical" onFinish={handleFinish} className="FormApply">
      <Row className="Row">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Col style={{ width: "300px" }} className="Col-Form">
            <Form.Item
              label="Mutaxassislik"
              name="specialization"
              rules={[{ required: true, message: "Mutaxassislikni tanlang!" }]}
            >
              <Selectt
                onChange={handleChangeSelect}
                placeholder="Выберите специализацию"
                options={flattenDoctors(doctors)}
              />
            </Form.Item>
          </Col>

          <Button
            type="primary"
            onClick={handleToggle}
            style={{
              height: "38px",
              width: "30px",
              marginTop: "6px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isExpanded ? <MinusOutlined /> : <PlusOutlined />}
          </Button>
        </div>

        <Col className="Col-Form">
          <Form.Item
            label="Ism"
            name="firstName"
            rules={[
              { required: true, message: "Ismni kiriting!" },
              {
                min: 2,
                message: "Ism kamida 3 harfdan iborat bo'lishi kerak!",
              },
            ]}
          >
            <Input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              placeholder="Имя"
            />
          </Form.Item>
        </Col>

        <div className="salaryBox">
          <Col>
            <Form.Item
              label="Familiya"
              name="lastName"
              rules={[
                { required: true, message: "Familiyani kiriting!" },
                {
                  min: 2,
                  message: "Familiya kamida 3 harfdan iborat bo'lishi kerak!",
                },
              ]}
            >
              <Input
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                type="text"
                placeholder="Фамилия"
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Otasini ismi"
              name="middleName"
              rules={[
                { required: true, message: "Otasini ismini kiriting!" },
                {
                  min: 2,
                  message:
                    "Otasini ismi kamida 3 harfdan iborat bo'lishi kerak!",
                },
              ]}
            >
              <Input
                value={middleName}
                onChange={(e) => setMiddleName(e.target.value)}
                type="text"
                placeholder="Отчество"
              />
            </Form.Item>
          </Col>
        </div>
      </Row>

      <Row className="Row">
        <Col className="Col-Form">
          <Form.Item
            label="Shaxsiy raqami"
            name="ID number"
            rules={[
              { required: true, message: "Shaxsiy raqamni kiriting!" },
              {
                len: 9,
                message:
                  "Shaxsiy raqam  2 ta harf 7 ta raqam belgidan iborat bo'lishi kerak!",
              },
              {
                pattern: /^[A-Za-z]{2}\d{7}$/,
                message: "To'g'ri format: ab1234567",
              },
            ]}
          >
            <OtpInput
              inputStyle={{
                border: "1px solid #ddd",
                margin: "2px",
                borderRadius: "5px",
                width: "32px",
                height: "32px",
              }}
              value={idNumber}
              onChange={setIdNumber}
              numInputs={9}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              placeholder="ab1234567"
            />
          </Form.Item>
        </Col>

        <Col className="Col-Form">
          <Form.Item
            label="Telefon raqami"
            name="phone"
            rules={[{ required: true, message: "Telefon raqamni kiriting!" }]}
          >
            <PhoneInput
              defaultCountry="uz"
              value={phone}
              onChange={(e) =>
                e.length === 13 && setPhone(e.replace("+998", ""))
              }
              inputStyle={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <div className="Col-Form_Box">
          <Col className={`${extraClass ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item
              label="Viloyat"
              name={["address", "region"]}
              rules={[{ required: true, message: "Viloyat?" }]}
            >
              <Selectt
                onMenuOpen={() => setExtraClass(true)}
                onMenuClose={() => setExtraClass(false)}
                onChange={(e) => {
                  getDistricts(e.regionID);
                  setAddress((address) => ({
                    ...address,
                    region: e.value,
                  }));
                }}
                options={regions}
                placeholder="Oбласть"
              />
            </Form.Item>
          </Col>

          <Col className={`${extraClass2 ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item
              label="Tuman"
              name={["address", "district"]}
              rules={[{ required: true, message: "Tuman?" }]}
            >
              <Selectt
                onMenuOpen={() => setExtraClass2(true)}
                onMenuClose={() => setExtraClass2(false)}
                onChange={(e) => {
                  getQuarters(e.districtID);
                  setAddress((address) => ({
                    ...address,
                    district: e.value,
                  }));
                }}
                options={districts}
                placeholder="Pайон"
              />
            </Form.Item>
          </Col>

          <Col className={`${extraClass3 ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item
              label="MFY"
              name={["address", "quarter"]}
              rules={[{ required: true, message: "MFY?" }]}
            >
              <Selectt
                onMenuOpen={() => setExtraClass3(true)}
                onMenuClose={() => setExtraClass3(false)}
                options={quarters}
                onChange={(e) =>
                  setAddress((address) => ({
                    ...address,
                    quarter: e.value,
                  }))
                }
                placeholder="МФЙ"
              />
            </Form.Item>
          </Col>
        </div>
      </Row>

      <Row className="Row">
        <Col className="Col-Form">
          <Form.Item
            label="Tug'ilgan sana:"
            name="year"
            rules={[{ required: true, message: "Tug'ilgan sanani kiriting!" }]}
          >
            <Input
              type="date"
              value={dateOfBirth}
              onChange={handleDateChange}
              placeholder="год/месяц/день"
            />
          </Form.Item>
        </Col>

        <div className="salaryBox">
          <Col>
            <Form.Item
              label="Login"
              name="login"
              rules={[{ required: true, message: "Loginni kiriting!" }]}
            >
              <Input
                value={login}
                onChange={(e) => setLogin(e.target.value)}
                type="text"
                placeholder="Логин"
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Paroli"
              name="password"
              rules={[{ required: true, message: "Parolni kiriting!" }]}
            >
              <Input
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                placeholder="Пароль"
              />
            </Form.Item>
          </Col>
        </div>

        <div className="salaryBox">
          <Col>
            <Form.Item
              label="Doktor maoshi"
              name="doctor salary"
              rules={[{ required: true, message: "Maosh turini tanlang!" }]}
            >
              <div className="docORrecep checkList">
                <label className="containerChe">
                  Foiz
                  <input
                    value="percent"
                    onChange={(e) => setSalaryType(e.target.value)}
                    name="salaryType"
                    type="radio"
                    checked={salaryType === "percent"}
                  />
                  <span className="checkmark"></span>
                </label>

                <label className="containerChe">
                  Oylik
                  <input
                    value="salary"
                    onChange={(e) => setSalaryType(e.target.value)}
                    name="salaryType"
                    type="radio"
                    checked={salaryType === "salary"}
                  />
                  <span className="checkmark"></span>
                </label>
              </div>
            </Form.Item>
          </Col>

          {salaryType === "percent" ? (
            <Col>
              <Form.Item label="Foiz" name="percent">
                <Input
                  value={percent}
                  onChange={(e) => setPercent(e.target.value)}
                  type="number"
                  placeholder="Процент"
                />
              </Form.Item>
            </Col>
          ) : (
            <Col>
              <Form.Item label="Oylik" name="salary">
                <Input
                  value={regularSalary}
                  onChange={(e) => setRegularSalary(e.target.value)}
                  type="text"
                  placeholder="Регулярная зарплата"
                />
              </Form.Item>
            </Col>
          )}
        </div>
      </Row>

      <Row className="Row">
        <div className="salaryBox">
          <Col>
            <Form.Item
              label="Davolash foizi"
              name="roomPercent"
              rules={[
                { required: true, message: "Davolash foizini kiriting!" },
                {
                  min: 0,
                  max: 100,
                  message: "Foiz 0 dan 100 gacha bo'lishi kerak!",
                },
              ]}
            >
              <Input
                maxLength={9}
                value={+roomPercent}
                onChange={(e) => setRoomPercent(e.target.value)}
                type="number"
                placeholder="Процент лечения"
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Tajriba"
              name="experience"
              rules={[{ required: true, message: "Tajribani kiriting!" }]}
            >
              <Input
                value={experience}
                onChange={(e) => setExperience(e.target.value)}
                type="text"
                placeholder="Опыт"
              />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item
              label="Xona raqami"
              name="roomNumber"
              rules={[{ required: true, message: "Xona raqamini kiriting!" }]}
            >
              <Input
                value={roomNumber}
                onChange={(e) => setRoomNumber(e.target.value)}
                type="text"
                placeholder="Номер комнаты"
              />
            </Form.Item>
          </Col>
        </div>

        <div className="PriceBox_doctor">
          <Form.Item
            label="Qabul narxi"
            name="feesPerCunsaltation"
            rules={[
              {
                required: !(
                  spec?.value === "massaj" ||
                  spec?.value === "fizioterapiya" ||
                  spec?.value === "analis" ||
                  spec?.value === "uzi" ||
                  spec?.value === "stomatolog"
                ),
                message: "Birlamchi to'lovni kiriting!",
              },
            ]}
          >
            <Input
              value={feesPerCunsaltation}
              onChange={(e) => setFeesPerCunsaltation(e.target.value)}
              type="number"
              placeholder="Сумма за консультацию"
              disabled={
                spec?.value === "massaj" ||
                spec?.value === "fizioterapiya" ||
                spec?.value === "analis" ||
                spec?.value === "uzi" ||
                spec?.value === "stomatolog"
              }
            />
          </Form.Item>

          <Form.Item label="Ikkilamchi qabul" name="secondPrice">
            <Input
              value={secondPrice}
              onChange={(e) => setSecondPrice(e.target.value)}
              type="number"
              placeholder="Сумма за консультацию"
              disabled={
                spec?.value === "massaj" ||
                spec?.value === "fizioterapiya" ||
                spec?.value === "analis" ||
                spec?.value === "uzi" ||
                spec?.value === "stomatolog"
              }
            />
          </Form.Item>
        </div>

        <Col>
          <Form.Item
            label="Kunduzgi muolajadan beriladigan foiz"
            name="onceTreatmentPercent"
          >
            <Input
              value={onceTreatmentPercent}
              onChange={(e) => setOnceTreatmentPercent(e.target.value)}
              type="number"
              placeholder="0"
            />
          </Form.Item>
        </Col>
      </Row>

      {(spec.value === "massaj" ||
        spec.value === "fizioterapiya" ||
        spec.value === "analis" ||
        spec?.value === "stomatolog" ||
        spec.value === "uzi") && (
          <>
            <h5 style={{ textAlign: "center", color: "grey" }}>
              {capitalizeFirstLetter(spec.name)} ga tegishli muolajalarni kiriting
            </h5>
            <Row gutter={16} style={{ marginBottom: "16px" }}>
              <Col span={8}>
                {spec.value === "analis" ? (
                  <Form.Item
                    name="subject"
                    label="Muolaja tanlang"
                    rules={[
                      { required: true, message: "Iltimos, fanni tanlang!" },
                    ]}
                  >
                    <Select
                      placeholder="Выберите анализ"
                      style={{ width: "100%" }}
                      onChange={handleChange}
                      onSearch={handleSearch}
                      showSearch
                      value={selectedValue?.name || ""} // Show the name of the selected option
                    >
                      {filteredData?.map((group) => (
                        <OptGroup key={group.label} label={group.label}>
                          {group.options.map((option) => (
                            <Option key={option.name} value={option.name}>
                              {option.name}
                            </Option>
                          ))}
                        </OptGroup>
                      ))}
                    </Select>
                  </Form.Item>
                ) : (
                  <Form.Item label="Muolaja nomi">
                    <Input
                      value={currentInput.name}
                      onChange={(e) =>
                        setCurrentInput({ ...currentInput, name: e.target.value })
                      }
                      placeholder="Название процедуры"
                    />
                  </Form.Item>
                )}
              </Col>

              <Col span={8}>
                <Form.Item label="Muolaja narxi">
                  <Input
                    value={extraPrice}
                    onChange={(e) => setExtraPrice(e.target.value)}
                    placeholder="Введите цену"
                  />
                </Form.Item>
              </Col>
              <div className="Col-Form_Box">
                <Col span={showInput ? 16 : 4.5}>
                  <Form.Item
                    label={showInput ? "Davolanyotkan bemor uchun" : " "}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {showInput ? (
                        <Button
                          onClick={() => {
                            setShowInput(false);
                            handleChange("extraPrice", "");
                          }}
                          type="danger"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <CloseCircleOutlined />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => setShowInput(true)}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <EyeOutlined />
                        </Button>
                      )}
                      {showInput && (
                        <Input
                          value={currentInput.extraPrice}
                          onChange={(e) =>
                            handleChange("extraPrice", e.target.value)
                          } // Correctly handle 'extraPrice'
                          placeholder="Введите цену"
                        />
                      )}
                    </div>
                  </Form.Item>
                </Col>

                <Form.Item style={{ width: "100%" }} label=" ">
                  <Button
                    style={{ width: "100%" }}
                    type="primary"
                    onClick={handleAdd}
                  >
                    Qo'shish
                  </Button>
                </Form.Item>
              </div>
            </Row>
          </>
        )}

      {isExpanded && (
        <>
          <h5 style={{ textAlign: "center", color: "grey" }}>
            Ikkinchi muolajalarni kiriting
          </h5>
          <Row className="Row">
            <Col className="Col-Form">
              <Form.Item label="Mutaxassislik">
                <Selectt
                  onChange={handleChangeExtraSelect}
                  options={flattenDoctorsExtra(doctors)}
                  value={extraSpec}
                  placeholder="Выберите специализацию"
                />
              </Form.Item>
            </Col>

            <div className="salaryBox">
              <Col>
                <Form.Item label="Muolaja narxi">
                  <Input
                    value={extraPrice}
                    onChange={(e) => setExtraPrice(e.target.value)}
                    placeholder="Введите цену"
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label="Doktor foizi">
                  <Input
                    value={extraPercent}
                    onChange={(e) => setExtraPercent(e.target.value)}
                    placeholder="Введите процент доктора"
                  />
                </Form.Item>
              </Col>
            </div>
            <Col className="Col-Form">
              <Form.Item style={{ width: "100%" }} label=" ">
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  onClick={handleAddExtra}
                >
                  Qo'shish
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      {data.length !== 0 && (
        <>
          <Table
            columns={columns}
            dataSource={data}
            bordered
            size="small"
            pagination={false}
            className="price-details"
            rowKey="key" // Set rowKey to the key field
          />
          <br />
        </>
      )}

      {extraData.length !== 0 && (
        <>
          <Table
            columns={extraColumns}
            dataSource={extraData}
            bordered
            size="small"
            pagination={false}
            className="price-details"
            rowKey="key"
          />
          <br />
        </>
      )}

      <Row gutter={24} className="Row">
        <Col span={24}>
          <Form.Item>
            <Button
              className="btn btn-primary"
              type="primary"
              htmlType="submit"
              loading={isLoading}
            >
              Saqlash
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddDoctors;
