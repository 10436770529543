import React, { useState, useEffect } from "react";
import { Layout, Menu, theme, Input } from "antd";
import { FaVial } from "react-icons/fa";
import { SearchOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { GiDroplets, GiKidneys, GiStomach } from "react-icons/gi";
import LayoutWrapper from "../layout/Layout";
import './style.css';

//=========================Biohimik=qon=taxlili==================================
import BMICalculator from "../analizCalculators/BioKimyoviy/BMICalculator";
import HASBLEDCalculator from "../analizCalculators/BioKimyoviy/HASBLEDCalculator";
import ApacheIICalculator from "../analizCalculators/BioKimyoviy/ApacheIICalculator";
import AAGradientCalculator from "../analizCalculators/BioKimyoviy/AAGradientCalculator";
import AnionGapCalculator from "../analizCalculators/BioKimyoviy/AnionGapCalculator";
import BSACalculator from "../analizCalculators/BioKimyoviy/BSACalculator";
import BicarbonateDeficitCalculator from "../analizCalculators/BioKimyoviy/BicarbonateDeficitCalculator";
import CHA2DS2VAScCalculator from "../analizCalculators/BioKimyoviy/CHA2DS2VAScCalculator";
import CalvertCalculator from "../analizCalculators/BioKimyoviy/CalvertCalculator";
import ChildPughCalculator from "../analizCalculators/BioKimyoviy/ChildPughCalculator";
import CorrectedCalciumCalculator from "../analizCalculators/BioKimyoviy/CorrectedCalciumCalculator";
import CorrectedPhenytoinCalculator from "../analizCalculators/BioKimyoviy/CorrectedPhenytoinCalculator";
import CorrectedSodiumCalculator from "../analizCalculators/BioKimyoviy/CorrectedSodiumCalculator";
import CreatinineClearanceCalculator from "../analizCalculators/BioKimyoviy/CreatinineClearanceCalculator";
import DdimerAdjustedForAgeCalculator from "../analizCalculators/BioKimyoviy/DdimerAdjustedForAgeCalculator";
import FENaCalculator from "../analizCalculators/BioKimyoviy/FENaCalculator";
import GICalculator from "../analizCalculators/BioKimyoviy/GICalculator";
import MAPCalculator from "../analizCalculators/BioKimyoviy/MAPCalculator";
import MDRDCalculator from "../analizCalculators/BioKimyoviy/MDRDCalculator";
import OICalculator from "../analizCalculators/BioKimyoviy/OICalculator";
import ParklandCalculator from "../analizCalculators/BioKimyoviy/ParklandCalculator";
import SodiumDeficitCalculator from "../analizCalculators/BioKimyoviy/SodiumDeficitCalculator";
import TIBCCalculator from "../analizCalculators/BioKimyoviy/TIBCCalculator";
import TransferrinSaturationCalculator from "../analizCalculators/BioKimyoviy/TransferrinSaturationCalculator";
import UrineAnionGapCalculator from "../analizCalculators/BioKimyoviy/UrineAnionGapCalculator";
import VasopressorDoseCalculator from "../analizCalculators/BioKimyoviy/VasopressorDoseCalculator";
import WaterDeficitCalculator from "../analizCalculators/BioKimyoviy/WaterDeficitCalculator";
import WestNomogramCalculator from "../analizCalculators/BioKimyoviy/WestNomogramCalculator";
import LDLCholesterolCalculator from "../analizCalculators/BioKimyoviy/calculateLDL";
import EgfrCalculator from "../analizCalculators/BioKimyoviy/eGFRCalculator";
import QSOFACalculator from "../analizCalculators/BioKimyoviy/qSOFACalculator";

//==========================Umumiy=qon=taxlili=================================
import ANCCalculator from "../analizCalculators/UmumiyQon/ANCCalculator";
import MCHCCalculator from "../analizCalculators/UmumiyQon/MCHCCalculator";
import MCHCalculator from "../analizCalculators/UmumiyQon/MCHCalculator";
import MCVCalculator from "../analizCalculators/UmumiyQon/MCVCalculator";
import NLRCalculator from "../analizCalculators/UmumiyQon/NLRCalculator";
import PLRCalculator from "../analizCalculators/UmumiyQon/PLRCalculator";
import RDWCalculator from "../analizCalculators/UmumiyQon/RDWCalculator";
import RPICalculator from "../analizCalculators/UmumiyQon/RPICalculator";

//==========================Peshob=taxlili=================================
import CalciumCalculator from "../analizCalculators/Urinalysis/CalciumCalculator";
import CalciumOxalateSaturation from "../analizCalculators/Urinalysis/CalciumOxalateSaturation";
import CholineExcretionCalculator from "../analizCalculators/Urinalysis/CholineExcretionCalculator";
import CreatinineClearance from "../analizCalculators/Urinalysis/CreatinineClearance";
import CystatinCCalculator from "../analizCalculators/Urinalysis/CystatinCCalculator";
import FENaCalculatorUrine from "../analizCalculators/Urinalysis/FENaCalculator";
import FEUreaCalculator from "../analizCalculators/Urinalysis/FEUreaCalculator";
import MagnesiumCreatinineRatioCalculator from "../analizCalculators/Urinalysis/MagnesiumCreatinineRatioCalculator";
import MicroalbuminuriaCalculation from "../analizCalculators/Urinalysis/MicroalbuminuriaCalculation";
import NitrateNitriteTestCalculator from "../analizCalculators/Urinalysis/NitrateNitriteTestCalculator";
import PotassiumCreatinineRatioCalculator from "../analizCalculators/Urinalysis/PotassiumCreatinineRatioCalculator";
import ProteinExcretionCalculator from "../analizCalculators/Urinalysis/ProteinExcretionCalculator";
import SodiumExcretionCalculator from "../analizCalculators/Urinalysis/SodiumExcretionCalculator";
import UricAcidCalculator from "../analizCalculators/Urinalysis/UricAcidCalculator";
import UrineAlbumin from "../analizCalculators/Urinalysis/UrineAlbumin";
import UrineAnionGap from "../analizCalculators/Urinalysis/UrineAnionGap";
import UrineOutputCalculator from "../analizCalculators/Urinalysis/UrineOutputCalculator";
import UrineOsmolality from "../analizCalculators/Urinalysis/UrineOsmolality";
import UrineProtein from "../analizCalculators/Urinalysis/UrineProtein";
import UrineSpecificGravity from "../analizCalculators/Urinalysis/UrineSpecificGravity";
import UUNCalculator from "../analizCalculators/Urinalysis/UUNCalculator";

//==========================Axlat=taxlili=================================
import FecalCalprotectinCalculator from "../analizCalculators/WasteAnalysis/FecalCalprotectinCalculator";
import FecalChlorideCalculator from "../analizCalculators/WasteAnalysis/FecalChlorideCalculator";
import FecalElastaseCalculator from "../analizCalculators/WasteAnalysis/FecalElastaseCalculator";
import FecalFatCalculator from "../analizCalculators/WasteAnalysis/FecalFatCalculator";
import FecalLactoferrinCalculator from "../analizCalculators/WasteAnalysis/FecalLactoferrinCalculator";
import FecalLeukocytesCalculator from "../analizCalculators/WasteAnalysis/FecalLeukocytesCalculator";
import FecalMagnesiumCalculator from "../analizCalculators/WasteAnalysis/FecalMagnesiumCalculator";
import FecalOsmolalityCalculator from "../analizCalculators/WasteAnalysis/FecalOsmolalityCalculator";
import FecalPHCalculator from "../analizCalculators/WasteAnalysis/FecalPHCalculator";
import FecalPotassiumCalculator from "../analizCalculators/WasteAnalysis/FecalPotassiumCalculator";
import FecalProteinLossCalculator from "../analizCalculators/WasteAnalysis/FecalProteinLossCalculator";
import FecalReducingSugarsCalculator from "../analizCalculators/WasteAnalysis/FecalReducingSugarsCalculator";
import FecalSodiumCalculator from "../analizCalculators/WasteAnalysis/FecalSodiumCalculator";
import FOBTCalculator from "../analizCalculators/WasteAnalysis/FOBTCalculator";
import StoolOsmoticGapCalculator from "../analizCalculators/WasteAnalysis/StoolOsmoticGapCalculator";
import StoolReducingSubstancesCalculator from "../analizCalculators/WasteAnalysis/StoolReducingSubstancesCalculator";
import StoolUrobilinogenCalculator from "../analizCalculators/WasteAnalysis/StoolUrobilinogenCalculator";


const { Content, Sider } = Layout;

const items2 = [
    {
        key: "sub1",
        icon: <FaVial />,
        label: "Biohimik Qon Tahlil",
        children: [
            {
                key: "1",
                label: "BMI Kalkulyator",
                component: <BMICalculator />
            },
            {
                key: "2",
                label: "HAS-BLED",
                component: <HASBLEDCalculator />,
            },
            {
                key: "3",
                label: "APACHE II",
                component: <ApacheIICalculator />,
            },
            {
                key: "4",
                label: "AA Gradus",
                component: <AAGradientCalculator />
            },
            {
                key: "5",
                label: "Anion Gap",
                component: <AnionGapCalculator />,
            },
            {
                key: "6",
                label: "BSA (Tana Yuzasi)",
                component: <BSACalculator />,
            },
            {
                key: "7",
                label: "Bikarbonat Yetishmovchiligi",
                component: <BicarbonateDeficitCalculator />
            },
            {
                key: "8",
                label: "CHA2DS2-VASc",
                component: <CHA2DS2VAScCalculator />,
            },
            {
                key: '9',
                label: 'Calvert Formula',
                component: <CalvertCalculator />
            },
            {
                key: '10',
                label: 'Child-Pugh',
                component: <ChildPughCalculator />
            },
            {
                key: '11',
                label: 'Korreksiya Qilingan Kalsiy',
                component: <CorrectedCalciumCalculator />
            },
            {
                key: '12',
                label: 'Korreksiya Qilingan Fenitoin',
                component: <CorrectedPhenytoinCalculator />
            },
            {
                key: '13',
                label: 'Korreksiya Qilingan Natriy',
                component: <CorrectedSodiumCalculator />
            },
            {
                key: '14',
                label: 'Kreatinin Klirensi',
                component: <CreatinineClearanceCalculator />
            },
            {
                key: '15',
                label: 'D-dimer (Yoshga Mos)',
                component: <DdimerAdjustedForAgeCalculator />
            },
            {
                key: '16',
                label: 'Natriy Fraksiya Ajralishi',
                component: <FENaCalculator />
            },
            {
                key: '17',
                label: 'Fraksiya Exkresiyasi (Mochevina)',
                component: <FEUreaCalculator />
            },
            {
                key: '18',
                label: 'Glisemik Indeks',
                component: <GICalculator />
            },
            {
                key: '19',
                label: 'MAP (O‘rtacha Arterial Bosim)',
                component: <MAPCalculator />
            },
            {
                key: '20',
                label: 'Buyrak Filtratsiyasi',
                component: <MDRDCalculator />
            },
            {
                key: '21',
                label: 'Kislorod Yetkazib Berish',
                component: <OICalculator />
            },
            {
                key: '22',
                label: 'Parkland Formula',
                component: <ParklandCalculator />
            },
            {
                key: '23',
                label: 'Natriy Yetishmovchiligi (Giponatriemiya)',
                component: <SodiumDeficitCalculator />
            },
            {
                key: '24',
                label: 'TIBC',
                component: <TIBCCalculator />
            },
            {
                key: '25',
                label: 'Transferrin Saturatsiyasi',
                component: <TransferrinSaturationCalculator />
            },
            {
                key: '26',
                label: 'Mochadagi Anion Gap',
                component: <UrineAnionGapCalculator />
            },
            {
                key: '27',
                label: 'Vazopressor Dozasi',
                component: <VasopressorDoseCalculator />
            },
            {
                key: '28',
                label: 'Suyuqlik Yetishmovchiligi (Gipernatriemiya)',
                component: <WaterDeficitCalculator />
            },
            {
                key: '29',
                label: 'West Nomogram',
                component: <WestNomogramCalculator />
            },
            {
                key: '30',
                label: 'LDL Xolesterin',
                component: <LDLCholesterolCalculator />
            },
            {
                key: '31',
                label: 'eGFR (Buyrak Filtratsiyasi)',
                component: <EgfrCalculator />
            },
            {
                key: '32',
                label: 'qSOFA',
                component: <QSOFACalculator />
            },
        ],
    },
    {
        key: "sub2",
        icon: <GiDroplets />,
        label: "Umumiy Qon Tahlil",
        children: [
            {
                key: "33",
                label: "Neutrofillar Soni",
                component: <ANCCalculator />,
            },
            {
                key: "34",
                label: "O‘rtacha Gemoglobin Kontsentratsiyasi",
                component: <MCHCCalculator />,
            },
            {
                key: "35",
                label: "O‘rtacha Gemoglobin Miqdori",
                component: <MCHCalculator />,
            },
            {
                key: "36",
                label: "O‘rtacha Eritrotsit Hajmi",
                component: <MCVCalculator />,
            },
            {
                key: "37",
                label: "Neutrofil/Limfotsit Nisbati",
                component: <NLRCalculator />,
            },
            {
                key: "38",
                label: "Trombosit/Limfotsit Nisbati",
                component: <PLRCalculator />,
            },
            {
                key: "39",
                label: "Eritrotsitlar Kengligi",
                component: <RDWCalculator />,
            },
            {
                key: "40",
                label: "Retikulosit Ishlab Chiqarish Indeksi",
                component: <RPICalculator />,
            },
        ],
    },
    {
        key: "sub3",
        icon: <GiKidneys />,
        label: "Peshob Tahlili",
        children: [
            {
                key: "41",
                label: "Kalsiy/Kreatinin Nisbati",
                component: <CalciumCalculator />
            },
            {
                key: "42",
                label: "Kalsiy Oksalat To‘yinganlik",
                component: <CalciumOxalateSaturation />
            },
            {
                key: "43",
                label: "Xolin Chiqarilishi",
                component: <CholineExcretionCalculator />
            },
            {
                key: "44",
                label: "Kreatinin Klirensi",
                component: <CreatinineClearance />
            },
            {
                key: "45",
                label: "Cystatin C",
                component: <CystatinCCalculator />
            },
            {
                key: "46",
                label: "Natriyning Fraksion Ajralishi",
                component: <FENaCalculatorUrine />
            },
            {
                key: "47",
                label: "Magniy/Kreatinin Nisbati",
                component: <MagnesiumCreatinineRatioCalculator />
            },
            {
                key: "48",
                label: "Microalbuminuriya",
                component: <MicroalbuminuriaCalculation />
            },
            {
                key: "49",
                label: "Nitrat/Nitrit Testi",
                component: <NitrateNitriteTestCalculator />
            },
            {
                key: "50",
                label: "Kaliy/Kreatinin Nisbati",
                component: <PotassiumCreatinineRatioCalculator />
            },
            {
                key: "51",
                label: "Oqsil Chiqarilishi",
                component: <ProteinExcretionCalculator />
            },
            {
                key: "52",
                label: "Natriy Chiqarilishi",
                component: <SodiumExcretionCalculator />
            },
            {
                key: "53",
                label: "Urik Kislota",
                component: <UricAcidCalculator />
            },
            {
                key: "54",
                label: "Albumin",
                component: <UrineAlbumin />
            },
            {
                key: "55",
                label: "Anion Bo‘shliq",
                component: <UrineAnionGap />
            },
            {
                key: "56",
                label: "Peshob Hajmi",
                component: <UrineOutputCalculator />
            },
            {
                key: "57",
                label: "Osmolyarlik",
                component: <UrineOsmolality />
            },
            {
                key: "58",
                label: "Oqsil/Kreatinin Nisbati",
                component: <UrineProtein />
            },
            {
                key: "59",
                label: "Xususiy Og‘irlik",
                component: <UrineSpecificGravity />
            },
            {
                key: "60",
                label: "Urea Azoti",
                component: <UUNCalculator />
            },
        ],
    },
    {
        key: "sub4",
        icon: <GiStomach />,  // Axlat tahliliga mos keluvchi ikon
        label: "Najosat Tahlili",
        children: [
            {
                key: "61",
                label: "Kalprotektin",
                component: <FecalCalprotectinCalculator />,
            },
            {
                key: "62",
                label: "Xlorid",
                component: <FecalChlorideCalculator />,
            },
            {
                key: "63",
                label: "Elastaza",
                component: <FecalElastaseCalculator />,
            },
            {
                key: "64",
                label: "Yog‘",
                component: <FecalFatCalculator />,
            },
            {
                key: "65",
                label: "Laktoferrin",
                component: <FecalLactoferrinCalculator />,
            },
            {
                key: "66",
                label: "Leykotsitlar",
                component: <FecalLeukocytesCalculator />,
            },
            {
                key: "67",
                label: "Magniy",
                component: <FecalMagnesiumCalculator />,
            },
            {
                key: "68",
                label: "Osmolyarlik",
                component: <FecalOsmolalityCalculator />,
            },
            {
                key: "69",
                label: "pH",
                component: <FecalPHCalculator />,
            },
            {
                key: "70",
                label: "Kaliy",
                component: <FecalPotassiumCalculator />,
            },
            {
                key: "71",
                label: "Oqsil Yo'qotish",
                component: <FecalProteinLossCalculator />,
            },
            {
                key: "72",
                label: "Qaytariluvchi Shakarlar",
                component: <FecalReducingSugarsCalculator />,
            },
            {
                key: "73",
                label: "Natriy",
                component: <FecalSodiumCalculator />,
            },
            {
                key: "74",
                label: "Yashirin Qon Testi (FOBT)",
                component: <FOBTCalculator />,
            },
            {
                key: "75",
                label: "Osmotik Bo‘shliq",
                component: <StoolOsmoticGapCalculator />,
            },
            {
                key: "76",
                label: "Qaytariluvchi Moddalar",
                component: <StoolReducingSubstancesCalculator />,
            },
            {
                key: "77",
                label: "Urobilinogen",
                component: <StoolUrobilinogenCalculator />,
            },
        ],
    }

];

const defaultSelectedKey = "1"; // Default selected key
// const defaultOpenKey = "sub1"; // Default open key

const CalculationLayout = () => {

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const [searchValue, setSearchValue] = React.useState('');
    const [selectedComponent, setSelectedComponent] = React.useState(
        items2
            .flatMap((item) => item.children)
            .find((child) => child.key === defaultSelectedKey)?.component
    );

    const handleMenuClick = (e) => {
        const component = items2
            .flatMap((item) => item.children)
            .find((child) => child.key === e.key)?.component;
        setSelectedComponent(component);
    };

    // Qidiruv bo'yicha komponentlarni filtrlaymiz
    const filteredComponents = items2
        .flatMap(item => item.children)
        .filter(child => child.label.toLowerCase().includes(searchValue.toLowerCase())); // Qidiruvga muvofiq filtrlash


    const handleReset = () => {
        setSearchValue(''); // Qidiruv qiymatini tozalash
    };



    //-------------------------------
    // const [collapsed, setCollapsed] = useState(false);
    const [siderWidth, setSiderWidth] = useState(200); // Siderning boshlang'ich kengligi
    const [isResizing, setIsResizing] = useState(false);

    const handleMouseDown = (e) => {
        setIsResizing(true);
    };

    const handleMouseMove = (e) => {
        if (isResizing) {
            const newWidth = e.clientX; // Kursorning x koordinatasini olish
            // Minimal kenglikni 200px va maksimal kenglikni 500px qilib belgilash
            if (newWidth > 500) {
                setSiderWidth(500);
            } else if (newWidth < 200) {
                setSiderWidth(200);
            } else {
                setSiderWidth(newWidth);
            }
        }
    };

    const handleMouseUp = () => {
        setIsResizing(false);
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMove);
        window.addEventListener('mouseup', handleMouseUp);
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isResizing]);
    return (
        <LayoutWrapper>
            <div className="calculationLayout">
                <Layout
                    style={{
                        background: borderRadiusLG,
                        paddingLeft: "0px",
                        width: "100%",
                        transition: 'width 0.2s',
                        borderRight: '2px solid #ddd', // Chegarani qo'shish
                    }}

                >
                    <Sider
                        style={{
                            background: colorBgContainer,
                            paddingLeft: "0px",
                            width: siderWidth,
                            // width: "250px",
                            transition: 'width 0.2s',
                        }}

                    >
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={[defaultSelectedKey]}
                            className="menu-scroll"
                            style={{
                                padding: "0",
                                margin: "0",
                                paddingLeft: "-10px",
                                maxHeight: "519px",
                                overflowY: "auto",
                                scrollbarWidth: "3px",
                            }}
                            items={items2.map((item) => ({
                                key: item.key,
                                label: (
                                    <div style={{ padding: "0", margin: "0" }}>
                                        {item.icon} {item.label}
                                    </div>
                                ),
                                children: item.children.map((child) => ({
                                    key: child.key,
                                    label: <div style={{ padding: "0" }}>{child.label}</div>,
                                })),
                            }))}
                            onClick={handleMenuClick}
                        />
                        <div
                            style={{
                                width: '10px',
                                cursor: 'ew-resize',
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                height: '100%',
                                zIndex: 1,
                            }}
                            onMouseDown={handleMouseDown}
                        />
                    </Sider>
                    <Content
                        style={{
                            minHeight: 280,
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                            <Input
                                placeholder="Kalkulyatorlarni izlash..."
                                size="middle"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)} // Qidiruv qiymatini yangilash
                                // onPressEnter={handleSearch} // Qidiruvni amalga oshirish
                                suffix={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <SearchOutlined style={{ color: '#1890ff', marginRight: '8px' }} />
                                        {searchValue && (
                                            <CloseCircleOutlined
                                                style={{ color: '#ff4d4f', cursor: 'pointer' }}
                                                onClick={handleReset} // Qidiruv qiymatini tozalash
                                            />
                                        )}
                                    </div>
                                }
                                style={{ width: "80%", margin: "7px 0", borderRadius: "5px" }}
                            />
                        </div>
                        {/* {selectedComponent || children} */}
                        {searchValue ? (
                            filteredComponents.length > 0 ? (
                                filteredComponents.map(item => (
                                    <div key={item.key}>
                                        {item.component} {/* Qidiruv natijalarini ko'rsatish */}
                                    </div>
                                ))
                            ) : (
                                <div>Hech qanday mos keladigan komponent topilmadi.</div>
                            )
                        ) : (
                            selectedComponent // Default komponentni ko'rsatish
                        )}
                    </Content>
                </Layout>
            </div>
        </LayoutWrapper>
    );
};

export default CalculationLayout;
