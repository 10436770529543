import React, { useState, useMemo, useEffect } from "react";
import { Table, Button, Tabs, Space, Tooltip } from "antd";
import { useParams } from "react-router-dom";
import { PiUsersThreeLight } from "react-icons/pi";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import LayoutWrapper from "../../../components/layout/Layout";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import { useGetSalaryQuery } from "../../../redux/salaryApi";
// import { useCreateSalaryMutation } from "../../../redux/salaryApi";
import { NumberFormat } from "../../../hook/NumberFormat";
import "./style.css";
import { useGetStoriesQuery } from "../../../redux/clientStores";
import { useGetRoomStoriesQuery } from "../../../redux/roomStoriesApi";

const KunlikBemorlarTable = () => {
  const { _id } = useParams();
  const { data: doctorsData } = useGetAllDoctorsQuery();
  const [dataNet, setDataNet] = useState(null);
  const [totalPatients, setTotalPatients] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalRoomPrice, setTotalRoomPrice] = useState(0);
  const [salary, setSalary] = useState(0);
  const [combinedData, setCombinedData] = useState([]);

  const [currentDate, setCurrentDate] = useState(new Date());
  let { data: stories } = useGetStoriesQuery();
  let { data: innerData } = useGetRoomStoriesQuery();
  let { data: salaryStores } = useGetSalaryQuery();
  const roomData = useMemo(
    () => innerData?.allRoomStories || [],
    [innerData?.allRoomStories]
  );
  const dataStories = useMemo(
    () => stories?.innerData || [],
    [stories?.innerData]
  );

  const dataSalary = useMemo(
    () => salaryStores?.innerData || [],
    [salaryStores?.innerData]
  );

  const handleBackClick = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() - 1);
    setCurrentDate(newDate);
  };

  const handleForwardClick = () => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + 1);
    setCurrentDate(newDate);
  };

  const monthRes = currentDate.toLocaleString("en-US", { month: "long" });
  const monthNames = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentabr",
    "Oktabr",
    "Noyabr",
    "Dekabr",
  ];
  const monthIndex = currentDate.getMonth();
  const monthName = monthNames[monthIndex];

  useEffect(() => {
    if (!doctorsData || !_id) return;

    const filteredDoctor = doctorsData?.data?.find(
      (doctor) => doctor.idNumber === _id
    );

    if (!filteredDoctor) return;

    setDataNet(filteredDoctor.percent);

    const AddPatients = dataStories?.filter((item) => item.month === monthRes && item.doctorIdNumber === _id && item.view === true);

    const AllRoom = roomData?.filter((item) => item.month === monthRes);

    const combinedData = combineData(AddPatients, AllRoom);
    setCombinedData(combinedData);

    const totalPatientsCount = AddPatients?.length;
    const totalPatientsPrice = AddPatients?.reduce(
      (acc, patient) => acc + patient.paySumm,
      0
    );
    const totalRoomPrice = AllRoom?.reduce(
      (acc, { payForRoom }) => acc + payForRoom,
      0
    );
    const calculatedSalary =
      (totalPatientsPrice * +dataNet) / 100 + totalRoomPrice;

    setTotalPatients(totalPatientsCount);
    setTotalPrice(totalPatientsPrice);
    setTotalRoomPrice(totalRoomPrice);
    setSalary(calculatedSalary);
  }, [doctorsData, _id, dataNet, monthRes, dataStories, roomData]);

  function combineData(bemorlar, bemorlarRoom) {
    const combinedData = {};

    bemorlar?.forEach(({ day, paySumm }) => {
      if (!combinedData[day]) {
        combinedData[day] = { day, bemorlar: 1, total: paySumm, payForRoom: 0 };
      } else {
        combinedData[day].bemorlar += 1;
        combinedData[day].total += paySumm;
      }
    });

    bemorlarRoom?.forEach(({ day, payForRoom }) => {
      if (!combinedData[day]) {
        combinedData[day] = { day, bemorlar: 0, total: 0, payForRoom };
      } else {
        combinedData[day].payForRoom += payForRoom;
      }
    });

    const roomTotal = bemorlarRoom.reduce(
      (acc, { payForRoom }) => acc + payForRoom,
      0
    );
    Object.values(combinedData).forEach((data) => {
      data.roomTotal = roomTotal;
    });

    return Object.values(combinedData);
  }

  const columns = [
    { title: "Sana", dataIndex: "day", key: "day" },
    {
      title: "Bemorlar Soni",
      dataIndex: "bemorlar",
      key: "bemorlar_soni",
      render: (bemorlar) => (
        <div className="SingleUsersLeng">
          <PiUsersThreeLight />  {bemorlar}
        </div>
      ),
    },
    {
      title: "Tulovlar",
      dataIndex: "total",
      key: "total",
      render: (total) => `${NumberFormat(total)} so'm`,
    },
    {
      title: "Davolanish",
      dataIndex: "roomTotal",
      key: "davolanish",
      render: (roomTotal) => `${NumberFormat(roomTotal)} so'm`,
    },
    {
      title: "Foiz",
      dataIndex: "total",
      key: "foiz",
      render: (total, record) =>
        `${NumberFormat((total * dataNet) / 100)} so'm`,
    },
  ];

  const Style = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "18px",
  };

  const SalaryDoctor = dataSalary.filter((stores) => stores.idNumber === _id);

  if (!SalaryDoctor) return;
  const columnsStores = [
    { title: "Sana", dataIndex: "getDay", key: "getDay" },
    {
      title: "Maosh turi",
      dataIndex: "type",
      key: "type",
      render: (total) => (total === "salary" ? "Oylik maosh" : "Avans"),
    },
    {
      title: "Summa",
      dataIndex: "salary",
      render: (salary, record) => `${salary !== 0 ? NumberFormat(salary) : NumberFormat(record.avans)} so'm`,
    },
    {
      title: "oy",
      dataIndex: "getMonth",
      key: "getMonth",
      render: (getMonth) => getMonth,
    },
  ];

  return (
    <LayoutWrapper>
      <div className="singlePageRep">
        <Tabs>
          <Tabs.TabPane defaultActiveKey="0" tab="Malumot" key={0}>
            <div className="fixed-footer">
              <span>{monthName} oyi uchun</span>
              <div>Umumiy bemorlar soni: {NumberFormat(totalPatients)} </div>
              <div>Umumiy pul: {NumberFormat(totalPrice)} so'm</div>
              <div>Davolanishda: {NumberFormat(totalRoomPrice)} so'm</div>
              <div>Maosh: {NumberFormat(salary)} so'm</div>
              <Space wrap="wrap" gap="small">
                <Tooltip title="O'tkan oylar">
                  <Button
                    style={Style}
                    onClick={handleBackClick}
                    type="primary"
                    shape="circle"
                    icon={<DownCircleOutlined />}
                  />
                </Tooltip>
                <Tooltip title="Oldinga">
                  <Button
                    style={Style}
                    onClick={handleForwardClick}
                    type="primary"
                    shape="circle"
                    icon={<UpCircleOutlined />}
                  />
                </Tooltip>
              </Space>
            </div>

            <Table
              rowKey={"_id"}
              pagination={false}
              dataSource={combinedData}
              columns={columns}
              size="small"
              loading={!combinedData ? true : false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane defaultActiveKey="1" tab="Oylik maosh tarixi" key={1}>
            <Table
              rowKey={"_id"}
              pagination={false}
              dataSource={SalaryDoctor}
              columns={columnsStores}
              size="small"
              loading={!SalaryDoctor ? true : false}
            />
          </Tabs.TabPane>
        </Tabs>
      </div>
    </LayoutWrapper>
  );
};

export default KunlikBemorlarTable;
