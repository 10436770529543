import { api } from "./api";

export const nursesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllNurses: builder.query({
      query: () => `nurses/getAllNurses`,
      providesTags: ["GetNurses"],
    }),

    addNurses: builder.mutation({
      query: (body) => ({
        url: "/nurses/addNurses",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetNurses"],
    }),

    updateNurses: builder.mutation({
      query: ({ id, body }) => ({
        url: `/nurses/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetNurses"],
    }),
    // update nurse Salary

    updateNurseSalary: builder.mutation({
      query: ({ id, body }) => ({
        url: `/nurses/update-salary/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetNurses"],
    }),

    // Delete room
    deleteNurses: builder.mutation({
      query: (id) => ({
        url: `nurses/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetNurses"],
    }),
  }),
});

export const {
  useGetAllNursesQuery,
  useAddNursesMutation,
  useUpdateNursesMutation,
  useUpdateNurseSalaryMutation,
  useDeleteNursesMutation,
} = nursesApi;
