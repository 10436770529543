import { api } from "./api";

///salary/getSalaries
export const salaryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSalary: builder.query({
      query: () => `salary/getSalaries`,
      providesTags: ["GetSalaries"],
    }),
    // POST || CREATE
    createSalary: builder.mutation({
      query: (body) => ({
        url: "salary/createSalary",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetDoctors", "GetSalaries"],
    }),
  }),
});

export const { useGetSalaryQuery, useCreateSalaryMutation } = salaryApi;
