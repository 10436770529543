import React, { useEffect, useMemo, useState } from "react";
import { message, Button, Modal, Select, Tooltip, Table } from "antd";
import moment from "moment";
import {
  PlusOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import { GiEntryDoor } from "react-icons/gi"; // react-icons librarydan foydalanib GiEntryDoor ni import qildik
import { useNavigate, useParams } from "react-router-dom";
import { NumberFormat, PhoneNumberFormat } from "../../../../hook/NumberFormat";
import {
  useDeleteUserFromRoomMutation,
  useGetAllRoomsQuery,
  useUpdateRoomMutation,
} from "../../../../redux/roomApi";

import {
  useCreatePaymentStoryMutation
} from "../../../../redux/paymentApi";
import socket from '../../../../socket'

import {
  useGetRoomStoriesQuery,
  useCreateRoomStoriesMutation,
  useUpdateRoomStoriesMutation,
  useUpdateRoomPaymentMutation,
  useUpdatePaidDaysMutation,
} from "../../../../redux/roomStoriesApi";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";
import { CountingDay } from "../../../../hook/ColculateDay";
import { calculateRoomPayment } from "../../../../hook/reduceRoomPrice";
import LayoutWrapper from "../../../../components/layout/Layout";
import RoomModal from "../../../../components/checkLists/roomModal/RoomModal";
import "./style.css";


function EnterRoom() {
  const [roomModalState, setRoomModalState] = useState(false);
  const [additionalPrice, setAdditionalPrice] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [debtPayment, setDebtPayment] = useState({});
  const [isInputDisabled, setIsInputDisabled] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  let navigate = useNavigate();
  let { id } = useParams();
  const { data: rooms } = useGetAllRoomsQuery();
  const allRooms = rooms?.innerData || [];

  let room = allRooms?.find((r) => r._id === id);

  const [updateRoomFunc] = useUpdateRoomMutation();
  const [createPaymentStory] = useCreatePaymentStoryMutation();
  const [createRoomStory] = useCreateRoomStoriesMutation();
  const [updateRoomStory] = useUpdateRoomStoriesMutation();
  let { data: roomStories, refetch } = useGetRoomStoriesQuery();
  const [updateRoomPayment] = useUpdateRoomPaymentMutation();
  const [updatePaidDays] = useUpdatePaidDaysMutation();

  const [deleteUserFromRoom] = useDeleteUserFromRoomMutation();
  const todaysTime = useMemo(
    () => moment().utcOffset("+05:00").format("DD.MM.YYYY HH:mm"),
    []
  );
  const { confirm } = Modal;

  const OutInRoom = (clientID, roomID, userRoomPay, record) => {
    // Aktif bo'lgan xonani va bemorni topamiz
    const activeRoomStories = roomStories?.innerData
      .filter((i) => i.active === true && i.clientID === record?.clientID)
      .map((i) =>
        i.endDay === "0"
          ? {
            ...i,
            endDay: todaysTime,
            clientPayForRoomPrice: calculateRoomPayment(record),
          }
          : i
      );


    // Har bir kun uchun to'lovning to'liq to'lanmaganligini tekshirish
    const hasUnpaidDays = record.paidDays?.some(day => !day.isPaid && day.price > 0);

    if (hasUnpaidDays) {
      return message.warning("Bemorning to'lamagan qarzlari bor. Avval to'lovni amalga oshiring.");
    }

    confirm({
      title: "Bemorni honadan chiqarmoqchimisiz?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        deleteUserFromRoom({
          clientID,
          roomID,
          body: activeRoomStories,
        })
          .then((res) => {
            if (res?.data?.success) {
              message?.success(res.data.message);
              navigate('/cabins')
            }
          })
          .catch((err) => console.log(err));
      },
    });
  };

  const iconStyle = useMemo(
    () => ({
      fontSize: "20px",
    }),
    []
  );

  // let doctorType = "room";
  const getCategoryLabel = (category) => {
    switch (category) {
      case "pollux":
        return "Polyuks";
      case "luxury":
        return "Lyuks";
      case "free":
        return "Oddiy";
      default:
        return category;
    }
  };

  const data = allRooms
    ?.filter((r) => r.capacity?.length !== r.usersNumber && r._id !== id)
    ?.map((room) => ({
      value: room._id,
      label: "[" + room.roomNumber + "] " + getCategoryLabel(room.category),
    }));


  // xona capacitysida bor bemorlarni ajratib olish
  let capacity = room?.capacity;
  const roomData = roomStories?.innerData.filter(
    (i) =>
      i?.roomNumber === room?.roomNumber &&
      i.active === true &&
      i.endDay === "0" &&
      capacity.some(
        (u) => u.idNumber === i.clientID || u.clientID === i.clientID
      )
  );

  useEffect(() => {
    socket.on('updateRoomPayment', () => {
      refetch()
    })
  }, [refetch])

  // bemorni xonasini almashtirish
  const addUserToRoom = (id, userInfo) => {
    let user = { ...userInfo };
    let xona = { ...allRooms.find((r) => r._id === id) };

    confirm({
      title: `Bemorni ${xona.roomNumber}-xonaga ko'chirmoqchimisiz?`,
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        let capacity = [...xona.capacity];
        if (capacity.some((u) =>
          u.idNumber ? u.idNumber === user.clientID : u.clientID === user.clientID
        )) {
          return message.warning("Bemor xonada avvaldan mavjud");
        }

        // Hozirgi sana
        const today = moment().format("YYYY-MM-DD");
        const paidDays = user.paidDays || [];

        // Bugungi sanadan keyingi kunlarni `newPaidDays`, bugungi va undan oldingi kunlarni `oldPaidDays` ga ajratish
        const [oldPaidDays, newPaidDays] = paidDays.reduce(
          ([old, newArr], day) => {
            if (moment(day.date).isAfter(today)) {
              newArr.push(day); // Bugungi kundan keyingi sanalar
            } else {
              old.push(day); // Bugungi va undan oldingi sanalar
            }
            return [old, newArr];
          },
          [[], []]
        );

        // Yangilangan userInfo ni tuzish
        const oldUserInfo = {
          ...user,
          endDay: moment().format("DD.MM.YYYY HH:MM"),
          paidDays: oldPaidDays,
          dayOfTreatment: oldPaidDays?.length,
          clientPayForRoomPrice: oldPaidDays.reduce((total, day) => total + day.price, 0) // price larni tuplash
        };
        const newUserInfo = {
          ...user,
          clientPayForRoomPrice: newPaidDays.reduce((total, day) => total + day.price, 0), // oldingi pul yig'iladi
          paidDays: newPaidDays.map((day) => ({ ...day, price: 0, isPaid: false })), // barcha price = 0, isPaid = false
          payForRoom: xona.pricePerDay, // xona uchun kundalik to'lov
          roomNumber: xona.roomNumber,
          dayOfTreatment: moment().add(1, 'days').valueOf(), // Ertangi sana timestamp formatida
        };
        // Pul taqsimlash jarayoni
        const totalClientPayForRoomPrice = newUserInfo.clientPayForRoomPrice;
        const payForRoom = newUserInfo.payForRoom; // Kundalik to'lov miqdori
        let remainingPrice = totalClientPayForRoomPrice; // To'lanishi kerak bo'lgan summa

        // `newPaidDays` dagi `price` ni qayta to'ldiramiz va `isPaid` ni yangilaymiz
        newUserInfo.paidDays = newUserInfo.paidDays.map((day, index) => {
          if (remainingPrice <= 0) return day; // Agar barcha summa taqsimlangan bo'lsa, davom etamiz

          const payableAmount = Math.min(payForRoom, remainingPrice); // Kundalik to'lov yoki qolgan summa
          remainingPrice -= payableAmount; // Qolgan summani kamaytiramiz

          // `isPaid` qiymatini `payableAmount` va `payForRoom` tengligiga qarab yangilash
          const isPaid = payableAmount === payForRoom;

          return { ...day, price: payableAmount, isPaid }; // Yangilangan kunlik price va isPaid qiymatlari qaytariladi
        });

        // Qolgan pulni tekshirish va logga chiqarish
        if (remainingPrice > 0) {
          console.log("Qolgan pul:", remainingPrice);
        }
        // -----------------------------------------



        // Xonaga bemorni biriktirish jarayoni
        capacity.push(newUserInfo);
        xona.capacity = capacity;

        // Yangi xona uchun yangilangan userInfo ni serverga jo'natamiz
        updateRoomFunc({ id: xona._id, body: xona })
          .then((res) => {
            if (res?.data?.success) {
              message.success(res?.data?.message);
            } else {
              message.warning(res?.data?.msg);
            }
          })
          .catch((err) => console.log("err>>", err));

        // Eski xonadan bemorni ochirish
        let filteredCapacity = room?.capacity?.filter((u) =>
          u.idNumber ? u.idNumber !== user.clientID : u.clientID !== user.clientID
        );
        let oldRoomInfo = { ...room, capacity: filteredCapacity };

        updateRoomFunc({ id: room._id, body: oldRoomInfo })
          .then((res) => {
            if (res?.data?.success) {
              message.success(res?.data?.message);
            } else {
              message.warning(res?.data?.msg);
            }
          })
          .catch((err) => console.log("err>>", err));

        // Eski xona hikoyasini yangilash
        let oldRoomStory = {
          ...oldUserInfo,
        };

        updateRoomStory({ id: oldRoomStory._id, body: oldRoomStory })
          .then((res) => {
            if (res?.data?.success) {
              message.success(res?.data?.message);
            } else {
              message.warning(res?.data?.message);
            }
          })
          .catch((err) => {
            console.log(err);
          });

        const { _id, ...restUser } = newUserInfo;
        createRoomStory(restUser)
          .then((res) => {
            if (res?.data?.success) {
              message.success(`Bemor ${room?.roomNumber}-xonaga muvaffaqiyatli joylandi!`);
            } else {
              message.warning(res.error.data.message.message);
            }
          })
          .catch((err) => console.log("err>>", err));

        // Eski xonada odam qolmasa, xonalar sahifasiga yo'naladi
        if (!filteredCapacity.length) {
          navigate(-1);
        }

      },
    });
  };


  const formatDateForServer = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 0-indekslash
    const year = date.getFullYear();
    return `${year}-${month}-${day}`; // ISO format: "YYYY-MM-DD"
  };

  // Arifmetik amalni inputdan olish uchun yangi funksiya
  const handleArithmeticOperation = (value, input) => {
    // const operation = input.match(/(\d+)([\+\-])(\d+)/); 
    const operation = input.match(/^(\d+)([\+\-])(\d+)$/);// 5+2 yoki 5-2 formatidagi amalni olish
    if (!operation) {
      return; // Agar amal topilmasa hech narsa qilmaymiz
    }

    let [, , operator, amount] = operation;

    amount = parseInt(amount, 10);

    // Sana qo'shish va serverga saqlash logikasi
    let lastDate = value.paidDays?.length
      ? new Date(value.paidDays[value.paidDays.length - 1].date.split('.').reverse().join('-')) // "DD.MM.YYYY" ni ISO formatga aylantirish
      : new Date(); // Bo'sh bo'lsa, bugungi sanadan boshlanadi

    if (isNaN(lastDate.getTime())) {
      lastDate = new Date(); // Agar invalid date bo'lsa, bugungi sanani oladi
    }


    if (operator === "+") {
      // Qo'shish amali
      let updatedPaidDays = [...value.paidDays];

      updatedPaidDays = updatedPaidDays.concat(
        Array.from({ length: amount }, (_, index) => {
          lastDate.setDate(lastDate.getDate() + 1); // Sana ketma-ket oshiriladi
          const newDate = new Date(lastDate); // Yangi sanani nusxalash
          return {
            day: updatedPaidDays.length + index + 1, // Raqamni ketma-ketlikda qo'shish
            price: 0,
            isPaid: false,
            date: formatDateForServer(newDate), // Serverga saqlash uchun format
          };
        })
      );

      return updatedPaidDays;
    } else if (operator === "-") {
      // Ayirish amali
      let updatedPaidDays = [...value.paidDays];
      updatedPaidDays.splice(-amount, amount); // Oxiridan amount miqdorda elementlarni olib tashlaymiz
      return updatedPaidDays;
    }

    return value.paidDays; // Agar hech narsa topilmasa eski holat qaytariladi
  };


  // Boshqa funksiyalarni o'zgartirmasdan, yangi operatsiyani ishlatish
  const handleDebtPaymentChange = (e, value) => {
    const valueInput = e.target.value;

    // Regulyar ifoda - haqiqiy qiymatlarni tanlash
    const validPattern = /^\d+([\+\-]\d+)*$/;

    // Kiritilgan qiymatni tekshirish
    if (validPattern.test(valueInput)) {
      const payment = parseFloat(valueInput);

      if (payment <= 0) {
        return message.error("To'lov miqdori musbat bo'lishi kerak");
      }

      const payForRoom = value?.payForRoom; // Har bir kunning to'lov miqdori

      // Optimizatsiya qilingan paidDays amalini tekshirish
      let updatedPaidDays = handleArithmeticOperation(value, valueInput);
      if (updatedPaidDays) {
        setDebtPayment({ ...debtPayment, [value.clientID]: updatedPaidDays });
        setInputValue(valueInput);
        return;
      }

      let remainingDebt = value?.paidDays?.filter((day) => !day.isPaid); // Faqat isPaid: false bo'lgan kunlar

      if (!remainingDebt.length) {
        return message.info("Qarz mavjud emas, barcha kunlar to'langan");
      }

      // To'lov miqdorini aniqlash
      const calculateTotalDebt = (remainingDebt) => {
        return remainingDebt.reduce((sum, day) => {
          return sum + (payForRoom - day.price);
        }, 0);
      };

      let totalDebt = calculateTotalDebt(remainingDebt);

      if (payment > totalDebt) {
        setIsInputDisabled(true);
        return message.error("To'lov miqdori umumiy qarzdan ko'p bo'la olmaydi");
      } else {
        setIsInputDisabled(false);
      }

      let remainingPayment = payment; // Qolgan to'lov miqdori

      let updatedPayments = remainingDebt.map((day) => {
        if (remainingPayment <= 0) {
          return day; // Agar to'lov qolmagan bo'lsa, kunni o'zgartirmasdan qaytaramiz
        }

        let newPrice;
        let isPaid;

        // Har bir kun uchun maksimal to'lov miqdorini hisoblaymiz
        let maxPaymentForDay = Math.min(payForRoom - day.price, remainingPayment);

        newPrice = day.price + maxPaymentForDay; // To'lovni mavjud price ga qo'shamiz
        remainingPayment -= maxPaymentForDay; // To'langan miqdorni kamaytiramiz
        isPaid = newPrice >= payForRoom; // Agar to'liq to'langan bo'lsa, isPaid true bo'ladi

        return {
          ...day,
          price: newPrice,
          isPaid: isPaid,
        };
      });

      setDebtPayment({ ...debtPayment, [value.clientID]: updatedPayments });
      setAdditionalPrice(payment);
    } else {
      console.log('Noto\'g\'ri formatdagi qiymat.');
    }
  };

  // --------handlePayDebt-----------
  const handlePayDebt = async (item) => {
    const payments = debtPayment[item.clientID];

    if (!payments || !payments.length) {
      return message.warning("Iltimos, to'lov miqdorini kiriting.");
    }

    const clientPrice = {
      clientPayForRoomPrice: item.clientPayForRoomPrice + additionalPrice, // To'lov miqdorini yangilash
    };


    //     // inputValue mavjudligi va string ekanligi tekshiriladi
    const arithmeticOperation = (inputValue && typeof inputValue === 'string')
      ? inputValue.match(/^(\d+)([\+\-])(\d+)$/)
      : null; // 5+4 yoki 5-4 formatidagi amal


    if (arithmeticOperation) {
      const updatedPaidDays = payments.map(payment => ({
        day: payment.day,
        date: payment.date,
        isPaid: payment.isPaid,
        price: payment.price,
        dailyStatus: payment.dailyStatus,
      }));

      try {
        await updatePaidDays({ _id: item._id, newPaidDays: updatedPaidDays })
        message.success("Davolanish kun muvaffaqiyatli yangilandi");
        setAdditionalPrice(null); // Reset the additional price
        setInputValue(null); // Reset the additional price
      } catch (error) {
        message.error("To'lovni yangilashda xato yuz berdi");
        console.error("Xato:", error);
      }

    } else {
      setRoomModalState({ record: item, totalPayForRoom: additionalPrice });
      // To'lovlarni serverga jo'natish
      await updateRoomPayment({
        id: item._id,
        payments: payments // to'lovlar serverga jo'natilmoqda
      })
        .unwrap()
        .then((response) => {
          message.success(response.message);
        })
        .catch((err) => {
          message.error("To'lovni yangilashda xato yuz berdi");
          console.error("Xato:", err);
        });

      // Agar amal bo'lmasa, to'lovni serverga jo'natishda oddiy miqdorni ishlatamiz
      await updateRoomStory({ id: item._id, body: clientPrice })
        .then((res) => {
          if (res?.data?.success) {
            setAdditionalPrice(null);
          } else {
            // Xatolik bo'lsa xabar
          }
        })
        .catch((err) => {
          console.log(err); // Xatolikni ushlash
        });


      // ------------------------------
      const allData = {
        clientID: item.clientMongooseId,
        price: additionalPrice,
        storyId: item._id,
        doctorIdNumber: item.doctorIdNumber,
        type: "room",
      }

      await createPaymentStory(allData);
    }
  };

  // -------------------------------
  const getCardClassName = (isPaid, price) => {
    if (isPaid) return 'ant-card-green extro-colors';
    if (!isPaid && price > 0) return 'ant-card-yellow extro-colors';
    return 'ant-card-red extro-colors';
  };


  // -------------------------------
  // Funksiya to'lanmagan kunlarni va qarzni hisoblash uchun
  const calculateDebt = (roomData) => {
    const payForRoom = roomData.payForRoom; // Har bir kunning to'lov miqdori

    // Faqat to'lanmagan kunlarni olish
    const unpaidDays = roomData.paidDays?.filter(day => !day.isPaid);

    // Har bir to'lanmagan kun uchun qarzni hisoblash
    const totalDebt = unpaidDays.reduce((sum, day) => {
      const price = payForRoom - day.price; // Har bir kun uchun qarz miqdori
      return sum + price;
    }, 0);

    // Yangilangan qarzni saqlash
    return totalDebt;
  };


  const handleExpand = (expanded, record) => {
    const keys = expanded ? [record._id] : [];
    setExpandedRowKeys(keys);
  };


  const columns = [
    {
      title: 'Bemor',
      dataIndex: 'clientFullname',
      key: 'clientFullname',
      render: (text) => <span>{capitalizeFirstLetter(text)}</span>,
    },
    {
      title: 'Telefon raqami',
      dataIndex: 'clientPhone',
      key: 'clientPhone',
      render: (text) => <span>{`+998 ${PhoneNumberFormat(text)}`}</span>,
    },
    {
      title: 'Boshlanish sanasi',
      dataIndex: 'startDay',
      key: 'startDay',
      render: (text) => <span>{text}</span>,
    },
    {
      title: 'Davolanish kuni',
      dataIndex: 'paidDays',
      key: 'paidDays',
      render: (paidDays) => <span>{paidDays?.length} kun</span>,
    },
    {
      title: 'To\'langan summalar',
      dataIndex: 'clientPayForRoomPrice',
      key: 'clientPayForRoomPrice',
      render: (price) => <span>{NumberFormat(price)} so'm</span>,
    },
    {
      title: 'Xonadan chiqish',
      key: 'outRoom',
      render: (value) => (
        <Tooltip title="Xonadan chiqish">
          <Button
            onClick={() => OutInRoom(
              value?.clientID,
              room._id,
              {
                dayOfTreatment: CountingDay(moment(value.startDay, "DD.MM.YYYY HH:mm").format("DD.MM.YYYY")),
                payForRoom: calculateRoomPayment(value),
                id: value?._id,
                outDay: todaysTime,
              },
              value
            )}
            className="btn btn-primary"
          >
            <GiEntryDoor style={iconStyle} />
          </Button>
        </Tooltip>
      ),
    },
    {
      title: 'Xonani almashtrish',
      key: 'changeRoom',
      render: (value) => (
        <Select
          showSearch
          className="InputForm"
          placeholder="Xona tanlash"
          optionFilterProp="children"
          options={data}
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
          onChange={(id) => addUserToRoom(id, value)}
        />

      ),
    },
  ];

  const expandedRowRender = (record) => (
    <div style={{ display: "flex" }}>
      <div className="my-table-box my-table-box_extro">
        {record?.paidDays?.map((item, i) => {
          const isToday = moment(item.date).isSame(moment(), 'day'); // Sana bugunga tengligini tekshiradi
          const cardClassName = isToday
            ? `${getCardClassName(item?.isPaid, item?.price)} border-green`  // Yashil chegara qo'shiladi
            : getCardClassName(item?.isPaid, item?.price); // Aks holda oddiy class name

          return (
            <div key={i} className={cardClassName}>
              <p style={{ color: "fff" }}>{moment(item.date).format("DD.MM.YYYY")}</p>
              <p style={{ color: "fff" }}>{NumberFormat(item.price)} so'm</p>
            </div>
          );
        })}
      </div>
      <div className="extro-inp-box">
        <input
          type="text"
          placeholder={`${NumberFormat(calculateDebt(record))} so'm`}
          onChange={(e) => handleDebtPaymentChange(e, record)}
        />
        <Button disabled={isInputDisabled} type="primary" onClick={() => handlePayDebt(record)}>
          <PlusOutlined />
        </Button>
      </div>
    </div>
  );

  return (
    <LayoutWrapper>
      <div className="updateRoom_wrapper">
        <Table
          columns={columns}
          dataSource={roomData}
          pagination={false}
          size="small"
          bordered={true}
          rowKey="_id"
          expandable={{
            expandedRowRender,
            expandRowByClick: true,
            expandedRowKeys,
            onExpand: handleExpand,
          }}
        />

        {roomModalState && (
          <RoomModal data={roomModalState} close={setRoomModalState} />
        )}
      </div>
    </LayoutWrapper>
  );
}

export default EnterRoom;