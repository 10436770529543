import { createSlice } from "@reduxjs/toolkit";

export const searchSlice = createSlice({
    name: "search",
    initialState: {
        searchLoad: false,
    },
    reducers: {
        showSearch: (state) => {
            state.searchLoad = true
        }, hideSearch: (state) => {
            state.searchLoad = false
        },
    },
})

export const { showSearch, hideSearch } = searchSlice.actions