import React from 'react';
import { Progress } from 'antd';
import './style.css'

const PatentsVisit = ({ data, year, month, manthByNomber, twoColors1 }) => {

    const calculateDoctorTypeStatistics = (data) => {
        let statistics = {};
        data.forEach((item) => {
            const doctorType = item.doctorType;
            if (doctorType) {
                if (!statistics[doctorType]) {
                    statistics[doctorType] = 1;
                } else {
                    statistics[doctorType]++;
                }
            }
        });

        return statistics;
    };

    const doctorTypeStatistics = calculateDoctorTypeStatistics(data);
    const totalPatients = data.length;

    // Calculate percentages
    const percentages = Object.keys(doctorTypeStatistics).reduce((acc, doctorType) => {
        acc[doctorType] = (doctorTypeStatistics[doctorType] / totalPatients) * 100;
        return acc;
    }, {});

    return (
        <div style={{ padding: "10px" }}>
            <h4 style={{ textAlign: "center", color: twoColors1 }}> <h4 style={{ textAlign: "center", color: twoColors1 }}>Doktorlarga tashrif buyiryotkan bemorlar bo'yicha</h4></h4>
            <p style={{ textAlign: "center", color: twoColors1, lineHeight: '5px' }}>{`${year}-yil ${manthByNomber}-${month}`} holatiga ko‘ra</p>
            {Object.keys(percentages).map((doctorType) => (
                <div className="calculateDoctorType" key={doctorType}>
                    <p>{doctorType}</p>
                    <Progress percent={Math.floor(percentages[doctorType])} />
                </div>
            ))}
        </div>
    );
};

export default PatentsVisit;