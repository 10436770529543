import { createSlice } from "@reduxjs/toolkit";

export const clientLengthSlice = createSlice({
  name: "clientLength",
  initialState: {
    info: "",
  },
  reducers: {
    setLength: (state, action) => {
      state.info = action.payload || 0;
    },
  },
});

export const { setLength } = clientLengthSlice.actions;
export default clientLengthSlice.reducer;
