import React from 'react';
import './style.css'
import { useNavigate, useParams } from 'react-router-dom';
import { CiLogout } from "react-icons/ci";
import { CheckOutlined } from "@ant-design/icons";
import LayoutWrapper from '../../layout/Layout';
import { useGetTerapiyaQuery } from "../../../redux/terapiyaApi";
import { capitalizeFirstLetter } from '../../../hook/CapitalizeFirstLitter';

const FizioterapiyaList = () => {
    const history = useNavigate()
    const { _id } = useParams();
    let { data: innerData } = useGetTerapiyaQuery();
    let data = innerData?.innerData || [];

    const handleGoBack = () => {
        history(-1)
        history('/', { replace: true })
    }
    const dataRes = data?.filter((item) => item.clientID === _id)


    const transformedData = dataRes?.map(item => {
        const newObj = {};
        Object.entries(item).forEach(([key, value]) => {
            if (key !== 'banka' && value === true && !['__v', '_id', 'day', "doctorIdNumber", 'clientID'].includes(key)) {
                newObj[key] = capitalizeFirstLetter(key);
            } else if (key === 'banka' && value > 0) {
                newObj[key] = value;
            }
            // Adding the "res" key with the value being the key itself
        });

        return newObj;
    }).filter(obj => Object.keys(obj).length !== 0);

    // Creating div boxes for transformed data
    const divs = transformedData.map((item, index) => (
        <div className="X__slider" key={index}>
            {Object.entries(item).map(([key, value]) => (
                <div key={key} className="X__list-item">
                    <div className="X__list-icon">
                        <div className="X__svg-container">
                            <CheckOutlined />
                        </div>
                    </div>
                    <div className="X__label">{value}</div>
                </div>
            ))}
        </div>
    ));


    return (
        <LayoutWrapper>
            <div className="analiseNav">
                <button onClick={handleGoBack}>
                    <CiLogout />
                </button>
            </div>
            <div className="X__slider">
                {divs}
            </div>
        </LayoutWrapper>
    )
}

export default FizioterapiyaList;
