import React, { useEffect } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import LayoutWrapper from "../../../components/layout/Layout";
import "./App.css";
import Balans from "../balans/Balans";
import DoctorsSlite from "../swiper/Carousel";
import { useGetStoriesQuery } from "../../../redux/clientStores";
import socket from "../../../socket";

const HomePage = () => {
  const { data: allUser, refetch } = useGetStoriesQuery();
  const users = allUser?.innerData || [];

  useEffect(() => {
    socket.on("patient_paid", (u) => refetch());
    return () => socket.off("patient_paid");
  }, [refetch]);

  const currentMonth = new Date().getMonth() + 1;

  const allUserForChart = users
    .filter((i) => i.view === true)
    ?.filter((i) => i?.day?.split(".")[1] == currentMonth)
    ?.sort((a, b) => a?.day?.split(".")[2] < b?.day?.split(".")[2]);

  // pastdagi commentdagi kodlar bir oylik summa ni kodi

  function findWeeklyUsersLength(start, end) {
    return allUserForChart?.filter(
      (i) => i.day.split(".")[0] >= start && i.day.split(".")[0] <= end
    )?.length;
    // ?.reduce((a, b) => a + b.paySumm, 0);
  }

  const data = [
    {
      name: "1-hafta",
      Bemorlar: findWeeklyUsersLength(1, 7),
    },
    {
      name: "2-hafta",
      Bemorlar: findWeeklyUsersLength(8, 14),
    },
    {
      name: "3-hafta",
      Bemorlar: findWeeklyUsersLength(15, 21),
    },
    {
      name: "4-hafta",
      Bemorlar: findWeeklyUsersLength(22, 31),
    },
  ];

  return (
    <LayoutWrapper>
      <div className="HomeOwner">
        <DoctorsSlite />
      </div>
      <div className="charts">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey={"Bemorlar"}
              stroke="dodgerblue"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>

        <Balans />
      </div>
    </LayoutWrapper>
  );
};

export default HomePage;
