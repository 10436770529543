import React, { useState, useEffect } from "react";
import { message, Table, Button, Modal } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import imgNoData from "../../../assets/nodata.png";
import {
  useGetStoriesQuery,
  useDeleteStoryMutation,
} from "../../../redux/clientStores";
import { useUpdateStoryMutation } from "../../../redux/clientStores";
import { NumberFormat, PhoneNumberFormat } from "../../../hook/NumberFormat";

const OnlineRegistration = ({ query }) => {
  const { confirm } = Modal;
  const { data: stories } = useGetStoriesQuery();
  const dataStories = stories?.innerData || [];
  const [deleteStories] = useDeleteStoryMutation();
  const [updateStory] = useUpdateStoryMutation();
  const [filter, setFilter] = useState(localStorage.getItem("filter") || "all");
  const data = dataStories?.filter((item) => item.online === true);

  useEffect(() => {
    localStorage.setItem("filter", filter);
  }, [filter]);

  const updatePayState = (item) => {
    let update = {
      ...item,
    };
    update.online = false;

    updateStory({ id: item?._id, body: update })
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
        }
      })
      .catch((err) => console.error(err));
  };

  const showDeleteClients = (_id) => {
    confirm({
      title: "O'chirib tashlaysizmi?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk: async () => {
        try {
          const res = await deleteStories(_id);
          if (res?.data?.success) {
            message.success("Bemor o'chirildi!");
          } else {
            message.error(res?.data?.message);
          }
        } catch (err) {
          console.error(err);
          message.error("Nimadir xato ketdi, iltimos qayta urinib ko'ring.");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const today = moment().format("DD.MM.YYYY");

  const columns = [
    {
      title: "Tashrif sanasi",
      dataIndex: "day",
      key: "day",
      render: (text, record) => {
        return (
          <li
            style={{
              backgroundColor: moment(record.date, "DD.MM.YYYY").isBefore(
                moment(),
                "day"
              )
                ? "#FF0000"
                : moment(record.date, "DD.MM.YYYY").isAfter(moment(), "day")
                ? "#0000FF"
                : "#06c100",
              color: "#FFFFFF",
              paddingLeft: "5px",
              borderRadius: "4px",
              fontWeight: "600",
            }}
          >
            {text}
            {moment(record.date, "DD.MM.YYYY").isBefore(moment(), "day") && " "}
            {moment(record.date, "DD.MM.YYYY").isAfter(moment(), "day") && " "}
            {record.date === today && ""}
          </li>
        );
      },
    },
    { title: "Navbati", dataIndex: "queueNumber", key: "queueNumber" },
    { title: "Bemor", dataIndex: "clientFullname", key: "clientFullname" },
    { title: "Telefon", dataIndex: "clientPhone", key: "clientPhone" },
    { title: "Yo'naltirildi", dataIndex: "choseDoctor", key: "choseDoctor" },
    { title: "Doktor", dataIndex: "doctorFullname", key: "doctorFullname" },
    {
      title: "To'lov",
      dataIndex: "peySumm",
      key: "peySumm",
      render: (peySumm) => {
        return (
          <del className="Tolanmadi lee">
            {peySumm >= 1 ? NumberFormat(peySumm) + " so'm" : "Ko'rikdan so'ng"}
          </del>
        );
      },
    },
    {
      title: "Faollashtirish",
      key: "online",
      render: (record) => (
        <Button
          onClick={(e) => updatePayState(record.online)}
          disabled={
            moment(record.date, "DD.MM.YYYY").isAfter(moment(), "day") && true
          }
        >
          Qabul qilish
        </Button>
      ),
    },
    {
      title: "O'chirish",
      key: "delete",
      render: (_, record) => (
        <Button onClick={() => showDeleteClients(record._id)} danger>
          <RiDeleteBin6Line />
        </Button>
      ),
    },
  ];

  const formatSana = (sana) => {
    const [day, month, year] = sana?.split(".");
    const sanaObj = new Date(year, month - 1, day);
    const oyNomi = [
      "Yanvar",
      "Fevral",
      "Mart",
      "Aprel",
      "May",
      "Iyun",
      "Iyul",
      "Avgust",
      "Sentabr",
      "Oktabr",
      "Noyabr",
      "Dekabr",
    ];
    return `${sanaObj.getDate()}-${oyNomi[sanaObj.getMonth()]}`;
  };

  function parseDateString(dateString) {
    const [day, month, year] = dateString.split(".").map(Number);
    return new Date(year, month - 1, day);
  }

  const handleFilter = (filterType) => {
    setFilter(filterType);
  };

  const filteredData = data
    ?.filter((item) => {
      const itemDate = moment(item.day, "DD.MM.YYYY");
      if (filter === "before") {
        return itemDate.isBefore(moment(), "day");
      } else if (filter === "today") {
        return itemDate.isSame(moment(), "day");
      } else if (filter === "after") {
        return itemDate.isAfter(moment(), "day");
      } else {
        return true;
      }
    })
    ?.sort((a, b) => parseDateString(a.day) - parseDateString(b.day));

  return (
    <>
      <div className="search_Navigation-Bar">
        <button onClick={() => handleFilter("before")}></button>
        <button onClick={() => handleFilter("today")}></button>
        <button onClick={() => handleFilter("after")}></button>
        <button onClick={() => handleFilter("all")}></button>
      </div>
      {!filteredData || filteredData.length === 0 ? (
        <div className="NoData">
          <div className="NoDataImg">
            <img src={imgNoData} alt="No Data" />
          </div>
        </div>
      ) : (
        <Table
          dataSource={filteredData
            ?.filter((asd) => asd.clientFullname?.toLowerCase().includes(query))
            ?.map((item, index) => ({
              key: index,
              _id: item._id,
              day: formatSana(item?.day),
              queueNumber: item.queueNumber,
              clientFullname: item.clientFullname,
              clientPhone: PhoneNumberFormat(item.clientPhone),
              choseDoctor: item.doctorType,
              doctorFullname: item.doctorFullname,
              peySumm: item.paySumm,
              online: item,
              date: item.day,
            }))}
          loading={!filteredData ? true : false}
          bordered={true}
          size="small"
          columns={columns}
          pagination={false}
          rowKey={"_id"}
        />
      )}
    </>
  );
};

export default OnlineRegistration;
