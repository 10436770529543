import React from 'react'
import LayoutWrapper from '../../../components/layout/Layout'

const CalCenterStory = () => {
    return (
        <LayoutWrapper>
            <div>
                <h1>CalCenterStory</h1>
            </div>
        </LayoutWrapper>

    )
}


export default CalCenterStory
