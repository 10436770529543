import { api } from "./api";

export const onceTreatmentApi = api.injectEndpoints({
  reducerPath: "onceTreatmentApi",
  endpoints: (builder) => ({
    getAllOnceTreatments: builder.query({
      query: () => "/once/getAllOnceTreatments",
      providesTags: ["OnceTreatments"], // Ma'lumotlar uchun teglar (tags) berish
    }),
    createOnceTreatment: builder.mutation({
      query: (data) => ({
        url: "/once/createOnceTreatment",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["OnceTreatments", "getMainBalans"], // Ma'lumotlarni yangilashda teglarni (tags) tanlash
    }),
    getOnceTreatmentById: builder.query({
      query: (id) => `/once/getOnceTreatmentById/${id}`,
    }),
    updateOnceTreatment: builder.mutation({
      query: ({ id, data }) => ({
        url: `/once/updateOnceTreatment/${id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["OnceTreatments", "getMainBalans"], // Ma'lumotlarni yangilashda teglarni (tags) tanlash
    }),
    deleteOnceTreatment: builder.mutation({
      query: (id) => ({
        url: `/once/deleteOnceTreatment/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["OnceTreatments", "getMainBalans"], // Ma'lumotlarni yangilashda teglarni (tags) tanlash
    }),
    calculateTotalPaySummForMonth: builder.query({
      query: (month) => `/once/totalPaySumm/month/${month}`,
    }),
    calculateTotalPaySummForDay: builder.query({
      query: (date) => `/once/totalPaySumm/day/${date}`,
    }),
  }),
});

export const {
  useGetAllOnceTreatmentsQuery,
  useCreateOnceTreatmentMutation,
  useGetOnceTreatmentByIdQuery,
  useUpdateOnceTreatmentMutation,
  useDeleteOnceTreatmentMutation,
  useCalculateTotalPaySummForMonthQuery,
  useCalculateTotalPaySummForDayQuery,
} = onceTreatmentApi;
