import { api } from "./api";

export const kassaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // Get kassa data
    getKassa: builder.query({
      query: () => ({
        url: "/kassa/getKassa",
        method: "GET",
      }),
      providesTags: ["GetKassa"],
    }),
    // Create new kassa entry
    createKassa: builder.mutation({
      query: (body) => ({
        url: "/kassa/createKassa",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetKassa"],
    }),
    // Update kassa entry
    updateKassa: builder.mutation({
      query: (body) => ({
        url: "/kassa/updateKassa",
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetKassa"],
    }),
    // Delete kassa entry
    deleteKassa: builder.mutation({
      query: (body) => ({
        url: "/kassa/deleteKassa",
        method: "DELETE",
        body,
      }),
      invalidatesTags: ["GetKassa"],
    }),
    // Filter kassa data
    filterKassa: builder.query({
      query: (date) => ({
        url: `/kassa/filter`,
        method: "GET",
        params: { date }, // Pass date as a query parameter
      }),
      providesTags: ["GetKassa"],
    }),
  }),
});

export const {
  useGetKassaQuery,
  useCreateKassaMutation,
  useUpdateKassaMutation,
  useDeleteKassaMutation,
  useFilterKassaQuery, // Add the hook for the filterKassa endpoint
} = kassaApi;
