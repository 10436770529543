import React, { useState } from "react";
import { Table, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import Administrator from "../../update/Administratorlar";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";


const ReadReception = ({
  NumberFormat,
  reception,
  PhoneNumberFormat,
  showDeleteConfirm,
  query,
}) => {
  const [editID, setEditID] = useState(null);
  const [open, setOpen] = useState(false);

  const showModal = (id) => {
    setOpen(true);
    setEditID(id);
  };
  const Style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };


  const roleMap = {
    reception: 'Administrator',
    buhgalter: 'Kassir',
    calCenter: 'Dispetcher'
  };
  return (
    <>
      <Table
        rowKey={"_id"}
        dataSource={reception?.filter((doc) => doc.firstName?.includes(query))}
        pagination={false}
        loading={!reception ? true : false}
        bordered={true}
        size="small"
      >
        <Table.Column
          title="Mutaxassisligi"
          dataIndex="docORrecep"
          key="docORrecep"
          render={(text) => roleMap[text] || text}
        />
        <Table.Column
          title="Ismi"
          dataIndex="firstName"
          key="firstName"
          render={(text) => capitalizeFirstLetter(text)}
        />
        <Table.Column
          title="Familiyasi"
          dataIndex="lastName"
          key="lastName"
          render={(text) => capitalizeFirstLetter(text)}
        />

        <Table.Column
          title="Tel raqam"
          dataIndex="phone"
          key="phone"
          render={(text) => PhoneNumberFormat(text)}
        />
        <Table.Column
          title="Maoshi"
          dataIndex="salary"
          key="salary"
          render={(text) => NumberFormat(text) + " so'm"}
        />
        <Table.Column
          title="Ish vaqti"
          key="workTime"
          render={(text) => {
            const startTime = text?.workTime?.startTime?.slice(0, 5); // Extracts the time part only
            const endTime = text?.workTime?.endTime?.slice(0, 5); // Extracts the time part only
            return (
              <span>
                {startTime} - {endTime}
              </span>
            );
          }}
        />
        <Table.Column
          title="Taxrirlash"
          key="action"
          render={(text, record) => (
            <Link>
              <Button onClick={() => showModal(record._id)}>
                <EditOutlined style={{ fontSize: "20px" }} />
              </Button>
            </Link>
          )}
        />
        <Table.Column
          title="O'chirish"
          key="action"
          render={(text, record) => (
            <Button
              style={Style}
              onClick={() => showDeleteConfirm(record?._id)}
              type="primary"
              danger
            >
              <DeleteOutlined />
            </Button>
          )}
        />
      </Table>
      {open && (
        <Administrator
          reception={reception}
          editID={editID}
          setOpenUpdate={setOpen}
        />
      )}
    </>
  );
};

export default ReadReception;
