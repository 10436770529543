import React, { useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { FiX } from "react-icons/fi";
import OtpInput from "react-otp-input";
import Selectt from "react-select";
import { doctors } from "../addDoctors/doctors/DataProfessions";
import { useUpdateDoctorMutation } from "../../../redux/doctorApi";
import "./style.css";

function Update({ doctor, setOpenUpdate, editID }) {
  let update = doctor?.find((i) => i._id === editID) || {};

  const [firstName, setFirstName] = useState(update?.firstName || "");
  const [lastName, setLastName] = useState(update?.lastName || "");
  const [phone, setPhone] = useState(update?.phone || "");
  const [roomNumber, setRoomNumber] = useState(update?.roomNumber || "");
  const [address, setAddress] = useState({
    region: update?.address?.region || "",
    district: update?.address?.district || "",
    quarter: update?.address?.quarter || "",
  });
  const [login, setLogin] = useState(update?.login || "");
  const [password, setPassword] = useState(update?.password || "");
  const [specialization, setSpecialization] = useState(
    update?.specialization || ""
  );
  const [experience, setExperience] = useState(update?.experience || "");
  const [feesPerCunsaltation, setFeesPerCunsaltation] = useState(
    update?.feesPerCunsaltation || ""
  );
  const [docORrecep] = useState("doctor");
  const [percent, setPercent] = useState(update?.percent || "");
  const [salary, setSalary] = useState(update?.salary || "");
  const [roomPercent, setRoomPercent] = useState(update?.roomPercent || "");
  const [idNumber, setIdNumber] = useState(update?.idNumber || "");
  const [dateOfBirth, setDateOfBirth] = useState(update?.dateOfBirth || "");
  const [onceTreatmentPercent, setOnceTreatmentPercent] = useState(
    update.onceTreatmentPercent || 0
  );

  let [updateDoctor] = useUpdateDoctorMutation();

  function updateDoctors(e) {
    e.preventDefault();

    let newInfo = {
      ...update,
      idNumber: idNumber.toLowerCase(),
      dateOfBirth,
      firstName,
      lastName,
      phone,
      roomNumber,
      address,
      login,
      password,
      specialization,
      roomPercent,
      experience,
      feesPerCunsaltation: +feesPerCunsaltation,
      docORrecep,
      percent: +percent,
      regularSalary: +salary,
      onceTreatmentPercent: +onceTreatmentPercent,
    };

    updateDoctor({ id: editID, body: newInfo })
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
        }
      })
      .catch((err) => console.log("err", err));
  }

  const handleChangeSelect = (value, option) => {
    setSpecialization({ name: value.label, value: value.value });
  };

  function flattenDoctors(doctors) {
    return (
      doctors?.flatMap((grp) =>
        grp?.options.map((option) => ({
          value: option.value,
          label: option.name,
        }))
      ) ?? []
    );
  }

  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(inputValue)) {
      message.warning("Invalid date!");
    }
    setDateOfBirth(inputValue);
  };

  return (
    <div className="updateDoctor">
      <div className="editForm">
        <Form className="FormItem" layout="vertical">
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Ism">
                <Input
                  type="text"
                  defaultValue={update?.firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Ism"
                  name="firstName"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Familiya">
                <Input
                  type="text"
                  defaultValue={update?.lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Familiya"
                  name="lastName"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Telefon raqami">
                <Input
                  type="text"
                  defaultValue={update?.phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Telefon raqami"
                  name="phone"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Viloyat">
                <Input
                  type="text"
                  defaultValue={update?.address?.region}
                  onChange={(e) =>
                    setAddress({ ...address, region: e.target.value })
                  }
                  placeholder="Viloyat"
                  name="region"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Tuman">
                <Input
                  type="text"
                  defaultValue={update?.address?.district}
                  onChange={(e) =>
                    setAddress({ ...address, district: e.target.value })
                  }
                  placeholder="Tuman"
                  name="district"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Mahalla">
                <Input
                  type="text"
                  defaultValue={update?.address?.quarter}
                  onChange={(e) =>
                    setAddress({ ...address, quarter: e.target.value })
                  }
                  placeholder="Mahalla"
                  name="quarter"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Mutaxassislik">
                {/* <Input
                  type="text"
                  defaultValue={update?.specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  placeholder="Mutaxassislik"
                  name="specialization"
                /> */}
                <Selectt
                  onChange={handleChangeSelect}
                  placeholder="Doktorni tanlang"
                  isSearchable // react-select is searchable by default
                  options={flattenDoctors(doctors)} // Assuming you flatten your doctors array
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Login">
                <Input
                  type="text"
                  defaultValue={update?.login}
                  onChange={(e) => setLogin(e.target.value)}
                  placeholder="Login"
                  name="login"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Parol">
                <Input
                  type="text"
                  defaultValue={update?.password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Parol"
                  name="password"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Row gutter={22}>
                <Col span={12}>
                  <Form.Item label="Tajriba">
                    <Input
                      type="text"
                      defaultValue={update?.experience}
                      onChange={(e) => setExperience(e.target.value)}
                      placeholder="Tajriba"
                      name="experience"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label="Xona raqami">
                    <Input
                      type="text"
                      defaultValue={update?.roomNumber}
                      onChange={(e) => setRoomNumber(e.target.value)}
                      placeholder="Xona raqami"
                      name="roomNumber"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            {!update?.type === "analis" && (
              <Col span={8}>
                <Form.Item label="Konsultatsiya to'lov miqdori">
                  <Input
                    type="text"
                    defaultValue={update?.feesPerCunsaltation}
                    onChange={(e) => setFeesPerCunsaltation(e.target.value)}
                    placeholder="Konsultatsiya to'lov miqdori"
                    name="feesPerCunsaltation"
                  />
                </Form.Item>
              </Col>
            )}

            {/* {update?.salaryType === "percent" ? ( */}
            <Col span={8}>
              <Row gutter={22}>
                <Col span={12}>
                  <Form.Item label="Oylik (Foiz)">
                    <Input
                      type="text"
                      defaultValue={update?.percent}
                      onChange={(e) => setPercent(e.target.value)}
                      placeholder="Foiz"
                      name="percent"
                    />
                  </Form.Item>
                </Col>
                {/* ) : ( */}
                <Col span={12}>
                  <Form.Item label="Oylik">
                    <Input
                      type="text"
                      defaultValue={update?.regularSalary}
                      onChange={(e) => setSalary(e.target.value)}
                      placeholder="Oylik"
                      name="salary"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* )} */}

            {update?.type === "doctor" ? (
              <Col span={8}>
                <Form.Item label="Davolash (Foizda)">
                  <Input
                    type="text"
                    defaultValue={update?.roomPercent}
                    onChange={(e) => setRoomPercent(e.target.value)}
                    placeholder="Davolash foizi"
                    name="roomPercent"
                  />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Qabul uchun to'lov miqdori">
                <Input
                  value={feesPerCunsaltation}
                  onChange={(e) => setFeesPerCunsaltation(e.target.value)}
                  type="number"
                  placeholder="Qabul uchun to'lov miqdori"
                  disabled={specialization.value === "analis" ? "disabled" : ""}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={"Shaxsiy raqami" + ` (hozirgisi: ${idNumber})`}
                name="ID number"
              >
                <OtpInput
                  inputStyle={{
                    border: "1px solid #ddd",
                    margin: "2px",
                    borderRadius: "5px",
                    width: "32px",
                    height: "32px",
                  }}
                  value={idNumber}
                  onChange={setIdNumber}
                  numInputs={9}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  placeholder="ab1234567"
                />
              </Form.Item>
            </Col>
            <Col className="Col-Form">
              <Form.Item
                label="Tug'ilgan sana:"
                name="year"
                direction="vertical"
              >
                <Input
                  type="date"
                  value={dateOfBirth}
                  defaultValue={dateOfBirth}
                  onChange={handleDateChange}
                  placeholder="yil/oy/kun"
                />
                {/* className="DatePicer" */}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Muolaja dan olinadigan foiz"
                name="onceTreatmentPercent"
              >
                <Input
                  type="text"
                  defaultValue={update?.onceTreatmentPercent}
                  onChange={(e) => setOnceTreatmentPercent(e.target.value)}
                  placeholder="Muolajadan olinadigan foiz"
                />
              </Form.Item>
            </Col>
          </Row>

          <div style={{ display: "flex", gap: "5px" }}>
            <Button
              style={{ width: "100%" }}
              type="primary"
              onClick={(e) => {
                updateDoctors(e);
                setTimeout(() => {
                  setOpenUpdate(false);
                }, 1000);
              }}
            >
              Taxrirlash
            </Button>
            <Button type="primary" danger onClick={() => setOpenUpdate(false)}>
              <FiX style={{ marginTop: "-4px", fontSize: "22px" }} />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Update;
