import React from "react";
import "./style.css";
import { Table, Button, Tabs } from "antd";
import { Link } from "react-router-dom";
import { EyeOutlined } from "@ant-design/icons";
import { NumberFormat } from "../../../hook/NumberFormat";
import LayoutWrapper from "../../../components/layout/Layout";
import { useGetRoomStoriesQuery } from "../../../redux/roomStoriesApi";
// import { useGetRoomStoriesQuery } from "../../../redux/roomStoriesApi";
import { ColculateBorthday } from "../../../hook/ColculateBorthday";
import { useGetAllRoomsQuery } from "../../../redux/roomApi";
import { CountingDay } from "../../../hook/ColculateDay";

function CabindEnter() {
  let { data: data } = useGetRoomStoriesQuery();
  let roomData = data?.innerData || [];
  let category = localStorage?.getItem("doctorID");
  let { data: rooms } = useGetAllRoomsQuery();
  let Allrooms = rooms?.innerData;

  let clients = roomData?.filter(
    (i) => i?.doctorIdNumber === category && i?.active === true
  );

  const specializations = "room";

  // ==================Dr. patients==========================
  const columns = [
    {
      title: "№ Xona/Qavati",
      dataIndex: "xona",
    },
    {
      title: "Bemor",
      dataIndex: "bemor",
    },
    {
      title: "Yoshi",
      dataIndex: "age",
      render: (age) => ColculateBorthday(age),
    },
    {
      title: "№ Tel",
      dataIndex: "tel",
    },
    {
      title: "Kun",
      dataIndex: "kun",
    },
    {
      title: "Tarix",
      dataIndex: "key",
      render: (key) => (
        <Link Link to={`/poteintsSlinglePage/${key}/${specializations}`}>
          <Button className="EyeOutlined" icon={<EyeOutlined />} />
          {console.log(key)}
        </Link>
      ),
    },
  ];
  let dataRoom = [];
  if (clients) {
    dataRoom = Array.from(Object.values(clients), (item) => ({
      key: item.clientMongooseId,
      xona: item?.roomNumber + "/" + item?.floor,
      age: item?.birthday,
      bemor: item?.clientFullname,
      tel: item?.clientPhone,
      kun: CountingDay(item?.startDay),
      active: item?.active,
    }));
  }

  // ==================Empty-rooms==========================
  let AllroomsData = Allrooms?.filter(
    (i) => i?.usersNumber - i?.capacity.length !== 0
  );
  const columnsRoom = [
    {
      title: "№ Xona",
      dataIndex: "xona",
    },
    {
      title: "№ Qavati",
      dataIndex: "floor",
    },
    {
      title: "Xona sig'imi",
      dataIndex: "sigim",
    },
    {
      title: "Bo'sh joy",
      dataIndex: "joy",
    },
    {
      title: "Kategoriy",
      dataIndex: "kategoriy",
    },
    {
      title: "Kunlik narxi",
      dataIndex: "kun",
    },
  ];
  let dataEmptyRoom = [];
  if (AllroomsData) {
    dataEmptyRoom = Array.from(Object.values(AllroomsData), (item, i) => ({
      key: i + 1,
      xona: item?.roomNumber,
      floor: item?.floor,
      sigim: item?.usersNumber,
      joy: item?.usersNumber - item?.capacity.length,
      kategoriy: item?.category,
      kun: NumberFormat(item?.pricePerDay) + " " + "so'm",
    }));
  }

  return (
    <LayoutWrapper>
      <Tabs>
        <Tabs.TabPane defaultActiveKey="0" tab={"Bemorlar"} key={0}>
          <Table
            rowKey={"key"}
            pagination={false}
            columns={columns}
            dataSource={dataRoom}
            bordered={true}
            size="small"
            paxxxgination={false}
            loading={!dataRoom}
          />
        </Tabs.TabPane>

        <Tabs.TabPane defaultActiveKey="1" tab="Bo'sh xonalar" key={1}>
          <Table
            rowKey={"_id"}
            pagination={false}
            columns={columnsRoom}
            dataSource={dataEmptyRoom}
            bordered={true}
            size="small"
            paxxxgination={false}
            loading={!dataEmptyRoom}
          />
        </Tabs.TabPane>
      </Tabs>
    </LayoutWrapper>
  );
}

export default CabindEnter;
