import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, message, Row, TimePicker } from "antd";
import { FiX } from "react-icons/fi";
import dayjs from "dayjs";
import Selectt from "react-select";
import OtpInput from "react-otp-input";
import customParseFormat from "dayjs/plugin/customParseFormat";
import axios from "axios";

import { useUpdateNursesMutation } from "../../../redux/nursesApi";
dayjs.extend(customParseFormat);

const Hamshira = ({ Nurses, setOpenUpdate, editID }) => {
  const update = Nurses?.find((i) => i._id === editID) || {};
  const [form] = Form.useForm(); // useForm hook'ini ishlatish
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [otp, setOtp] = useState("");

  const [address, setAddress] = useState({
    region: update?.address?.region || "",
    district: update?.address?.district || "",
    quarter: update?.address?.quarter || "",
  });

  const [extraClass, setExtraClass] = useState(false);
  const [extraClass2, setExtraClass2] = useState(false);
  const [extraClass3, setExtraClass3] = useState(false);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);

  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };

  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));
  }, []);

  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };

  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  const [updateNurses] = useUpdateNursesMutation();

  const onFinish = (values) => {
    const newInfo = {
      ...update,
      ...values,
      phone: values.phone.replace("+998", ""),
      address,
      salary: +values.salary,
      workTime: {
        startTime, // Add startTime
        endTime, // Add endTime
      },
    };

    // console.log(newInfo);
    updateNurses({ id: editID, body: newInfo })
      .then((res) => {
        console.log(res);
        if (res?.data?.success) {
          message.success(res?.data?.message);
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => setOpenUpdate(false));
  };

  const onChangeStart = (time, timeString) => {
    setStartTime(timeString);
  };
  const onChangeEnt = (time, timeString) => {
    setEndTime(timeString);
  };
  return (
    <div className="updateDoctor">
      <div className="editForm">
        {/* <FiX className="updateCloseBtn" onClick={() => setOpenUpdate(false)} /> */}
        <h3 style={{ textAlign: "center" }}>Yangilash</h3>
        <Form
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={update}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Ism" name="firstName">
                <Input placeholder="Ism" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Familiya" name="lastName">
                <Input placeholder="Familiya" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Tug'ilgan yili" name="dateOfBirth">
                <Input placeholder="Tug'ilgan yili" />
              </Form.Item>
            </Col>

            <Col>
              <div
                style={{
                  display: "flex",
                  gap: "30px",
                }}
              >
                <Form.Item label="Kunlik maosh" name="dayliMoney">
                  <Input placeholder="Maosh" />
                </Form.Item>

                <Form.Item label="Tungi navbat" name="nightMoney">
                  <Input placeholder="Maosh" />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Shaxsiy raqami" name="idNumber">
                <OtpInput
                  inputStyle={{
                    border: "1px solid #ddd",
                    margin: "2.2px",
                    borderRadius: "5px",
                    width: "30px",
                    height: "32px",
                  }}
                  value={otp}
                  onChange={setOtp}
                  numInputs={9}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  placeholder="ab1234567"
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Telefon raqami" name="phone">
                <Input placeholder="Telefon raqami" />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <Row gutter={16}>
                <Col>
                  <Form.Item
                    className={extraClass && "updateAdministrator_select"}
                    label="Viloyat"
                  >
                    <Selectt
                      styles={{ background: "red" }}
                      onMenuOpen={() => setExtraClass(true)}
                      onMenuClose={() => setExtraClass(false)}
                      onChange={(e) => {
                        getDistricts(e.regionID);
                        setAddress({ ...address, region: e.value });
                      }}
                      options={regions}
                      placeholder={"Viloyat"}
                      defaultInputValue={update?.address?.region}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    className={extraClass2 && "updateAdministrator_select"}
                    label="Shahar Tuman"
                  >
                    <Selectt
                      onMenuOpen={() => setExtraClass2(true)}
                      onMenuClose={() => setExtraClass2(false)}
                      onChange={(e) => {
                        getQuarters(e.districtID);
                        setAddress({ ...address, district: e.value });
                      }}
                      options={districts}
                      placeholder={"Tuman"}
                      defaultInputValue={update?.address?.district}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item
                    className={extraClass3 && "updateAdministrator_select"}
                    label="Mahalla Fuqarolar yig‘ini"
                  >
                    <Selectt
                      onMenuOpen={() => setExtraClass3(true)}
                      onMenuClose={() => setExtraClass3(false)}
                      options={quarters}
                      placeholder={"M.F.Y"}
                      onChange={(e) =>
                        setAddress({ ...address, quarter: e.value })
                      }
                      defaultInputValue={update?.address?.quarter}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row gutter={16}>
                <Col span={18}>
                  <Form.Item label="Ish vaqti" name="workTime">
                    <div style={{ display: "flex", gap: "10px" }}>
                      <TimePicker
                        placeholder="Boshlanishi"
                        onChange={onChangeStart}
                        defaultValue={dayjs(
                          update?.workTime?.startTime,
                          "HH:mm"
                        )}
                      />
                      <TimePicker
                        placeholder="Tugashi"
                        onChange={onChangeEnt}
                        defaultValue={dayjs(update?.workTime?.endTime, "HH:mm")}
                      />
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <div
            style={{
              display: "flex",
              gap: "10px",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button htmlType="submit" style={{ width: "95%" }} type="primary">
              Taxrirlash
            </Button>
            <Button
              style={{
                color: "#fff",
                display: "flex",
                width: "5%",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "24px",
              }}
              type="primary"
              danger
            >
              <FiX onClick={() => setOpenUpdate(false)} />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Hamshira;
