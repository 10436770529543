import React, { useState, useMemo, useEffect } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Table, Button, Pagination, DatePicker } from "antd";
import { Link } from "react-router-dom";
import { LuClipboardEdit } from "react-icons/lu";
import moment from "moment";
import { useGetUserByParamQuery } from "../../../redux/clientStores";
import { ColculateBorthday } from "../../../hook/ColculateBorthday";
import EditUser from "../Cabins/editUser/EditUser";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import { PhoneNumberFormat } from "../../../hook/NumberFormat";
import "./style.css";

const { RangePicker } = DatePicker;

const Patients = ({ doctorQuery, query, dataStories }) => {
  const [openUse, setOpenUse] = useState(false);
  const [editIDUse, setEditIDUse] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [dateRange, setDateRange] = useState([null, null]);

  const { data: users = [] } = useGetUserByParamQuery({
    pageParam: currentPage,
    searchQuery: query,
    doctorIdNumber: doctorQuery,
    startDate: dateRange[0] ? dateRange[0].format("DD.MM.YYYY") : null,
    endDate: dateRange[1] ? dateRange[1].format("DD.MM.YYYY") : null,
  });
  const AllData = useMemo(() => users?.data || [], [users?.data]);

  useEffect(() => {
    if (users.success) {
      setTotalPages(Math.ceil(users.totalClients / 10));
    }
  }, [users]);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates || [null, null]); // Ensure dateRange is never null
    setCurrentPage(1); // Reset to first page when date range changes
  };

  const dataUsers = useMemo(() => {
    let filteredData = AllData;

    if (dateRange && dateRange[0] && dateRange[1]) {
      const [start, end] = dateRange;

      filteredData = filteredData.filter((item) => {
        // const itemDate = moment(item.sana, "DD.MM.YYYY");
        const itemDate = moment(item.day, "DD.MM.YYYY");
        return itemDate.isBetween(start, end, "days", "[]");
      });
    }

    return filteredData
      .filter((i) => i?.view === true && i?.online !== true)
      .filter((doc) => doc.doctorIdNumber?.includes(doctorQuery))
      .filter((doc) => doc.clientFullname?.includes(query));
  }, [AllData, dateRange, doctorQuery, query]);

  const columnsUsers = [
    {
      title: "Sana",
      dataIndex: "sana",
      render: (text, record) => record.day,
    },
    {
      title: "Bemor",
      dataIndex: "fullName",
      render: (e, v) =>
        capitalizeFirstLetter(v.clientFullname.split(" ")[0]) +
        " " +
        capitalizeFirstLetter(v.clientFullname.split(" ")[1]),
    },
    {
      title: "Yoshi",
      dataIndex: "birthday",
      render: (text) => ColculateBorthday(text),
    },
    {
      title: "Telefon",
      dataIndex: "clientPhone",
      render: (text) => PhoneNumberFormat(text),
    },
    {
      title: "Yo'naltirildi",
      dataIndex: "doctorType",
      render: (text) => capitalizeFirstLetter(text),
    },
    {
      title: "Address",
      dataIndex: "address",
      render: (address) => {
        if (!address) {
          return (
            <div className="addressRegn">
              <span>N/A</span>/<span>N/A</span>/<span>N/A</span>
            </div>
          );
        }

        const { region, district, quarter } = address;
        return (
          <div className="addressRegn">
            <span> {region || "N/A"} </span>/<span> {district || "N/A"} </span>/
            <span> {quarter || "N/A"} </span>
          </div>
        );
      },
    },
    {
      title: "Taxrirlash",
      dataIndex: "_id",
      render: (text, record) => (
        <Button
          type="primary"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
          }}
          onClick={() => {
            setOpenUse(true);
            setEditIDUse({ user_id: record.clientID, story_id: record._id });
          }}
        >
          <LuClipboardEdit />
        </Button>
      ),
    },
    {
      title: "Tarix",
      dataIndex: "_id",
      render: (text, record) => (
        <Link to={`/poteintsSlinglePage/${record?._id}`}>
          <Button
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "18px",
            }}
            onClick={() => {
              setOpenUse(true);
              setEditIDUse(record._id);
            }}
          >
            <EyeOutlined />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <RangePicker format="DD.MM.YYYY" onChange={handleDateRangeChange} />
      <Table
        dataSource={dataUsers}
        bordered={true}
        size="small"
        loading={!dataUsers}
        pagination={false} // Disable default pagination
        columns={columnsUsers}
        rowKey="_id"
      />
      {users?.totalClients > 10 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "4px 0",
          }}
        >
          <Pagination
            current={currentPage}
            total={totalPages * 10}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
      {openUse && <EditUser editID={editIDUse} setOpenUpdate={setOpenUse} />}
    </>
  );
};

export default Patients;
