import React, { useState, useEffect } from "react";
import "./style.css";
import { useParams, useNavigate } from "react-router-dom";
import { Button, message, Checkbox } from "antd";
import moment from "moment";
import LayoutWrapper from "../../../../components/layout/Layout";
import {
  useGetStoriesQuery,
  useUpdateStoryPartMutation,
} from "../../../../redux/clientStores";
import { useUpdateClientMutation } from "../../../../redux/clientApi";
import { useGetSingleClinicInfoQuery } from "../../../../redux/clinicApi";
import { useCreatePaymentStoryMutation } from "../../../../redux/paymentApi";
import {
  useGetRoomStoriesQuery,
  useUpdateRoomStoriesMutation,
} from "../../../../redux/roomStoriesApi";
import { NumberFormat, PhoneNumberFormat } from "../../../../hook/NumberFormat";

function QarzdorliList() {
  const { _id, user } = useParams();
  const patient = JSON.parse(user);
  const history = useNavigate();
  let { data: stories } = useGetStoriesQuery();
  let dataStories = stories?.innerData || [];
  let filterStores = dataStories?.filter(
    ({ clientID, debtor, view, online }) =>
      clientID === _id && debtor && view && !online
  );

  const { data: roomStories } = useGetRoomStoriesQuery();
  let dataRoom = roomStories?.innerData || [];

  const firstDataArray = dataRoom?.filter(
    (i) =>
      i.clientID === patient?.idNumber &&
      i.paidDays.some((day) => day.price === 0)
  );

  const { data: clinic } = useGetSingleClinicInfoQuery(
    localStorage.getItem("clinicId")
  );
  const clinicData = clinic?.data;

  const [createPaymentStory] = useCreatePaymentStoryMutation();
  const [updateStoryPart] = useUpdateStoryPartMutation();
  const [updateRoomStories] = useUpdateRoomStoriesMutation();
  const [updateClient] = useUpdateClientMutation();

  // ----------------------------------------------------------
  const [totalDebt, setTotalDebt] = useState(0);

  useEffect(() => {
    // `filterStores` dan qarzdorlik summasini hisoblash
    const secondDataDebt = filterStores?.reduce((acc, patient) => {
      return acc + patient.paySumm;
    }, 0);

    // `firstDataArray` dan to'lanmagan va qisman to'langan kunlar uchun to'lanishi kerak bo'lgan summani hisoblash
    const firstDataDebt = firstDataArray?.reduce((acc, room) => {
      // To'lanmagan kunlar va qisman to'langan kunlarni hisoblash
      const unpaidAmount = room.paidDays?.reduce((dayAcc, day) => {
        if (!day.isPaid) {
          if (day.price === 0) {
            // Agar kun uchun hech qanday to'lov qilinmagan bo'lsa, to'liq `payForRoom` miqdorini qo'shamiz
            return dayAcc + room.payForRoom;
          } else if (day.price < room.payForRoom) {
            // Agar qisman to'lov qilingan bo'lsa, qolgan qarzdorlik miqdorini qo'shamiz
            return dayAcc + (room.payForRoom - day.price);
          }
        }
        return dayAcc; // To'liq to'langan kunlarni hisobga olmaslik
      }, 0);

      return acc + unpaidAmount;
    }, 0);

    // Umumiy qarzlarni hisoblash
    const totalDebt = secondDataDebt + firstDataDebt;
    setTotalDebt(totalDebt);
  }, [filterStores, firstDataArray]);

  // ----------------------------------------------------------
  function formatManagerName(fullName) {
    if (!fullName) return ""; // Agar fullName bo'lmasa, bo'sh qiymat qaytaradi
    const nameParts = fullName?.split(" "); // Bo'shliq bo'yicha ajratamiz
    const firstName = nameParts[0]; // Ism
    const lastNameInitial = nameParts[1]?.charAt(0); // Familiya bosh harfi (M)

    // Agar ism va familiya mavjud bo'lsa, to'g'ri formatda chiqaramiz
    return firstName + " " + (lastNameInitial ? lastNameInitial + "." : "");
  }

  const data = {
    name: patient?.firstname + " " + patient?.lastname,
    birthDate: moment(patient?.year).format("DD.MM.YYYY"),
    phone: "+998 " + PhoneNumberFormat(patient?.phone),
    gender: patient?.gender === "male" ? "Erkak" : "Ayol",
    employeeInfo: {
      name: formatManagerName(clinicData?.manager), // Qisqartirilgan ism va familiya
      dateTime: moment().format("HH:mm / DD.MM.YYYY"), // Hozirgi vaqt va sana
    },
  };
  const patientData = data;
  const handleGoBack = () => {
    history(-1);
    history("/", { replace: true });
  };

  // ----------------------------------------------------------
  // Qarzdorlikni hisoblash funksiyasi
  const calculateDebt = (service) => {
    const unpaidAmount = service?.paidDays?.reduce((dayAcc, day) => {
      if (!day.isPaid) {
        if (day.price === 0) {
          // Agar kun uchun hech qanday to'lov qilinmagan bo'lsa, to'liq `payForRoom` miqdorini qo'shamiz
          return dayAcc + service?.payForRoom;
        } else if (day.price < service?.payForRoom) {
          // Agar qisman to'lov qilingan bo'lsa, qolgan qarzdorlik miqdorini qo'shamiz
          return dayAcc + (service?.payForRoom - day.price);
        }
      }
      return dayAcc; // To'liq to'langan kunlarni hisobga olmaslik
    }, 0);
    return unpaidAmount;
  };

  const [totalAmount, setTotalAmount] = useState(0);
  // const [updatedData, setUpdatedData] = useState([...filterStores, ...firstDataArray]); // O'zgargan ma'lumotlar uchun massiv
  const [updatedData, setUpdatedData] = useState([]); // O'zgargan ma'lumotlar uchun massiv

  // Checkbox o'zgarganda chaqiriladigan funksiya
  const handleCheckboxChange = (service, type, checked) => {
    let updatedArray;

    // Har bir ob'ektdan nusxa olish va yangi debtor qiymatini qo'shish
    const updatedService = { ...service, debtor: !checked };

    // Jami summani yangilash
    if (checked) {
      if (type === "filterStores") {
        setTotalAmount((prev) => prev + service.paySumm);
      } else {
        setTotalAmount((prev) => prev + calculateDebt(service));
      }
    } else {
      if (type === "filterStores") {
        setTotalAmount((prev) => prev - service.paySumm);
      } else {
        setTotalAmount((prev) => prev - calculateDebt(service));
      }
    }

    // Har bir massiv ustidan iteratsiya qilish
    if (type === "filterStores") {
      updatedArray = filterStores.map((item) =>
        item === service ? updatedService : item
      );
    } else {
      updatedArray = firstDataArray.map((item) =>
        item === service ? updatedService : item
      );
    }

    // Faol checkboxlar bilan yangilangan massiv
    const activeServices = updatedArray.filter((item) => item.debtor === false);

    // Faqat faol checkboxlar uchun yangilangan ma'lumotlarni saqlash
    setUpdatedData((prev) => {
      if (checked) {
        // Yangi ma'lumotlar alohida saqlanadi
        const newServices = activeServices?.filter(
          (item) => !prev.some((existing) => existing._id === item._id)
        );
        return [...prev, ...newServices]; // Yangi xizmatlarni qo'shish
      } else {
        // Agar checkbox olib tashlangan bo'lsa, bu xizmatni olib tashlash
        return prev.filter((item) => item._id !== service._id);
      }
    });
  };

  const saveCheckedData = async () => {
    try {
      if (!updatedData || !updatedData.length)
        return message.success("Ma'lumotlarni kiriting.");

      for (let value of updatedData) {
        const isRoomPayment = !!value.paidDays;
        const price = isRoomPayment ? calculateDebt(value) : value.paySumm;

        const allData = {
          clientID: value.clientID,
          price: price,
          storyId: value._id,
          doctorIdNumber: value.doctorIdNumber,
          type: isRoomPayment ? "room" : "viewing",
        };

        // Create payment story
        await createPaymentStory(allData);

        if (isRoomPayment) {
          let newPaymentsTotal = 0; // To store newly added payments only

          // Optimize paidDays processing
          const updatedPaidMoney = value.paidDays.map((item) => {
            const currentPrice = item.price || 0;
            const remainingPayment = value.payForRoom - currentPrice;

            if (!item.isPaid && remainingPayment > 0) {
              newPaymentsTotal += remainingPayment;
              return {
                ...item,
                isPaid: true,
                price: currentPrice + remainingPayment,
              };
            }

            // Return unchanged if already paid or no remaining payment
            return {
              ...item,
              price: value.payForRoom,
              isPaid: true,
            };
          });

          // Update the client payment total for the room
          const updatedClientPayForRoomPrice =
            (value.clientPayForRoomPrice || 0) + newPaymentsTotal;

          await updateRoomStories({
            id: value._id,
            body: {
              ...value,
              debtor: false,
              paidDays: updatedPaidMoney,
              clientPayForRoomPrice: updatedClientPayForRoomPrice,
            },
          });
        } else {
          await updateStoryPart({
            id: value._id,
            body: { ...value, debtor: false },
          });
        }
      }

      // If total debt is zero, update client's debt status
      if (totalDebt === 0) {
        await updateClient({
          id: patient.clientID,
          body: { ...patient, debtor: false, totalPrice: 0 },
        });
      }

      // Clear the state after processing
      setUpdatedData([]);
      setTotalAmount(0);
      message.success("To'lov muvaffaqiyatli amalga oshirildi!");
    } catch (error) {
      console.error(error);
      message.warning("To'lovlarni amalga oshirishda xatolik yuz berdi!");
    }
  };

  // Update the client's debt status if necessary
  useEffect(() => {
    const updateClientDebtStatus = async () => {
      try {
        // Agar `firstDataArray` va `filterStores` mavjud emas bo‘lsa
        if (!firstDataArray && !filterStores) {
          // Debtor holatini yangilash lozim bo'lsa
          await updateClient({
            id: patient._id,
            body: { ...patient, debtor: false, totalPrice: 0 },
          });
          history("/debtors");
        }
      } catch (error) {
        console.error("Error updating client:", error);
      }
    };

    // faqat `firstDataArray` va `filterStores` mavjud emas holatida chaqiriladi
    if (!firstDataArray && !filterStores) {
      updateClientDebtStatus();
    }
  }, [filterStores, firstDataArray, patient, updateClient]);

  return (
    <LayoutWrapper>
      <div className="App_Qarz">
        <div className="header_Qarz">
          <h1 onClick={handleGoBack} className="logo_Qarz">
            {clinicData?.name}
          </h1>
          <div className="contact-info">
            <p>{clinicData?.address}</p>
            <p>+998 {PhoneNumberFormat(clinicData?.contacts[0])}</p>
          </div>
        </div>

        <h2>Patsiyent haqida ma'lumot</h2>
        <table className="services">
          <thead>
            <tr>
              <th>F.I.O</th>
              <th>Tug'ilgan sana</th>
              <th>Telefon</th>
              <th>Jins</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{patientData.name}</td>
              <td>{patientData.birthDate}</td>
              <td>{patientData.phone}</td>
              <td>{patientData.gender}</td>
            </tr>
          </tbody>
        </table>

        <h2>Xizmatlar</h2>
        <table style={{ marginBottom: "5px" }} className="services">
          <thead>
            <tr>
              <th>T/R</th>
              <th>Doktor</th>
              <th>Sana</th>
              <th>Soni</th>
              <th>Narxi</th>
              <th>To‘lashi kerak</th>
              <th>Tanlash</th>
            </tr>
          </thead>
          <tbody>
            {filterStores?.map((service, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{service.doctorType}</td>
                <td>{service.day}</td>
                <td>{1}</td>
                <td>{NumberFormat(service.paySumm)}</td>
                <td>{NumberFormat(service.paySumm)}</td>
                <td>
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxChange(
                        service,
                        "filterStores",
                        e.target.checked
                      )
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Ikkinchi jadval */}
        <table style={{ marginTop: "0" }} className="services">
          <thead>
            <tr>
              <th></th>
              <th>Doktor</th>
              <th>Palataga yotgan sana</th>
              <th>Necha kun yotgan</th>
              <th>To‘lagan puli</th>
              <th>To‘lashi kerak</th>
              <th>Palatadan chiqqan sana</th>
              <th>Tanlash</th>
            </tr>
          </thead>
          <tbody>
            {firstDataArray?.map((service, index) => (
              <tr key={index}>
                <td>{filterStores?.length + index + 1}</td>
                <td>{service.doctorType}</td>
                <td>{service.startDay}</td>
                <td>{service.paidDays?.length}</td>
                <td>{NumberFormat(service.clientPayForRoomPrice)}</td>
                <td>{NumberFormat(calculateDebt(service))} so‘m</td>
                <td style={{ margin: "auto" }}>
                  {service.endDay === "0" ? (
                    <div className="animation_con-box">
                      <div className="animation-box" />
                    </div>
                  ) : (
                    service.endDay
                  )}
                </td>
                <td>
                  <Checkbox
                    onChange={(e) =>
                      handleCheckboxChange(
                        service,
                        "firstDataArray",
                        e.target.checked
                      )
                    }
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td
                style={{ textAlign: "center", fontWeight: 800 }}
                colSpan={2.5}
              >
                Jami qarzdorlik
              </td>
              <td
                style={{ textAlign: "center", fontWeight: 800 }}
                colSpan={totalAmount === 0 ? 6 : 3}
              >
                {NumberFormat(totalDebt)} so‘m
              </td>
              {totalAmount === 0 ? (
                ""
              ) : (
                <td
                  style={{ textAlign: "center", fontWeight: 800 }}
                  colSpan={3}
                >
                  {NumberFormat(totalAmount)} so‘m
                  <Button
                    style={{ marginLeft: "15px" }}
                    type="primary"
                    onClick={saveCheckedData}
                  >
                    To‘lovlarni saqlash
                  </Button>
                </td>
              )}
            </tr>
          </tbody>
        </table>

        <div className="employee-info">
          <div>
            Bosh shifokor: <b>{data.employeeInfo.name}</b>
          </div>
          <div>
            Sana va vaqt: <b>{data.employeeInfo.dateTime}</b>
          </div>
        </div>
      </div>
    </LayoutWrapper>
  );
}

export default QarzdorliList;
