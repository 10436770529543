import React from 'react';
import './style.css'

const LoadingTik = () => {
    return (
        <div className="containerTik">
            <div className="loadingTik">
                <span className="tiktok-loader"></span>
            </div>
        </div>
    )
}

export default LoadingTik