import { api } from "./api";

export const doctorApi = api.injectEndpoints({
  endpoints: (builder) => ({
    // CRUD operations for doctors
    getAllDoctors: builder.query({
      query: () => `admin/getAllDoctors`,
      providesTags: ["GetDoctors"],
    }),

    getDoctorInfo: builder.query({
      query: (id) => ({
        url: `doctor/getDoctorInfo`,
        method: "GET",
        body: { userId: id },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }),
    }),

    getDoctorByIdNumber: builder.query({
      query: (id) => `/admin/getDoctorOne/${id}`,
    }),

    createDoctor: builder.mutation({
      query: (body) => ({
        url: "admin/register",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetDoctors"],
    }),

    deleteDoctor: builder.mutation({
      query: (id) => ({
        url: `admin/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetDoctors", "GetReports"],
    }),

    updateDoctor: builder.mutation({
      query: ({ id, body }) => ({
        url: `admin/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetDoctors"],
    }),

    updateDoctorSalary: builder.mutation({
      query: (body) => ({
        url: `admin/update-salary`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetDoctors"],
    }),

    // CRUD operations for extraSpecialization
    addExtraSpecialization: builder.mutation({
      query: (body) => ({
        url: `admin/add-extra-specialization`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetDoctors"],
    }),

    getExtraSpecialization: builder.query({
      query: () => `admin/get-extra-specialization`,
    }),

    updateExtraSpecialization: builder.mutation({
      query: (body) => ({
        url: `admin/update-extra-specialization`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetDoctors"],
    }),

    deleteExtraSpecialization: builder.mutation({
      query: (id) => ({
        url: `admin/delete-extra-specialization`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["GetDoctors"],
    }),

    // CRUD operations for extraTreatment
    addExtraTreatment: builder.mutation({
      query: (body) => ({
        url: `admin/add-extra-treatment`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetDoctors"],
    }),

    getExtraTreatment: builder.query({
      query: (doctorId) => `admin/get-extra-treatment/${doctorId}`,
      providesTags: ["GetDoctors"],
    }),

    updateExtraTreatment: builder.mutation({
      query: ({ doctorId, treatmentId, updatedData }) => ({
        url: `admin/${doctorId}/update-extra-treatment/${treatmentId}`,
        method: 'PUT',
        body: updatedData,
      }),
      invalidatesTags: ({ doctorId }) => [{ type: 'Doctors', id: doctorId }],
    }),

    deleteExtraTreatment: builder.mutation({
      query: (id) => ({
        url: `admin/delete-extra-treatment`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["GetDoctors"],
    }),
  }),
});

export const {
  useGetAllDoctorsQuery,
  useGetDoctorInfoQuery,
  useGetDoctorByIdNumberQuery,
  useCreateDoctorMutation,
  useDeleteDoctorMutation,
  useUpdateDoctorMutation,
  useUpdateDoctorSalaryMutation,
  useAddExtraSpecializationMutation,
  useGetExtraSpecializationQuery,
  useUpdateExtraSpecializationMutation,
  useDeleteExtraSpecializationMutation,
  useAddExtraTreatmentMutation,
  useGetExtraTreatmentQuery,
  useUpdateExtraTreatmentMutation,
  useDeleteExtraTreatmentMutation,
} = doctorApi;




// import { api } from "./api";

// export const doctorApi = api.injectEndpoints({
//   endpoints: (builder) => ({
//     getAllDoctors: builder.query({
//       query: () => `admin/getAllDoctors`,
//       providesTags: ["GetDoctors"],
//     }),

//     getDoctorInfo: builder.query({
//       query: (id) => ({
//         url: `doctor/getDoctorInfo`,
//         method: "GET",
//         body: { userId: id },
//         headers: {
//           Authorization: `Bearer ${localStorage.getItem("token")}`,
//         },
//       }),
//     }),

//     getDoctorByIdNumber: builder.query({
//       query: (id) => `/admin/getDoctorOne/${id}`,
//     }),

//     createDoctor: builder.mutation({
//       query: (body) => ({
//         url: "admin/register",
//         method: "POST",
//         body,
//       }),
//       invalidatesTags: ["GetDoctors"],
//     }),

//     deleteDoctor: builder.mutation({
//       query: (id) => ({
//         url: `admin/delete/${id}`,
//         method: "DELETE",
//       }),
//       invalidatesTags: ["GetDoctors", "GetReports"],
//     }),

//     updateDoctor: builder.mutation({
//       query: ({ id, body }) => ({
//         url: `admin/update/${id}`,
//         method: "PUT",
//         body,
//       }),
//       invalidatesTags: ["GetDoctors"],
//     }),

//     updateDoctorSalary: builder.mutation({
//       query: (body) => ({
//         url: `admin/update-salary`,
//         method: "PUT",
//         body,
//       }),
//       invalidatesTags: ["GetDoctors"],
//     }),
//   }),
// });

// export const {
//   useGetAllDoctorsQuery,
//   useGetDoctorInfoQuery,
//   useGetDoctorByIdNumberQuery,
//   useCreateDoctorMutation,
//   useDeleteDoctorMutation,
//   useUpdateDoctorMutation,
//   useUpdateDoctorSalaryMutation,
// } = doctorApi;


