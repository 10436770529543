import React from "react";
import "./style.css";
import Rooms from "../../admin/rooms/Rooms";

const Cabins = () => {
  let close = "none";
  return <Rooms close={close} />;
};

export default Cabins;
