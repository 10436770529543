import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import { message, Dropdown, Input, Button, Popover, Table } from "antd";
import {
  MdOutlineContactSupport,
  MdNotifications,
  MdNotificationsActive,
  MdRestore,
} from "react-icons/md";
import { FiVolume2, FiVolumeX } from "react-icons/fi"; // React Icons-dan import qilish
import { FaUsers, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import {
  FileTextOutlined,
  SearchOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { GoSidebarExpand } from "react-icons/go";
import { GiTakeMyMoney } from "react-icons/gi";
import moment from "moment";
import {
  onerMenu,
  adminMenu,
  doctorMenu,
  KesserMenu,
  CalCenter,
} from "../../utils/DataSidebar";
import HeartLine from "../loading/HeartLine";
import Snowfall from "../snowFall/Snowfall";
import { NumberFormat } from "../../hook/NumberFormat";
import {
  useGetAllDoctorsQuery,
  useUpdateDoctorMutation,
} from "../../redux/doctorApi";
import { setVolume, toggleMute } from "../../redux/recordList/audioSlice";
import { useGetRoomStoriesQuery } from "../../redux/roomStoriesApi";
import { useGetBalanceQuery } from "../../redux/balans";
import { useUpdateVisitMutation } from "../../redux/visitApi";
import { capitalizeFirstLetter } from "../../hook/CapitalizeFirstLitter";
import "./style.css";
import socket from "../../socket";

const LayoutWrapper = ({ children }) => {
  const { searchLoad } = useSelector((state) => state.search);
  const { volume, isMuted } = useSelector((state) => state.audio);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // useStates
  const [sideOpen, setSideOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [isForwardDisabled, setIsForwardDisabled] = useState(true);

  // get some data from localStorage
  const admin = localStorage.getItem("admin") || {};
  const visitId = localStorage.getItem("visitId") || {};
  const doctorID = localStorage.getItem("doctorID");
  const adminStatus = localStorage.getItem("admin");

  // RTK QUERIES
  const [updateDoctor] = useUpdateDoctorMutation();
  const { data: allDoctor } = useGetAllDoctorsQuery();
  const { data: room } = useGetRoomStoriesQuery();
  const [updateVisit] = useUpdateVisitMutation();
  const { data: balans, refetch } = useGetBalanceQuery({
    date: moment().startOf("day")?.format("DD.MM.YYYY"),
  });

  const { monthlyCost, mainBalans } = balans?.innerData || {};
  useEffect(() => {
    socket.on("patient_paid", (u) => refetch());
    socket.on("balans", (b) => refetch());
    return () => {
      socket.off("patient_paid");
      socket.off("balans");
    };
  }, [refetch]);

  const doctor = allDoctor?.data?.find((i) => i.idNumber === doctorID);
  const roomData = room?.innerData || [];
  const results = roomData?.filter(
    (item) =>
      item.clientFullname?.toLowerCase().includes(query) ||
      item.clientID?.toLowerCase().includes(query)
  );

  let SidebarMenu;
  if (admin === "doctor") {
    SidebarMenu = doctorMenu;
  } else if (admin === "reception") {
    SidebarMenu = adminMenu;
  } else if (admin === "buhgalter") {
    SidebarMenu = KesserMenu;
  } else if (admin === "calCenter") {
    SidebarMenu = CalCenter;
  } else {
    SidebarMenu = onerMenu;
  }

  const UpdateVisit = async () => {
    try {
      if (!visitId) return console.error("Visit ID topilmadi");

      const allData = {
        typeLife: false,
        endVisit: moment().format("DD.MM.YYYY"),
        endOclock: moment().format("HH:mm"),
      };

      await updateVisit({ id: visitId, body: allData });
      localStorage.clear();
      message.success("Tizimdan chiqildi");
      navigate("/");
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Popover content
  const popoverContent = () => (
    <div className="Popover">
      <p>Direktor qabuliga uchrashingizni so'raymiz!</p>
      <Button onClick={offSalaryRing}>OK</Button>
    </div>
  );
  const offSalaryRing = () => {
    updateDoctor({ id: doctor?._id, body: { getSalary: false } });
  };

  // User profile menu
  const handleMenuClick = ({ key }) => {
    if (key === "3") {
      UpdateVisit();
    } else if (key === "1") {
      navigate(`/workersSinglePage/${doctorID}`);
    } else if (key === "2") {
      navigate(`/visitLogIn/${doctorID}`);
    }
  };

  const items = [
    {
      label: "Sahifangiz",
      key: "1",
      icon: <UserOutlined style={{ fontSize: "15px" }} />,
    },
    {
      label: "Tizimga kirish tarixi",
      key: "2",
      icon: <MdRestore style={{ fontSize: "17px" }} />,
    },
    {
      label: "Tizimdan chiqish",
      key: "3",
      icon: <LogoutOutlined style={{ fontSize: "15px" }} />,
      danger: true,
    },
  ];

  const filteredItems =
    adminStatus === "owner" ? items.filter((item) => item.key === "3") : items;

  const menuProps = {
    items: filteredItems,
    onClick: handleMenuClick,
  };

  useEffect(() => {
    setIsForwardDisabled(false);
  }, [location]);

  // Table columns for room data
  const columns = [
    {
      title: "Foydalanuvchi Ismi",
      dataIndex: "clientFullname",
      key: "clientFullname",
      render: (_, record) => capitalizeFirstLetter(record.clientFullname),
    },
    {
      title: "Xona Raqami",
      dataIndex: "roomNumber",
      key: "roomNumber",
    },
    {
      title: "Qavat",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Bemor Tarixi",
      key: "action",
      render: (_, record) => (
        <div className="roomSearchModal_item">
          <Link to={`/poteintsSlinglePage/${record.clientMongooseId}`}>
            <FileTextOutlined />
            Tarix
          </Link>
        </div>
      ),
    },
  ];

  // ----------------------------------------------

  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    dispatch(setVolume(newVolume));
  };
  // ----------------------------------------------
  const content = (
    <div className="audio_muted_unmuted">
      <Button type="primary" onClick={() => dispatch(toggleMute())}>
        {isMuted ? <FiVolumeX /> : <FiVolume2 />}
      </Button>

      <input
        type="range"
        min="0"
        max="10"
        step="0.1"
        value={isMuted ? 0 : volume} // Agar ovoz o'chirilgan bo'lsa 0 bo'ladi
        onChange={handleVolumeChange} // Ovoz darajasini o'zgartirish
        disabled={isMuted} // Agar ovoz o'chirilgan bo'lsa range ishlamaydi
      />
    </div>
  );
  return (
    <div className="Main-Lay">
      <div className="LayoutWrapper">
        <div className="SidCont">
          <div className={`Sidebar ${sideOpen ? "sideOpen" : "Sidebar"}`}>
            <div className="SidebarHeader">
              <h6 className={` ${sideOpen ? "LinkNone" : "noneText"}`}>
                MedMe
              </h6>
              <button
                className={`OpenWindowSid ${sideOpen ? "OpenWindowRight" : ""}`}
              >
                <GoSidebarExpand onClick={() => setSideOpen((i) => !i)} />
              </button>
              <p className="MobileSidText">MedMe</p>
            </div>
            <div className="menu">
              {SidebarMenu?.map((menu, inx) => {
                return (
                  <NavLink to={menu.path} key={inx} className="menu-item">
                    {menu.icon}
                    <div className={` ${sideOpen ? "LinkNone" : "noneText"}`}>
                      {menu.name}
                    </div>
                    <p className="MobileSidText">{menu.name}</p>
                  </NavLink>
                );
              })}
            </div>
            <Link to="/support" className="supportBtn">
              <MdOutlineContactSupport /> Qo'llab quvvatlash
            </Link>
          </div>
        </div>

        <div className={`content  ${sideOpen ? "contentOpen" : "content"}`}>
          <div className="MainNavbar">
            <Snowfall />
            <div className="MainNavbar_container">
              <div className="button-container">
                <Button className="nav-button" onClick={() => navigate(-1)}>
                  <FaArrowLeft />
                </Button>
                <Button
                  className="nav-button"
                  onClick={() => navigate(1)}
                  disabled={isForwardDisabled}
                >
                  <FaArrowRight />
                </Button>
              </div>

              {searchLoad && (
                <div className="SearchBar  search-room">
                  <div className="search searchNav">
                    <div>
                      <FaUsers /> {}
                    </div>

                    <Input
                      allowClear
                      // value={query}
                      onChange={(e) => setQuery(e.target.value.toLowerCase())}
                      type="text"
                      placeholder="Izlash..."
                    />

                    <SearchOutlined />
                  </div>

                  {query && (
                    <div className="roomSearchModal">
                      <Table
                        columns={columns}
                        size="small"
                        dataSource={results}
                        rowKey={(record) => record._id}
                        pagination={false}
                        style={{ width: "100%" }} // Jadval kengligini 100% qilish
                      />
                    </div>
                  )}
                </div>
              )}

              <div className="nav_actions">
                {(admin === "buhgalter" || admin === "doctor") && (
                  <Popover content={content} trigger="click">
                    <Button>
                      <FiVolume2 />
                    </Button>
                  </Popover>
                )}

                {admin === "owner" ? (
                  ""
                ) : (
                  <>
                    {admin === "buhgalter" ? (
                      ""
                    ) : (
                      <div className="Bell_box">
                        {doctor?.getSalary === true ? (
                          <div className="icon-wrapper">
                            <Popover content={popoverContent()}>
                              <MdNotificationsActive className="bell-icon icon-bell" />
                            </Popover>
                          </div>
                        ) : (
                          <MdNotifications className="icon-bell" />
                        )}
                      </div>
                    )}
                  </>
                )}
                {admin === "buhgalter" ? (
                  <>
                    <div className="allBalans_bx">
                      <div
                        className="allBalans_bx_item1"
                        style={{
                          color: mainBalans < 0 ? "red" : "inherit",
                        }}
                      >
                        <GiTakeMyMoney /> Balans
                      </div>
                      <div
                        style={{
                          color: mainBalans < 0 ? "red" : "inherit",
                        }}
                      >
                        {mainBalans < 0 ? "-" : ""}
                        {NumberFormat(mainBalans)} so'm
                      </div>
                    </div>
                    <div className="allBalans_bx">
                      <div className="allBalans_bx_cost">
                        <GiTakeMyMoney /> Bir oylik harajat
                      </div>
                      <div style={{ color: "red" }}>
                        -{NumberFormat(monthlyCost)} so'm
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <Dropdown menu={menuProps}>
                  <Button>
                    <UserOutlined />
                  </Button>
                </Dropdown>
              </div>
            </div>
          </div>
          {false ? (
            <div className="BoxLine">
              <div>
                <HeartLine />
              </div>
            </div>
          ) : (
            <div className="body">{children}</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default LayoutWrapper;
