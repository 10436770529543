import React from "react";
import { Tabs } from "antd";
import { useNavigate } from "react-router-dom";
import { CiLogout } from "react-icons/ci";
import { biokimyoviyTaxliliData } from "../../../utils/analisData";
import "./style.css";

const BiochemicalAnalysis = () => {
  const history = useNavigate();
  // const { _id } = useParams();

  // // Ma'lumotlarni olish
  // const { data: dataUmumiy } = useGetAnaliseUmumiyQuery();
  // const { data: dataBio } = useGetAnalisQuery();
  // const { data: dataUrgent } = useGetUrgentAnalisQuery();

  // // Ma'lumotlarni tekshirish va o'zgaruvchilarga solish
  // const Databio = dataBio?.innerData || [];
  // const urgentSchema = dataUrgent?.innerData || [];
  // const umumiyObj = dataUmumiy?.innerData || [];

  // Funktsiyalar
  // function filterDataByID(dataArray, id) {
  //     return dataArray.find(item => item?.clientID === id) || {};
  // }
  // const urgentData = filterDataByID(urgentSchema, _id);
  // const bioData = filterDataByID(Databio, _id);
  // const umumiyData = filterDataByID(umumiyObj, _id);

  const bioKimyovi = biokimyoviyTaxliliData?.filter((i) => i.analis === 1);
  const umumiyTaxlili = biokimyoviyTaxliliData?.filter((i) => i.analis === 2);
  const eritrotsitlarData = biokimyoviyTaxliliData?.filter(
    (i) => i.analis === 3
  );
  const siydikData = biokimyoviyTaxliliData?.filter((i) => i.analis === 4);
  const ChokmasData = biokimyoviyTaxliliData?.filter((i) => i.analis === 5);
  // BioKimyovi qon taxlil
  // const bioKimyovi = biokimyoviyTaxliliData?.map(item => ({
  //     ...item,
  //     value: bioData?.[item?.field] || '',
  // }));

  // // Umumiy Qon taxlili
  // const umumiyTaxlili = umumiyTaxliliData?.map(item => ({
  //     ...item,
  //     value: umumiyData?.[item?.result] || '',
  // }));
  // const eritrotsitlarData = eritrotsitlar?.map(item => ({
  //     ...item,
  //     value: umumiyData?.[item?.result] || '',
  // }));

  // // Siydik taxlili
  // const siydikData = formSections?.map(item => ({
  //     ...item,
  //     value: urgentData?.[item?.result] || '',
  //     res: urgentData?.[item?.ml] || '',
  // }));
  // const ChokmasData = ChokmasDataAnalis?.map(item => ({
  //     ...item,
  //     value: urgentData?.[item?.result] || '',
  // }));

  const handleGoBack = () => {
    history(-1);
    history("/", { replace: true });
  };

  return (
    <>
      <div className="analiseNav">
        <button onClick={handleGoBack}>
          <CiLogout />
        </button>
      </div>
      <Tabs style={{ padding: "0 10px" }}>
        <Tabs.TabPane
          defaultActiveKey="0"
          tab="Qonning Biokimyoviy Taxlili"
          key={0}
        >
          <div className="blood-test-report">
            <table>
              <tbody>
                <tr>
                  <th className="thAnalises">Tahlil Nomlari</th>
                  <th className="thAnalises">Natija</th>
                  <th className="thAnalises">Norma</th>
                  <th className="thAnalises">SI birlik</th>
                </tr>
                {bioKimyovi?.map((value, inx) => {
                  return (
                    <tr key={inx}>
                      <td className="tdAnalises">{value.name}</td>
                      {/* <td className="tdAnalises">{value.value}</td> */}
                      <td className="tdAnalises">{value.norma}</td>
                      <td className="tdAnalises">{value.siBirlik}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane defaultActiveKey="1" tab="Qonning Umumiy Taxlili" key={1}>
          <div className="blood-test-report">
            <table>
              <thead>
                <tr>
                  <th>Ko'rsatkich</th>
                  <th>Natija</th>
                  <th colSpan={2}>Norma (SI birligi)</th>
                </tr>
              </thead>
              <tbody>
                {umumiyTaxlili.map((value, inx) => {
                  return (
                    <tr key={inx}>
                      <td>{value.parameter}</td>
                      <td>{value.value}</td>
                      <td>
                        {typeof value.referenceRange === "object"
                          ? `E: ${value.referenceRange.E}  A: ${value.referenceRange.A}`
                          : value.referenceRange}
                      </td>
                      <td>{value.unit}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="lab-report">
              <h2>Eritrotsitlar morfologiyasi</h2>
              <form>
                {eritrotsitlarData.map((item, inx) => {
                  return (
                    <div key={inx} className="form-group">
                      <label>{item.label}</label>
                      <span className="form-line">{item.value}</span>
                    </div>
                  );
                })}
              </form>
            </div>
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane defaultActiveKey="2" tab="Siydik Taxlili" key={2}>
          <div className="urine-analysis-report">
            <p>FIZIK - KIMYOVIY XOSSASI</p>
            <form>
              {siydikData?.map((item, inx) => {
                return (
                  <div key={inx} className="form-section">
                    <label htmlFor="bilirubin">{item.label}</label>
                    <span className="form-line">
                      {item.value}{" "}
                      {item.value !== "" ? (
                        <>
                          {" "}
                          <b>{item.gl}</b> {item.res} <b>{item.gr}</b>
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                  </div>
                );
              })}
            </form>
            <p>SIYDIK CHO'KMASI MIKROSKOPYASI</p>
            <form>
              {ChokmasData?.map((item, inx) => {
                return (
                  <div key={inx} className="form-section">
                    <label htmlFor="bilirubin">{item.label}</label>
                    <span className="form-line">{item.value}</span>
                  </div>
                );
              })}
            </form>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default BiochemicalAnalysis;
