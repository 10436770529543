import React from 'react'
import LayoutWrapper from '../../../../components/layout/Layout'
import AddUserToRoom from '../../register/addUserRoom'

const addUserToRoom = () => {
    return (
        <LayoutWrapper>
            <AddUserToRoom />
        </LayoutWrapper>
    )
}

export default addUserToRoom