import React, { useState } from 'react';
import {
  Table
} from 'antd';
import { useParams } from "react-router-dom";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useGetAllVisitQuery } from "../../redux/visitApi";
import LayoutWrapper from "../layout/Layout";
import "./style.css";
import SearchBar from '../SearchComponent';

const VisitLogIn = () => {
  const { data: allVisit } = useGetAllVisitQuery();
  const { id } = useParams();
  const [query, setQuery] = useState("");
  const filteredData = allVisit?.innerData.filter(item => item.idNumber === id && (item.startVisit?.includes(query)));


  filteredData?.sort((a, b) => a.startVisit - b.startVisit);

  const columns = [
    {
      title: <div style={{ display: "flex", alignItems: "center", gap: '8px' }}>Sana  <DownOutlined style={{ color: "green" }} /></div>,
      dataIndex: 'startVisit',
      key: "startVisit"
    },
    {
      title: <div style={{ display: "flex", alignItems: "center", gap: '8px' }}>Soat  <DownOutlined style={{ color: "green" }} /></div>,
      dataIndex: 'startOclock',
      key: "startOclock"
    },
    {
      title: <div style={{ display: "flex", alignItems: "center", gap: '8px' }}>Sana  <UpOutlined style={{ color: "red" }} /></div>,
      dataIndex: 'endVisit',
      key: "endVisit",
      render: (endVisit) => endVisit === "" ? <div style={{ color: "green" }}>Aktiv</div> : endVisit
    },
    {
      title: <div style={{ display: "flex", alignItems: "center", gap: '8px' }}>Soat  <UpOutlined style={{ color: "red" }} /></div>,
      dataIndex: 'endOclock',
      key: "endOclock",
      render: (endOclock) => endOclock === "" ? <div style={{ color: "green" }}>Aktiv</div> : endOclock
    },
  ];

  return (
    <LayoutWrapper>
      {/* SearchBar componentini ishlatish */}
      <SearchBar data={filteredData} query={query} setQuery={setQuery} />
      {/* Table componentini chiqarish */}
      <Table bordered={true} pagination={false} columns={columns} dataSource={filteredData} size="small" />
    </LayoutWrapper>
  );
}

export default VisitLogIn;
