import React, { useState, useRef, useEffect } from "react";
import {
  Select,
  Input,
  Button,
  Form,
  Table,
  message,
  Tabs,
  Checkbox,
  Row,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "./style.css";
import moment from "moment";
import {
  useDecrementMutation,
  useIncrementMutation,
} from "../../../redux/balans";
import {
  useCreateKassaMutation,
  useGetKassaQuery,
} from "../../../redux/kassaApi";
import LayoutWrapper from "../../../components/layout/Layout";

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const options = [
  {
    label: "Savdo tushumlari",
    options: ["Savdo tushum", "Boshqa tushumlar"],
  },
  {
    label: "Ish haqilari",
    options: [
      "Shifokorlar ish haqilari",
      "Hamshiralar ish haqilari",
      "Laborant ish haqilari",
    ],
  },
  {
    label: "Labatoriya mahsulotlari",
    options: ["Labatoriya mahsulotlari"],
  },
  {
    label: "Adminstrativ xarajatlar",
    options: [
      "Adminstrativ hodimlarni o'zgarmas ish haqisi",
      "Oziq-ovqatga",
      "Kanselariyaga",
      "Yo'lkiraga",
      "Internet to'lovlariga",
      "Xo'jalik mollariga",
      "Kamunalkaga",
      "Bank xizmatiga",
      "Transport benzin va remontiga",
      "Autsorsing",
      "Abanent to'lovlari",
      "Adminstrativ boshqa xarajatlar",
    ],
  },
  {
    label: "Sotuvchilarni xarajatlar",
    options: [
      "Sotuvchilarni o'zgaruvchan ish haqisi",
      "Reklamaga",
      "Marketinga",
      "Logotip buyumlar",
      "QVP virachlariga",
    ],
  },
  {
    label: "Soliqlar va badallar",
    options: [
      "Ijtimoiy soliq",
      "Ish haqi soliqlari",
      "Jismoniy shaxslar daromadidan olinadigan soliq",
      "Pensiya badaliga ajratma",
    ],
  },
  {
    label: "Moliya xarajatlari",
    options: ["Kridit foiziga", "Divident", "Xayriya", "Zaxira"],
  },
  {
    label: "Qarz operatsiyalari",
    options: [
      "Qarz berildi",
      "Qarz qaytdi",
      "Qarz olindi",
      "Qarz qaytarildi",
      "Kriditga",
      "Mehmonlarga",
      "Jamoani rivojlantirishga",
    ],
  },
  {
    label: "Investitsiya",
    options: [
      "Jalb qilingan investitsiya",
      "Asosiy vosita sotib olishga",
      "Asosiy vosita sotib yuborishdan tushum",
      "Reninvestitsiya",
      "Qurilishga",
      "Kunlik ishchilarga",
      "Xizmat safariga",
    ],
  },
  {
    label: "Hisob-raqam almashinuvi",
    options: ["Kassadan bankga keldi", "Kassadan bankga ketdi"],
  },
];

const Expenses = () => {
  const formRef = useRef();
  const [filteredData, setFilteredData] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [status, setStatus] = useState(false); // status false bolsa balansdan pul ayiriladi chiqim
  const [decrement] = useDecrementMutation();
  const [increment] = useIncrementMutation();
  const [createKassa] = useCreateKassaMutation();
  const { data: allKassa, isLoading } = useGetKassaQuery();
  let kassaData = allKassa?.innerData;

  useEffect(() => {
    setFilteredData(kassaData);
    setDataSource(kassaData);
  }, [kassaData]);

  const onSubmit = async (data) => {
    try {
      data.amount = +data.amount;
      data.status = status;

      let date = moment().format("DD.MM.YYYY");
      const formData = {
        ...data,
        date: {
          day: date,
          time: moment().format("HH:mm"),
        },
      };

      await createKassa(formData)
        .then((res) => {
          if (res?.data?.status === "success") {
            message.success(res?.data?.message);
          }
        })
        .catch((err) => console.log(err));

      if (status) {
        await increment(data.amount)
          .then((res) => {
            if (res?.data?.status === "success") {
              message.success(res?.data?.message);
            }
          })
          .catch((res) => console.log(res));
      } else {
        await decrement(data.amount)
          .then((res) => {
            if (res?.data?.status === "success") {
              message.success(res?.data?.message);
            }
          })
          .catch((res) => console.log(res));
      }
      formRef?.current?.resetFields();
      setStatus(false);
      return;
      setDataSource();
    } catch (error) {
      console.error("Server error:", error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    const filtered = dataSource.filter((item) =>
      Object.values(item).some((val) =>
        val.toString().toLowerCase().includes(value)
      )
    );
    setFilteredData(filtered);
  };

  const handleDateFilter = (value, record) => {
    if (!value) return true;
    return record.date.day === value;
  };

  const handleStatusFilter = (value, record) => {
    if (value === "all") return true;
    return record.status.toString() === value;
  };

  // barcha kirim va chiqimlarni kunlarini ajratib olish
  let days = [];
  if (dataSource?.length) {
    for (let id of dataSource) {
      if (!days.includes(id?.date?.day)) {
        days.push(id?.date?.day);
      }
    }
  }

  const columns = [
    {
      title: "Sana",
      dataIndex: "date",
      key: "date",
      filters: days?.map((date) => ({ text: date, value: date })),
      onFilter: handleDateFilter,
      render: (e) => (
        <p style={{ whiteSpace: "nowrap", marginBottom: "0em" }}>
          {e.day + " | " + e.time}
        </p>
      ),
    },
    {
      title: "Ism familya",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Miqdor",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Rasxodlar turi",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Izoh",
      dataIndex: "decription",
      key: "decription",
      render: (e) => <p className="rasxodDescription">{e}</p>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Kirim", value: "true" },
        { text: "Chiqim", value: "false" },
        { text: "Hammasi", value: "all" },
      ],
      onFilter: handleStatusFilter,
      render: (status) => (status ? "Kirim" : "Chiqim"),
    },
  ];

  return (
    <LayoutWrapper>
      <Tabs>
        <Tabs.TabPane tab="Xarajatlar yozish" key={0}>
          <Form
            ref={formRef}
            className="xisobotlar-right-box"
            layout="vertical"
            onFinish={onSubmit}
          >
            <Form.Item name="name" label="Kim tomonidan">
              <Input placeholder="Ism familya" />
            </Form.Item>

            <Form.Item name={"category"} label="Rasxodlar turi">
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder="Rasxod turini tanlang"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                }
              >
                {options.map((group) => (
                  <OptGroup key={group.label} label={group.label}>
                    {group.options.map((option) => (
                      <Option key={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </OptGroup>
                ))}
              </Select>
            </Form.Item>

            <Row style={{ display: "flex", gap: "20px" }}>
              <Form.Item name={"amount"} label="Pul miqdori">
                <Input type="number" placeholder="Miqdor" />
              </Form.Item>
              <Form.Item name={"status"} label="Kirim">
                <Checkbox
                  value={true}
                  onChange={(e) => setStatus(e.target.checked)}
                />
              </Form.Item>
            </Row>

            <Form.Item name={"decription"} label="Izoh">
              <TextArea
                style={{ height: 100, resize: "none" }}
                placeholder="Izoh"
                rows={4}
              />
            </Form.Item>
            <Form.Item style={{ width: "100%" }}>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
              >
                Yuborish
              </Button>
            </Form.Item>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Xarajatlar" key={1}>
          <div className="xisobotlar">
            <div className="xisobotlar-right-box kassaTable">
              <Input
                placeholder="Qidiruv"
                size="middle"
                prefix={<SearchOutlined />}
                onChange={handleSearch}
                style={{ marginBottom: 5 }}
              />

              <Table
                className="small-text-table"
                size="small"
                dataSource={filteredData}
                columns={columns}
                rowKey={(record) => record._id}
                loading={isLoading}
                pagination={false}
                rowClassName={(row) => (row.status ? "greenRow" : "redRow")}
              />
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </LayoutWrapper>
  );
};

export default Expenses;
