import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { FaUser } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { PiLockKeyFill } from "react-icons/pi";
import { message } from "antd";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";
import axios from "../api";
import { showLoading, hideLoading } from "../redux/features/indexSlice";
import bg from "../assets/img/bg.svg";
import wave from "../assets/img/wave.png";
import imgDoc from "../assets/img/singleImg1.png";
import "../styles/login.css";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [eye, setEye] = useState(false);
  const [login, setUsername] = useState("");
  const [password, setPassword] = useState("");

  useEffect(() => {
    const admin = localStorage.getItem("admin");
    if (admin) {
      const routes = {
        owner: "/reports",
        reception: "/receptionHome",
        doctor: "/appointments",
        buhgalter: "/cashier-reception",
        calCenter: "/appointments_calCenter",
      };
      navigate(routes[admin]);
    }
  }, [navigate]);

  const getGeolocation = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  };

  const onFinishHandler = async (e) => {
    e.preventDefault();

    if (!login || !password) {
      return message.warning("Foydalanuvchi login va parolingizni kiriting!");
    }
    try {
      const position = await getGeolocation();
      const { longitude, latitude } = position.coords;

      dispatch(showLoading());
      const res = await axios.post("/admin/login", {
        login,
        password,
        longitude,
        latitude,
      });
      dispatch(hideLoading());

      if (res.data.success) {
        const { user: exactAdmin, userType, token } = res.data;
        const {
          _id,
          idNumber,
          type,
          specialization,
          phone,
          name,
          lastName,
          firstName,
          visitId,
          clinicId,
        } = exactAdmin;
        localStorage.setItem("doctorID", idNumber);
        localStorage.setItem("token", token);
        localStorage.setItem("admin", userType || specialization);
        localStorage.setItem("doctorMongoId", _id);
        localStorage.setItem("doctorType", type);
        localStorage.setItem("category", specialization);
        localStorage.setItem("doctorPhone", phone);
        localStorage.setItem("clinicName", name);
        localStorage.setItem("doctorName", `${lastName} ${firstName}`);
        localStorage.setItem("visitId", visitId);
        localStorage.setItem("clinicId", clinicId || _id);

        message.success("Tizimga kirish muvaffaqiyatli yakunlandi!");
      }
    } catch (error) {
      console.log("err>>>", error);
      dispatch(hideLoading());
      message.error(error.response?.data?.msg || "Xato yuz berdi");
    }
  };

  return (
    <div className="Loginbodylog">
      <img className="wave" src={wave} alt="kirish" />
      <div className="containerlog">
        <div className="imgLog">
          <img src={bg} alt="kirish" />
        </div>
        <div className="login-content">
          <form className="FormLogin" onSubmit={onFinishHandler}>
            <img src={imgDoc} alt="kirish LOGO" />
            <h2>Tizimga kirish</h2>
            <div className="input-div one">
              <div className="iconCont">
                <FaUser />
              </div>
              <div className="Inputdiv">
                <input
                  value={login}
                  onChange={(e) => setUsername(e.target.value)}
                  type="text"
                  className="input"
                  placeholder="login"
                />
              </div>
            </div>
            <div className="input-div pass">
              <div className="iconCont">
                <PiLockKeyFill />
              </div>
              <div className="Inputdiv">
                <input
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  type={!eye ? "password" : "text"}
                  className="input"
                  placeholder="parol"
                />
                <button type="button" onClick={() => setEye(!eye)}>
                  {eye ? <AiFillEye /> : <AiFillEyeInvisible />}
                </button>
              </div>
            </div>
            <input type="submit" className="btnIN" value="Kirish" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
