import React, { useRef, useState } from "react";
import "./DailyReport.css";
import moment from "moment";
import { Button, DatePicker, Tabs } from "antd";
import { PrinterOutlined } from "@ant-design/icons";
import ReactToPrint from "react-to-print";
import { useReactToPrint } from "react-to-print";
import { SiMicrosoftexcel } from "react-icons/si";
import * as XLSX from "xlsx";
import Layout from "../../../components/layout/Layout";
import {
  useGetDailyReportQuery,
  useGetDailyCostQuery,
} from "../../../redux/dailyReportApi";
import { NumberFormat } from "../../../hook/NumberFormat";

const { RangePicker } = DatePicker;

function DailyReport() {
  const printRefKunlik = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRefKunlik.current,
    pageStyle: `
      @media print {
        .dailyReport {
          width: 210mm;
        }
        table {
          border-collapse: collapse;
          width: 210mm;
        }
        th, td {
          border: 1px solid black;
          padding: 8px;
        }
      }
    `
  });

  const printRefChiqimlar = useRef();

  // USE STATES
  const today = moment().format("DD.MM.YYYY");
  const [selectedDate] = useState(moment());
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [startDateLive, setStartDateLive] = useState(today); // chiqim uchun
  const [endDateLive, setEndDateLive] = useState(today); // chiqim uchun

  // RTK QUERIES
  const { data: data, refetch } = useGetDailyReportQuery({
    startDate,
    endDate,
  });

  const { data: dailyCostData, refetch: refetchCost } = useGetDailyCostQuery({
    startDate: startDateLive,
    endDate: endDateLive,
  });

  const handleDateRangeChangeLive = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      const start = startDate.format("DD.MM.YYYY");
      const end = endDate.format("DD.MM.YYYY");

      setStartDateLive(start);
      setEndDateLive(end);
      refetchCost();
    } else {
      setStartDateLive(null);
      setEndDateLive(null);
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      const start = startDate.format("DD.MM.YYYY");
      const end = endDate.format("DD.MM.YYYY");

      // Tanlangan sanalarni state ga saqlash
      setStartDate(start);
      setEndDate(end);
      refetch();
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const exportToExcelDoctors = () => {
    const dataForExcel = data?.doctorReport?.map((item) => ({
      Doktor: item.doctor,
      Bemorlar: item.length,
      "Тўлов (нақт)": item.totalSum,
      "Тўлов (карта)": item.plasticCardSum,
    }));

    const worksheet = XLSX.utils.json_to_sheet(dataForExcel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Kunlik Hisobot");

    const totalRow = {
      Doktor: "Jami",
      Bemorlar: data?.overallReport.totalPatients?.toLocaleString(),
      "Тўлов (нақт)": data.overallReport.totalCash,
      "Тўлов (карта)": data.overallReport.totalPlasticCard,
    };
    XLSX.utils.sheet_add_json(worksheet, [totalRow], {
      skipHeader: true,
      origin: -1,
    });

    XLSX.writeFile(workbook, `Kunlik_Hisobot_${today}.xlsx`);
  };

  const exportToExcelChiqimlar = () => {
    const dataForExcelChiqimlar = dailyCostData?.dailyCost?.map(
      (item, index) => ({
        "№": index + 1,
        "Kim Tomonidan": item.name,
        Kategoriya: item.category,
        "Summa (so'm)": item.amount?.toLocaleString(),
      })
    );

    const worksheet = XLSX.utils.json_to_sheet(dataForExcelChiqimlar);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Chiqimlar");

    const totalRow = {
      "№": "",
      "KIm Tomonidan": "Jami",
      Kategoriya: "",
      "Summa (so'm)": dailyCostData?.totalAmount?.toLocaleString(),
    };
    XLSX.utils.sheet_add_json(worksheet, [totalRow], {
      skipHeader: true,
      origin: -1,
    });

    XLSX.writeFile(workbook, `Chiqimlar_Hisoboti_${today}.xlsx`);
  };

  return (
    <Layout>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="Kunlik Hisobot" key="1">
          <div
            className="ReactToPrint"
            style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
          >
            <RangePicker
              onChange={handleDateRangeChange}
              format="DD.MM.YYYY"
              style={{ marginBottom: 5 }}
              placeholder={["Boshlanish sanasi", "Tugash sanasi"]}
            />

            <ReactToPrint
              trigger={() => (
                <Button type="primary">
                  <PrinterOutlined style={{ fontSize: "20px" }} />
                </Button>
              )}
              content={() => printRefKunlik.current}
            />
            <Button type="primary" onClick={exportToExcelDoctors}>
              <SiMicrosoftexcel />
              Excel
            </Button>
          </div>
          <div ref={printRefKunlik} className="dailyReport">
            <b className="dailyReport_title">
              {startDate === today && endDate === today
                ? `Kunlik hisobot - ${selectedDate.format("DD.MM.YYYY")}`
                : `${startDate} dan ${endDate} gacha bo'lgan hisobot`}
            </b>
            <p className="dailyReport_text">
              Ushbu hisobotda har bir bo'limning{" "}
              {startDate === today && endDate === today ? "kunlik" : ""} qabul
              qilgan bemorlari va tushgan
              <br />
              umumiy pullar haqida ma'lumot beriladi.
            </p>

            <table className="report_table">
              <thead>
                <tr>
                  <th>Doktor</th>
                  <th>Bemorlar</th>
                  <th>To'lov (naqd)</th>
                  <th>To'lov (karta)</th>
                  <th>Qarzdorlik</th>
                </tr>
              </thead>
              <tbody>
                {data?.doctorReport?.map((item, index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "left", paddingLeft: "5px" }}>
                      {item.doctor}
                    </td>
                    <td>{item.length}</td>
                    <td>{NumberFormat(item.totalSum)}</td>
                    <td>{NumberFormat(item.plasticCardSum)}</td>
                    <td>{NumberFormat(item.debt)}</td> {/* Qarzdorlikni qo'shish */}
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td>Jami</td>
                  <td>{NumberFormat(data?.overallReport?.totalPatients?.toLocaleString())}</td>
                  <td>{NumberFormat(data?.overallReport?.totalCash)}</td>
                  <td>{NumberFormat(data?.overallReport?.totalPlasticCard)}</td>
                  <td>{NumberFormat(data?.overallReport?.totalDebt)}</td> {/* Qarzdorlik jamini ko'rsatish */}
                </tr>
              </tfoot>
            </table>

          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab="Chiqimlar" key="2">
          <div
            className="ReactToPrint"
            style={{ display: "flex", gap: "10px", marginBottom: "15px" }}
          >
            <RangePicker
              onChange={handleDateRangeChangeLive}
              format="DD.MM.YYYY"
              style={{ marginBottom: 5 }}
              placeholder={["Boshlanish sanasi", "Tugash sanasi"]}

            />


            <Button onClick={handlePrint} type="primary">
              <PrinterOutlined style={{ fontSize: "20px" }} />
            </Button>


            <Button type="primary" onClick={exportToExcelChiqimlar}>
              <SiMicrosoftexcel />
              Excel
            </Button>
          </div>
          <div ref={printRefChiqimlar} className="dailyReport">
            <b className="dailyReport_title">
              {startDateLive === today && endDateLive === today
                ? `Bugungi chiqimlar - ${selectedDate.format("DD.MM.YYYY")}`
                : `${startDateLive} dan ${endDateLive} gacha bo'lgan chiqim hisobot`}
            </b>

            <p className="dailyReport_text">
              Quyidagi jadvalda{" "}
              {startDateLive === today && endDateLive === today
                ? "bugungi kun davomida"
                : ""}{" "}
              amalga oshirilgan barcha chiqimlar ko'rsatilgan.
            </p>

            <table className="report_table">
              <thead>
                <tr>
                  <th>№</th>
                  {startDateLive === today && endDateLive === today ? (
                    <></>
                  ) : (
                    <th>Sana</th>
                  )}
                  <th>Kim Tomonidan</th>
                  <th>Kategoriyа</th>
                  <th>Summa (so'm)</th>
                </tr>
              </thead>
              <tbody>
                {dailyCostData?.dailyCost?.map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    {startDateLive === today && endDateLive === today ? (
                      <></>
                    ) : (
                      <td>{item.date.day}</td>
                    )}
                    <td>{item.name}</td>
                    <td>{item.category}</td>
                    <td>{item.amount?.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <td></td>
                  <td>Jami</td>
                  <td></td>
                  {startDateLive === today && endDateLive === today ? (
                    <></>
                  ) : (
                    <td></td>
                  )}
                  <td>{dailyCostData?.totalAmount}</td>
                </tr>
              </tfoot>
            </table>

          </div>
        </Tabs.TabPane>
      </Tabs>
    </Layout>
  );
}

export default DailyReport;
