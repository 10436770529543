import { api } from "./api";

export const reportsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReports: builder.query({
      query: (doctorsIDs) => ({
        url: `reports/getReports`,
        method: "POST",
        body: doctorsIDs,
      }),
      providesTags: ["GetReports"],
    }),
  }),
});

export const { useGetReportsQuery } = reportsApi;
