import React from "react";
import "./style.css";
import { MdOutlineBedroomChild } from "react-icons/md";
import {
  PlusCircleOutlined,
  // CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { TbElevator } from "react-icons/tb";
import { Input, Select, Button, Tooltip, Space } from "antd";
const { Option } = Select;

const SearchRooms = ({
  kassir,
  setSelectedCapacity,
  setQueryCategory,
  setOptenAddModal,
  setSelectedFloor,
  optenAddModal,
  InnerData,
  setQuery,
  close,
  query,
  selectedFloor,
  selectedCapacity,
  queryCategory,
}) => {
  const handleFloorChange = (value) => {
    setSelectedFloor(value);
  };

  const handleCapacityChange = (value) => {
    setSelectedCapacity(value);
  };

  const handleCategoryChange = (value) => {
    setQueryCategory(value);
  };

  // const handleClearFilters = () => {
  //     setSelectedFloor(null);
  //     setSelectedCapacity(null);
  //     setQueryCategory('');
  //     setQuery('');
  // };

  return (
    <div className="search-rooms">
      <Space wrap style={{ display: "flex", gap: "20px" }}>
        <Tooltip title="Xona raqami">
          <div className="searchRoom">
            <Input
              size="small"
              style={{
                boxShadow: "none",
                border: "none",
                fontSize: "13px",
              }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              type="search"
              allowClear
              placeholder="Xona raqami..."
            />
            <SearchOutlined />
          </div>
        </Tooltip>

        <Tooltip title="Qavatlar">
          <Select
            size="small"
            allowClear
            style={{
              width: 100,
            }}
            value={selectedFloor}
            placeholder="Qavatlar..."
            onChange={handleFloorChange}
          >
            {Array?.from(new Set(InnerData?.map((item) => item?.floor)))
              ?.sort((a, b) => a - b)
              ?.map((floor) => (
                <Option key={floor} value={floor}>
                  <TbElevator />: {floor}
                </Option>
              ))}
          </Select>
        </Tooltip>

        <Tooltip title="Xona sig'imi">
          <Select
            size="small"
            allowClear
            style={{
              width: 100,
            }}
            value={selectedCapacity}
            placeholder="Xona sig'imi..."
            onChange={handleCapacityChange}
          >
            {Array?.from(new Set(InnerData?.map((item) => item?.usersNumber)))
              ?.sort((a, b) => a - b)
              ?.map((usersNumber) => (
                <Option key={usersNumber} value={usersNumber}>
                  <MdOutlineBedroomChild />: {usersNumber}
                </Option>
              ))}
          </Select>
        </Tooltip>

        <Tooltip title="Lyuks / Polyuks">
          <Select
            size="small"
            placeholder="Xona turi"
            allowClear
            style={{
              width: 120,
            }}
            value={queryCategory}
            onChange={handleCategoryChange}
            options={[
              {
                value: "luxury",
                label: "Lyuks",
              },
              {
                value: "pollux",
                label: "Polyuks",
              },
              {
                value: "free",
                label: "Oddiy",
              },
            ]}
          />
        </Tooltip>
        {/* <Tooltip title="Filtrlarni tozalash">
                    <Button className="clearFilters" onClick={handleClearFilters}>
                        <CloseOutlined />
                    </Button>
                </Tooltip> */}
      </Space>

      {!kassir && (
        <Tooltip placement="left" title="Xona qo'shish">
          <Button
            onClick={() => setOptenAddModal(!optenAddModal)}
            style={{ display: "flex", alignItems: "center" }}
            type="primary"
            shape="round"
            icon={<PlusCircleOutlined />}
            size="small"
          />
        </Tooltip>
      )}
    </div>
  );
};

export default SearchRooms;
