import React from 'react'
import LayoutWrapper from '../../components/layout/Layout';
import QarzdorlarBolimi from '../receptionPage/patients/qarzdorlarBolimi/QarzdorlarBolimi';

const Debtors = () => {
    return (
        <LayoutWrapper>
            <QarzdorlarBolimi />
        </LayoutWrapper>
    )
}

export default Debtors;