import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message, TimePicker } from "antd";
import { FiX } from "react-icons/fi";
import dayjs from "dayjs";
import Selectt from "react-select";
import axios from "axios";
import { useUpdateDoctorMutation } from "../../../redux/doctorApi";
import "./style.css";

function Administrator({ reception, setOpenUpdate, editID }) {
  let [updateDoctor] = useUpdateDoctorMutation();

  let update = reception?.find((i) => i._id === editID) || {};

  const [firstName, setFirstName] = useState(update?.firstName || "");
  const [lastName, setLastName] = useState(update?.lastName || "");
  const [phone, setPhone] = useState(update?.phone || "");
  const [address, setAddress] = useState({
    region: update?.address?.region || "",
    district: update?.address?.district || "",
    quarter: update?.address?.quarter || "",
  });
  const [login, setLogin] = useState(update?.login || "");
  const [password, setPassword] = useState(update?.password || "");
  const [specialization] = useState(update?.specialization || "");
  const [experience] = useState(update?.experience || "");
  const [salary, setSalary] = useState(update?.salary || "");
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);

  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };

  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));
  }, []);

  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };

  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  function updateDoctors(e) {
    e.preventDefault();

    let newInfo = {
      ...update,
      firstName,
      lastName,
      phone,
      address,
      login,
      password,
      specialization,
      experience,
      salary: +salary,
      workTime: {
        startTime, // Add startTime
        endTime, // Add endTime
      },
    };

    updateDoctor({ id: editID, body: newInfo })
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
        }
      })
      .catch((err) => console.log("err", err));
  }

  const [extraClass, setExtraClass] = useState(false);
  const [extraClass2, setExtraClass2] = useState(false);
  const [extraClass3, setExtraClass3] = useState(false);

  const onChangeStart = (time, timeString) => {
    setStartTime(timeString);
  };
  const onChangeEnt = (time, timeString) => {
    setEndTime(timeString);
  };
  return (
    <div className="updateDoctor">
      <div className="editForm">
        <Form className="FormItem" layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Ism">
                <Input
                  type="text"
                  defaultValue={update?.firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder="Ism"
                  name="firstName"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Familiya">
                <Input
                  type="text"
                  defaultValue={update?.lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  placeholder="Familiya"
                  name="lastName"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Telefon raqami">
                <Input
                  type="text"
                  defaultValue={update?.phone}
                  onChange={(e) => setPhone(e.target.value)}
                  placeholder="Telefon raqami"
                  name="phone"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Form.Item
                  className={extraClass && "updateAdministrator_select"}
                  label="Viloyat"
                >
                  <Selectt
                    styles={{ background: "red" }}
                    onMenuOpen={() => setExtraClass(true)}
                    onMenuClose={() => setExtraClass(false)}
                    onChange={(e) => {
                      getDistricts(e.regionID);
                      setAddress({ ...address, region: e.value });
                    }}
                    options={regions}
                    placeholder={"Viloyat"}
                    defaultInputValue={update?.address?.region}
                  />
                </Form.Item>

                <Form.Item
                  className={extraClass2 && "updateAdministrator_select"}
                  label="Shahar Tuman"
                >
                  <Selectt
                    onMenuOpen={() => setExtraClass2(true)}
                    onMenuClose={() => setExtraClass2(false)}
                    onChange={(e) => {
                      getQuarters(e.districtID);
                      setAddress({ ...address, district: e.value });
                    }}
                    options={districts}
                    placeholder={"Tuman"}
                    defaultInputValue={update?.address?.district}
                  />
                </Form.Item>

                <Form.Item
                  className={extraClass3 && "updateAdministrator_select"}
                  label="Mahalla Fuqarolar yig‘ini"
                >
                  <Selectt
                    onMenuOpen={() => setExtraClass3(true)}
                    onMenuClose={() => setExtraClass3(false)}
                    options={quarters}
                    placeholder={"M.F.Y"}
                    onChange={(e) =>
                      setAddress({ ...address, quarter: e.value })
                    }
                    defaultInputValue={update?.address?.quarter}
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Maoshi">
                <Input
                  type="text"
                  defaultValue={update?.salary}
                  onChange={(e) => setSalary(e.target.value)}
                  placeholder="Maoshi"
                  name="salary"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <div style={{ display: "flex", gap: "5px" }}>
                <Form.Item label="Login">
                  <Input
                    type="text"
                    defaultValue={update?.login}
                    onChange={(e) => setLogin(e.target.value)}
                    placeholder="Login"
                    name="login"
                  />
                </Form.Item>
                <Form.Item label="Parol">
                  <Input
                    type="text"
                    defaultValue={update?.password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Parol"
                    name="password"
                  />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={18}>
              <Form.Item label="Ish vaqti" name="workTime">
                <div style={{ display: "flex", gap: "10px" }}>
                  <TimePicker
                    placeholder="Boshlanishi"
                    onChange={onChangeStart}
                    defaultValue={dayjs(
                      update?.workTime?.startTime,
                      "HH:mm"
                    )}
                  />
                  <TimePicker
                    placeholder="Tugashi"
                    onChange={onChangeEnt}
                    defaultValue={dayjs(update?.workTime?.endTime, "HH:mm")}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <div style={{ display: "flex", gap: "5px" }}>
          <Button
            style={{ width: "100%" }}
            type="primary"
            onClick={(e) => {
              updateDoctors(e);
              setTimeout(() => {
                setOpenUpdate(false);
              }, 1000);
            }}
          >
            Taxrirlash
          </Button>
          <Button type="primary" danger onClick={() => setOpenUpdate(false)}>
            <FiX style={{ marginTop: "-4px", fontSize: "22px" }} />
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Administrator;
