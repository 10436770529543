import React, { useRef } from "react";
import "./RoomModal.css";
import { PiPrinterFill } from "react-icons/pi";
import { MdClose } from "react-icons/md"; // Import the close icon
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { useGetSingleClinicInfoQuery } from "../../../redux/clinicApi";
import { NumberFormat, PhoneNumberFormat } from "../../../hook/NumberFormat";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";

const RoomModal = ({ data, close, debt }) => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrintAndClose = () => {
    handlePrint();
    setTimeout(() => {
      if (close) close();
    }, 3000); // 3 seconds wait
  };

  const { data: clinic } = useGetSingleClinicInfoQuery(
    localStorage.getItem("clinicId")
  );
  const clinicInfo = clinic?.data;

  // Get the current date and time
  const currentDateTime = moment().format("DD.MM.YYYY HH:mm");
  const phoneNumber = clinicInfo?.contacts[0];

  return (
    <div className="roomModal">
      <div className="roomModal-scroll">
        <div className="modalInfo" ref={componentRef}>
          <h1
            style={{
              fontSize: clinicInfo?.name?.length > 18 ? "22px" : "32px",
            }}
          >
            {clinicInfo?.name}
          </h1>
          <p className="clinicContactTitle">Aloqa uchun ma'lumot</p>
          <span className="clinicAddress">
            <b>Manzil:</b> {clinicInfo?.address}
          </span>
          <br />
          <span className="clinicPhone">
            <b>Telefon:</b>+998 {PhoneNumberFormat(phoneNumber)}
          </span>
          <h2 className="paymentTitle">Davolanish to'lovi</h2>
          <ul className="patientInfo">
            <li>
              <strong>Bemor:</strong>
              {capitalizeFirstLetter(data.record.clientFullname)}
            </li>
            <li>
              <strong>Doktor:</strong> {data.record.doctorType + " "}
              {capitalizeFirstLetter(data.record.doctorFullname)}
            </li>
          </ul>
          <ul className="roomChecklist">
            {/* {data.activeRoomStories?.map((story) => ( */}
            <li className="roomItem">
              <div className="roomDetails">
                <div className="roomDetails-row">
                  <span className="roomNumber">Xona raqami:</span>
                  <span className="roomNumber">{data.record.roomNumber}</span>
                </div>
                <div className="roomDetails-row">
                  <span className="payForRoom">Kundalik to'lovi:</span>
                  <span className="payForRoom">
                    {NumberFormat(data.record.payForRoom)} so'm
                  </span>
                </div>
                <div className="roomDetails-row">
                  <span className="startDay">Kirish:</span>
                  <span className="startDay">{data.record.startDay}</span>
                </div>
                <div className="roomDetails-row">
                  <span className="endDay">Chiqish:</span>
                  <span className="endDay">    {data.record.paidDays?.length > 0
                    ? new Date(data.record.paidDays[data.record.paidDays?.length - 1].date).toLocaleDateString('uz-UZ', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })
                    : 'Ma’lumot yo\'q'}</span>
                </div>
                {debt &&
                  <div className="roomDetails-row">
                    <span className="startDay">Qoldiq summa:</span>
                    <span className="startDay">{NumberFormat(debt)} so'm</span>
                  </div>
                }
                <div className="roomDetails-row">
                  <span className="totalPay">To'lov miqdori:</span>
                  <span className="totalPay">
                    {NumberFormat(data.totalPayForRoom)} so'm
                  </span>
                </div>
              </div>
            </li>
            {/* ))} */}
            <li className="summary">
              <span className="date">Sana: {currentDateTime}</span>
              <span className="totalAmount">
                Jami: {NumberFormat(data.totalPayForRoom)} so'm
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="print-close-btn">
        <button className="printButton" onClick={handlePrintAndClose}>
          Chop etish <PiPrinterFill />
        </button>
        <button
          className="printButton closeButton"
          onClick={() => close(false)}
        >
          <MdClose />
        </button>
      </div>
    </div>
  );
};

export default RoomModal;
