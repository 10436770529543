import { api } from "./api";

export const balansApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBalance: builder.query({
      query: ({ date }) => `balans/getBalance?date=${date}`,
      providesTags: ["getMainBalans"],
    }),

    getMainBalans: builder.query({
      query: () => `/mainBalans`,
      providesTags: ["getMainBalans"],
    }),

    increment: builder.mutation({
      query: (paySum) => ({
        url: `/mainBalans/increment`,
        method: "PUT",
        body: { paySum },
      }),
      invalidatesTags: ["getMainBalans"],
    }),

    decrement: builder.mutation({
      query: (paySum) => ({
        url: `/mainBalans/decrement`,
        method: "PUT",
        body: { paySum },
      }),
      invalidatesTags: ["getMainBalans"],
    }),
  }),
});

export const {
  useGetBalanceQuery,
  useGetMainBalansQuery,
  useIncrementMutation,
  useDecrementMutation,
} = balansApi;
