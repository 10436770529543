import React, { useRef } from "react";
import { PiPrinterFill } from "react-icons/pi";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
import { RiScreenshot2Line } from "react-icons/ri";
import { NumberFormat } from "../../../hook/NumberFormat";
import CheckList from "../checkList/CheckList";
import "./style.css";
import { useGetSingleClinicInfoQuery } from "../../../redux/clinicApi";

function ModelCheck({ data, setList, type }) {
  const componentRef = useRef();

  let { data: clinic } = useGetSingleClinicInfoQuery(
    // "667865582b7d44c649554e7a"
    localStorage.getItem("clinicId")
  );
  let clinicInfo = clinic?.data;

  const time = new Date();
  const todaysTime =
    time.getDate() + "." + (time.getMonth() + 1) + "." + time.getFullYear();
  const Hours = time.getHours() + ":" + time.getMinutes();

  // -------------------------------------
  const contentRef = useRef(null);

  const takeScreenshot = () => {
    html2canvas(contentRef.current).then((canvas) => {
      // Convert canvas to image
      const imgData = canvas.toDataURL("image/png");

      // Create a download link
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "screenshot.png";
      link.click();
    });
  };

  return (
    <>
      <div className="viewBoxModel">
        {type === "Screen" ? (
          <button
            onClick={takeScreenshot}
            className="PrintChekList"
            type="submit"
          >
            <RiScreenshot2Line />
          </button>
        ) : (
          <ReactToPrint
            trigger={() => (
              <button className="PrintChekList" type="submit">
                <PiPrinterFill />
              </button>
            )}
            content={() => componentRef.current}
          ></ReactToPrint>
        )}
        <div ref={contentRef} className="waveListModel">
          <center id="top">
            <div className="logo">
              {/* <h1>{clinicInfo?.name}</h1> */}
              <h1
                style={{
                  fontSize: clinicInfo?.name?.length > 13 ? "20px" : "32px",
                }}
              >
                {clinicInfo?.name}
              </h1>
              <p>Shifoxona markazi</p>
            </div>
            <div className="info">
              <h2 className="item-h2">Har doim siz bilan!</h2>
            </div>
          </center>

          <div id="mid" style={{ borderBottom: ".5px solid #d6d6d6" }}>
            <div className="info">
              <h2 style={{ textAlign: "center" }} className="item-h2">
                Aloqa uchun ma'lumot
              </h2>
              <p style={{ color: " #848484" }} className="text_p">
                Manzil : {clinicInfo?.address}
                <br />
                Telefon : {clinicInfo?.contacts[0]}
                <br />
              </p>
            </div>
          </div>

          <div id="bot">
            <div id="table">
              <div className="tabletitle">
                <div className="item_check">
                  {/* <h2 className="item-h2">Element</h2> */}
                </div>
                <div className="Hours">
                  <h2 className="item-h2"></h2>
                </div>
                <div className="Rate">
                  <h2 className="item-h2"></h2>
                </div>
              </div>

              <div
                style={{ borderBottom: ".5px solid #d6d6d6" }}
                className="service"
              >
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    {data?.doctor_info?.specialization}
                    {/* : Oliy toifali shifokor */}
                  </p>
                </div>

                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    {data?.doctor_info?.lastName} {data?.doctor_info?.firstName}
                  </p>
                </div>
              </div>

              <div
                className="service"
                style={{ borderBottom: ".5px solid #d6d6d6" }}
              >
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    Doktor Tel :
                  </p>
                </div>
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    +998{data.doctor_info.phone}{" "}
                  </p>
                </div>
              </div>

              <div
                className="service"
                style={{ borderBottom: ".5px solid #d6d6d6" }}
              >
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    Qabul :
                  </p>
                </div>

                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    <div>
                      {NumberFormat(+data.paySum)}
                      so'm
                    </div>
                  </p>
                </div>
              </div>

              <div
                className="service"
                style={{ borderBottom: ".5px solid #d6d6d6" }}
              >
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    Bemor:
                  </p>
                </div>
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext">
                    {data.values.firstname} {data.values.lastname}
                  </p>
                </div>
              </div>

              <div
                className="service"
                style={{ borderBottom: ".5px solid #d6d6d6" }}
              >
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext text_p">
                    Sana :
                  </p>
                </div>
                <div className="tableitem">
                  <p style={{ color: " #848484" }} className="itemtext text_p">
                    {Hours} {todaysTime}
                  </p>
                </div>
              </div>

              <div className="tabletitle">
                <div className="tableitem">
                  <p>Jami: </p>
                </div>

                <div className="payment">
                  {/* <h2 className="item-h1">{NumberFormat(paySum)} so'm</h2> */}
                  <h2 className="item-h1">
                    <div>
                      {NumberFormat(data.paySum)}
                      so'm
                    </div>
                  </h2>
                </div>
              </div>
            </div>

            <div id="legalcopy" style={{ borderBottom: ".5px solid #d6d6d6" }}>
              {/* <h2>{queueNumber}</h2> */}
              <h2>{data?.queueNumber}</h2>
              <p>{data.doctor_info?.specialization}</p>
              <p>Sizning navbatingiz!</p>
            </div>
          </div>
        </div>
      </div>

      <div onClick={() => setList(false)} className="viewCheckListModel"></div>

      <div style={{ display: "none" }}>
        <CheckList
          componentRef={componentRef}
          firstname={data.values.firstname}
          lastname={data.values.lastname}
          todaysTime={todaysTime}
          Hours={Hours}
          doctorsData={[data.doctor_info]}
          queueNumber={data?.queueNumber}
          paySum={data.paySum}
        />
      </div>
    </>
  );
}

export default ModelCheck;
