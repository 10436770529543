import { api } from "./api";

export const paymentApi = api.injectEndpoints({
    endpoints: (builder) => ({

        getPaymentStories: builder.query({
            query: () => '/peyStry/getPayment',
            providesTags: ["getPayment"],
        }),


        createPaymentStory: builder.mutation({
            query: (newPaymentStory) => ({
                url: '/peyStry/createPayment',
                method: 'POST',
                body: newPaymentStory,
            }),
            invalidatesTags: ['getPayments'], // So'nggi so'rovlarni qayta yuklash uchun

        }),
    }),
});

export const {
    useCreatePaymentStoryMutation,
    useGetPaymentStoriesQuery,
} = paymentApi;