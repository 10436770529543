import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FaArrowLeftLong } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import HeartLine from "../../../assets/heartLogo.png";
import LayoutWrapper from "../../../components/layout/Layout";
import { useGetSingleAmbulatorQuery } from "../../../redux/ambulatorAPI";
import { useGetAllBotQuery } from "../../../redux/telegraf";

import "./style.css";

const Ambulator = () => {
  const history = useNavigate();
  const { id } = useParams();
  const { data: ambulator } = useGetSingleAmbulatorQuery(id);
  const availableData = ambulator?.data;
  const { data: dateInfo } = useGetAllBotQuery();
  console.log(dateInfo);
  const mapDataToFormFields = (data) => ({
    // UserIdNumber: data.idNumber,
    // fullName: data.fullName,
    // birthDay: data.birthDay,
    // birthMonth: data.birthMonth,
    // birthYear: data.birthYear,
    // gender: data.gender,
    // city: data?.city,
    // village: data.village,
    // street: data?.street,
    // house: data.house,
    // room: data.room,
    // phone: data.phone,
    // permanentAddress: data.permanentAddress,
    // temporaryAddress: data.temporaryAddress,
    // workPlace: data.workPlace,
    // registrationDate: data.registrationDate,
    // deregistrationReason: data.deregistrationReason,
    // dispensary: data.dispensary,
    // specificInstitution: data.specificInstitution,
    // otherInstitution: data.otherInstitution,
    // oilaviy_ahvoli: data.oilaviy_ahvoli,
    // complaints: data.complaints,
    // ovqat_hazm_qilish: data.ovqat_hazm_qilish,
    // tashxis: data.tashxis,
    // hozirgi_kasallik: data.hozirgi_kasallik,

    UserIdNumber: data.idNumber,
    fullName: data.fullName,
    birthDay: data.birthDay,
    birthMonth: data.birthMonth,
    birthYear: data.birthYear,
    gender: data.gender,
    city: data.city,
    village: data.village,
    street: data.street,
    house: data.house,
    room: data.room,
    phone: data.phone,
    permanentAddress: data.permanentAddress,
    temporaryAddress: data.temporaryAddress,
    workPlace: data.workPlace,
    registrationDate: data.registrationDate,
    deregistrationReason: data.deregistrationReason,
    dispensary: data.dispensary,
    specificInstitution: data.specificInstitution,
    otherInstitution: data.otherInstitution,
    oilaviy_ahvoli_boydok: data.oilaviy_ahvoli_boydok,
    oilaviy_ahvoli_turmush: data.oilaviy_ahvoli_turmush,
    oilaviy_ahvoli_turmush_yoq: data.oilaviy_ahvoli_turmush_yoq,
    oilaviy_ahvoli_uylangan: data.oilaviy_ahvoli_uylangan,
    complaints: data.complaints,
    ovqat_hazm_qilish: data.ovqat_hazm_qilish,
    tashxis: data.tashxis,
    hozirgi_kasallik_bor: data.hozirgi_kasallik_bor,
    hozirgi_kasallik_qayer: data.hozirgi_kasallik_qayer,
    hozirgi_kasallik_yoq: data.hozirgi_kasallik_yoq,
    alkogol_bazida: data.alkogol_bazida,
    alkogol_istemol_qilmaydi: data.alkogol_istemol_qilmaydi,
    alkogol_muntazam: data.alkogol_muntazam,
    anamnesisMorbi: data.anamnesisMorbi,
    anamnesis_hayot: data.anamnesis_hayot,
    anamnesis_vitae_kasal: data.anamnesis_vitae_kasal,
    aorta_ton_akcenti: data.aorta_ton_akcenti,
    appendix: data.appendix,
    auscultation: data.auscultation,
    bloodGroup: data.bloodGroup,
    bodyTemperature: data.bodyTemperature,
    boneDeformation: data.boneDeformation,
    breathRate: data.breathRate,
    chestPain: data.chestPain,
    constipation: data.constipation,
    consultationResults: data.consultationResults,
    diurez_kamaygan: data.diurez_kamaygan,
    diurez_ozgarmagan: data.diurez_ozgarmagan,
    doridarmon_allergiya: data.doridarmon_allergiya,
    dullSound: data.dullSound,
    ekg_xulosasi: data.ekg_xulosasi,
    epid_anamnez_aorta_bor: data.epid_anamnez_aorta_bor,
    epid_anamnez_aorta_yoq: data.epid_anamnez_aorta_yoq,
    epid_anamnez_dan: data.epid_anamnez_dan,
    epid_anamnez_gacha: data.epid_anamnez_gacha,
    finalDiagnosis: data.finalDiagnosis,
    fingerprintStatus: data.fingerprintStatus,
    gallBladderPain: data.gallBladderPain,
    gemotransfuziya_ha: data.gemotransfuziya_ha,
    gemotransfuziya_yoq: data.gemotransfuziya_yoq,
    hayz_korish: data.hayz_korish,
    heartMurmurs: data.heartMurmurs,
    heartRate: data.heartRate,
    height: data.height,
    irsy_moyillik: data.irsy_moyillik,
    jarroh_ha: data.jarroh_ha,
    jarroh_yoq: data.jarroh_yoq,
    jigar_kattalashgan: data.jigar_kattalashgan,
    jigar_kattalashmagan: data.jigar_kattalashmagan,
    jigar_sm: data.jigar_sm,
    kidneyPain: data.kidneyPain,
    leftArmBP: data.leftArmBP,
    legSwelling: data.legSwelling,
    lungSounds: data.lungSounds,
    lymphNodes: data.lymphNodes,
    maxs_manikyur: data.maxs_manikyur,
    maxs_manzil: data.maxs_manzil,
    maxs_pedikyur: data.maxs_pedikyur,
    maxs_pirsing: data.maxs_pirsing,
    maxs_tatuaj: data.maxs_tatuaj,
    oxirgi_1oy_harorat: data.oxirgi_1oy_harorat,
    oxirgi_6oy_muolajalar: data.oxirgi_6oy_muolajalar,
    parental_muolajalar: data.parental_muolajalar,
    percussionBoundary1: data.percussionBoundary1,
    percussionBoundary2: data.percussionBoundary2,
    physiologicalActivity: data.physiologicalActivity,
    pulseAdditionalInfo: data.pulseAdditionalInfo,
    pulseQuality: data.pulseQuality,
    pulseRate: data.pulseRate,
    qorin_ogriqli: data.qorin_ogriqli,
    qorin_ogriqsiz: data.qorin_ogriqsiz,
    qorin_qattiq: data.qorin_qattiq,
    qorin_shishgan: data.qorin_shishgan,
    qorin_yumshoq: data.qorin_yumshoq,
    recommendations: data.recommendations,
    rhesusFactor: data.rhesusFactor,
    rightArmBP: data.rightArmBP,
    salbiy_chekadi_kun: data.salbiy_chekadi_kun,
    salbiy_chekadi_vaqt: data.salbiy_chekadi_vaqt,
    salbiy_chekadi_yil: data.salbiy_chekadi_yil,
    skinCondition: data.skinCondition,
    stasionar_ha: data.stasionar_ha,
    stasionar_yoq: data.stasionar_yoq,
    stomachPain: data.stomachPain,
    swelling: data.swelling,
    talok_kattalashgan: data.talok_kattalashgan,
    talok_kattalashmagan: data.talok_kattalashmagan,
    talok_sm: data.talok_sm,
    til_nam: data.til_nam,
    til_oq: data.til_oq,
    til_qarash: data.til_qarash,
    til_quruq: data.til_quruq,
    til_sariq: data.til_sariq,
    til_toza: data.til_toza,
    tomoq_qara: data.tomoq_qara,
    tomoq_qizargan: data.tomoq_qizargan,
    tomoq_qizarmagan: data.tomoq_qizarmagan,
    tomoq_toza: data.tomoq_toza,
    umumiy_ahvoli: data.umumiy_ahvoli,
    urination: data.urination,
    weight: data.weight,
    yuqumli_kasalliklar: data.yuqumli_kasalliklar,
    yurak_shovqinlari: data.yurak_shovqinlari,
  });
  const { register, setValue } = useForm();

  useEffect(() => {
    if (availableData) {
      const mappedData = mapDataToFormFields(availableData);
      Object.keys(mappedData)?.forEach((key) => {
        setValue(key, mappedData[key]);
      });
    }
  }, [availableData, setValue]);

  const handleGoBack = () => {
    history(-1);
    history("/", { replace: true });
  };

  return (
    <LayoutWrapper>
      <div className="form-container_Medical singleAmbulator">
        <div className="header_MedicalForm">
          <div className="header_Medical">
            <p>ЎЗБЕКИСТОН РЕСПУБЛИКАСИ</p>
            <p>СОҒЛИҚНИ САҚЛАШ ВАЗИРЛИГИ</p>
            <p>Кардиология илмий амалий тиббиёт</p>
            <p>маркази Наманган филиали</p>
          </div>
          <div className="center-header">
            <img width={80} src={HeartLine} alt="" />
            <h2>
              MEDME <br />
              <i style={{ fontSize: "14px", color: "#6a6a6a" }}>CLINICS</i>{" "}
            </h2>
          </div>
          <div className="header_Medical">
            <p>Ўзбекистон Республикаси</p>
            <p>Соғлиқни сақлаш вазирининг</p>
            <p>2020 йил 31 декабрдаги № 363-сонли</p>
            <p>буюрғи билан тасдиқланган</p>
            <p>025-рақамли тиббий хужжат шакли</p>
          </div>
        </div>
        <h2 className="title">АМБУЛАТОР ТИББИЙ КАРТА №__</h2>
        <form>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            className="form-group_Medical"
          >
            <label>1. Фамилия. Исми:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("fullName")}
            />
          </div>
          <div className="form-group_Medical">
            <label>2. Туғилган сана кун:</label>
            <input readOnly={true} type="text" {...register("birthDay")} />
            <label>ой:</label>
            <input readOnly={true} type="text" {...register("birthMonth")} />
            <label>йил:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("birthYear")}
            />
          </div>

          <div className="form-group_Medical">
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              3. Жинси: эр
              <input
                readOnly={true}
                type="radio"
                value="male"
                {...register("gender")}
              />
              аёл
              <input
                readOnly={true}
                type="radio"
                value="female"
                {...register("gender")}
              />
            </div>
          </div>
          <div className="form-group_Medical">
            <label>4. Яшаш жойи, туман (шаҳар):</label>
            <input readOnly={true} type="text" {...register("city")} />
            <label>қишлоқ:</label>
            <input readOnly={true} type="text" {...register("village")} />
            <label>кўча:</label>
            <input readOnly={true} type="text" {...register("street")} />
            <label>уй:</label>
            <input readOnly={true} type="text" {...register("house")} />
            <label>хона:</label>
            <input readOnly={true} type="text" {...register("room")} />
            <label>телефон:+998</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("phone")}
            />
          </div>
          <div className="form-group_Medical">
            <label>5. Доимий яшаш жойи:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("permanentAddress")}
            />
          </div>
          <div className="form-group_Medical">
            <label>
              6. Вақтинча яшайди: бошқа шаҳар, қишлоқдан келган (чизинг)
            </label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("temporaryAddress")}
            />
          </div>
          <div className="form-group_Medical">
            <label>7. Иш (ўқиш) жойи:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("workPlace")}
            />
          </div>
          <div className="form-group_Medical">
            <label>8. Рўйхатдан чиққан сана:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("registrationDate")}
            />
          </div>
          <div className="form-group_Medical">
            <label>9. Рўйхатдан чиқиш сабаби кўрсатилсин:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("deregistrationReason")}
            />
          </div>
          <div className="form-group_Medical">
            <label>10. Диспансеризация:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("dispensary")}
            />
          </div>
          <div className="form-group_Medical">
            <label>
              10.1 Айнан шу муассасада: (шифокорлик хуқуқ № ва хуқуқ номи):
            </label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("specificInstitution")}
            />
          </div>
          <div className="form-group_Medical">
            <label>10.2 Бошқа муассасада (ташкилот номи):</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("otherInstitution")}
            />
          </div>
          <h2 className="title_bottom">Муҳим белгилар</h2>
          <div className="form-group_Medical">
            <label>Қон гуруҳи:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("bloodGroup")}
            />
            <label>Резус мансублик:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("rhesusFactor")}
            />
          </div>

          <div className="form-group_Medical">
            <label>Беморнинг шикоятлари:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("complaints")}
            />
          </div>
          <div className="form-group_Medical">
            <label>Anamnesis morbi (хозирги касаллик тафсилоти):</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("anamnesisMorbi")}
            />
          </div>
          <div className="form-group_Medical">
            <label>
              Тавсия этилган текширишлар ва консультацияларнинг натижалар:
            </label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("consultationResults")}
            />
          </div>
          <div className="form-group_Medical">
            <label>Якуний ташхис:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("finalDiagnosis")}
            />
          </div>
          <div className="form-group_Medical">
            <label>ТАВСИЯЛАР:</label>
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("recommendations")}
            />
          </div>
          <div className="status-presents">
            <h1>STATUS PRESENTS:</h1>
            <div>
              Умумий ахволи: қониқарли{" "}
              <input
                readOnly={true}
                {...register("umumiy_ahvoli")}
                type="radio"
                value="qoniqarli"
              />
              , ўртача{" "}
              <input
                readOnly={true}
                {...register("umumiy_ahvoli")}
                type="radio"
                value="ortacha"
              />
              , оғир{" "}
              <input
                readOnly={true}
                {...register("umumiy_ahvoli")}
                type="radio"
                value="ogir"
              />{" "}
              (белгиланг)
            </div>
            <div>
              Тана харорати{" "}
              <input
                readOnly={true}
                style={{ width: "120px" }}
                type="text"
                {...register("bodyTemperature")}
              />{" "}
              С; бўйи (см){" "}
              <input
                readOnly={true}
                style={{ width: "120px" }}
                {...register("height")}
              />{" "}
              танасининг вазни (кг){" "}
              <input
                readOnly={true}
                style={{ flex: "2" }}
                type="text"
                {...register("weight")}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                flexWrap: "wrap",
              }}
            >
              Тери ва кўринадиган шиллик қаватларининг холати ( соғлом
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="соғлом"
              />
              , ранги оқарган
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="ранги оқарган"
              />
              , сарғимтир
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="сарғимтир"
              />
              , цианоз
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="цианоз"
              />
              , акрацианоз
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="акрацианоз"
              />
              , қуруқ
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="қуруқ"
              />
              , нам
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="нам"
              />
              , ҳар хил тошмалар
              <input
                readOnly={true}
                {...register("skinCondition")}
                type="radio"
                value="ҳар хил тошмалар"
              />
              ) (белгиланг).
            </div>
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Сўяк-мушак тизими деформацияланмаган, деформацияланган:
            </label>{" "}
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("boneDeformation")}
            />
          </div>
          <div className="form-group_Medical">
            <label> Периферик лимфа тугунлар холати: катталашган:</label>{" "}
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("lymphNodes")}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            className="form-group_Medical"
          >
            Oёқларда шиш бор
            <input
              readOnly={true}
              {...register("swelling")}
              type="radio"
              value="бор"
            />
            , симметрик
            <input
              readOnly={true}
              {...register("swelling")}
              type="radio"
              value="симметрик"
            />
            , ассимметрик
            <input
              readOnly={true}
              {...register("swelling")}
              type="radio"
              value="ассимметрик"
            />
            , йўқ
            <input
              readOnly={true}
              {...register("swelling")}
              type="radio"
              value="йўқ"
            />
            (белгиланг).
          </div>

          <h2 className="status-presents_title">НАФАС ОЛИШ ТИЗИМИ</h2>
          <div className="form-group_Medical">
            <label></label> Нафас равон, юза, дақиқасига{" "}
            <input
              readOnly={true}
              style={{ width: "30%" }}
              type="text"
              {...register("breathRate")}
            />{" "}
            марта
          </div>
          <div className="form-group_Medical">
            <label></label> Ўпкада везикуляр, суст везикуляр, дагал нафас,
            қуруқ, нам хириллаш{" "}
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("lungSounds")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Қўкрак қафаси оғриксиз, оғрик бор:{" "}
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("chestPain")}
            />
          </div>
          <h2 className="status-presents_title">ЮРАК-ҚОН ТОМИР ТИЗИМИ</h2>
          <div className="form-group_Medical">
            <label></label> Артериал қон босими (мм.сим.уст) ўнг қўл{" "}
            <input readOnly={true} type="text" {...register("rightArmBP")} />{" "}
            чап қўл{" "}
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("leftArmBP")}
            />
          </div>

          <div
            style={{ display: "flex", alignItems: "center", gap: "5px" }}
            className="form-group_Medical"
          >
            <label>Пульс дақиқасига</label>
            <input
              readOnly={true}
              type="text"
              {...register("pulseRate")}
            />{" "}
            марта, текис
            <input
              readOnly={true}
              {...register("pulseQuality")}
              type="radio"
              value="текис"
            />
            , нотекис
            <input
              readOnly={true}
              {...register("pulseQuality")}
              type="radio"
              value="нотекис"
            />
            (белгиланг)
            <input
              readOnly={true}
              style={{ flex: "1" }}
              type="text"
              {...register("pulseAdditionalInfo")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> ЮЎС дақиқасига{" "}
            <input readOnly={true} type="text" {...register("heartRate")} />{" "}
            марта, текис, нотекис
          </div>
          <h3 className="status-presents_title">ПЕРКУСИЯ:</h3>
          <div className="form-group_Medical">
            <label></label> Чегараси: чап-5-қовурғалар орасида
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("percussionBoundary1")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> чап қовурғалар-сўл{" "}
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("percussionBoundary2")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> ТЎҚ ШОВҚИН
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("dullSound")}
            />
          </div>
          <h3 className="status-presents_title">АУСКУЛТАЦИЯ:</h3>
          <div className="form-group_Medical">
            <label></label> Юрак шовқинлари йўқ, бор:{" "}
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("heartMurmurs")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Оёқларда шиш бор:{" "}
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("legSwelling")}
            />
          </div>
          <h2 className="status-presents_title">ХАЗМ ҚИЛИШ ТИЗИМИ</h2>
          <div className="form-group_Medical">
            <label></label> Ошқозон-яланг, оғриқсиз, оғриқ бор:{" "}
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("stomachPain")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Ўт пуфаги-яланг, оғриқсиз, оғриқ бор:{" "}
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("gallBladderPain")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Буйрак-оғриқсиз, оғриқ бор:{" "}
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("kidneyPain")}
            />
          </div>
          <div className="form-group_Medical">
            <label></label> Физиологик фаолият нормал:{" "}
            <input
              readOnly={true}
              type="text"
              style={{ flex: "1" }}
              {...register("physiologicalActivity")}
            />
          </div>

          <div className="status-presents">
            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
              <b style={{ fontSize: "16px" }}>АУСКУЛЬТАЦИЯ:</b> Юрак товушлари
              бўйикроқ
              <input
                readOnly={true}
                {...register("auscultation")}
                type="radio"
                value="бўйикроқ"
              />
              , бўғик
              <input
                readOnly={true}
                {...register("auscultation")}
                type="radio"
                value="бўғик"
              />
              , аник
              <input
                readOnly={true}
                {...register("auscultation")}
                type="radio"
                value="аник"
              />
              , текис
              <input
                readOnly={true}
                {...register("auscultation")}
                type="radio"
                value="текис"
              />
              , нотекис
              <input
                readOnly={true}
                {...register("auscultation")}
                type="radio"
                value="нотекис"
              />
              (белгиланг).
            </div>
          </div>
          <div className="form-group_Medical">
            Юрак шовқинлари:{" "}
            <input
              readOnly={true}
              {...register("yurak_shovqinlari")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>

          <div className="form-group_Medical">
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              Аортада II тон акценти бор
              <input
                readOnly={true}
                {...register("aorta_ton_akcenti")}
                type="radio"
                name="aorta_ton_akcenti"
                value="bor"
              />
              йўқ
              <input
                readOnly={true}
                {...register("aorta_ton_akcenti")}
                type="radio"
                name="aorta_ton_akcenti"
                value="yo'q"
              />
            </div>
          </div>

          <h3 className="status-presents_title">ОВҚАТ ХАЗМ ҚИЛИШ ТИЗИМИ</h3>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Тил -</b> тоза{" "}
            <input readOnly={true} {...register("til_toza")} type="radio" />,
            нам <input readOnly={true} {...register("til_nam")} type="radio" />,
            қуруқ{" "}
            <input readOnly={true} {...register("til_quruq")} type="radio" />,
            оқ <input readOnly={true} {...register("til_oq")} type="radio" />,
            сариқ{" "}
            <input readOnly={true} {...register("til_sariq")} type="radio" />,
            қараш босган
            <input
              readOnly={true}
              {...register("til_qarash")}
              type="radio"
            />{" "}
            (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Томоқ -</b> қизарган
            <input
              readOnly={true}
              {...register("tomoq_qizargan")}
              type="radio"
            />
            , қизармаган
            <input
              readOnly={true}
              {...register("tomoq_qizarmagan")}
              type="radio"
            />
            , тоза
            <input readOnly={true} {...register("tomoq_toza")} type="radio" />,
            қараш босган
            <input
              readOnly={true}
              {...register("tomoq_qara")}
              type="radio"
            />{" "}
            (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Муртак безлари -</b> катталашмаган
            <input
              readOnly={true}
              {...register("fingerprintStatus")}
              type="radio"
              value="notDeveloped"
            />
            , катталашган
            <input
              readOnly={true}
              {...register("fingerprintStatus")}
              type="radio"
              value="developed"
            />
            (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Қорин -</b> юмшок
            <input
              readOnly={true}
              {...register("qorin_yumshoq")}
              type="radio"
            />
            , қоттик
            <input readOnly={true} {...register("qorin_qattiq")} type="radio" />
            , оғрикли
            <input
              readOnly={true}
              {...register("qorin_ogriqli")}
              type="radio"
            />
            , оғриксиз
            <input
              readOnly={true}
              {...register("qorin_ogriqsiz")}
              type="radio"
            />
            , шишган
            <input
              readOnly={true}
              {...register("qorin_shishgan")}
              type="radio"
            />
            .
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Жигар -</b> катталашмаган
            <input
              readOnly={true}
              {...register("jigar_kattalashmagan")}
              type="radio"
            />
            , катталашган
            <input
              readOnly={true}
              {...register("jigar_kattalashgan")}
              type="radio"
            />{" "}
            (+{" "}
            <input
              readOnly={true}
              {...register("jigar_sm")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                width: "70px",
              }}
              type="text"
            />{" "}
            см).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Талок -</b> катталашмаган
            <input
              readOnly={true}
              {...register("talok_kattalashmagan")}
              type="radio"
            />
            , катталашган
            <input
              readOnly={true}
              {...register("talok_kattalashgan")}
              type="radio"
            />{" "}
            (+{" "}
            <input
              readOnly={true}
              {...register("talok_sm")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                width: "70px",
              }}
              type="text"
            />{" "}
            см) (белгиланг). <span>Қабзият бор</span>
            <input
              readOnly={true}
              {...register("constipation")}
              type="radio"
              value="present"
            />
            , йўқ
            <input
              readOnly={true}
              {...register("constipation")}
              type="radio"
              value="absent"
            />
            (белгиланг)..
          </div>
          <div>
            <input
              readOnly={true}
              {...register("ovqat_hazm_qilish")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                width: "100%",
              }}
              type="text"
            />
          </div>

          <h3 className="status-presents_title">СИЙДИК АЖРАТИШ ТИЗИМИ</h3>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <b style={{ fontSize: "16px" }}>Диурез -</b> равон
            <input
              readOnly={true}
              {...register("urination")}
              type="radio"
              value="normal"
            />
            , эркин
            <input
              readOnly={true}
              {...register("urination")}
              type="radio"
              value="frequent"
            />
            , оғрикли
            <input
              readOnly={true}
              {...register("urination")}
              type="radio"
              value="painful"
            />
            , оғриксиз
            <input
              readOnly={true}
              {...register("urination")}
              type="radio"
              value="painFree"
            />
            (белгиланг).
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            Микдори - камайган
            <input
              readOnly={true}
              {...register("diurez_kamaygan")}
              type="radio"
            />
            , ўзгармаган
            <input
              readOnly={true}
              {...register("diurez_ozgarmagan")}
              type="radio"
            />{" "}
            (белгиланг)
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            Туртки белгиси - мусбат
            <input
              readOnly={true}
              {...register("appendix")}
              type="radio"
              value="positive"
            />
            , манфий
            <input
              readOnly={true}
              {...register("appendix")}
              type="radio"
              value="negative"
            />
            , ўнг
            <input
              readOnly={true}
              {...register("appendix")}
              type="radio"
              value="right"
            />
            , чап томонлама
            <input
              readOnly={true}
              {...register("appendix")}
              type="radio"
              value="leftAligned"
            />
            (белгиланг).
          </div>
          <div className="form-group_Medical">
            <b style={{ fontSize: "16px" }}>ЭКГ хулосаси:</b>{" "}
            <input
              readOnly={true}
              {...register("ekg_xulosasi")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                flex: "1",
              }}
              type="text"
            />
          </div>
          <br />
          <div className="form-group_Medical">
            <label htmlFor="">
              Беморнинг шикоятлари, касаллик тарихи, ҳаёт тарзи, умумий ахволи
              ва объектив текширувлар асосидаги
            </label>{" "}
          </div>
          <div className="form-group_Medical">
            <b style={{ fontSize: "16px" }}>ТАШХИС:</b>{" "}
            <input
              readOnly={true}
              {...register("tashxis")}
              style={{
                border: "none",
                borderBottom: "1px solid #ccc",
                flex: "1",
              }}
              type="text"
            />
          </div>

          <div className="status-presents">
            <div>Anamnesis vitae: беморнинг кечирган касалликлари:</div>
            <div>
              <input
                readOnly={true}
                {...register("anamnesis_vitae_kasal")}
                style={{ width: "100%" }}
                type="text"
              />
            </div>
            <div>
              Эпид. анамнез: доимий яшаш, иш, ўқишда юқумли касалликлар (қорин
              тифи, паратиф, сальмонеллёз,
              <br /> дизентерия бошқа ўткир ичак инфекциялари, вирусли гепатит,
              тери-таносил касалликлари) билан оғриган беморлар билан <br />{" "}
              муносабатда бўлганми (касалликнинг максимал инкубацион даврини
              ҳисобга олган ҳолда)?
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                бор
                <input
                  readOnly={true}
                  {...register("epid_anamnez_aorta_bor")}
                  type="radio"
                  name="epid_anamnez_aorta"
                  value="bor"
                />
                йўқ
                <input
                  readOnly={true}
                  {...register("epid_anamnez_aorta_yoq")}
                  type="radio"
                  name="epid_anamnez_aorta"
                  value="yo'q"
                />
                (белгиланг).{" "}
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
                className="form-group_Medical"
              >
                Мулоқотда бўлган вақти: дан:
                <input
                  readOnly={true}
                  {...register("epid_anamnez_dan")}
                  type="text"
                />
                гача:
                <input
                  readOnly={true}
                  {...register("epid_anamnez_gacha")}
                  type="text"
                />
              </div>
            </div>

            <div>
              Ҳақиқий (ҳозирги) касаллик бошланишидан 1 ҳафта - 2 ой олдин аҳоли
              пунктидан четда бўлганми? <br />{" "}
              <div
                style={{ display: "flex", alignItems: "center", gap: "6px" }}
              >
                (бор
                <input
                  readOnly={true}
                  {...register("hozirgi_kasallik_bor")}
                  type="radio"
                  name="hozirgi_kasallik"
                  value="bor"
                />
                йўқ
                <input
                  readOnly={true}
                  {...register("hozirgi_kasallik_yoq")}
                  type="radio"
                  name="hozirgi_kasallik"
                  value="yo'q"
                />
                ) (белгиланг). Қаерда бўлган ва қачон қайтиб келган:{" "}
                <input
                  readOnly={true}
                  {...register("hozirgi_kasallik_qayer")}
                  style={{ width: "100%" }}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form-group_Medical">
            <label htmlFor="">Қандай юқумли касалликлар билан оғриган?</label>{" "}
            <input
              readOnly={true}
              {...register("yuqumli_kasalliklar")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="status-presents">
            <div>Сўнгги 6 ой ичида:</div>
          </div>
          <div className="status-presents">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                flexWrap: "wrap",
              }}
            >
              Стационар ёки амбулатор даволанишда бўлганми? (ҳа{" "}
              <input
                readOnly={true}
                {...register("stasionar_ha")}
                type="radio"
                name="stasionar"
                value="bor"
              />
              , йўқ
              <input
                readOnly={true}
                {...register("stasionar_yoq")}
                type="radio"
                name="stasionar"
                value="yo'q"
              />
              ); гемотрансфузия (қон ва унинг компонентлари) ўтказилганми? (ҳа{" "}
              <input
                readOnly={true}
                {...register("gemotransfuziya_ha")}
                type="radio"
                name="gemotransfuziya"
                value="bor"
              />
              , йўқ
              <input
                readOnly={true}
                {...register("gemotransfuziya_yoq")}
                type="radio"
                name="gemotransfuziya"
                value="yo'q"
              />
              ); беморга жарроҳлик муолажалари ўтказилганми? (ҳа{" "}
              <input
                readOnly={true}
                {...register("jarroh_ha")}
                type="radio"
                name="jarroh"
                value="bor"
              />
              , йўқ
              <input
                readOnly={true}
                {...register("jarroh_yoq")}
                type="radio"
                name="jarroh"
                value="yo'q"
              />
              ).
            </div>
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Парентерал муолажалар (жумладан, игна билан даволаш) қабул
              қилганми?
            </label>{" "}
            <input
              readOnly={true}
              {...register("parental_muolajalar")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                flexWrap: "wrap",
              }}
            >
              {" "}
              Маъиший хизмат турларидан фойдаланганми (маникюр
              <input
                readOnly={true}
                {...register("maxs_manikyur")}
                type="radio"
                name="maxs_xizmat"
                value="manikyur"
              />
              , педикюр
              <input
                readOnly={true}
                {...register("maxs_pedikyur")}
                type="radio"
                name="maxs_xizmat"
                value="pedikyur"
              />
              , пирсинг
              <input
                readOnly={true}
                {...register("maxs_pirsing")}
                type="radio"
                name="maxs_xizmat"
                value="pirsing"
              />
              , татуаж ва б.
              <input
                readOnly={true}
                {...register("maxs_tatuaj")}
                type="radio"
                name="maxs_xizmat"
                value="tatuaj"
              />
              ) қачон ва қаерда (манзил)?
            </label>{" "}
            <input
              readOnly={true}
              {...register("maxs_manzil")}
              style={{ width: "100%" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label> Ирсий мойиллик:</label>{" "}
            <input
              readOnly={true}
              {...register("irsy_moyillik")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Anamnesis vitae (беморнинг ҳаёт тарихи, кесирган касалликлари)
            </label>{" "}
            <input
              readOnly={true}
              {...register("anamnesis_hayot")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Салбий одатлари: чекади:
              <input
                readOnly={true}
                {...register("salbiy_chekadi_yil")}
                style={{ width: "70px" }}
                type="text"
              />{" "}
              йил, кунига{" "}
              <input
                readOnly={true}
                {...register("salbiy_chekadi_kun")}
                style={{ width: "70px" }}
                type="text"
              />{" "}
              та, чекмайди (вақти кўрсатилсин){" "}
              <input
                readOnly={true}
                {...register("salbiy_chekadi_vaqt")}
                style={{ flex: "1" }}
                type="text"
              />
            </label>
          </div>
          <div className="status-presents">
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              Алкогол ичимликлар истеъмоли: (мунтазам
              <input
                readOnly={true}
                {...register("alkogol_muntazam")}
                type="radio"
                name="alkogol_istemoli"
                value="bor"
              />
              баъзида
              <input
                readOnly={true}
                {...register("alkogol_bazida")}
                type="radio"
                name="alkogol_istemoli"
                value="yo'q"
              />
              истеъмол қилмайди
              <input
                readOnly={true}
                {...register("alkogol_istemol_qilmaydi")}
                type="radio"
                name="alkogol_istemoli"
                value="yo'q"
              />
              ) (белгиланг).
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
              Оилавий аҳволи: (турмушга чиққан,
              <input
                readOnly={true}
                {...register("oilaviy_ahvoli_turmush")}
                type="radio"
                name="oilaviy_ahvoli"
                value="bor"
              />
              турмушга чиқмаган,
              <input
                readOnly={true}
                {...register("oilaviy_ahvoli_turmush_yoq")}
                type="radio"
                name="oilaviy_ahvoli"
                value="yo'q"
              />
              уйланган,
              <input
                readOnly={true}
                {...register("oilaviy_ahvoli_uylangan")}
                type="radio"
                name="oilaviy_ahvoli"
                value="yo'q"
              />
              бўйдок,
              <input
                readOnly={true}
                {...register("oilaviy_ahvoli_boydok")}
                type="radio"
                name="oilaviy_ahvoli"
                value="yo'q"
              />
              ) (белгиланг).
            </div>
          </div>
          <div className="form-group_Medical">
            <label> Ҳайз кўриш даври, ҳомиладорлиги</label>{" "}
            <input
              readOnly={true}
              {...register("hayz_korish")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label> Охирги 6 ой ичида қабул қилган муолажалари</label>{" "}
            <input
              readOnly={true}
              {...register("oxirgi_6oy_muolajalar")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label> Дори-дармон ва озиқ-овқатга аллергияси</label>{" "}
            <input
              readOnly={true}
              {...register("doridarmon_allergiya")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
          <div className="form-group_Medical">
            <label>
              {" "}
              Охирги 1 ой ичида тана ҳарорати кўтарилган, кўтарилмаган (тaгига
              чизилсин)
            </label>{" "}
            <input
              readOnly={true}
              {...register("oxirgi_1oy_harorat")}
              style={{ flex: "1" }}
              type="text"
            />
          </div>
        </form>

        <button className="ambulatorGoBackBtn" onClick={handleGoBack}>
          <FaArrowLeftLong />
        </button>
      </div>
    </LayoutWrapper>
  );
};

export default Ambulator;
