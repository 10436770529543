import React, { useState } from "react";
import { Button, message, Modal, Table, Popover } from "antd";
import {
  ExclamationCircleFilled,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { LuClipboardEdit } from "react-icons/lu";
import { PhoneNumberFormat, NumberFormat } from "../../../../hook/NumberFormat";
import {
  useDeleteDoctorMutation,
  useGetAllDoctorsQuery,
} from "../../../../redux/doctorApi";
import Update from "../../update/Doctor";
import "../../update/style.css";
import PopoverContent from "./PopoverContent"; // Import the PopoverContent component

const { confirm } = Modal;

const ReadDoctors = ({ doctorQuery, query }) => {
  const { data: allDoctor } = useGetAllDoctorsQuery();
  const doctor = allDoctor?.data;
  const [editID, setEditID] = useState(null);
  const [open, setOpen] = useState(false);
  const [deleteDoctor] = useDeleteDoctorMutation();
  const doctorsData = doctor
    ?.filter(
      (doc) =>
        doc.docORrecep === "doctor" && doc.idNumber?.includes(doctorQuery)
    )
    ?.filter((doc) => doc.firstName?.includes(query));

  const showModal = (id) => {
    setOpen(true);
    setEditID(id);
  };

  const showDeleteConfirm = (_id) => {
    confirm({
      title: "O'chirib tashlaysizmi?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        deleteDoctor(_id)
          .then((res) => {
            if (res.data.success) {
              message.success("Doktor o'chirildi!");
            } else {
              message.error(res.data.message);
            }
          })
          .catch((err) => console.log(err));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const Style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const columns = [
    {
      title: "№",
      dataIndex: "roomNumber",
      key: "roomNumber",
    },
    {
      title: "Ismi",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Familiyasi",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Kasbi",
      dataIndex: "specialization",
      key: "specialization",
    },
    {
      title: "Tel raqam",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => PhoneNumberFormat(phone),
    },
    {
      title: "Qabuli",
      dataIndex: "analis",
      key: "analis",
      render: (_, record) => {
        if (record.type === "analis" || record.type === "fizioterapiya" || record.type === "uzi" || record.type === "massaj") {
          return (
            <Popover content={<PopoverContent record={record} />}>
              <Button style={Style}>
                <EyeOutlined />
              </Button>
            </Popover>
          );
        } else {
          return (
            <div>
              <div>{NumberFormat(record.feesPerCunsaltation)} so'm</div>
              {record.secondPrice === 0
                ? " "
                : `Ikkilamchi: ${NumberFormat(record.secondPrice)} so'm`}
            </div>
          );
        }
      },
    },
    {
      title: "Oylik",
      dataIndex: "percent",
      key: "percent",
      render: (percent, record) => {
        return percent ? `${percent} %` : `${NumberFormat(record.salary)} so'm`;
      },
    },
    {
      title: "Taxrirlash",
      key: "edit",
      render: (_, record) => (
        <Button style={Style} onClick={() => showModal(record._id)}>
          <LuClipboardEdit />
        </Button>
      ),
    },
    {
      title: "O'chirish",
      key: "delete",
      render: (_, record) => (
        <Button
          style={Style}
          onClick={() => showDeleteConfirm(record._id)}
          type="primary"
          danger
        >
          <DeleteOutlined />
        </Button>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={"_id"}
        pagination={false}
        loading={!doctorsData ? true : false}
        bordered={true}
        size="small"
        dataSource={doctorsData}
        columns={columns}
      />

      {open && (
        <Update doctor={doctor} editID={editID} setOpenUpdate={setOpen} />
      )}
    </>
  );
};

export default ReadDoctors;


