import React, { useState, useEffect } from "react";
import { EyeOutlined } from "@ant-design/icons";
import { FcDocument } from "react-icons/fc";
import { GiTakeMyMoney } from "react-icons/gi";
import {
  Button,
  Tabs,
  Popconfirm,
  Space,
  InputNumber,
  Table,
  message,
  Statistic,
} from "antd";
import { Link, NavLink } from "react-router-dom";
import { MdNotifications, MdNotificationsActive } from "react-icons/md";
import CountUp from "react-countup";
import LayoutWrapper from "../../../components/layout/Layout";
import {
  useGetAllDoctorsQuery,
  useUpdateDoctorMutation,
} from "../../../redux/doctorApi";
import SearchBar from "../../../components/SearchComponent";
import {
  useGetSalaryQuery,
  useCreateSalaryMutation,
} from "../../../redux/salaryApi";
import "./style.css";
import { useGetAllNursesQuery } from "../../../redux/nursesApi";
import { NumberFormat } from "../../../hook/NumberFormat";

const SalaryDep = () => {
  const width = window.innerWidth;
  const [createSalary] = useCreateSalaryMutation();
  const [updateDoctor] = useUpdateDoctorMutation();

  const data = useGetAllDoctorsQuery()?.data?.data;
  const allnurses = useGetAllNursesQuery()?.data?.innerData;
  const salary = useGetSalaryQuery()?.data?.innerData;
  const [dataSource, setDataSource] = useState([]);
  const [Adminstrators, setAdminstators] = useState([]);
  const [avansPrice, setAvansPrice] = useState(0);
  const [nurseAvansPrice, setNurseAvansPrice] = useState(0);
  const [query, setQuery] = useState("");
  const [doctorQuery, setDoctorQuery] = useState("");

  useEffect(() => {
    setDataSource(data?.filter((data) => data.docORrecep === "doctor"));
    setAdminstators(data?.filter((data) => data.docORrecep === "reception"));
  }, [data]);

  // let allData = ([...dataSource, ...allnurses, ...Adminstrators]);
  let allData = [
    ...(dataSource || []),
    ...(allnurses || []),
    ...(Adminstrators || []),
  ];

  const time = new Date();
  const today =
    time.getDate() + "." + (time.getMonth() + 1) + "." + time.getFullYear();

  const Month = time.toLocaleString("en-US", { month: "long" });

  // Notification
  const handleNote = (id, currentGetSalary) => {
    const newInfo = {
      getSalary: !currentGetSalary,
    };

    updateDoctor({ id: id, body: newInfo })
      .then((res) => console.log(res))
      .catch((res) => console.error(res));
  };
  const formatter = (value) => <CountUp end={value} separator="," />;

  const columns = [
    {
      title: "Doktor",
      dataIndex: "firstName",
      render: (_, record) => record.firstName + "." + record.lastName[0],
    },
    {
      title: "Mutaxassis",
      dataIndex: "specialization",
    },
    {
      title: "Oylik",
      dataIndex: "salary",
      render: (_, record) => (
        <div className="main-ant-statistic-content-value">
          <Statistic value={record.salary} formatter={formatter} />
        </div>
      ),
    },
    {
      title: "Avans",
      dataIndex: "salary",
      render: (_, record) => (
        <Space>
          <InputNumber placeholder="Avans sumasi" onChange={setAvansPrice} />
          <Popconfirm
            title="Avans maoshini bermoqchimisiz?"
            onConfirm={async () => {
              if (!avansPrice) {
                return message.warning("Avval avans summasini kiriting!");
              }

              const body = {
                type: "avans",
                idNumber: record?.idNumber,
                avans: avansPrice,
                salary: 0,
                salarySatate: false,
                getDay: today,
                getMonth: Month,
              };

              try {
                const res = await createSalary(body);
                if (res?.data?.success) {
                  message.success("Avans saqlandi");
                }
                setAvansPrice(0);
              } catch (err) {
                console.log(err);
                message.error(
                  "Xatolik yuz berdi. Iltimos, qayta urinib ko'ring."
                );
              }
            }}
          >
            <Button>Berish</Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "qongiroq",
      render: (_, record) =>
        salary?.find(
          (item) =>
            item.idNumber === record.idNumber &&
            item.type === "salary" &&
            item.getMonth === Month
        ) ? (
          <p style={{ color: "green" }}>Berilgan</p>
        ) : (
          <p style={{ color: "red" }}>Berilmagan</p>
        ),
    },
    {
      title: "Habar berish",
      dataIndex: "Habar berish",
      render: (_, record) => {
        const currentGetSalary = record.getSalary;
        return (
          <Button
            onClick={() => handleNote(record._id, currentGetSalary)}
            style={{ fontSize: "19px" }}
            className="btnAddMoney"
          >
            {currentGetSalary ? (
              <MdNotificationsActive style={{ color: "red" }} />
            ) : (
              <MdNotifications />
            )}
          </Button>
        );
      },
    },
    {
      title: "Maosh berish",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title={` ${NumberFormat(
              record.salary
            )} so'm Oylik maoshini bermoqchimisiz?`}
            onConfirm={() => {
              let body = {
                type: "salary",
                idNumber: record?.idNumber,
                avans: 0,
                salary: record.salary,
                salarySatate: true,
                getDay: today,
                getMonth: Month,
              };
              createSalary(body)
                .then((res) => {
                  if (res?.data?.success) {
                    return message.success(
                      `${NumberFormat(record.salary)} oylik berildi`
                    );
                  }
                })
                .catch((err) => console.log(err));
            }}
          >
            <Button>
              <GiTakeMyMoney style={{ fontSize: "22px" }} />
            </Button>
          </Popconfirm>
        ) : null,
    },
    {
      title: "Tarix",
      dataIndex: "Habar berish",
      render: (_, record) => (
        <NavLink to={`/kunlikBemorlar/${record.idNumber}`}>
          <Button type="primary" className="btnAddMoney">
            <EyeOutlined />
          </Button>
        </NavLink>
      ),
    },
  ];

  const nursesColumns = [
    {
      title: "Hamshira",
      dataIndex: "firstName",
      render: (_, record) => record.firstName + "." + record.lastName[0],
    },
    {
      title: "Oylik",
      dataIndex: "salary",
      render: (_, record) => (
        <div>
          <div style={{ color: "green" }}>{NumberFormat(record.salary)}</div>
          <div style={{ color: "red" }}>
            {NumberFormat(
              salary
                ?.filter(
                  (i) =>
                    i.idNumber === record.idNumber &&
                    i.getMonth === Month &&
                    i.type === "avans"
                )
                ?.reduce((a, b) => a + b.avans, 0) || 0
            )}
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "qongiroq",
      render: (_, record) =>
        salary?.find(
          (item) =>
            item?.idNumber === record?.idNumber &&
            item?.type === "salary" &&
            item?.getMonth === Month
        ) ? (
          <p style={{ color: "green" }}>Berilgan</p>
        ) : (
          <p style={{ color: "red" }}>Berilmagan</p>
        ),
    },
    {
      title: "Avans",
      dataIndex: "salary",
      render: (_, record) => (
        <Space>
          <InputNumber
            type="number"
            onChange={(e) => setNurseAvansPrice(e)}
            value={nurseAvansPrice}
          />
          <Popconfirm
            title="Avans maoshini bermoqchimisiz?"
            onConfirm={() => {
              let body = {
                type: "avans",
                idNumber: record?.idNumber,
                avans: nurseAvansPrice,
                salary: 0,
                salarySatate: false,
                getDay: today,
                getMonth: Month,
              };
              createSalary(body)
                .then((res) => {
                  if (res?.data?.data?.success) {
                    return message.success();
                  }
                })
                .catch((err) => console.log(err));
              setNurseAvansPrice(0);
            }}
          >
            <Button>Berish</Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Maosh berish",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Oylik maoshini bermoqchimisiz?"
            onConfirm={() => {
              let oldAvans =
                salary
                  ?.filter(
                    (i) =>
                      i.idNumber === record.idNumber &&
                      i.getMonth === Month &&
                      i.type === "avans"
                  )
                  ?.reduce((a, b) => a + b.avans, 0) || 0;

              let body = {
                type: "salary",
                idNumber: record?.idNumber,
                salary: record.salary - oldAvans,
                salarySatate: true,
                getDay: today,
                getMonth: Month,
              };

              createSalary(body)
                .then((res) => {
                  if (res?.data?.data?.success) {
                    return message.success(res?.data?.data?.message);
                  }
                })
                .catch((err) => console.log(err));
            }}
          >
            <Button>
              <GiTakeMyMoney style={{ fontSize: "22px" }} />
            </Button>
          </Popconfirm>
        ) : null,
    },
    {
      title: "Tarix",
      dataIndex: "Habar berish",
      render: (_, record) => (
        <NavLink to={`/singleNurseSalary/${record.idNumber}`}>
          <Button type="primary" className="btnAddMoney">
            <EyeOutlined />
          </Button>
        </NavLink>
      ),
    },
  ];

  const adminstratorsColumns = [
    {
      title: "Adminstrator",
      dataIndex: "firstName",
      render: (_, record) => record.firstName + "." + record.lastName[0],
    },
    {
      title: "Mutaxassis",
      dataIndex: "specialization",
      render: () => {
        return "Adminstrator";
      },
    },
    {
      title: "Oylik",
      dataIndex: "salary",
      render: (_, record) => (
        <div>
          <div style={{ color: "green" }}>{NumberFormat(record.salary)}</div>
        </div>
      ),
    },
    {
      title: "Avans",
      dataIndex: "salary",
      render: (_, record) => (
        <Space>
          <InputNumber onChange={(e) => setAvansPrice(e)} />
          <Popconfirm
            title="Avans bermoqchimisiz?"
            onConfirm={() => {
              const body = {
                type: "avans",
                idNumber: record?.idNumber,
                avans: avansPrice,
                salary: 0,
                salarySatate: false,
                getDay: today,
                getMonth: Month,
              };

              if (!body.idNumber) {
                return message.warning("ID number is required");
              }
              createSalary(body)
                .then((res) => {
                  if (res?.data?.success) {
                    return message.success("avans saqlandi");
                  }
                })
                .catch((err) => console.log(err));
              setAvansPrice(0);
            }}
          >
            <Button>Berish</Button>
          </Popconfirm>
        </Space>
      ),
    },
    {
      title: "Status",
      dataIndex: "qongiroq",
      render: (_, record) =>
        salary?.find(
          (item) =>
            item.idNumber === record.idNumber &&
            item.type === "salary" &&
            item.getMonth === Month
        ) ? (
          <p style={{ color: "green" }}>Berilgan</p>
        ) : (
          <p style={{ color: "red" }}>Berilmagan</p>
        ),
    },
    {
      title: "Habar berish",
      dataIndex: "Habar berish",
      render: (_, record) => {
        const currentGetSalary = record.getSalary;
        return (
          <Button
            onClick={() => handleNote(record._id, currentGetSalary)}
            style={{ fontSize: "19px" }}
            className="btnAddMoney"
          >
            {currentGetSalary ? (
              <MdNotificationsActive style={{ color: "red" }} />
            ) : (
              <MdNotifications />
            )}
          </Button>
        );
      },
    },
    {
      title: "Maosh berish",
      dataIndex: "operation",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm
            title="Oylik maoshini bermoqchimisiz?"
            onConfirm={() => {
              const body = {
                type: "salary",
                idNumber: record?.idNumber,
                avans: 0,
                salary: +record.salary,
                salarySatate: true,
                getDay: today,
                getMonth: Month,
              };

              if (!body.idNumber) {
                return message.warning("ID number is required");
              }
              createSalary(body)
                .then((res) => {
                  console.log(res);
                  if (res?.data?.success) {
                    return message.success(
                      `${NumberFormat(record.salary)} oylik berildi`
                    );
                  }
                })
                .catch((err) => console.log(err));
            }}
          >
            <Button>
              <GiTakeMyMoney style={{ fontSize: "22px" }} />
            </Button>
          </Popconfirm>
        ) : null,
    },
  ];

  const noteRegularSalary = () => {
    dataSource?.forEach((doctor) => {
      if (doctor.regularSalary > 0) {
        const newInfo = {
          salary: doctor.salary + doctor.regularSalary,
        };

        updateDoctor({ id: doctor._id, body: newInfo })
          .then((res) =>
            console.log(
              `Doctor ${doctor.firstName}'s salary updated successfully`
            )
          )
          .catch((res) =>
            console.error(`Error updating doctor ${doctor.firstName}:`, res)
          );
      } else {
        console.log(
          `Doctor ${doctor.firstName} has a regularSalary of 0, no update performed`
        );
      }
    });
  };

  const operations = (
    <div className="xisobod-main">
      <Button
        style={{
          marginRight: 8,
          display: "inline-flex",
          alignItems: "center",
          gap: 8,
          padding: "8px 16px",
          fontSize: "14px",
          backgroundColor: "#f0f0f0",
          border: "1px solid #d9d9d9",
          borderRadius: 4,
          cursor: "pointer",
          transition: "background-color 0.3s",
        }}
        onClick={noteRegularSalary}
        onMouseEnter={(e) =>
          (e.currentTarget.style.backgroundColor = "#e6f7ff")
        }
        onMouseLeave={(e) =>
          (e.currentTarget.style.backgroundColor = "#f0f0f0")
        }
      >
        <span role="img" aria-label="download">
          💸
        </span>
        Oylik maoshlarni tushirish
      </Button>
      <Link to="/vidomost">
        {" "}
        <Button
          style={{
            marginRight: "8px",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <FcDocument style={{ fontSize: "20px" }} /> Oylik hisobot
        </Button>
      </Link>
    </div>
  );

  return (
    <LayoutWrapper>
      <SearchBar
        dataLength={allData}
        allDoctor={dataSource}
        setDoctorQuery={setDoctorQuery}
        setQuery={setQuery}
      />
      <Tabs tabBarExtraContent={operations}>
        <Tabs.TabPane tab={`${width > 450 ? "Doktorlar" : "D"}`} key={0}>
          <Table
            loading={!dataSource ? true : false}
            bordered={true}
            size="small"
            pagination={false}
            dataSource={dataSource
              ?.filter((doc) => doc.idNumber?.includes(doctorQuery))
              ?.filter(
                (doc) =>
                  doc.firstName?.includes(query) ||
                  doc.lastName?.includes(query)
              )}
            columns={columns}
            rowKey="_id"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${width > 450 ? "Hamshiralar" : "H"}`} key={1}>
          <Table
            pagination={false}
            bordered
            size="small"
            dataSource={allnurses?.filter(
              (doc) =>
                doc.firstName?.includes(query) || doc.lastName?.includes(query)
            )}
            columns={nursesColumns}
            rowKey="_id"
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={`${width > 450 ? "Adminstartorlar" : "A"}`} key={2}>
          <Table
            loading={!dataSource ? true : false}
            bordered={true}
            size="small"
            pagination={false}
            dataSource={Adminstrators?.filter(
              (doc) =>
                doc.firstName?.includes(query) || doc.lastName?.includes(query)
            )}
            columns={adminstratorsColumns}
            rowKey="_id"
          />
        </Tabs.TabPane>
      </Tabs>
    </LayoutWrapper>
  );
};

export default SalaryDep;
