import React, { useState, useMemo } from "react";
import "./style.css";
import { message, Space, Table, Tabs, Modal, Button } from "antd";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { LuClipboardEdit } from "react-icons/lu";
import { RiDeleteBin6Line } from "react-icons/ri";
import moment from "moment";
import LayoutWrapper from "../../../components/layout/Layout";
import UpdatePotients from "../editPotients/EditUser";
import {
  useGetStoriesQuery,
  useDeleteStoryMutation,
} from "../../../redux/clientStores";
import { NumberFormat, PhoneNumberFormat } from "../../../hook/NumberFormat";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import SearchBar from "../../../components/SearchComponent";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import OnlineRegistration from "./OnlineRegistration";
import QarzdorlarBolimi from "./qarzdorlarBolimi/QarzdorlarBolimi";
import Patients from "./Patients";

const MainPatients = () => {
  let today = moment().format("DD.MM.YYYY");
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  let [deleteStories] = useDeleteStoryMutation();
  const [editID, setEditID] = useState(null);
  let { data: stories } = useGetStoriesQuery();
  let dataStories = stories?.innerData || [];
  const { data: doctor } = useGetAllDoctorsQuery();

  const allData = useMemo(() => doctor?.data || [], [doctor]);
  const [doctorQuery, setDoctorQuery] = useState("");

  let dataUsers = dataStories?.filter(
    (i) => i?.view === false && i?.online === false && i.day === today
  );

  let buhgalter = allData?.find((i) => i.docORrecep === "buhgalter");

  dataUsers.sort((a, b) => a.queueNumber - b.queueNumber);
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAges] = useState();
  // -----------Delete-------------
  const { confirm } = Modal;

  const showDeleteClients = (_id) => {
    confirm({
      title: "O'chirib tashlaysizmi?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk: async () => {
        try {
          const res = await deleteStories(_id);
          if (res?.data?.success) {
            message.success("Bemor o'chirildi!");
          } else {
            message.error(res?.data?.message);
          }
        } catch (err) {
          console.error(err);
          message.error("Nimadir xato ketdi, iltimos qayta urinib ko'ring.");
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const columns = [
    {
      title: "Navbati",
      dataIndex: "navbati",
      key: "navbati",
    },
    {
      title: "Bemor",
      dataIndex: "bemor",
      key: "bemor",
      // render: (bemor) => capitalizeFirstLetter(bemor),
      render: (v) =>
        capitalizeFirstLetter(v.split(" ")[0]) +
        " " +
        capitalizeFirstLetter(v.split(" ")[1]),
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => PhoneNumberFormat(phone),
    },
    {
      title: "Yo'naltirildi",
      dataIndex: "dispatch",
      key: "dispatch",
      render: (v) => capitalizeFirstLetter(v),
    },
    {
      title: "Doktor",
      dataIndex: "doktor",
      key: "doktor",
      render: (v) =>
        capitalizeFirstLetter(v.split(" ")[0]) +
        " " +
        capitalizeFirstLetter(v.split(" ")[1]),
    },
    {
      title: "To'landi",
      dataIndex: "payd",
      key: "payd",
      render: (payd) => NumberFormat(payd) + " so'm",
    },
    {
      title: "Taxrirlash",
      key: "taxrirlash",
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => {
              setOpen(true);
              setEditID(record?.key);
            }}
            type="primary"
            className="btnAddMoney"
          >
            <LuClipboardEdit />
          </Button>
        </Space>
      ),
    },
    {
      title: "O'chirish",
      key: "delete",
      render: (_, record) => (
        <Space>
          <Button
            onClick={() => showDeleteClients(record?.key)}
            type="primary"
            danger
          >
            <RiDeleteBin6Line />
          </Button>
        </Space>
      ),
    },
  ];

  const data = dataUsers
    ?.filter((doc) => doc.clientFullname?.includes(query))
    ?.filter((doc) => doc.doctorIdNumber?.includes(doctorQuery))
    ?.map((client) => ({
      key: client._id,
      navbati: client.queueNumber,
      bemor: client.clientFullname,
      phone: client.clientPhone,
      dispatch: client.doctorType,
      doktor: client.doctorFullname,
      payd: client.paySumm,
    }));

  return (
    <LayoutWrapper>
      <SearchBar
        minAge={minAge}
        setMaxAges={setMaxAges}
        setMinAge={setMinAge}
        maxAge={maxAge}
        setDoctorQuery={setDoctorQuery}
        allDoctor={allData}
        data={data}
        query={query}
        setQuery={setQuery}
      />
      <Tabs>
        <Tabs.TabPane defaultActiveKey="0" tab="Navbatdagilar" key={0}>
          <Table
            rowKey={"key"}
            loading={!data ? true : false}
            bordered={true}
            size="small"
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          {open && (
            <UpdatePotients
              user={dataUsers}
              editID={editID}
              setOpenUpdate={setOpen}
            />
          )}
        </Tabs.TabPane>
        {buhgalter ? (
          ""
        ) : (
          <Tabs.TabPane defaultActiveKey="1" tab="Qarzdorlar bo'limi" key={1}>
            <QarzdorlarBolimi query={query} setQuery={setQuery} />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane
          defaultActiveKey="2"
          tab="Online ro'yhatdan o'tkanlar"
          key={2}
        >
          <OnlineRegistration query={query} setQuery={setQuery} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Barcha bemorlar" defaultActiveKey="3" key={3}>
          <Patients
            doctorQuery={doctorQuery}
            dataStories={dataStories}
            query={query}
            setQuery={setQuery}
          />
        </Tabs.TabPane>
      </Tabs>
    </LayoutWrapper>
  );
};

export default MainPatients;
