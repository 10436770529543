import React from 'react'
import { Tabs } from "antd";
import { useGetStoriesQuery } from "../../redux/clientStores"
import LayoutWrapper from '../layout/Layout';
import AgeStatistic from './ageStatistic/AgeStatistik';
import PatentsVisit from './patentsVisitDoc/PatentsVisit';
import OfAddress from './statisticsOfAddress/OfAddress';

const Main = () => {
    const { data: users = [] } = useGetStoriesQuery();
    const data = users?.innerData || [];

    const months = [
        "yanvar",
        "fevral",
        "mart",
        "aprel",
        "may",
        "iyun",
        "iyul",
        "avgust",
        "sentabr",
        "oktabr",
        "noyabr",
        "dekabr"
    ];

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const manthByNomber = currentDate.getMonth() + 1;
    const month = months[currentDate.getMonth()];


    const twoColors1 = ["#1f646d"];
    const twoColors2 = ["#ff8215"];


    return (
        <LayoutWrapper>
            <Tabs>
                <Tabs.TabPane defaultActiveKey="0" tab="Jinsi va Yoshi bo'yicha" key={0}>
                    <AgeStatistic data={data} twoColors2={twoColors2} twoColors1={twoColors1} year={year} month={month} manthByNomber={manthByNomber} />
                </Tabs.TabPane>
                <Tabs.TabPane defaultActiveKey="1" tab="Doktorlar bo'yicha" key={1}>
                    <PatentsVisit data={data} twoColors2={twoColors2} twoColors1={twoColors1} year={year} month={month} manthByNomber={manthByNomber} />
                </Tabs.TabPane>
                <Tabs.TabPane defaultActiveKey="2" tab="Hududlar bo'yicha" key={2}>
                    <OfAddress data={data} twoColors1={twoColors1} year={year} month={month} manthByNomber={manthByNomber} />
                </Tabs.TabPane>
            </Tabs>
        </LayoutWrapper>
    )
}

export default Main
