import React, { useEffect, useState } from "react";
import { Form, Button, Input, Row, Col, message, Radio } from "antd";
import { PhoneInput } from "react-international-phone";
import { useForm } from "antd/lib/form/Form";
import "./style.css";
import { FiX } from "react-icons/fi";
import { FaFemale, FaMale } from "react-icons/fa";
import { useUpdateStoryMutation } from "../../../redux/clientStores";
import { useUpdateClientMutation } from "../../../redux/clientApi";

function UpdatePotients({ user, setOpenUpdate, editID }) {
  const [form] = useForm();
  const [phone, setPhone] = useState("");
  const [updateStoryInfo, setUpdateStory] = useState(
    user.find((item) => item._id === editID)
  );

  // RTK QUERIES
  let [updateStory] = useUpdateStoryMutation();
  let [updateClient] = useUpdateClientMutation();

  useEffect(() => {
    form.setFieldsValue({
      firstname: updateStoryInfo?.clientFullname?.split(" ")[0],
      lastname: updateStoryInfo?.clientFullname?.split(" ")[1],
      clientPhone: "+998" + updateStoryInfo?.clientPhone,
      birthday: updateStoryInfo?.birthday,
      temperature: updateStoryInfo?.temperature,
      height: updateStoryInfo?.height,
      weight: updateStoryInfo?.weight,
      phone: updateStoryInfo?.phone,
      gender: updateStoryInfo?.gender,
      address: {
        region: updateStoryInfo?.address?.region,
        district: updateStoryInfo?.address?.district,
        quarter: updateStoryInfo?.address?.quarter,
      },
    });
    setUpdateStory(user.find((item) => item._id === editID));
  }, [user, editID, form]);

  const onFinish = (values) => {
    values.clientPhone = phone;

    values.clientFullname = values.firstname + " " + values.lastname;

    let { firstname, lastname, ...changedStoryInfo } = values;
    let changedClientInfo = {
      firstname: values.firstname,
      lastname: values.lastname,
      phone: values.clientPhone,
      gender: values.gender,
      address: values.address,
      year: values.birthday,
    };

    updateStory({ id: editID, body: changedStoryInfo })
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          setOpenUpdate(false);
          return;
        }
        message.error("xatolik");
      })
      .catch((err) => console.log("err", err));

    // client update
    updateClient({ id: updateStoryInfo?.clientID, body: changedClientInfo });
  };

  const optionsWithDisabled = [
    {
      label: <FaMale style={{ fontSize: "17px", margin: "0 5px" }} />,
      value: "male",
    },
    {
      label: <FaFemale style={{ fontSize: "17px", margin: "0 5px" }} />,
      value: "female",
    },
  ];

  return (
    <div className="updateDoctor">
      <div className="editForm">
        <h3>Bemor malumotlarini yangilash</h3>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Ism" name="firstname">
                <Input placeholder="Ism" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Familiya" name="lastname">
                <Input placeholder="Familiya" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Telefon" name="clientPhone">
                <PhoneInput
                  defaultCountry="uz"
                  onChange={(e) => e.length === 13 && setPhone(e)}
                  inputStyle={{ width: "100%" }}
                  className="PhoneInput"
                  placeholder="+998 xx xxx-xx-xx"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Tug'ilgan sana" name="birthday">
                <Input type="date" placeholder="yil/oy/kun" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Tana harorati" name="temperature">
                <Input placeholder="Tana harorati" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Vazni" name="weight">
                <Input placeholder="Vazni" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item label="Bo'yi" name="height">
                <Input placeholder="Bo'yi" />
              </Form.Item>
            </Col>

            <Col span={4}>
              <Form.Item
                name="gender"
                label="Jinsi: Erkak/Ayol"
                rules={[
                  {
                    required: true,
                    message: "Jinsini tanlang",
                  },
                ]}
              >
                <Radio.Group
                  options={optionsWithDisabled}
                  optionType="button"
                  buttonStyle="solid"
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Viloyat" name={["address", "region"]}>
                <Input
                  type="text"
                  // defaultValue={updateStoryInfo.address.region}
                  placeholder="Viloyat"
                  name="region"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Tuman" name={["address", "district"]}>
                <Input
                  type="text"
                  // defaultValue={updateStoryInfo.address.district}
                  placeholder="Tuman"
                  name="region"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="M.F.Y" name={["address", "quarter"]}>
                <Input
                  type="text"
                  // defaultValue={updateStoryInfo.address.quarter}
                  placeholder="MYF"
                  name="region"
                />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
              Taxrirlash
            </Button>
            <Button type="primary" danger onClick={() => setOpenUpdate(false)}>
              <FiX style={{ marginTop: "-4px", fontSize: "22px" }} />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default UpdatePotients;
