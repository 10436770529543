import React, { useEffect } from "react";
import "./Carousel.css";
import { FaUserDoctor } from "react-icons/fa6";
import { LiaMoneyBillWaveSolid } from "react-icons/lia";
import { FaUsers } from "react-icons/fa6";
import { TbFilePercent } from "react-icons/tb";
import { GiTakeMyMoney } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { message } from "antd";
import { NumberFormat } from "../../../hook/NumberFormat";
import { useGetReportsQuery } from "../../../redux/reports";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import socket from "../../../socket";

function DoctorsSlite() {
  const navigate = useNavigate();
  const { data: allDoctors } = useGetAllDoctorsQuery();

  const onlyDoctors = allDoctors?.data?.filter(
    (i) => i.docORrecep === "doctor"
  );

  let doctorsIDs = onlyDoctors?.map((doctor) => doctor.idNumber);

  let {
    data: allReports,
    isLoading,
    error,
    refetch,
  } = useGetReportsQuery(doctorsIDs);
  let reports = allReports?.innerData || [];

  useEffect(() => {
    socket.on("patient_paid", (u) => refetch());
    return () => socket.off("patient_paid");
  }, [refetch]);

  if (error?.data?.message === "No token provided") {
    message.error(error?.data?.message);
    localStorage.clear();
    navigate("/login");
  }

  const findDoctorPrice = (id) => {
    return reports?.find((i) => i.idNumber === id);
  };

  return (
    <div className="carousel">
      {onlyDoctors?.length > 0 ? (
        onlyDoctors?.map((value, inx) => {
          return (
            <Link
              to={`/doctorSinglePage/${value.idNumber}/${value.specialization}`}
              key={inx}
              className="card"
              style={{
                minWidth: value?.firstName.length >= 11 ? "180px" : "150px",
              }}
            >
              <div className="card-inner">
                <FaUserDoctor className="card_icon" />
                <span className="doctorname">
                  {value?.firstName
                    ? value?.firstName?.toUpperCase() +
                      "." +
                      value?.lastName[0]?.toUpperCase()
                    : ""}
                </span>
                <b>{value?.specialization}</b>
              </div>
              <div className="allInfoTotal">
                <div className="sDay">
                  {isLoading
                    ? "Loading..."
                    : findDoctorPrice(value?.idNumber)?.today}
                </div>
                <div className="CountDay-M">
                  <LiaMoneyBillWaveSolid />{" "}
                  {NumberFormat(findDoctorPrice(value?.idNumber)?.totalPrice)}
                </div>
                <div className="CountDay-M">
                  <FaUsers />
                  {" " +
                    NumberFormat(
                      findDoctorPrice(value?.idNumber)?.clientLength
                    )}
                </div>

                <div className="CountDay-M">
                  {value?.percent ? (
                    <div className="CountDay-Box">
                      <div className="CountDay-M">
                        <TbFilePercent /> {value?.percent}%
                      </div>
                      <div className="CountDay-M">
                        <GiTakeMyMoney />{" "}
                        {" " +
                          NumberFormat(
                            findDoctorPrice(value.idNumber)?.ownPrice
                          )}
                      </div>
                    </div>
                  ) : (
                    <div className="CountDay-M">
                      <GiTakeMyMoney /> {NumberFormat(value.salary)} so'm
                    </div>
                  )}
                </div>
              </div>
            </Link>
          );
        })
      ) : (
        <>
          <div className="cardSkl">
            <div className="headerSkl">
              <div className="imgSkl"></div>
              <div className="details">
                <span className="nameSkl"></span>
                <span className="about"></span>
              </div>
            </div>
            <div className="description">
              <div className="lineSkl line-1"></div>
              <div className="lineSkl line-2"></div>
            </div>
          </div>
          <div className="cardSkl">
            <div className="headerSkl">
              <div className="imgSkl"></div>
              <div className="details">
                <span className="nameSkl"></span>
                <span className="about"></span>
              </div>
            </div>
            <div className="description">
              <div className="lineSkl line-1"></div>
              <div className="lineSkl line-2"></div>
            </div>
          </div>
          <div className="cardSkl">
            <div className="headerSkl">
              <div className="imgSkl"></div>
              <div className="details">
                <span className="nameSkl"></span>
                <span className="about"></span>
              </div>
            </div>
            <div className="description">
              <div className="lineSkl line-1"></div>
              <div className="lineSkl line-2"></div>
            </div>
          </div>
          <div className="cardSkl">
            <div className="headerSkl">
              <div className="imgSkl"></div>
              <div className="details">
                <span className="nameSkl"></span>
                <span className="about"></span>
              </div>
            </div>
            <div className="description">
              <div className="lineSkl line-1"></div>
              <div className="lineSkl line-2"></div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default DoctorsSlite;
