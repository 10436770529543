import React from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Table } from "antd";
import { Link } from "react-router-dom";
// import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import { useGetAmbulatorQuery } from "../../../redux/ambulatorAPI";
import LayoutWrapper from "../../../components/layout/Layout";
import "dayjs/locale/zh-cn";
import "./style.css";

function Ambulatoriya() {
  const { data: ambulator } = useGetAmbulatorQuery();
  const storiesData = ambulator?.innerData || [];

  const columns = [
    {
      title: "Bemor",
      dataIndex: "fullName",
    },

    {
      title: "Tarix",
      dataIndex: "tarix",
      render: (text, record) => (
        <Link to={`/ambulatoriya-signle/${record?._id}`}>
          <Button className="EyeOutlined" icon={<EyeOutlined />} />
        </Link>
      ),
    },
  ];

  return (
    <LayoutWrapper>
      <Table
        loading={!storiesData}
        bordered={true}
        size="small"
        columns={columns}
        dataSource={storiesData}
        pagination={false}
        rowKey={"_id"}
      />
    </LayoutWrapper>
  );
}

export default Ambulatoriya;
