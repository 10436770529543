import React from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import { GiMoneyStack } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { NumberFormat } from "../../../../hook/NumberFormat";
import img1 from "../../../../assets/img/singleImg1.png";
import img2 from "../../../../assets/img/singleImg2.png";
import LogoMedme from "../../../../assets/img/logo.png";
import { useGetReportsQuery } from "../../../../redux/reports";
import { useGetAllDoctorsQuery } from "../../../../redux/doctorApi";
import ReportsLoading from "./ReportsLoading";

function SingleReports() {
  const { _id } = useParams();
  let time = new Date().toLocaleString("en-Us", { month: "long" });

  const { data: allDoctors } = useGetAllDoctorsQuery();
  const doctorInfo = allDoctors?.data?.find((i) => i.idNumber === _id);

  let { data: allReports, isLoading } = useGetReportsQuery([_id]);
  let report = allReports?.innerData[0] || {};

  if (isLoading) return <ReportsLoading />;

  return (
    <div className="Search-Box">
      <div className="WalletSNG">
        <div className="headerWallet">
          <div className="header-summary">
            <div className="summary-text-owner">Balans</div>
            <div className="summary-balance">
              {NumberFormat(doctorInfo?.salary)} so'm
            </div>
            {doctorInfo?.regularSalary > 0 && (
              <>
                <div className="summary-text-owner-salary">
                  Oylik ish maoshi
                </div>
                <div className="summary-text-owner-salary">
                  {NumberFormat(doctorInfo?.regularSalary)} so'm
                </div>
              </>
            )}
          </div>

          <div className="imgBoxProfile">
            <div className="user-profile">
              <img
                src={doctorInfo?.lastName?.endsWith("a") ? img2 : img1}
                alt=""
              />
            </div>
            <div>
              {doctorInfo?.firstName?.toUpperCase() +
                "." +
                doctorInfo?.lastName[0]?.toUpperCase()}
            </div>
            <p>{doctorInfo?.specialization}</p>
          </div>
        </div>
        <div className="contentWallet">
          <div className="cardWallet">
            <div className="upper-row">
              <div className="card-item">
                <span>Bugungi balans</span>
                <span>{NumberFormat(report?.ownPrice)} so'm</span>
              </div>
              <div className="card-item">
                <span>Bugungi kirim (umumiy)</span>
                <span>{NumberFormat(report?.todaysTotalPrice)} so'm</span>
              </div>
              <div className="card-item">
                <span>Oylik foizda</span>
                <span>{doctorInfo?.percent}%</span>
              </div>
            </div>
            <div className="lower-row">
              <div className="icon-item">
                <div className="icon">{NumberFormat(report?.clientLength)}</div>
                <div className="icon-text">
                  <FaUsers /> Bugun
                </div>
              </div>
              <div className="icon-item">
                <div className="icon">
                  {NumberFormat(report?.monthClientLength)}
                </div>
                <div className="icon-text">
                  <FaUsers /> {time}
                </div>
              </div>
              <div className="icon-item">
                <div className="icon">
                  {NumberFormat(report?.monthlyTotalPrice)}{" "}
                </div>
                <div className="icon-text">
                  <GiMoneyStack /> Bir oylik tushim
                </div>
              </div>
              <div className="icon-item">
                <div className="icon">
                  {NumberFormat(doctorInfo?.feesPerCunsaltation)} so'm
                </div>
                <div className="icon-text">
                  <GiMoneyStack /> Qabul
                </div>
              </div>
              <div className="icon-item">
                <div className="icon">
                  {NumberFormat(doctorInfo?.secondPrice || 0)} so'm
                </div>
                <div className="icon-text">
                  <GiMoneyStack /> Ikkilamchi
                </div>
              </div>
              <div className="icon-item">
                <div className="icon">
                  {NumberFormat(report?.roomPrice || 0)} so'm
                </div>
                <div className="icon-text">
                  <GiMoneyStack /> Davolanish
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="drawer">
          <img width={200} src={LogoMedme} alt="" />
        </div>
      </div>
    </div>
  );
}

export default SingleReports;
