import { api } from "./api";

export const clientApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAmbulator: builder.query({
      query: () => `ambulator/get`,
      providesTags: ["GetSalaries"],
    }),

    // single ambulator carta
    getSingleAmbulator: builder.query({
      query: (id) => `ambulator/${id}`,
    }),

    // POST || CREATE ambulator
    createAmbulator: builder.mutation({
      query: (body) => ({
        url: "ambulator/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["Getambulators", "getStories"],
    }),

    // DELETE ambulator
    deleteambulator: builder.mutation({
      query: (id) => ({
        url: `ambulator/delete/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Getambulators"],
    }),

    // PUT || UPDATE ambulator
    updateambulator: builder.mutation({
      query: ({ id, body }) => ({
        url: `/ambulator/update/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Getambulators"],
    }),
  }),
});

export const {
  useGetAmbulatorQuery,
  useCreateAmbulatorMutation,
  useDeleteAmbulatorMutation,
  useUpdateAmbulatorMutation,
  useGetSingleAmbulatorQuery,
} = api;
