import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  message,
  Row,
  Select,
  Col,
  TimePicker,
  Button,
} from "antd";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { PhoneInput } from "react-international-phone";
import { useNavigate } from "react-router-dom";
import OtpInput from "react-otp-input";
import Selectt from "react-select";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useAddNursesMutation } from "../../../../redux/nursesApi";
import { useGetAllDoctorsQuery } from "../../../../redux/doctorApi";
import axios from "../../../../api";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";

dayjs.extend(customParseFormat);
const AddNurses = () => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const navigate = useNavigate();
  const [addNurses] = useAddNursesMutation();
  const { data: all_Doctor } = useGetAllDoctorsQuery();
  const allDoctor = all_Doctor?.data || [];
  const sortedData = allDoctor?.filter((i) => i.docORrecep === "doctor");
  const [choseDoctor, setChoseDoctor] = useState("");
  const [startTime, setStartTime] = useState(
    dayjs("08:00", "HH:mm").format("HH:mm")
  );
  const [endTime, setEndTime] = useState(
    dayjs("17:00", "HH:mm").format("HH:mm")
  );

  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState({
    region: "",
    district: "",
    quarter: "",
  });
  const [idNumber, setIdNumber] = useState("");
  const [loading, setLoading] = useState(false);

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);

  const [extraClass, setExtraClass] = useState(false);
  const [extraClass2, setExtraClass2] = useState(false);
  const [extraClass3, setExtraClass3] = useState(false);

  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };

  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));
  }, []);

  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };

  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  const data = [];
  for (const item of sortedData) {
    data.push({
      value: item._id,
      fullData: { id: item._id, name: item.firstName + " " + item.lastName },
      label:
        item.specialization +
        ` [${
          capitalizeFirstLetter(item.firstName) +
          " " +
          capitalizeFirstLetter(item.lastName)
        }]`,
    });
  }

  const onSubmit = async (data) => {
    data.atteached = choseDoctor;
    data.workTime = { startTime, endTime };
    data.address = address;
    data.phone = phone;
    data.idNumber = idNumber;
    data.onceTreatmentPercent = +data.onceTreatmentPercent;

    try {
      setLoading(true); // Start loading
      const res = await addNurses(data);

      if (res?.data?.success) {
        message.success(res?.data?.message);
        navigate("/xodimlar");
        reset();
      } else {
        message.error(res.data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    dateRegex.test(inputValue)
      ? setValue("dateOfBirth", inputValue)
      : message.warning("Noto'g'ri sana formati");
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="FormApply"
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Ism"
            required
            validateStatus={errors.firstName ? "error" : ""}
            help={errors.firstName ? errors.firstName.message : ""}
          >
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "Ism kiritish shart" }}
              render={({ field }) => <Input {...field} placeholder="Ism" />}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Familiya"
            required
            validateStatus={errors.lastName ? "error" : ""}
            help={errors.lastName ? errors.lastName.message : ""}
          >
            <Controller
              name="lastName"
              control={control}
              rules={{ required: "Familiya kiritish shart" }}
              render={({ field }) => (
                <Input {...field} placeholder="Familiya" />
              )}
            />
          </Form.Item>
        </Col>
        <Col className="Col-Form">
          <Form.Item
            label="Tug'ilgan sana:"
            name="dateOfBirth"
            direction="vertical"
            validateStatus={errors.year ? "error" : ""}
            help={errors.year ? errors.year.message : ""}
          >
            <input
              type="date"
              style={{ width: "100%" }}
              placeholder="yil/oy/kun"
              className="DatePicer"
              onChange={handleDateChange}
              name="dateOfBirth"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col className="Col-Form">
          <Form.Item
            label="Telefon raqami"
            name="phone"
            required
            rules={[{ required: true }]}
          >
            <PhoneInput
              defaultCountry="uz"
              value={phone}
              onChange={(e) =>
                e.length === 13 && setPhone(e.replace("+998", ""))
              }
              inputStyle={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <div className="Col-Form_Box">
          <Col className={`${extraClass ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item label="Viloyat" name={["address", "region"]}>
              <Selectt
                onMenuOpen={() => setExtraClass(true)}
                onMenuClose={() => setExtraClass(false)}
                onChange={(e) => {
                  getDistricts(e.regionID);
                  setAddress((address) => ({
                    ...address,
                    region: e.value,
                  }));
                }}
                options={regions}
              />
            </Form.Item>
          </Col>
          <Col className={`${extraClass2 ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item label="Tuman" name={["address", "district"]}>
              <Selectt
                onMenuOpen={() => setExtraClass2(true)}
                onMenuClose={() => setExtraClass2(false)}
                onChange={(e) => {
                  getQuarters(e.districtID);
                  setAddress((address) => ({
                    ...address,
                    district: e.value,
                  }));
                }}
                options={districts}
              />
            </Form.Item>
          </Col>
          <Col className={`${extraClass3 ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item label="MFY" name={["address", "quarter"]}>
              <Selectt
                onMenuOpen={() => setExtraClass3(true)}
                onMenuClose={() => setExtraClass3(false)}
                options={quarters}
                onChange={(e) =>
                  setAddress((address) => ({
                    ...address,
                    quarter: e.value,
                  }))
                }
              />
            </Form.Item>
          </Col>
        </div>

        <Col span={8}>
          <Form.Item
            label="ID raqami"
            required
            validateStatus={errors.idNumber ? "error" : ""}
            help={errors.idNumber ? errors.idNumber.message : ""}
            name={"idNumber"}
          >
            <OtpInput
              inputStyle={{
                border: "1px solid #ddd",
                margin: "2px",
                borderRadius: "5px",
                width: "32px",
                height: "32px",
              }}
              value={idNumber}
              onChange={setIdNumber}
              numInputs={9}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              placeholder="ab1234567"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Doktor" name="doctor">
            <Select
              style={{ width: "100%" }}
              placeholder="Doktorlar"
              // onChange={(label) => setChoseDoctor(label)}
              onChange={(value, { fullData }) => setChoseDoctor(fullData)}
              options={data}
            />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            label="Kunlik maosh"
            required
            validateStatus={errors.dayliMoney ? "error" : ""}
            help={errors.dayliMoney ? errors.dayliMoney.message : ""}
          >
            <Controller
              name="dayliMoney"
              control={control}
              rules={{ required: "Kunlik maosh kiritish shart" }}
              render={({ field }) => (
                <Input {...field} placeholder="Kunlik maosh" />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label="Tungi navbat"
            required
            validateStatus={errors.dayliMoney ? "error" : ""}
            help={errors.dayliMoney ? errors.dayliMoney.message : ""}
          >
            <Controller
              name="nightMoney"
              control={control}
              rules={{ required: "Tungi navbat kiritish shart" }}
              render={({ field }) => (
                <Input {...field} placeholder="Tungi navbat" />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Ish vaqti"
            required
            validateStatus={errors.workTime ? "error" : ""}
            help={errors.workTime ? errors.workTime.message : ""}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <TimePicker
                placeholder="Boshlanishi"
                onChange={(time, timeString) => setStartTime(timeString)}
                defaultValue={dayjs("08:00", "HH:mm")}
              />
              <TimePicker
                placeholder="Tugashi"
                onChange={(time, timeString) => setEndTime(timeString)}
                defaultValue={dayjs("17:00", "HH:mm")}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} className="Row">
        <Col span={8}>
          <Form.Item
            label="Kunduzgi muolajadan beriladigan foizi"
            required
            validateStatus={errors.onceTreatmentPercent ? "error" : ""}
            help={
              errors.onceTreatmentPercent
                ? errors.onceTreatmentPercent.message
                : ""
            }
          >
            <Controller
              name="onceTreatmentPercent"
              control={control}
              rules={{
                required: "Kunduzgi muolajadan beriladigan foizini kiriting",
              }}
              render={({ field }) => <Input {...field} placeholder="0" />}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label=" ">
            <Button
              className="btn btn-primary"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Ishga qabul qilish
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default AddNurses;
