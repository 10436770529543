import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, message } from "antd";
import { FiX } from "react-icons/fi";
import { useForm } from "antd/lib/form/Form";
import OtpInput from "react-otp-input";
import Selectt from "react-select";
import axios from "../../../../api";
import {
  useUpdateClientMutation,
  useGetOneUserQuery,
} from "../../../../redux/clientApi";
import { useUpdateStoryMutation } from "../../../../redux/clientStores";

function EditUser({ setOpenUpdate, editID }) {
  const [form] = useForm();

  let [updateClient] = useUpdateClientMutation();
  let [updateStory] = useUpdateStoryMutation();
  const { data: userOne } = useGetOneUserQuery(editID.user_id);
  const updateUser = userOne?.data;

  const [idNumber, setIdNumber] = useState(updateUser?.idNumber || "");
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);

  // // addressni olish
  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };
  // viloyat
  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));
  }, []);
  // tuman
  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };
  // MFY
  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (updateUser) {
      form.setFieldsValue({
        idNumber: updateUser.idNumber,
        firstname: updateUser.firstname,
        lastname: updateUser.lastname,
        year: updateUser.year,
        phone: updateUser.phone,
      });
    }
  }, [updateUser, editID, form]);

  const onFinish = (values) => {
    let address = {
      region: values.address.region?.value,
      district: values.address.district?.value,
      quarter: values.address.quarter?.value,
    };

    values.address = address;
    values.idNumber = idNumber;

    updateClient({ id: editID.user_id, body: values })
      .then((res) => {
        if (res?.data?.success) {
          message.success("Yangilash muvaffaqiyatli amalga oshirildi!");
          setOpenUpdate(false);
        }
      })
      .catch((err) => console.log("err", err));

    // update story
    let story = {
      clientFullname: values.firstname + " " + values.lastname,
      clientPhone: values.phone,
      birthday: values.year,
      doctorType: values?.doctorType,
      address,
    };

    updateStory({ id: editID.story_id, body: story });
  };

  return (
    <div className="updateDoctor">
      <div className="editForm">
        <FiX className="updateCloseBtn" onClick={() => setOpenUpdate(false)} />
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Row gutter={16}>
            {/* <Col span={8}>
              <Form.Item label="Shaxsiy raqami" name="idNumber">
                <Input maxLength={9} placeholder="AA 1234567" />
              </Form.Item>
            </Col> */}
            <Col span={8}>
              <Form.Item label={"Shaxsiy raqami"} name="idNumber">
                <OtpInput
                  inputStyle={{
                    border: "1px solid #ddd",
                    margin: "2px",
                    borderRadius: "5px",
                    width: "32px",
                    height: "32px",
                  }}
                  value={idNumber}
                  onChange={setIdNumber}
                  numInputs={9}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                  placeholder="ab1234567"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Ismi" name="firstname">
                <Input placeholder="Ismi" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Familiya" name="lastname">
                <Input placeholder="Familiya" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Telefon" name="phone">
                <Input maxLength={9} placeholder="Telefon" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Tug'ilgan sana:" name="year">
                <Input type="date" placeholder="yil/oy/kun" />
              </Form.Item>
            </Col>
            {/* <Col span={8}>
              <Form.Item label="Manzil" name="address">
                <Input placeholder="Manzil" />
              </Form.Item>
            </Col> */}
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Viloyat"
                name={["address", "region"]}
                rules={[
                  {
                    required: true,
                    message: "Viloyatni tanlang",
                  },
                ]}
              >
                <Selectt
                  onChange={(e) => getDistricts(e.regionID)}
                  options={regions}
                  placeholder="Viloyat"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Tuman"
                name={["address", "district"]}
                rules={[
                  {
                    required: true,
                    message: "Tumanni tanlang",
                  },
                ]}
              >
                <Selectt
                  onChange={(e) => getQuarters(e.districtID)}
                  options={districts}
                  placeholder="Tuman"
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="M.F.Y"
                name={["address", "quarter"]}
                rules={[
                  {
                    required: true,
                    message: "M.F.Y ni tanlang",
                  },
                ]}
              >
                <Selectt options={quarters} placeholder="M.F.Y" />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ display: "flex", gap: "5px" }}>
            <Button style={{ width: "95%" }} type="primary" htmlType="submit">
              Taxrirlash
            </Button>
            <Button
              danger
              type="primary"
              style={{
                display: "flex",
                width: "5%",
                alignItems: "center",
                fontSize: "19px",
              }}
            >
              <FiX onClick={() => setOpenUpdate(false)} />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default EditUser;
