import { api } from "./api";

export const terapiyaApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTerapiya: builder.query({
      query: () => `terapiya/getTerapiya`,
      providesTags: ["GetTerapiya"],

    }),

    // POST || CREATE CLIENT
    createTerapiya: builder.mutation({
      query: (body) => ({
        url: "terapiya/create",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetTerapiya"],
    }),
  }),
});

export const { useCreateTerapiyaMutation, useGetTerapiyaQuery } = terapiyaApi;
