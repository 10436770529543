export const doctors = [
    {
        category: "Terapiya va ichki kasalliklar",
        options: [
            { name: "Terapevt", value: "doctor" },
            { name: "Kardiolog", value: "doctor" },
            { name: "Endokrinolog", value: "doctor" },
            { name: "Gastroenterolog", value: "doctor" },
            { name: "Pulmonolog", value: "doctor" },
            { name: "Nefrolog", value: "doctor" },
            { name: "Revmatolog", value: "doctor" },
            { name: "Geriatr", value: "doctor" },
        ],
    },
    {
        category: "Jarrohlik",
        options: [
            { name: "Umumiy jarroh", value: "doctor" },
            { name: "Ortopedik jarroh", value: "doctor" },
            { name: "Neyrojarroh", value: "doctor" },
            { name: "Kardiotorakal jarroh", value: "doctor" },
            { name: "Plastika jarrohi", value: "doctor" },
            { name: "Urologik jarroh", value: "doctor" },
            { name: "Laparoskopik jarroh", value: "doctor" },
        ],
    },
    {
        category: "Bolalar tibbiyoti (Pediatriya)",
        options: [
            { name: "Pediatr", value: "doctor" },
            { name: "Bolalar kardiologi", value: "doctor" },
            { name: "Bolalar jarrohi", value: "doctor" },
            { name: "Neonatolog", value: "doctor" },
        ],
    },

    {
        category: "Ayollar salomatligi",
        options: [
            { name: "Ginekolog", value: "doctor" },
            { name: "Akusher-ginekolog", value: "doctor" },
            { name: "Reproduktiv tibbiyot mutaxassisi", value: "doctor" },
        ],
    },
    {
        category: "Nevrologiya va ruhiy salomatlik",
        options: [
            { name: "Nevrolog", value: "doctor" },
            { name: "Psixiatr", value: "doctor" },
            { name: "Psixoterapevt", value: "doctor" },
        ],
    },
    {
        category: "Onkologiya va gematologiya",
        options: [
            { name: "Onkolog", value: "doctor" },
            { name: "Gematolog", value: "doctor" },
            { name: "Radioterapevt", value: "doctor" },
        ],
    },
    {
        category: "Ko‘z va quloq-burun-tomoq (ENT)",
        options: [
            { name: "Oftalmolog", value: "doctor" },
            { name: "LOR", value: "doctor" },
        ],
    },
    {
        category: "Teri va kosmetik tibbiyot",
        options: [
            { name: "Dermatolog", value: "doctor" },
            { name: "Kosmetolog", value: "doctor" },
        ],
    },
    {
        category: "Diagnostika va tasviriy tibbiyot",
        options: [
            { name: "Laboratoriya", value: "analis" }, //analis
            { name: "Radiolog", value: "diagnostica" },
            { name: "Ultratovush", value: "diagnostica" },
            { name: "UZI", value: "uzi" },
            { name: "KT", value: "diagnostica" },
            { name: "MRT", value: "diagnostica" },
            { name: "PET", value: "diagnostica" },
            { name: "Nuklear", value: "diagnostica" },
            { name: "EKG", value: "diagnostica" },
            { name: "EEG", value: "diagnostica" },
            { name: "Endoskopiya", value: "diagnostica" },
            { name: "Mamografiya", value: "diagnostica" },
            { name: "Biopsiya", value: "diagnostica" },
            { name: "Patologiya", value: "diagnostica" },
            { name: "Mikrobiologiya", value: "diagnostica" },
            { name: "Biokimyo", value: "diagnostica" },
            { name: "Hematologiya", value: "diagnostica" },
            { name: "Genetika", value: "diagnostica" },
            { name: "Dermatopatologiya", value: "diagnostica" },
            { name: "Kardiyak", value: "diagnostica" },
            { name: "Neurodiagnostika", value: "diagnostica" },
        ],
    },
    {
        category: "Reabilitatsiya va fizioterapiya",
        options: [
            { name: "Fizioterapevt", value: "fizioterapiya" },
            { name: "Reabilitolog", value: "fizioterapiya" },
            { name: "Massaj", value: "massaj" },
        ],
    },
    {
        category: "Urg‘ochi tibbiyot va shoshilinch tibbiyot",
        options: [
            { name: "Shoshilinch vrach", value: "doctor" },
            { name: "Reanimatolog", value: "doctor" },
        ],
    },
    {
        category: "Diyetologiya va ovqatlanish",
        options: [
            { name: "Diyetolog", value: "doctor" },
            { name: "Nutritionist", value: "doctor" },
        ],
    },
    {
        category: "Stomatologiya",
        options: [
            { name: "Stomatolog", value: "stomatolog" },
            { name: "Ortodont", value: "stomatolog" },
            { name: "Parodontolog", value: "stomatolog" },
        ],
    },

];





