import { createSlice } from "@reduxjs/toolkit";

export const clinicData = createSlice({
  name: "clinicData",
  initialState: {
    info: "",
  },
  reducers: {
    setClinicData: (state, action) => {
      state.info = action.payload;
    },
  },
});

export const { setClinicData } = clinicData.actions;
export default clinicData.reducer;
