import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes, Route } from "react-router-dom";
import LoadingTik from "./components/loading/tiktok/LoadingTik";
import PageNotFound from "./components/pageNotFound/PageNotFound";
import { routes } from "./routes/Routes";
import { setBlackBalanceState } from "./redux/features/blackBalanc";

function App() {
  let dispatch = useDispatch();
  const { loading } = useSelector((state) => state.alerts);
  const [isOnline, setIsOnline] = useState("");

  window.ononline = () => setIsOnline("Online");
  window.onoffline = () => setIsOnline("Ofline");
  setTimeout(() => setIsOnline(""), [5000]);

  // ----The language will be added in the second update-------
  // const startLanguage = localStorage.getItem("language");
  // const [language, setLanguage] = useState(startLanguage || "uz");
  // const handleLanguageChange = (newLanguage) => {
  //   setLanguage(newLanguage);
  // };
  // useEffect(() => {
  //   localStorage.setItem("language", language);
  // }, [language]);
  // ------------------------------------------

  const [view, setView] = useState(false);
  const [lastKeyPress, setLastKeyPress] = useState(0);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const currentTime = new Date().getTime();
      // if (event.key === "Tab") {
      if (event.key === "a" && event.ctrlKey === true) {
        setView(!view);
        dispatch(setBlackBalanceState());
        localStorage.setItem("blackBalance", view ? 30 : 100);
        setLastKeyPress(currentTime);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [lastKeyPress]);

  return (
    <div className="app">
      {isOnline && (
        <p
          style={{ background: isOnline === "Ofline" ? "red" : "#17cd32" }}
          className="isOnline"
        >
          {isOnline}
        </p>
      )}
      {loading ? (
        <LoadingTik />
      ) : (
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.component} />
          ))}
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </div>
  );
}

export default App;
