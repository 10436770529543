import React from "react";
import { useParams } from "react-router-dom";
import GetPatients from "./getPatients/GetPatients";
import SingleReports from "./singleReports/SingleReports";
import "./style.css";

const SinglePage = () => {
  const { doctorType } = useParams();

  return (
    <div className="SearchBar-Box">
      <SingleReports />
      <GetPatients doctor={doctorType} />
    </div>
  );
};

export default SinglePage;
