import React, { useState } from "react";
import { Tabs, Table, Space, Switch, Tooltip, Button, message } from "antd";
import { Link } from "react-router-dom";
import { MdOutlineLightMode, MdOutlineNightlight } from "react-icons/md";
import { IoEyeOutline } from "react-icons/io5";
import { TbSunMoon } from "react-icons/tb";
import {
  useCreateNusresDayMutation,
  useGetNusresDayQuery,
  useUpdateNursesDayliSalaryMutation,
} from "../../../redux/nusresDayliApi";
import {
  useUpdateNurseSalaryMutation,
  useGetAllNursesQuery,
} from "../../../redux/nursesApi";
import { PhoneNumberFormat } from "../../../hook/NumberFormat";
import { ColculateBorthday } from "../../../hook/ColculateBorthday";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import SearchBar from "../../../components/SearchComponent";
import LayoutWrapper from "../../../components/layout/Layout";

const Nurse = () => {
  const [createNusresDay] = useCreateNusresDayMutation();
  const { data: nurse } = useGetNusresDayQuery();
  const getNurse = nurse?.innerData || [];
  const { data: nurseData } = useGetAllNursesQuery();
  const Nurses = nurseData?.innerData || [];
  const [updateNurseSalary] = useUpdateNurseSalaryMutation();
  const [checkStrictly, setCheckStrictly] = useState(false);
  const [query, setQuery] = useState("");

  const [updateNursesDayliSalary] = useUpdateNursesDayliSalaryMutation();

  const time = new Date();
  const today = `${time.getDate()}.${
    time.getMonth() + 1
  }.${time.getFullYear()}`;
  const monthRes = time.toLocaleString("en-US", { month: "long" });

  const handleClick = async (item, type) => {
    const nurse = Nurses?.find((i) => i._id === item?._id);
    const reasultNurse = getNurse.find(
      (i) => i.idNumber === item?.idNumber && i.getDay === today
    );
    const reasultNurseRes = getNurse.find(
      (i) =>
        i.idNumber === item?.idNumber &&
        i.getDay === today &&
        i.nightType === true
    );
    const dayliMoney = nurse.dayliMoney;
    const nightMoney = nurse.nightMoney;
    let salary = dayliMoney;
    let messageSuccess = "Kunduzgi smena mofaqyatli qo'shildi!";
    let nightType = false;

    if (type === "night") {
      if (reasultNurseRes) {
        message.warning("Kechirasiz!, Bugungi ish kuni boshlangan + smena");
        return;
      }
      salary = nightMoney;
      messageSuccess = "Tungi navbat mofaqyatli qo'shildi!";
      nightType = true;
    } else if (type === "nightLight") {
      if (reasultNurseRes) {
        message.warning(
          "Kun va Tun ishni belgilamoqchi bulsangiz, 'Semana' tugmasini bosing!"
        );
        return;
      }
      salary = dayliMoney + nightMoney;
      messageSuccess = "Kunduzgi va Tungi navbat mofaqyatli qo'shildi!";
      nightType = true;
    }

    if (reasultNurse) {
      updateNurseSalary({ id: item._id, body: { money: salary } });
      updateNursesDayliSalary({
        id: reasultNurse._id,
        body: { money: salary },
      });
      message.success(messageSuccess);
    } else {
      const AllData = {
        clinicId: nurse.clinicId,
        idNumber: nurse.idNumber,
        salary,
        firstName: nurse.firstName,
        lastName: nurse.lastName,
        workTime: {
          startTime: nurse.workTime.startTime,
          endTime: nurse.workTime.endTime,
        },
        phone: nurse.phone,
        type: "nurse",
        nightType,
      };

      try {
        const res = await createNusresDay(AllData);
        if (res.data.success) {
          message.success(
            `"${monthRes}" oyning ${time.getDate()}-kuni, Ish kuni boshlandi!`
          );
        } else {
          message.error(res.data.message);
        }
        updateNurseSalary({ id: item._id, body: { money: salary } });
      } catch (err) {
        message.error(err);
      }
    }
  };

  const columns = [
    { title: "Sana", dataIndex: "getDay", key: "getDay" },
    {
      title: "Ism",
      dataIndex: "firstName",
      key: "firstName",
      render: (firstName) => capitalizeFirstLetter(firstName),
    },
    {
      title: "Familya",
      dataIndex: "lastName",
      key: "lastName",
      render: (lastName) => capitalizeFirstLetter(lastName),
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => PhoneNumberFormat(phone),
    },
  ];

  const data = getNurse;
  const dataNight = getNurse?.filter(
    (a) => a.getDay === today && a.nightType === checkStrictly
  );

  return (
    <LayoutWrapper>
      <SearchBar query={query} setQuery={setQuery} none={true} />

      <Tabs>
        <Tabs.TabPane defaultActiveKey="0" tab="Barcha hamshiralar" key={0}>
          <Table
            dataSource={Nurses?.filter((doc) => doc.lastName?.includes(query))}
            pagination={false}
            loading={!Nurses}
            bordered
            size="small"
            rowKey={"_id"}
          >
            <Table.Column title="Ismi" dataIndex="firstName" key="firstName" />
            <Table.Column
              title="Familiyasi"
              dataIndex="lastName"
              key="lastName"
            />
            <Table.Column
              title="Tel raqam"
              dataIndex="phone"
              key="phone"
              render={PhoneNumberFormat}
            />
            <Table.Column
              title="Yoshi"
              dataIndex="dateOfBirth"
              key="dateOfBirth"
              render={ColculateBorthday}
            />
            <Table.Column
              title="Ish vaqti"
              key="workTime"
              render={(text) => (
                <span>
                  {text?.workTime?.startTime?.slice(0, 5)} -{" "}
                  {text?.workTime?.endTime?.slice(0, 5)}
                </span>
              )}
            />
            <Table.Column
              title="Davomat"
              key="attendance"
              render={(text, record) => (
                <span style={{ display: "flex", gap: "8px", width: "70px" }}>
                  <Tooltip title="Kunduzi" color="blue">
                    <Button
                      onClick={() => handleClick(record, "light")}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "18px",
                        padding: "0 5px",
                        width: "100%",
                      }}
                    >
                      <MdOutlineLightMode />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Smena" color="blue">
                    <Button
                      type="primary"
                      onClick={() => handleClick(record, "night")}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "18px",
                        background: "#000",
                        padding: "0 5px",
                        width: "100%",
                      }}
                    >
                      <MdOutlineNightlight />
                    </Button>
                  </Tooltip>
                  <Tooltip title="Kunduzi + Smena" color="blue">
                    <Button
                      type="primary"
                      onClick={() => handleClick(record, "nightLight")}
                      style={{
                        alignItems: "center",
                        display: "flex",
                        fontSize: "18px",
                      }}
                    >
                      <TbSunMoon />
                    </Button>
                  </Tooltip>
                </span>
              )}
            />
            <Table.Column
              title="Tarix"
              key="history"
              render={(text, record) => (
                <Link to={`/singlePageNurse/${record.idNumber}`}>
                  <Button>
                    <IoEyeOutline
                      style={{ marginBottom: "1px", fontSize: "18px" }}
                    />
                  </Button>
                </Link>
              )}
            />
          </Table>
        </Tabs.TabPane>
        {data?.length > 0 && (
          <Tabs.TabPane tab="Smendagilar" key={1}>
            <Space align="center" style={{ marginBottom: 16 }}>
              <Switch checked={checkStrictly} onChange={setCheckStrictly} />{" "}
              {checkStrictly ? "Tungi smendagilar" : "Barcha hamshiralar"}
            </Space>
            <Table
              rowKey={"_id"}
              pagination={false}
              dataSource={checkStrictly ? dataNight : data}
              columns={columns}
              loading={!data}
              bordered
              size="small"
            />
          </Tabs.TabPane>
        )}
      </Tabs>
    </LayoutWrapper>
  );
};

export default Nurse;
