import React, { useState, useEffect } from "react";
import { Button, Space, Pagination, Table } from "antd";
import { NavLink } from "react-router-dom";
import { GiTakeMyMoney } from "react-icons/gi";
import { NumberFormat, PhoneNumberFormat } from "../../../../hook/NumberFormat";
import "./style.css";
import { useGetUsersQuery } from "../../../../redux/clientApi";
import { useGetAllRoomsQuery } from "../../../../redux/roomApi";
import { useGetRoomStoriesQuery } from "../../../../redux/roomStoriesApi";
import { useGetStoriesQuery } from "../../../../redux/clientStores";
import { capitalizeFirstLetter } from "../../../../hook/CapitalizeFirstLitter";

const QarzdorlarBolimi = () => {
  // const { confirm } = Modal;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0); // Sahifalar soni
  const { data: users = [] } = useGetUsersQuery();

  const { data: roomData } = useGetAllRoomsQuery();

  let roomClients = [];
  roomData?.innerData?.map((r) => {
    r.capacity.length
      ? roomClients.push(
        ...r.capacity.map((i) =>
          i ? { ...i, pricePerDay: r.pricePerDay } : i
        )
      )
      : roomClients.push();
  });

  useEffect(() => {
    if (users.success) {
      setTotalPages(Math.ceil(users.totalClients));
    }
  }, [users]);

  const clients = users?.data || [];
  const clientData = clients?.filter((i) => i.debtor === true);

  const { data: roomStories } = useGetRoomStoriesQuery();
  let { data: stories } = useGetStoriesQuery();
  let dataStories = stories?.innerData || [];

  const allDebtors = (deb) => {
    const firstDataArray = roomStories?.innerData?.filter(
      (i) => i.clientID === deb?.idNumber
    );
    let filterStores = dataStories?.filter(
      ({ clientId, debtor, view, online }) =>
        clientId === deb?.clinicID && debtor && view && !online
    );

    // `filterStores` dan qarzdorlik summasini hisoblash
    const secondDataDebt = filterStores?.reduce((acc, patient) => {
      return acc + patient.paySumm;
    }, 0);

    // `firstDataArray` dan to'lanmagan va qisman to'langan kunlar uchun to'lanishi kerak bo'lgan summani hisoblash
    const firstDataDebt = firstDataArray?.reduce((acc, room) => {
      // To'lanmagan kunlar va qisman to'langan kunlarni hisoblash
      const unpaidAmount = room.paidDays?.reduce((dayAcc, day) => {
        if (!day.isPaid) {
          if (day.price === 0) {
            // Agar kun uchun hech qanday to'lov qilinmagan bo'lsa, to'liq `payForRoom` miqdorini qo'shamiz
            return dayAcc + room.payForRoom;
          } else if (day.price < room.payForRoom) {
            // Agar qisman to'lov qilingan bo'lsa, qolgan qarzdorlik miqdorini qo'shamiz
            return dayAcc + (room.payForRoom - day.price);
          }
        }
        return dayAcc; // To'liq to'langan kunlarni hisobga olmaslik
      }, 0);

      return acc + unpaidAmount;
    }, 0);

    // Umumiy qarzlarni hisoblash
    const totalDebt = secondDataDebt + firstDataDebt;
    return totalDebt;
  };

  const allDebtorsLength = (deb) => {
    const firstDataArray = roomStories?.innerData?.filter(
      (i) => i.clientID === deb?.idNumber && deb?.debtor === true
    );
    let filterStores = dataStories?.filter(
      ({ clientId, debtor, view, online }) =>
        clientId === deb?.clinicID && debtor && view && !online
    );

    const totalDebt = filterStores?.length + firstDataArray?.length;
    return totalDebt;
  };

  const columns = [
    {
      title: "Bemor",
      dataIndex: "firstname",
      render: (_, user) =>
        capitalizeFirstLetter(user.firstname + " " + user.lastname),
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      render: (phone) => "+999 " + PhoneNumberFormat(phone),
    },
    {
      title: "Manzil",
      dataIndex: "address",
      render: (address) =>
        capitalizeFirstLetter(address?.district) +
        " " +
        capitalizeFirstLetter(address?.quarter),
    },
    {
      title: "Molajalar Soni",
      dataIndex: "totalPrice",
      render: (_, user) => {
        return `${NumberFormat(allDebtorsLength(user))}`;
      },
    },
    {
      title: "Qarzdorlik",
      dataIndex: "totalPrice",
      render: (_, user) => {
        return `${NumberFormat(allDebtors(user))} so'm`;
      },
    },
    {
      title: "To'lov qilish",
      dataIndex: "history",
      render: (_, record) => (
        <Space>
          <NavLink
            to={`/userDebtorSinglePage/${record._id}/${JSON.stringify(record)}`}
          >
            <Button type="primary" className="btnAddMoney">
              <GiTakeMyMoney style={{ fontSize: "20px" }} />
            </Button>
          </NavLink>
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        rowKey={"_id"}
        loading={!clientData ? true : false}
        bordered={true}
        size="small"
        columns={columns}
        dataSource={clientData}
        pagination={false}
      />
      {clientData?.length > 10 ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            margin: "4px 0",
          }}
        >
          <Pagination
            current={currentPage}
            total={totalPages}
            onChange={(page) => setCurrentPage(page)}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default QarzdorlarBolimi;
