import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { IoEyeOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import { Button, Table, Popover } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { NumberFormat, PhoneNumberFormat } from "../../../hook/NumberFormat";
import { showLoading, hideLoading } from "../../../redux/features/lineIoad";
import LayoutWrapper from "../../../components/layout/Layout";
import "../../../styles/table.css";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import "./style.css";
import SearchBar from "../../../components/SearchComponent";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import PopoverContent from "../../admin/addDoctors/doctors/PopoverContent"; // Import the PopoverContent component

const Doctor = () => {
  const [query, setQuery] = useState("");
  const [doctorQuery, setDoctorQuery] = useState("");

  const dispatch = useDispatch();
  const { data: doctor, isLoading } = useGetAllDoctorsQuery();

  const Style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  useEffect(() => {
    if (isLoading) {
      dispatch(showLoading());
    } else {
      dispatch(hideLoading());
    }
  }, [isLoading, dispatch]);

  const data = doctor?.data
    ?.filter((i) => i.docORrecep === "doctor")
    ?.filter((doc) => doc.firstName?.includes(query))
    ?.filter((doc) => doc.lastName?.includes(query))
    ?.filter((doc) => doc.idNumber?.includes(doctorQuery));

  data?.sort((a, b) => a.roomNumber - b.roomNumber);


  const columns = [
    {
      title: "№",
      dataIndex: "roomNumber",
      key: "roomNumber",
    },
    {
      title: "Ismi",
      dataIndex: "firstName",
      key: "firstName",
      render: (v) => capitalizeFirstLetter(v),
    },
    {
      title: "Familiyasi",
      dataIndex: "lastName",
      key: "lastName",
      render: (v) => capitalizeFirstLetter(v),
    },
    {
      title: "Kasbi",
      dataIndex: "specialization",
      key: "specialization",
      render: (v) => capitalizeFirstLetter(v),
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
      render: (phone) => PhoneNumberFormat(phone),
    },
    {
      title: "Qabuli",
      dataIndex: "analis",
      key: "analis",
      render: (_, record) => {
        if (record.type === "analis" || record.type === "fizioterapiya" || record.type === "uzi" || record.type === "massaj") {
          return (
            <Popover content={<PopoverContent record={record} />}>
              <Button style={Style}>
                <EyeOutlined />
              </Button>
            </Popover>
          );
        } else {
          return (
            <div>
              <div>{NumberFormat(record.feesPerCunsaltation)} so'm</div>
              {record.secondPrice === 0
                ? " "
                : `Ikkilamchi: ${NumberFormat(record.secondPrice)} so'm`}
            </div>
          );
        }
      },
    },
    {
      title: "Bemorlari",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        // <Link to={`/doctorSinglePageAdmin/${record.idNumber}/${record.specialization}`}>
        <Link to={`/GetSinglePage/${record.idNumber}/${record.specialization}`}>
          <Button
            style={{
              background: "transparent",
              fontSize: "18px",
              border: "none",
              padding: "0px 10pxpx",
              boxShadow: "none",
            }}
          >
            <IoEyeOutline style={{ marginBottom: "10px" }} />
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <LayoutWrapper>
      <SearchBar
        dataLength={data}
        allDoctor={data}
        setDoctorQuery={setDoctorQuery}
        query={query}
        setQuery={setQuery}
      />
      <Table
        dataSource={data}
        columns={columns}
        rowKey={(record) => record.idNumber}
        rowClassName="custom-row"
        loading={!data ? true : false}
        bordered={true}
        size="small"
        pagination={false}
      />
    </LayoutWrapper>
  );
};

export default Doctor;
