import React, { useState } from "react";
import { Table, Button, message, Modal } from "antd";

import { DeleteOutlined, ExclamationCircleFilled, EditOutlined } from "@ant-design/icons";


import { useDeleteNursesMutation } from "../../../../redux/nursesApi";
import { NumberFormat, PhoneNumberFormat } from "../../../../hook/NumberFormat";
import { ColculateBorthday } from "../../../../hook/ColculateBorthday";
import Hamshira from "../../update/Hamshira";

const ReadNurses = ({ Nurses, none, query }) => {
  const [editID, setEditID] = useState(null);
  const [open, setOpen] = useState(false);
  const showModal = (id) => {
    setOpen(true);
    setEditID(id);
  };
  const [deleteNurses] = useDeleteNursesMutation();

  const Style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const { confirm } = Modal;
  const showDeleteConfirm = (_id) => {
    confirm({
      title: "O'chirib tashlaysizmi?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        deleteNurses(_id)
          .then((res) => {
            if (res.data.success) {
              message.success("Doktor o'chirildi!");
            } else {
              message.error(res.data.message);
            }
          })
          .catch((err) => message.log(err));
      },
      onCancel() {
        message.log("Cancel");
      },
    });
  };


  return (
    <>
      <Table
        dataSource={Nurses?.filter(doc => doc.lastName?.includes(query))}
        // dataSource={Nurses}
        pagination={false}
        loading={!Nurses ? true : false}
        bordered={true}
        size="small"
        rowKey={"_id"}
      >
        <Table.Column title="Ismi" dataIndex="firstName" key="firstName" />
        <Table.Column title="Familiyasi" dataIndex="lastName" key="lastName" />

        <Table.Column
          title="Tel raqam"
          dataIndex="phone"
          key="phone"
          render={(text) => PhoneNumberFormat(text)}
        />
        {none === "none" ? (
          ""
        ) : (
          <Table.Column
            title="Kunlik Maosh"
            render={(record) => (
              <>
                <div>{NumberFormat(record.dayliMoney)} so'm</div>
                {record.nightMoney !== 0 && (
                  <div>smena: {NumberFormat(record.nightMoney)} so'm</div>
                )}
              </>
            )}
          />
        )}
        <Table.Column
          title="Yoshi"
          dataIndex="dateOfBirth"
          key="dateOfBirth"
          render={(text) => ColculateBorthday(text)}
        />
        <Table.Column
          title="Ish vaqti"
          key="workTime"
          render={(text) => {
            const startTime = text?.workTime?.startTime?.slice(0, 5); // Extracts the time part only
            const endTime = text?.workTime?.endTime?.slice(0, 5); // Extracts the time part only
            return (
              <span>
                {startTime} - {endTime}
              </span>
            );
          }}
        />

        <Table.Column
          title="Taxrirlash"
          key="action"
          render={(text, record) => (
            <Button
              onClick={() => showModal(record._id)}
            >
              <EditOutlined style={{ fontSize: "20px" }} />
            </Button>

          )}
        />


        <Table.Column
          title="O'chirish"
          key="action"
          style={none}
          render={(text, record) => (
            <Button
              style={Style}
              onClick={() => showDeleteConfirm(record._id)}
              type="primary"
              danger
            >
              <DeleteOutlined />
            </Button>
          )}
        />

      </Table >

      {
        open && (
          <Hamshira Nurses={Nurses} editID={editID} setOpenUpdate={setOpen} />
        )
      }
    </>
  );
};

export default ReadNurses;