import { api } from "./api";

export const nusresDayliApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNusresDay: builder.query({
      query: () => `nusresDayli/getSalaries`,
      providesTags: ["GetNusresDayliApi"],
    }),
    // POST || CREATE
    createNusresDay: builder.mutation({
      query: (body) => ({
        url: "nusresDayli/createNusresDayli",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetNusresDayliApi"],
    }),

    updateNurseSalaryDay: builder.mutation({
      query: ({ id, body }) => ({
        url: `/nusresDayli/updateNursesDayli/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetNusresDayliApi"],
    }),
    updateNursesDayliSalary: builder.mutation({
      query: ({ id, body }) => ({
        url: `/nusresDayli/updateNursesDayliSalary/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetNusresDayliApi"],
    }),
  }),
});

export const { useCreateNusresDayMutation, useGetNusresDayQuery, useUpdateNurseSalaryDayMutation, useUpdateNursesDayliSalaryMutation } = nusresDayliApi;
