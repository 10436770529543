import React, { useState } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { Modal, Tabs, message } from "antd";
import {
  useGetAllDoctorsQuery,
  useDeleteDoctorMutation,
} from "../../../../redux/doctorApi";
import { NumberFormat, PhoneNumberFormat } from "../../../../hook/NumberFormat";
import { useGetAllNursesQuery } from "../../../../redux/nursesApi";
import SearchBar from "../../../../components/SearchComponent";
import LayoutWrapper from "../../../../components/layout/Layout";
import ReadDoctors from "../doctors/ReadDoctors";
import ReadReception from "../reseption/ReadReception";
import ReadNurses from "../nurses/ReadNurses";
import "../style.css";

const ReadMainjsjs = () => {
  const { data: allDoctor } = useGetAllDoctorsQuery();
  const doctor = allDoctor?.data;
  const [deleteDoctor] = useDeleteDoctorMutation();
  const filterData1 = doctor?.filter((i) => i.docORrecep === "doctor");
  const { data: getAllNurses } = useGetAllNursesQuery();
  const Nurses = getAllNurses?.innerData;
  const block = false;
  const [query, setQuery] = useState("");
  const [doctorQuery, setDoctorQuery] = useState("");

  const validRoles = ["reception", "buhgalter", "calCenter"];
  const reception = doctor?.filter((i) => validRoles.includes(i.docORrecep));
  const { confirm } = Modal;

  const showDeleteConfirm = (_id) => {
    confirm({
      title: "O'chirib tashlaysizmi?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        deleteDoctor(_id)
          .then((res) => {
            if (res.data.success) {
              message.success("Doktor o'chirildi!");
            } else {
              message.error(res.data.message);
            }
          })
          .catch((err) => console.log(err));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const width = window.innerWidth;
  const noneSelery = "none";
  return (
    <LayoutWrapper>
      <SearchBar
        setDoctorQuery={setDoctorQuery}
        dataLength={filterData1}
        allDoctor={filterData1}
        query={query}
        setQuery={setQuery}
      />
      <Tabs>
        <Tabs.TabPane
          tab={`${width > 450 ? "Doktorlar:" : "D"} ${
            filterData1?.length === 0
              ? 0
              : ` ${
                  filterData1?.length === undefined ? 0 : filterData1?.length
                }`
          }`}
          key={0}
        >
          <ReadDoctors
            query={query}
            doctorQuery={doctorQuery}
            block={block}
            doctor={doctor}
            filterData1={filterData1}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={`${width > 450 ? "Hamshiralar:" : "Hamshira+"} ${
            Nurses?.length === 0
              ? 0
              : Nurses?.length === undefined
              ? 0
              : Nurses?.length
          }`}
          key={1}
        >
          <ReadNurses
            query={query}
            doctorQuery={doctorQuery}
            noneSelery={noneSelery}
            Nurses={Nurses}
          />
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={`${width > 450 ? "Administratorlar:" : "A"}  ${
            reception?.length === 0
              ? 0
              : ` ${reception?.length === undefined ? 0 : reception?.length}`
          }`}
          key={2}
        >
          <ReadReception
            query={query}
            doctorQuery={doctorQuery}
            NumberFormat={NumberFormat}
            reception={reception}
            PhoneNumberFormat={PhoneNumberFormat}
            showDeleteConfirm={showDeleteConfirm}
          />
        </Tabs.TabPane>
      </Tabs>
    </LayoutWrapper>
  );
};

export default ReadMainjsjs;
