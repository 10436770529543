import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { DownCircleOutlined, UpCircleOutlined } from "@ant-design/icons";
import { Table, Button, Space, Tooltip } from 'antd';
import LayoutWrapper from '../../../components/layout/Layout';
import { useGetNusresDayQuery } from "../../../redux/nusresDayliApi";
import { NumberFormat } from "../../../hook/NumberFormat";
import './style.css';
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
// import { useCreateSalaryMutation } from "../../../redux/salaryApi";
import './style.css';


const SingleNurse = () => {
    const { _id } = useParams();
    const { data: nurse, } = useGetNusresDayQuery();
    const getNurse = nurse?.innerData || [];
    const [currentDate, setCurrentDate] = useState(new Date());


    const columns = [
        {
            title: "Sana",
            dataIndex: 'getDay',
            key: 'getDay'
        },
        {
            title: 'Ism Familya',
            dataIndex: 'fullName',
            key: 'fullName',
            render: fullName => capitalizeFirstLetter(fullName)

        },
        {
            title: 'Maosh',
            dataIndex: 'salary',
            key: 'salary',
            render: salary => `${NumberFormat(salary)} so'm`
        }
    ];

    const Style = { display: "flex", alignItems: "center", justifyContent: "center", fontSize: "18px" }


    const handleBackClick = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() - 1);
        setCurrentDate(newDate);
    };

    const handleForwardClick = () => {
        const newDate = new Date(currentDate);
        newDate.setMonth(newDate.getMonth() + 1);
        setCurrentDate(newDate);
    };
    const monthRes = currentDate.toLocaleString('en-US', { month: 'long' });
    const monthNames = [
        "Yanvar",
        "Fevral",
        "Mart",
        "Aprel",
        "May",
        "Iyun",
        "Iyul",
        "Avgust",
        "Sentabr",
        "Oktabr",
        "Noyabr",
        "Dekabr"
    ];

    const data = getNurse.filter((a) => a.idNumber === _id && a.getMonth === monthRes);
    const totalNursetsPrice = data?.reduce((acc, nurse) => acc + nurse.salary, 0);

    const monthIndex = currentDate.getMonth();
    const monthName = monthNames[monthIndex];


    return (
        <LayoutWrapper>
            <div className="singlePageRep">
                <div className="fixed-footer">
                    <span>{monthName} oyi uchun</span>
                    <div>Umumiy ish kuni: {NumberFormat(data?.length)} </div>
                    <div>Maosh: {NumberFormat(totalNursetsPrice)} so'm</div>

                    <Space wrap="wrap" gap="small">
                        <Tooltip title="O'tkan oylar">
                            <Button
                                style={Style}
                                onClick={handleBackClick}
                                type="primary"
                                shape="circle"
                                icon={<DownCircleOutlined />}
                            />
                        </Tooltip>
                        <Tooltip title="Oldinga">
                            <Button
                                style={Style}
                                onClick={handleForwardClick}
                                type="primary"
                                shape="circle"
                                icon={<UpCircleOutlined />}
                            />
                        </Tooltip>
                    </Space>
                </div>
                <Table
                    rowKey={"_id"}
                    pagination={false}
                    dataSource={data}
                    columns={columns}
                    loading={!data ? true : false}
                    bordered={true}
                />
            </div>
        </LayoutWrapper>
    )
}

export default SingleNurse;