import React from "react";
import { Input, Select } from "antd";
import { FaUsers } from "react-icons/fa";
import { SearchOutlined } from "@ant-design/icons";
const { Option } = Select;

const SearchBar = ({
    setDoctorQuery,
    allDoctor,
    dataLength,
    query,
    setQuery,
    // minAge,
    // maxAge,
    // setMinAge,
    // setMaxAge,
    none
}) => {
    let sortedData = allDoctor?.filter((i) => i.docORrecep === "doctor") || [];

    const dataSelect = [];
    for (const item of sortedData) {
        dataSelect.push({
            value: item?.idNumber,
            label: item.specialization,
        });
    }


    const options = [];
    for (let i = 0; i < 100000; i++) {
        const value = `${i.toString(36)}${i}`;
        options.push({
            label: value,
            value,
            disabled: i === 10,
        });
    }
    // const handleMinAgeChange = (value) => {
    //     setMinAge(value);
    // };

    // const handleMaxAgeChange = (value) => {
    //     setMaxAge(value);
    // };

    // Generate options for age selection
    const ageOptions = [];
    for (let i = 1; i <= 90; i++) {
        ageOptions.push(<Option key={i} value={i}>{i}</Option>);
    }


    return (
        <div className="SearchBar">

            <div className="search" style={{ overflow: "hidden", border: "1px solid grey" }}>
                <div>
                    <FaUsers /> {dataLength?.length}
                </div>
                {none ? "" :
                    <div className="searchSelect">

                        <Select
                            showSearch
                            allowClear
                            style={{
                                width: 200,
                            }}
                            placeholder="Doktorlar..."
                            size="small"
                            optionFilterProp="children"
                            onChange={(value) => setDoctorQuery(value)}
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            options={dataSelect}

                        />

                    </div>
                }
                <Input
                    allowClear
                    value={query}
                    onChange={(e) => setQuery(e.target.value.toLowerCase())}
                    type="text"
                    placeholder="Izlash..."
                    style={{
                        width: "100%",
                        margin: "0",
                        boxShadow: "none",
                        border: "none",
                        height: "28px",
                    }}
                />

                <SearchOutlined />
            </div>
        </div>
    );
};

export default SearchBar;

