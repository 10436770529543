import React, { useState } from "react";
import { MdOutlineBedroomChild } from "react-icons/md";
import { GiMoneyStack } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { FiUserPlus, FiEye } from "react-icons/fi";
import { ExclamationCircleFilled, CloseOutlined } from "@ant-design/icons";
import { TbElevator } from "react-icons/tb";
import { message, Button, Radio, Input, Form, Modal, Table, Row } from "antd";
import { AiOutlineDelete } from "react-icons/ai";
import { PiLockKeyOpenFill, PiLockKeyFill } from "react-icons/pi";
import { Link } from "react-router-dom";
import Door from "../../../assets/door.png";
import LayoutWrapper from "../../../components/layout/Layout";
import { NumberFormat } from "../../../hook/NumberFormat";
import EnterRoom from "../../receptionPage/Cabins/enterRoom/EnterRoom";
import {
  useGetAllRoomsQuery,
  useUpdateRoomMutation,
  useDeleteRoomMutation,
} from "../../../redux/roomApi";
import "./style.css";
import SearchRooms from "../../../components/searchRooms/SearchRooms";
import { useAddRoomMutation } from "../../../redux/roomApi";

const Rooms = ({ close }) => {
  const [openRoom, setOpenRoom] = useState(false);
  const { data: rooms, isLoading } = useGetAllRoomsQuery();
  const [deleteRoom] = useDeleteRoomMutation();
  const [updateRoom] = useUpdateRoomMutation();
  const data = rooms?.innerData || [];
  const [query, setQuery] = useState("");
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedCapacity, setSelectedCapacity] = useState(null);
  const [queryCategory, setQueryCategory] = useState("");
  const [form] = Form.useForm(); // useForm hookini ishlatish
  const [addRoom] = useAddRoomMutation();
  const [optenAddModal, setOptenAddModal] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const Style = {
    display: "none",
  };

  const { confirm } = Modal;

  const showDeleteConfirm = (id) => {
    confirm({
      title: "Xonani o'chirib tashlaysizmi?",
      icon: <ExclamationCircleFilled />,
      okText: "Ha",
      okType: "danger",
      cancelText: "Yo'q",
      onOk() {
        setLoading(true);
        deleteRoom(id)
          .then((res) => {
            if (res.data.success) {
              message.success(res?.data?.message);
            } else {
              message.error(res.data.message);
            }
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const toggleRoomStatus = async (id, closeRoom) => {
    try {
      let allData = {
        closeRoom: closeRoom,
      };
      await updateRoom({ id: id, body: allData });
    } catch (error) {
      console.log("Error >>", error);
    }
  };

  const kassir = localStorage.getItem("admin");

  const getCategoryLabel = (category) => {
    switch (category) {
      case "pollux":
        return "Polyuks";
      case "luxury":
        return "Lyuks";
      case "free":
        return "Oddiy";
      default:
        return category;
    }
  };

  const columns = [
    {
      title: "Xona nomeri",
      dataIndex: "roomNumber",
      key: "roomNumber",
      render: (text, record) => (
        <div className="imgRoor">
          <img src={Door} alt="" />
          <div className="roomN">
            <b className="roomNumber">{text}</b>
          </div>
        </div>
      ),
    },
    {
      title: "Qavati",
      key: "usersNumber",
      render: (text) => (
        <div className="room_Box-length">
          <div>Qavat</div>
          <div>
            <TbElevator /> {text?.floor}
          </div>
        </div>
      ),
    },
    {
      title: "Xona sig'imi",
      dataIndex: "usersNumber",
      key: "usersNumber",
      render: (text) => (
        <div className="room_Box-length">
          <div>Xona sig'imi</div>
          <div>
            <MdOutlineBedroomChild /> {text}
          </div>
        </div>
      ),
    },
    {
      title: "Bemorlar soni",
      dataIndex: "capacity",
      key: "capacity",
      render: (text, record) => (
        <div className="room_Box-length">
          <div>Bemorlar soni</div>
          {record.capacity.length === record.usersNumber ? (
            <p className="busyRoom">Xonada joy yo'q</p>
          ) : (
            <div>
              {record.capacity.length === 0 ? (
                <div className="emptyRoom">Bo'sh xona</div>
              ) : (
                <div>
                  {" "}
                  <FaUsers /> {record.capacity.length}
                </div>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      title: `1 kunlik to'lov`,
      key: "data",
      render: (data) => (
        <div className="room_Box-length">
          <div>
            1 kunlik to'lov | <b> {getCategoryLabel(data?.category)}</b>
          </div>
          <div>
            <GiMoneyStack /> {NumberFormat(data?.pricePerDay)} so'm
          </div>
        </div>
      ),
    },
    {
      title: "Xonaga kirish",
      key: "enterButton",
      render: (text, record) => (
        <Button
          disabled={record.capacity.length === 0 && "disabled"}
          id="cabins"
          button="true"
          // onClick={() => setOpenRoom(record)}
          className="btn btn-primary"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
          }}
        >
          <Link
            style={{ color: "#fff", width: "100%" }}
            to={`/cabins/${record?._id}`}
          >
            <FiEye />
          </Link>
        </Button>
      ),
    },
    {
      ...(!close && {
        title: "Yopish / O'chirish",
        key: "enterButton",
        render: (text, record) => (
          <div className="CloseRoom">
            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
              }}
              id="cabins"
              onClick={() => toggleRoomStatus(record?._id, !record?.closeRoom)}
              type="primary"
              ghost={!record?.closeRoom}
              danger={record?.closeRoom}
            >
              {record?.closeRoom ? <PiLockKeyFill /> : <PiLockKeyOpenFill />}
            </Button>

            <Button
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
              }}
              id="cabins"
              button="true"
              onClick={() => showDeleteConfirm(record?._id)}
              className="btn btn-danger"
              loading={loading}
            >
              <AiOutlineDelete />
            </Button>
          </div>
        ),
      }),
    },
    {
      ...(close && {
        title: "Bemor qo'shish",
        key: "openUpdate",
        render: (record) => (
          <>
            {record.closeRoom ? (
              <Button
                disabled={true}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  fontSize: "18px",
                }}
                id="cabins"
                type="primary"
                danger
              >
                <PiLockKeyFill />
              </Button>
            ) : (
              <>
                {record.capacity.length === record.usersNumber ? (
                  <Button
                    disabled={record.capacity.length === record.usersNumber}
                    id="cabins"
                    button="true"
                    className="btn btn-success"
                  >
                    <FiUserPlus />
                  </Button>
                ) : (
                  <Link
                    to={
                      kassir === "buhgalter" ? "" : `/updateRoom/${record._id}`
                    }
                  >
                    <Button
                      disabled={
                        record.capacity.length === record.usersNumber ||
                        kassir === "buhgalter"
                      }
                      id="cabins"
                      button="true"
                      className="btn btn-success"
                    >
                      <FiUserPlus />
                    </Button>
                  </Link>
                )}
              </>
            )}
          </>
        ),
      }),
    },
  ];

  // -----------------------------------

  const filteredRooms = data
    ?.filter((doc) => doc.category?.includes(queryCategory))
    ?.filter((item) => !selectedFloor || item.floor === parseInt(selectedFloor))
    ?.filter(
      (item) =>
        !selectedCapacity || item.usersNumber === parseInt(selectedCapacity)
    )
    ?.filter((item) => !query || item.roomNumber === parseInt(query));

  // -----------------------------------

  const onFinish = (values) => {
    if (
      values.roomNumber <= 0 ||
      values.pricePerDay <= 0 ||
      values.usersNumber <= 0 ||
      values.floor <= 0
    ) {
      return message.warning("Xatolik: Iltimos, to'g'ri son kiritng");
    }
    if (values.usersNumber >= 6) {
      return message.warning(
        "Xatolik: Bir xonaga 6 tadan ko'p odam joylab bulmaydi."
      );
    }

    values.roomNumber = +values.roomNumber;
    values.pricePerDay = +values.pricePerDay;
    values.usersNumber = +values.usersNumber;
    values.floor = +values.floor;

    setLoading(true);

    addRoom(values)
      .then((res) => {
        if (res?.data?.success) {
          message.success(res?.data?.message);
          form.resetFields(); // Formani tozalash
          setOptenAddModal(false);
        } else {
          message.error(res?.data?.message);
        }
      })
      .catch((error) => {
        message.error("Xatolik yuz berdi. Iltimos, qayta urinib ko'ring.");
      })
      .finally(() => setLoading(false));
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const maxLength = 5;
    if (inputValue.length > maxLength) {
      setError(`Xona sig'imi ${maxLength} dan ko'proq bo'lishi mumkin emas!`);
    } else {
      setError("");
    }
  };

  return (
    <LayoutWrapper>
      <div style={{ position: "relotive", width: "100%" }}>
        {openRoom && (
          <EnterRoom none={Style} room={openRoom} setOpenRoom={setOpenRoom} />
        )}
        <SearchRooms
          kassir={kassir === "buhgalter"}
          setOptenAddModal={setOptenAddModal}
          query={query}
          selectedFloor={selectedFloor}
          selectedCapacity={selectedCapacity}
          queryCategory={queryCategory}
          setQueryCategory={setQueryCategory}
          setSelectedCapacity={setSelectedCapacity}
          close="none"
          InnerData={data}
          setSelectedFloor={setSelectedFloor}
          setQuery={setQuery}
        />
        <Table
          rowKey="_id"
          pagination={false}
          loading={isLoading}
          bordered
          size="small"
          columns={columns}
          dataSource={
            filteredRooms
              ? [...filteredRooms].sort((a, b) => a.roomNumber - b.roomNumber)
              : []
          }
        />
        <div
          onClick={() => setOptenAddModal(false)}
          className={`${
            optenAddModal ? "OptenAddModalDiv" : "OptenAddModalCloseDiv"
          }`}
        ></div>
        <div
          div
          className={`${
            optenAddModal ? "OptenAddModal" : "OptenAddModalClose"
          }`}
        >
          <Form
            layout="vertical"
            form={form}
            onFinish={onFinish}
            className="FormApply"
          >
            <h4 style={{ textAlign: "center", lineHeight: "10px" }}>
              Xona qo'shish
            </h4>

            <Form.Item
              label="Xonaning raqami"
              name="roomNumber"
              rules={[
                {
                  required: true,
                  message: "Iltimos, xona raqamini kiriting!",
                },
              ]}
            >
              <Input type="number" placeholder="Xona raqami" />
            </Form.Item>

            <Form.Item
              label="Bir kunlik narxi"
              name="pricePerDay"
              rules={[
                {
                  required: true,
                  message: "Iltimos, kunlik to‘lovi kiriting!",
                },
              ]}
            >
              <Input type="number" placeholder="1 kunlik to'lovi" />
            </Form.Item>

            <Form.Item
              label="Xona sig'imi"
              name="usersNumber"
              className="FormItemAdd"
              validateStatus={error ? "error" : ""}
              help={error}
              rules={[
                {
                  required: true,
                  message: "Iltimos, xona sigimini kiriting!",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Ex: 4ta"
                maxLength={5}
                onChange={handleInputChange}
              />
            </Form.Item>
            <Form.Item
              label="Qavat"
              name="floor"
              rules={[
                { required: true, message: "Iltimos, qavatni kiriting!" },
              ]}
            >
              <Input type="number" placeholder="Ex: 1-qavat" />
            </Form.Item>

            <Form.Item
              label="Lyuks yoki Pollyuks"
              name="category"
              rules={[
                { required: true, message: "Iltimos, kategoriya tanlang!" },
              ]}
            >
              <Radio.Group>
                <Radio value="free">Oddiy</Radio>
                <Radio value="luxury">Lyuks</Radio>
                <Radio value="pollux">Pol lyuks</Radio>
              </Radio.Group>
            </Form.Item>

            {/* <Col className="Col-Form"> */}
            <Row
              style={{
                display: "flex",
                // gap: "5px",
                rowGap: "5px",
              }}
            >
              <Button
                style={{ width: "88%" }}
                loading={loading}
                type="primary"
                htmlType="submit"
              >
                Saqlash
              </Button>
              <Button
                style={{
                  width: "10%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                htmlType="submit"
                onClick={() => setOptenAddModal(false)}
              >
                <CloseOutlined />
              </Button>
            </Row>
          </Form>
        </div>
      </div>
    </LayoutWrapper>
  );
};

export default Rooms;
