import {
  Col,
  Button,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
  Radio,
} from "antd";
import React, { useMemo, useState, useEffect, useRef } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import OtpInput from "react-otp-input";
import { FaMale, FaFemale } from "react-icons/fa";
import Selectt from "react-select";
import "./style.css";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import { NumberFormat } from '../../../hook/NumberFormat'
import {
  // useGetUserByIDNumberQuery,
  useGetOneUserQuery,
  useCreateClientAddToRoomMutation,
} from "../../../redux/clientApi";
import { useUpdateRoomMutation } from "../../../redux/roomApi";
import { useGetAllRoomsQuery } from "../../../redux/roomApi";
import { useCreateRoomStoriesMutation } from "../../../redux/roomStoriesApi";
import axios from "../../../api";
import bad from "../../../assets/single-bed.png";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import RoomModal from "../../../components/checkLists/roomModal/RoomModal";

const AddUserToRoom = () => {
  const today_with_hour = moment()
    .utcOffset("+05:00")
    .format("DD.MM.YYYY HH:mm");
  const navigate = useNavigate();
  const clinicId = localStorage.getItem("clinicId");
  const month = new Date().toLocaleString("en-US", { month: "long" });
  const [form] = Form.useForm();
  const formRef = useRef();
  const [year, setYear] = useState("");
  const [phone, setPhone] = useState("");
  const [choseDoctor, setChoseDoctor] = useState("");
  const [getDays, setGetDays] = useState(0);
  const [getMoney, setGetMoney] = useState(0);
  const [otp, setOtp] = useState("");
  const [gender, setGender] = useState("");
  const [isDisabled, setIsDisabled] = useState("");
  const [extraClass, setExtraClass] = useState(false);
  const [extraClass2, setExtraClass2] = useState(false);
  const [extraClass3, setExtraClass3] = useState(false);
  const [selectedExtraSpecialization, setSelectedExtraSpecialization] =
    useState(false);
  const [
    selectedExtraSpecializationState,
    setSelectedExtraSpecializationState,
  ] = useState(false);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState(false);
  const [roomModalState, setRoomModalState] = useState(false);

  const { id, room } = useParams();

  const { data: singleUser } = useGetOneUserQuery(id || "");

  const { data: all_Doctor } = useGetAllDoctorsQuery();
  const { data: rooms } = useGetAllRoomsQuery();
  const [createRoomStory] = useCreateRoomStoriesMutation();
  const [updateRoomFunc] = useUpdateRoomMutation();
  const [createClientAddToRoom] = useCreateClientAddToRoomMutation();

  let allDoctor = useMemo(() => all_Doctor?.data || [], [all_Doctor]);
  let onlyDoctors = allDoctor?.filter((i) => i.docORrecep === "doctor");

  let doctor_info = allDoctor?.find((d) => d._id === choseDoctor);
  let roomData = rooms?.innerData;

  const findedRoomArray = roomData?.filter((i) => i._id === room);
  const findedRoom = findedRoomArray?.length > 0 ? findedRoomArray[0] : null;

  // addressni olish
  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };

  // tuman
  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };
  // viloyat
  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));

    getDistricts(7);
  }, []);

  // MFY
  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  // Agar room dan ma'lumot kelsa, uni avtomatik tanlash
  useEffect(() => {
    if (findedRoom && findedRoom !== "undefined") {
      // "undefined" stringini tekshirish
      try {
        if (findedRoom?._id) {
          form.setFieldsValue({ room: findedRoom._id }); // room avtomatik tanlanadi
          setSelectedRoom(findedRoom);
        }
      } catch (error) {
        console.error("Room parsing failed:", error); // JSON parsingda xatolik yuz bersa consolda ko'rsatiladi
      }
    }
  }, [room, form]);

  useEffect(() => {
    if (singleUser?.data) {
      const userInfo = singleUser.data;
      const updatedValues = {
        firstname: userInfo.firstname,
        lastname: userInfo.lastname,
        idNumber: userInfo.idNumber,
        year: userInfo.year,
        address: userInfo.address,
        gender: userInfo.gender,
      };

      // Agar findedRoom mavjud bo'lsa, phone qiymatini qo'shish
      if (findedRoom) {
        updatedValues.phone = `998${userInfo.phone}`;
      }

      form.setFieldsValue(updatedValues);
    }
  }, [singleUser, form, findedRoom]);

  // doctorlarni qabulidagi bemorlar soni
  const data = [];
  for (const item of onlyDoctors) {
    data.push({
      value: item._id,
      label:
        item.specialization +
        ` (${capitalizeFirstLetter(item.firstName) +
        " " +
        capitalizeFirstLetter(item.lastName)
        })`,
    });
  }

  //------------ JINSLAR ---------------------
  const optionsWithDisabled = [
    {
      label: <FaMale style={{ fontSize: "17px", margin: "0 5px" }} />,
      value: "male",
    },
    {
      label: <FaFemale style={{ fontSize: "17px", margin: "0 5px" }} />,
      value: "female",
    },
  ];

  // ------------yyy-mm-dd------------
  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    dateRegex.test(inputValue)
      ? setYear(inputValue)
      : message.warning("Noto'g'ri sana formati");
  };

  // promoy xonaga qoshyatganda bemorni saqlash
  // Counting money
  const totalPrice = selectedRoom?.pricePerDay * getDays;

  const handleMoneyChange = (e) => {
    const inputMoney = parseFloat(e.target.value); // Kiritilgan summani olish va floatga aylantirish
    setGetMoney(inputMoney);

    if (inputMoney > totalPrice) {
      message.warning(`Tulov miqdori palataning ${getDays} kunlik narxidan oshib ketdi!`); // Ogoohlantirish xabari
    }
  };

  const handleOperationsClick = async () => {

    if (getMoney > totalPrice) {
      message.warning(`Tulov miqdori palataning ${getDays} kunlik narxidan oshib ketdi!`); // Ogoohlantirish xabari
      return
    }

    try {
      const values = await form.validateFields(); // Form qiymatlarini olish
      setIsDisabled(true);

      const address = {
        region: values.address.region?.value,
        district: values.address.district?.value,
        quarter: values.address.quarter?.value,
      };

      const AllInfo = {
        clinicId: clinicId,
        idNumber: values?.idNumber?.toLowerCase(),
        firstname: values.firstname,
        lastname: values.lastname,
        phone: values.phone.replace("+998", ""),
        address,
        year: values.year,
        gender,
        debtor: totalPrice > getMoney,
        treating: true,
      };

      // Agar singleUser mavjud bo'lsa, createClientAddToRoom(AllInfo) ishlamasin
      let createClientResponse = {};
      if (!singleUser) {
        createClientResponse = await createClientAddToRoom(AllInfo);

        if (!createClientResponse?.data?.success) {
          return message.warning(createClientResponse.error.data);
        }
        formRef?.current?.resetFields();
      }

      // const payForRoom = selectedRoom?.pricePerDay || 0; // Har bir kunlik narx
      const payForRoom = selectedRoom
        ? selectedRoom.pricePerDay
        : findedRoom
          ? findedRoom.pricePerDay
          : 0;
      const totalPaid = values.clientPayForRoomPrice || 0; // To'langan summa
      let remainingAmount = totalPaid; // Qolgan summa

      // Sanani tekshirish va to'lov foizini aniqlash
      const now = new Date(); // Hozirgi sana va vaqt
      const appointmentTime = new Date(today_with_hour); // Bemorning tayinlangan vaqti
      const appointmentHour = appointmentTime.getHours(); // Tayinlangan soat
      const appointmentMinute = appointmentTime.getMinutes(); // Tayinlangan daqiqa
      let firstDayPaymentPercentage = 1; // To'liq to'lov - 100%

      // Agar bemor tayinlangan vaqt (soat 12:00 dan keyin) kelgan bo'lsa, 50% to'lov qilsin
      if (
        appointmentHour > 12 ||
        (appointmentHour === 12 && appointmentMinute > 0)
      ) {
        firstDayPaymentPercentage = 0.5; // 50% to'lov
      }

      // Birinchi kun uchun to'lovni hisoblash
      let firstDayPayment = payForRoom * firstDayPaymentPercentage;

      // `dayOfTreatment` qiymati bilan paidDays massivini ko'paytirish
      const paidDays = Array.from({ length: values.dayOfTreatment }, (v, i) => {
        let dayPayment = 0;
        let isPaid = false;

        if (i === 0) {
          // Birinchi kun uchun maxsus to'lov
          if (remainingAmount >= firstDayPayment) {
            dayPayment = firstDayPayment;
            isPaid = true;
            remainingAmount -= firstDayPayment;
          } else if (remainingAmount > 0) {
            dayPayment = remainingAmount;
            remainingAmount = 0;
          }
        } else {
          // Keyingi kunlar uchun to'lov
          if (remainingAmount >= payForRoom) {
            dayPayment = payForRoom;
            isPaid = true;
            remainingAmount -= payForRoom;
          } else if (remainingAmount > 0) {
            dayPayment = remainingAmount;
            remainingAmount = 0;
          }
        }

        // Har bir kunning sanasini yaratamiz
        const dayDate = new Date();
        dayDate.setDate(now.getDate() + i);

        // Sanani DD.MM.YYYY formatida olish
        const formattedDate = dayDate.toLocaleDateString("uz-UZ", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

        return {
          day: i + 1, // 1-kundan boshlab
          date: formattedDate, // "DD.MM.YYYY" formatidagi sana
          isPaid, // To'langanmi yoki yo'qmi
          price: dayPayment, // Shu kun uchun to'langan miqdor
          dailyStatus: "", // Doktor har kun yangilaydigan holat (boshlanishda bo'sh)
        };
      });

      // Palatada joylashtirish
      const roomStory = {
        clinicId: clinicId,
        clientMongooseId: singleUser
          ? singleUser?.data?._id
          : createClientResponse.data.innerData?._id,
        clientID: AllInfo.idNumber,
        clientFullname: `${AllInfo.firstname} ${AllInfo.lastname}`,
        clientPhone: AllInfo.phone,
        doctorIdNumber: doctor_info?.idNumber,
        doctorFullname: `${doctor_info?.firstName} ${doctor_info?.lastName}`,
        doctorType: selectedExtraSpecializationState
          ? selectedExtraSpecialization.specialization
          : doctor_info?.specialization,
        startDay: today_with_hour, // Bemor yotgan vaqti ("DD.MM.YYYY HH:MM")
        endDay: "0",
        month,
        debtor: totalPrice > getMoney,
        dayOfTreatment: values.dayOfTreatment ?? 0, // 'values.dayOfTreatment' mavjud bo'lmasa 0
        payForRoom: selectedRoom?.pricePerDay ?? findedRoom?.pricePerDay ?? 0, // 'selectedRoom.pricePerDay' mavjud bo'lmasa, 'findedRoom.pricePerDay' yoki 0
        clientPayForRoomPrice: values.clientPayForRoomPrice ?? 0, // 'values.clientPayForRoomPrice' mavjud bo'lmasa 0
        roomNumber: selectedRoom?.roomNumber ?? findedRoom?.roomNumber ?? "", // 'selectedRoom.roomNumber' mavjud bo'lmasa, 'findedRoom.roomNumber' yoki bo'sh qator
        floor: selectedRoom?.floor ?? findedRoom?.floor ?? "", // 'selectedRoom.floor' mavjud bo'lmasa, 'findedRoom.floor' yoki bo'sh qator
        active: true,
        birthday: AllInfo.year,
        paidDays, // Yaratilgan massivni palatada saqlash
      };
      // console.log(roomStory);

      // return
      if (getMoney > 0) {
        setRoomModalState({ record: roomStory, totalPayForRoom: values.clientPayForRoomPrice });
      }

      await createRoomStory(roomStory).then((res) => {
        if (!res?.data?.success) {
          message.warning("Xatolik yuz berdi.");
        }
      });

      // Palatani yangilash jarayoni
      const updatedRoom = {
        ...selectedRoom,
        capacity: [
          ...selectedRoom.capacity,
          singleUser ? singleUser.data : createClientResponse?.data?.innerData,
        ],
      };

      const updateRoomResponse = await updateRoomFunc({
        id: updatedRoom._id,
        body: updatedRoom,
      });

      if (updateRoomResponse?.data?.success) {
        message.success(
          `Bemor: ${AllInfo.firstname} ${AllInfo.lastname} muvaffaqiyatli palataga joylandi!`
        );

        if (getMoney <= 0) {
          navigate("/cabins");
        }

      } else {
        message.warning("Xona yangilanishida xatolik yuz berdi.");
      }
    } catch (err) {
      message.error("Amallarni bajarishda xatolik yuz berdi.");
    } finally {
      setIsDisabled(false);
    }
  };

  // Room kategoriya nomini olish uchun yordamchi funksiya
  const getCategoryLabel = (category) => {
    switch (category) {
      case "pollux":
        return "Polyuks";
      case "luxury":
        return "Lyuks";
      case "free":
        return "Oddiy";
      default:
        return category;
    }
  };

  // Select komponenti uchun options yaratish xona uchun
  const listOfRoom = roomData?.map((room) => ({
    value: room._id,
    label: (
      <div style={{ fontSize: "13px" }}>
        Xona: {room.roomNumber} | Qavati: {room.floor} | Turi:{" "}
        {getCategoryLabel(room.category)} | Sig'imi: {room.usersNumber}/
        {room?.capacity?.length}
      </div>
    ),
    disabled: room.usersNumber === room?.capacity?.length,
  }));


  // Select o'zgarishini boshqarish xona uchun
  const choseRoom = (value) =>
    setSelectedRoom(roomData?.find((room) => room._id === value));

  return (
    <Form
      form={form}
      layout="vertical"
      ref={formRef}
      className="FormApply"
      initialValues={{
        address: {
          region: singleUser?.data?.address?.region || "Namangan",
        },
      }}
    >
      <Row className="Row">
        <Col className="Col-Form">
          <Form.Item
            label="Telefon"
            name="phone"
            rules={[
              {
                required: true,
                message: "Telefon raqamini kiriting",
              },
              {
                message:
                  "To'g'ri telefon raqamini kiriting (+998 xx xxx-xx-xx)",
              },
            ]}
          >
            <PhoneInput
              defaultCountry="uz"
              value={phone}
              onChange={(e) => e.length === 13 && setPhone(e)}
              inputStyle={{ width: "100%" }}
              className="PhoneInput"
              placeholder="+998 xx xxx-xx-xx"
            />
          </Form.Item>
        </Col>

        <Col className="Col-Form">
          <Form.Item
            label="Ismi"
            name="firstname"
            rules={[
              {
                required: true,
                message: "Ismni kiriting",
              },
              {
                min: 2,
                message: "Ism kamida 2 ta belgidan iborat bo'lishi kerak",
              },
            ]}
          >
            <Input type="text" placeholder="Имя" />
          </Form.Item>
        </Col>

        <Col className="Col-Form">
          <Form.Item
            label="Familiya"
            name="lastname"
            rules={[
              {
                required: true,
                message: "Familiyani kiriting",
              },
              {
                min: 2,
                message: "Familiya kamida 2 ta belgidan iborat bo'lishi kerak",
              },
            ]}
          >
            <Input type="text" placeholder="Фамилия" />
          </Form.Item>
        </Col>
      </Row>

      <Row className="Row">
        <Col className="Col-Form">
          <Form.Item
            label="Shaxsiy raqami"
            name="idNumber"
            rules={[
              { required: true, message: "Shaxsiy raqamni kiriting!" },
              {
                len: 9,
                message:
                  "Shaxsiy raqam  2 ta harf 7 ta raqam belgidan iborat bo'lishi kerak!",
              },
              {
                pattern: /^[A-Za-z]{2}\d{7}$/,
                message: "To'g'ri format: ab1234567",
              },
            ]}
          >
            <OtpInput
              inputStyle={{
                border: "1px solid #ddd",
                margin: "2.2px",
                borderRadius: "5px",
                width: "30px",
                height: "32px",
              }}
              value={otp}
              onChange={setOtp}
              numInputs={9}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              placeholder="ab1234567"
            />
          </Form.Item>
        </Col>
        <div className="Col-Form_Box">
          <Form.Item
            style={{ width: "63%" }}
            label="Tug'ilgan sana"
            name="year"
            rules={[
              {
                required: true,
                message: "Iltimos, tug'ilgan sanani kiriting",
              },
            ]}
          >
            <input
              type="date"
              style={{ width: "100%" }}
              id="dateInput"
              value={year}
              max={new Date().toISOString().split("T")[0]}
              onChange={handleDateChange}
              placeholder="год/месяц/день"
              className="DatePicer"
            />
          </Form.Item>

          <Form.Item
            name="gender"
            label="Jinsi: Erkak/Ayol"
            rules={[
              {
                required: true,
                message: "Jinsini tanlang",
              },
            ]}
          >
            <Radio.Group
              options={optionsWithDisabled}
              onChange={(e) => setGender(e.target.value)}
              value={gender}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </div>
        <div className="Col-Form_Box">
          <Col className={`${extraClass ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item
              label="Viloyat"
              name={["address", "region"]}
              rules={[
                {
                  required: true,
                  message: "Viloyatni tanlang",
                },
              ]}
            >
              <Selectt
                defaultValue={"Namangan"}
                defaultInputValue="Namangan"
                onMenuOpen={() => setExtraClass(true)}
                onMenuClose={() => setExtraClass(false)}
                onChange={(e) => getDistricts(e.regionID)}
                options={regions}
                placeholder="Область"
              />
            </Form.Item>
          </Col>

          <Col className={`${extraClass2 ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item
              label="Tuman"
              name={["address", "district"]}
              rules={[
                {
                  required: true,
                  message: "Tumanni tanlang",
                },
              ]}
              initialValue={singleUser?.data?.address?.district} // `defaultValue` o'rnida `initialValue`
            >
              <Selectt
                onMenuOpen={() => setExtraClass2(true)}
                onMenuClose={() => setExtraClass2(false)}
                onChange={(e) => getQuarters(e.districtID)}
                options={districts}
                placeholder="Район"
              />
            </Form.Item>
          </Col>

          <Col className={`${extraClass3 ? "Col-Form col11" : "Col-Form"}`}>
            <Form.Item
              label="M.F.Y"
              name={["address", "quarter"]}
              rules={[
                {
                  required: true,
                  message: "M.F.Y ni tanlang",
                },
              ]}
              initialValue={singleUser?.data?.address?.quarters} // `defaultValue` o'rnida `initialValue`
            >
              <Selectt
                onMenuOpen={() => setExtraClass3(true)}
                onMenuClose={() => setExtraClass3(false)}
                options={quarters}
                placeholder="МФУ"
              />
            </Form.Item>
          </Col>
        </div>
      </Row>

      <Row className="Row">
        <Col className="Col-Form">
          <Form.Item
            label="Palatani tanlang"
            name="room"
            rules={[
              {
                required: true,
                message: "Iltimos, palatani tanlang",
              },
            ]}
          >
            <Select
              placeholder="Выберите палату"
              onChange={choseRoom}
              options={listOfRoom}
            />
          </Form.Item>
        </Col>

        <div className="Col-Form_Box">
          <Col width={100} className="Col-Form Col-FormCheck">
            <Form.Item label="Doktor" name="doctor" required={true}>
              <Select
                // mode="multiple"
                style={{ width: "100%" }}
                placeholder="Доктора"
                onChange={(value) => {
                  setChoseDoctor(value);
                  setSelectedExtraSpecialization(false);
                  setSelectedExtraSpecializationState(false);
                }}
                options={data}
                optionrender={(option) => (
                  <Space>
                    <span role="img" aria-label={data.label}>
                      {data.data.label}
                    </span>
                    {option.label}
                  </Space>
                )}
              // required={true}
              />
            </Form.Item>
          </Col>
        </div>

        <div className="Col-Form_Box">
          <Col style={{ width: "50%" }}>
            <Form.Item
              label="Davolanish kun"
              name="dayOfTreatment"
              rules={[
                {
                  required: true,
                  message: "Davolanish kunini kiriting",
                },
              ]}
            >
              <Input
                onChange={(e) => setGetDays(e.target.value)}
                type="text"
                placeholder="День лечения"
              />
            </Form.Item>
          </Col>
          <Col style={{ width: "50%" }}>
            <Form.Item label={totalPrice ? `Jami: ${NumberFormat(totalPrice - getMoney)} so'm` : "Palata uchun tulov"} name="clientPayForRoomPrice">
              <Input onChange={handleMoneyChange} type="text" placeholder="Оплата за палату" />
            </Form.Item>
          </Col>
        </div>
      </Row>

      <Row className="Row">
        <Col className="Col-Form">
          <Button
            type="primary"
            style={{
              background: isDisabled ? "crimson" : "dodgerblue",
              cursor: isDisabled ? "no-drop" : "pointer",
              padding: "7px 16px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              gap: "4px",
              justifyContent: "center",
            }}
            onClick={handleOperationsClick}
          >
            <img style={{ marginTop: "8px" }} width={28} src={bad} alt="" />
            Davolash
          </Button>
        </Col>
      </Row>
      {roomModalState && (
        <RoomModal data={roomModalState} close={setRoomModalState} debt={totalPrice - getMoney} />
      )}
    </Form>
  );
};

export default AddUserToRoom;
