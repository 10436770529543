import React, { useState, useEffect } from 'react';
import { Table, Button, Form, Input, InputNumber, message, Modal } from 'antd';
import {
    useGetAllFizioterapiyaPricesQuery,
    useAddFizioterapiyaPriceMutation,
    useUpdateFizioterapiyaPriceMutation,
    useDeleteFizioterapiyaPriceMutation
} from '../../redux/fizioterapiyaApi';
import LayoutWrapper from '../../components/layout/Layout';

const DoctorFizioterapiyaPricesComponent = () => {
    let doctorId = localStorage.getItem("doctorMongoId");
    const { data: prices = [], isLoading, isError, refetch } = useGetAllFizioterapiyaPricesQuery(doctorId);
    const [addPrice] = useAddFizioterapiyaPriceMutation();
    const [updatePrice] = useUpdateFizioterapiyaPriceMutation();
    const [deletePrice] = useDeleteFizioterapiyaPriceMutation();

    const [form] = Form.useForm();
    const [editingPrice, setEditingPrice] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deletingPriceId, setDeletingPriceId] = useState(null);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);

    const handleAddPrice = async (values) => {
        try {
            await addPrice({ doctorId, priceData: values }).unwrap();
            message.success('Narx muvaffaqiyatli qo\'shildi');
            form.resetFields();
            refetch();
            setIsAddModalVisible(false);
        } catch (err) {
            message.error('Narxni qo\'shishda xato yuz berdi');
        }
    };

    const handleUpdatePrice = async (priceId, values) => {
        try {
            await updatePrice({ doctorId, priceId, updatedPriceData: values }).unwrap();
            message.success('Narx muvaffaqiyatli yangilandi');
            setEditingPrice(null);
            form.resetFields();
            refetch();
        } catch (err) {
            message.error('Narxni yangilashda xato yuz berdi');
        }
    };

    const handleDeletePrice = async () => {
        try {
            await deletePrice({ doctorId, priceId: deletingPriceId }).unwrap();
            message.success('Narx muvaffaqiyatli o\'chirildi');
            setDeletingPriceId(null);
            setIsModalVisible(false);
            refetch();
        } catch (err) {
            message.error('Narxni o\'chirishda xato yuz berdi');
        }
    };

    const handleReset = () => {
        form.resetFields();
    };

    useEffect(() => {
        if (editingPrice) {
            form.setFieldsValue(editingPrice);
        } else {
            form.resetFields();
        }
    }, [editingPrice, form]);

    if (isLoading) return <p>Yuklanmoqda...</p>;
    if (isError) return <p>Narxlarni olishda xato</p>;

    const formatPrice = (value) => {
        return `${Number(value).toLocaleString()} so'm`;
    };

    const columns = [
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Yangi Mijoz Narxi',
            dataIndex: 'newClient',
            key: 'newClient',
            render: (text) => formatPrice(text),
        },
        {
            title: 'Davolanayotgan Bemor Narxi',
            dataIndex: 'treatingPatent',
            key: 'treatingPatent',
            render: (text) => formatPrice(text),
        },
        {
            title: 'Amallar',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => setEditingPrice(record)}
                    >
                        Yangilash
                    </Button>
                    <Button
                        type="link"
                        danger
                        onClick={() => {
                            setDeletingPriceId(record.key);
                            setIsModalVisible(true);
                        }}
                    >
                        O'chirish
                    </Button>
                </>
            ),
        },
    ];

    return (
        <LayoutWrapper>
            <div>
                <h3 style={{ textAlign: "center" }}>Fizioterapiya muolajalari</h3>
                <Button style={{ marginLeft: "8px" }} type="primary" onClick={() => setIsAddModalVisible(true)}>
                    Yangi muolaja qo'shish
                </Button>
                <Table pagination={false} size="small" columns={columns} dataSource={prices} rowKey="key" />

                <Modal
                    title={editingPrice ? "Narxni Yangilash" : "Yangi Narx Qo'shish"}
                    visible={editingPrice !== null || isAddModalVisible}
                    onCancel={() => {
                        setEditingPrice(null);
                        setIsAddModalVisible(false);
                    }}
                    footer={null}
                >
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={editingPrice ? (values) => handleUpdatePrice(editingPrice.key, values) : handleAddPrice}
                        initialValues={editingPrice}
                    >
                        <Form.Item
                            name="name"
                            label="Nomi"
                            rules={[{ required: true, message: 'Iltimos, nomini kiriting!' }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="newClient"
                            label="Yangi Mijoz Narxi"
                            rules={[{ required: true, message: 'Iltimos, yangi mijoz narxini kiriting!' }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                        <Form.Item
                            name="treatingPatent"
                            label="Davolanayotgan Bemor Narxi"
                            rules={[{ required: true, message: 'Iltimos, davolanayotgan bemor narxini kiriting!' }]}
                        >
                            <InputNumber min={0} style={{ width: '100%' }} />
                        </Form.Item>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <Button style={{ width: "100%" }} type="primary" htmlType="submit">
                                {editingPrice ? "Yangilash" : "Qo'shish"}
                            </Button>
                            <Button type="default" onClick={handleReset}>
                                Tozalash
                            </Button>
                        </div>
                    </Form>
                </Modal>

                <Modal
                    title="Narxni o'chirish"
                    open={isModalVisible}
                    onOk={handleDeletePrice}
                    onCancel={() => setIsModalVisible(false)}
                >
                    <p>Ushbu narxni o'chirishga ishonchingiz komilmi?</p>
                </Modal>
            </div>
        </LayoutWrapper>
    );
};

export default DoctorFizioterapiyaPricesComponent;
