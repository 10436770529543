import { createSlice } from "@reduxjs/toolkit";

export const reloadSlice = createSlice({
    name: "reload",
    initialState: {
        reload: false,
    },
    reducers: {
        showReload: (state) => {
            state.reload = !state.reload
        },
    },
})


export const { showReload } = reloadSlice.actions
