import React from 'react';
import { Table, Tabs } from 'antd';
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";
import LayoutWrapper from '../../../components/layout/Layout';
import "./style.css";
import { NumberFormat } from '../../../hook/NumberFormat';


const { TabPane } = Tabs;

const AdditionalTreatments = () => {
    const { data: allDoctors } = useGetAllDoctorsQuery();
    const doctorType = localStorage.getItem("doctorID");
    const doctor = allDoctors?.data?.find((i) => i.idNumber === doctorType);

    const combinedData = [
        ...(doctor?.extraSpecialization || []).map(item => ({
            ...item,
            name: item.specialization,
        })),
        ...(doctor?.extraTreatment || []).map(item => ({
            ...item,
        }))
    ];

    // Maxsus laboratoriya ustunlari
    const laboratoryColumns = [
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Narx',
            dataIndex: 'price',
            key: 'price',
            render: (text) => NumberFormat(text) + " so'm",
        },
        {
            title: 'Foiz',
            dataIndex: 'percent',
            key: 'percent',
            render: (text) => text + "%",
        },
        {
            title: 'Analiz turi',
            dataIndex: 'analis',
            key: 'analis',
            render: (text) => text || '-',
        },
        {
            title: 'Natija',
            dataIndex: 'result',
            key: 'result',
            render: (text) => text || '-',
        },
    ];

    // Default ustunlar
    const defaultColumns = [
        {
            title: 'Nomi',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Narx',
            dataIndex: 'price',
            key: 'price',
            render: (text) => NumberFormat(text) + " so'm",
        },
        {
            title: 'Foiz',
            dataIndex: 'percent',
            key: 'percent',
            render: (text) => text + "%",
        },
    ];

    // Ustunlarni tanlash
    const columns = doctor?.extraSpecialization?.find(item => item.specialization === "Laboratoriya")
        ? laboratoryColumns
        : defaultColumns;



    return (
        <LayoutWrapper>
            <div className="Additional-ant-tabs-tab-btn">
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Qo'shimcha muolajalar" key="1">
                        <Table size="small" pagination={false} dataSource={combinedData} columns={columns} rowKey={(record) => record._id || record.name} />
                    </TabPane>
                </Tabs>

            </div>
        </LayoutWrapper>
    );
}

export default AdditionalTreatments;

