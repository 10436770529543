import React from "react";
import { useSelector } from "react-redux";
import { PhoneNumberFormat } from "../../../hook/NumberFormat";
import { useGetSingleClinicInfoQuery } from "../../../redux/clinicApi";
import logo1 from "./logo1.png";
import "./style.css";

function RecordList(props) {
  const { info } = useSelector((s) => s.recordList);
  const userInfo = info;

  const { data: clinic } = useGetSingleClinicInfoQuery(
    localStorage.getItem("clinicId")
  );
  const clinicData = clinic?.data;

  // Matnni qatorlarga ajratamiz va 16 ta qator yaratamiz
  // birinchi so'zning birinchi harfini katta qilamiz
  const capitalizeFirstLetterOfFirstWord = (line) => {
    const words = line.split(" ");
    if (words.length > 0) {
      words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
    }
    return words.join(" ");
  };

  const lines = [...Array(15)].map((_, i) => {
    const line = userInfo?.retseptList?.split("\n")[i] || " ";
    return capitalizeFirstLetterOfFirstWord(line);
  });

  return (
    <div ref={props?.componentRef} className="container-main-list">
      <div className="header-main-list">
        <div className="left-main-list">
          <p>
            Ўзбекистон Республикаси
            <br />
            Соғлиқни Сақлаш Вазирлиги
            <br />
            Наманган вилояти Соғлиқни
            <br />
            сақлаш Бошқармаси
          </p>
        </div>
        <div className="img-main-list">
          <img src={logo1} alt="" />
        </div>
        <div className="right-main-list">
          <p>
            {" "}
            {clinicData ? clinicData?.address : userInfo?.address} "
            {clinicData?.name}" Мед Centр мчж га
            <br />
            қарашли даволаш маскани
            <br />
            Тел: +998{" "}
            {PhoneNumberFormat(clinicData?.contacts[0].replace("+998"))}
          </p>
        </div>
      </div>

      <div className="info-main">
        <div className="info-main-list">
          <div>
            Сана: <div className="user-infoMain">{userInfo?.day}</div>
          </div>
          <div>
            Бемор:{" "}
            <div className="user-infoMain">{userInfo?.clientFullname}</div>
          </div>
          <div>
            Туғулган сана:{" "}
            <div className="user-infoMain">{userInfo?.birthday}</div>
          </div>
          <div>
            Ташхис: <div className="user-infoMain">{userInfo?.sickname}</div>
          </div>
        </div>
      </div>

      <div className="recommendation">
        <p>Тавсия:</p>
        <div className="lines-main-list">
          {lines?.map((line, i) => (
            <div key={i} className="line-main-list">
              <p>{line}</p>
            </div>
          ))}
        </div>
      </div>

      <div className="footer-main">
        <div className="footer-main-list">
          <p>
            {userInfo?.doctorType}: {userInfo?.doctorFullname}
          </p>
          <b>+998 {PhoneNumberFormat(userInfo?.doctorPhone)}</b>
        </div>

        <div className="footer-main-list">
          <p style={{ width: "200px" }}></p>
        </div>
      </div>

      <div className="bgImageLogo">
        <img src={logo1} alt="" />
      </div>
    </div>
  );
}

export default RecordList;
