import { api } from "./api";

export const VisitApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllVisit: builder.query({
      query: () => `/visit/getAllVisiti`,
      providesTags: ["GetVisit"],
    }),

    addVisit: builder.mutation({
      query: (body) => ({
        url: "/visit/addVisitiController",
        method: "POST",
        body,
      }),
      invalidatesTags: ["GetVisit"],
    }),

    updateVisit: builder.mutation({
      query: ({ id, body }) => ({
        url: `/visit/updateVisiti/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["GetVisit"],
    }),

    // Delete room
    deleteVisit: builder.mutation({
      query: (id) => ({
        url: `/visit/deleteVisitie/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["GetVisit"],
    }),
  }),
});

export const {
  useAddVisitMutation,
  useDeleteVisitMutation,
  useGetAllVisitQuery,
  useUpdateVisitMutation
} = VisitApi;
