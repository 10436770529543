import { createSlice } from "@reduxjs/toolkit";

export const blackBalance = createSlice({
  name: "blackBalance",
  initialState: false,
  reducers: {
    setBlackBalanceState: (state, action) => (state = !state),
  },
});

export const { setBlackBalanceState } = blackBalance.actions;
