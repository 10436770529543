import React, { useMemo, useState } from "react";
import { Form, Input, Col, message, Button, Table, Select } from "antd";
import { FcCalendar } from "react-icons/fc";
import { PhoneInput } from "react-international-phone";
import {
  useCreateOnceTreatmentMutation,
  useGetAllOnceTreatmentsQuery,
} from "../../../redux/once";
import { useGetAllNursesQuery } from "../../../redux/nursesApi";
import "./style.css";
import { NumberFormat, PhoneNumberFormat } from "../../../hook/NumberFormat";
import { capitalizeFirstLetter } from "../../../hook/CapitalizeFirstLitter";
import { useGetAllDoctorsQuery } from "../../../redux/doctorApi";

const Once = () => {
  const [form] = Form.useForm();
  const { TextArea } = Input;

  const [nurse, setNurse] = useState(null);
  const [phone, setPhone] = useState("");
  const [choseDoctor, setChoseDoctor] = useState("");

  const { data: nursesResponse } = useGetAllNursesQuery();
  const { data: allOnceTreatments, isLoading: isLoadingOnceTreatments } =
    useGetAllOnceTreatmentsQuery();
  const [createOnceTreatment, { isLoading }] = useCreateOnceTreatmentMutation();
  const { data: all_Doctor } = useGetAllDoctorsQuery();

  let data = allOnceTreatments?.innerData || [];
  const nurses = nursesResponse?.innerData || [];
  let allDoctor = useMemo(() => all_Doctor?.data || [], [all_Doctor]);
  let onlyDoctors = allDoctor?.filter((i) => i.docORrecep === "doctor");

  const totalPaySummByMonth = data?.reduce((totals, { month, paySumm }) => {
    totals[month] = (totals[month] || 0) + paySumm;
    return totals;
  }, {});

  const columns = [
    {
      title: "Sana",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Ism Familya",
      dataIndex: "fullname",
      key: "fullname",
      render: (v) =>
        capitalizeFirstLetter(v?.split(" ")[0]) +
        " " +
        capitalizeFirstLetter(v?.split(" ")[1]),
    },
    {
      title: "Telefon",
      dataIndex: "phone",
      key: "phone",
      render: (record) => PhoneNumberFormat(record),
    },
    {
      title: "To'lov",
      dataIndex: "paySumm",
      key: "paySumm",
      render: (record) => NumberFormat(record) + " so'm",
    },
    {
      title: "Malumot",
      dataIndex: "description",
      key: "description",
    },
  ];

  const handleSubmit = async (values) => {
    try {
      values.phone = phone;
      values.nurse = nurse;
      values.suggested_doctor = choseDoctor;

      if (
        !values.fullname ||
        !values.phone ||
        !values.description ||
        !values.nurse ||
        !values.paySumm
      ) {
        return message.error("malumotlarni kiriting");
      }

      await createOnceTreatment(values)
        .then((res) => {
          if (res?.data?.success) {
            setChoseDoctor("");
            form.resetFields(); // Formani maydonlarini tozalash
            return message.success(res.data.message);
          }
          message.error(res?.error?.data?.message);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.error(err);
    }
  };

  const nursesData = [];
  for (const item of nurses) {
    nursesData.push({
      value: item._id,
      label:
        capitalizeFirstLetter(item.firstName) +
        " " +
        capitalizeFirstLetter(item.lastName),
      nurseInfo: item,
    });
  }

  const doctorsData = [];
  for (const item of onlyDoctors) {
    doctorsData.push({
      value: item._id,
      label:
        item.specialization +
        " (" +
        capitalizeFirstLetter(item.firstName) +
        " " +
        capitalizeFirstLetter(item.lastName) +
        ")",
    });
  }

  return (
    <div className="OnceContainer">
      <Form
        onFinish={handleSubmit}
        className="OnceBoxForm"
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <Col>
          <Form.Item
            style={{ margin: "0", padding: "5px 0" }}
            label="Ism familyasi"
            name="fullname"
            required
            rules={[
              {
                required: true,
                message: "Ism familya kiriting!",
              },
            ]}
          >
            <Input type="text" placeholder="Ism familiya" />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            style={{ margin: "0", padding: "5px 0" }}
            label="Telefon"
            name="phone"
            required
            rules={[
              {
                required: true,
                message: "Hamshira tanlang!",
              },
            ]}
          >
            <PhoneInput
              defaultCountry="uz"
              value={phone}
              onChange={(e) => e.length === 13 && setPhone(e)}
              inputStyle={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            style={{ margin: "0", padding: "5px 0" }}
            label="Hamshira"
            name="nurse"
            required
            rules={[
              {
                required: true,
                message: "Hamshira tanlang!",
              },
            ]}
          >
            <Select
              onChange={(value, { nurseInfo }) => setNurse(nurseInfo)}
              options={nursesData}
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            style={{ margin: "0", padding: "5px 0" }}
            label="Taklif qilgan doktor"
            name="suggested_doctor"
          >
            <Select
              onChange={(value) => setChoseDoctor(value)}
              options={doctorsData}
            />
          </Form.Item>
        </Col>

        <Col>
          <Form.Item
            style={{ margin: "0", padding: "5px 0" }}
            label="To'langan summa"
            name="paySumm"
            required
            rules={[
              {
                required: true,
                message: "To'langan summa kiriting!",
              },
            ]}
          >
            <Input type="text" placeholder="To'lov summasi" />
          </Form.Item>
        </Col>
        <Form.Item
          style={{
            marginTop: "5px",
          }}
          name="description"
          label="To'liq malumot"
          required
          rules={[
            {
              required: true,
              message: "To'liq malumot kiriting!",
            },
          ]}
        >
          <TextArea
            showCount
            maxLength={150}
            placeholder="Tavsif"
            style={{
              height: 120,
              resize: "none",
            }}
          />
        </Form.Item>
        <Button
          loading={isLoading}
          htmlType="submit"
          type="primary"
          style={{ width: "100%" }}
        >
          Saqlash
        </Button>
      </Form>

      <div className="OnceBox">
        <Table
          rowKey={"_id"}
          loading={isLoadingOnceTreatments}
          pagination={false}
          bordered={true}
          columns={columns}
          dataSource={data}
          size="small"
          footer={() => (
            <div className="FooterBox">
              {Object.entries(totalPaySummByMonth).map(
                ([month, totalPaySumm]) => (
                  <div className="totalMoneyFooter" key={month}>
                    <FcCalendar />
                    <b>
                      {month}: {NumberFormat(totalPaySumm)} so'm
                    </b>
                  </div>
                )
              )}
            </div>
          )}
        />
      </div>
    </div>
  );
};

export default Once;
