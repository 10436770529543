import React, { useEffect, useState } from "react";
import { Modal } from "antd";

const formatNumber = (number) => number.toLocaleString("en-US") + " so'm";

const PopoverContent = ({ record }) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (record && record.extraTreatment && record.extraTreatment.length > 10) {
            setIsModalVisible(true);
        }
    }, [record]);

    if (!record || !record.extraTreatment) {
        return null;
    }

    const treatments = record.extraTreatment || [];

    // Split the treatments array into chunks of 5
    const chunkedTreatments = [];
    for (let i = 0; i < treatments.length; i += 5) {
        chunkedTreatments.push(treatments.slice(i, i + 5));
    }

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div className="Popover">
            <div>
                <span>Muolaja turlari!</span>
                {treatments.length > 10 ? (
                    <Modal
                        title="Barcha Muolajalar"
                        open={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                    >
                        <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                            {chunkedTreatments.map((chunk, chunkIndex) => (
                                <div style={{ flex: "1 1 45%" }} key={chunkIndex}>
                                    {chunk.map((therapy, index) => (
                                        <div style={{ display: "flex", gap: "5px" }} key={index}>
                                            <p>{therapy.name}:</p>
                                            <b>{formatNumber(therapy.price)}</b>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </Modal>
                ) : (
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {chunkedTreatments.map((chunk, chunkIndex) => (
                            <div style={{ flex: "1 1 45%" }} key={chunkIndex}>
                                {chunk.map((therapy, index) => (
                                    <div style={{ display: "flex", gap: "5px" }} key={index}>
                                        <p style={{ whiteSpace: "nowrap" }}>{therapy.name}:</p>
                                        <b style={{ whiteSpace: "nowrap" }}>{formatNumber(therapy.price)}</b>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PopoverContent;
