import React, { useEffect, useState } from "react";
import "./style.css";
import { useSelector } from "react-redux";
import { IoChevronDownOutline } from "react-icons/io5";
import { IoChevronUpOutline } from "react-icons/io5";
import moment from "moment";
import { GiMoneyStack } from "react-icons/gi";
import { MdOutlineBedroomChild } from "react-icons/md";
import { TbUsersGroup } from "react-icons/tb";
import CountUp from "react-countup";
import { Col, Row, Statistic } from "antd";
import { useGetBalanceQuery } from "../../../redux/balans";
import socket from "../../../socket";

const Balans = () => {
  let blackBalanceState = useSelector((s) => s.blackBalance);

  const [selectedDate, setSelectedDate] = useState(moment().startOf("day"));
  const [blackBalance, setBlackBalance] = useState(
    localStorage.getItem("blackBalance") || 100
  );
  const [newData, setNewData] = useState(null);

  // RTK QUREIES
  const { data: balans, refetch } = useGetBalanceQuery({
    date: selectedDate?.format("DD.MM.YYYY"),
  });

  useEffect(() => {
    socket.on("patient_paid", (u) => refetch());
    socket.on("balans", (b) => refetch());
    return () => {
      socket.off("patient_paid");
      socket.off("balans");
    };
  }, [refetch]);

  useEffect(() => {
    setNewData(balans?.innerData);
  }, [balans]);

  useEffect(
    () => setBlackBalance(localStorage.getItem("blackBalance") || 100),
    [blackBalanceState]
  );

  const back = () => setSelectedDate(selectedDate.clone().subtract(1, "day"));
  const forward = () => setSelectedDate(selectedDate.clone().add(1, "day"));

  const formatter = (value) => <CountUp end={value} separator="." />;

  const oyNomlari = [
    "Yanvar",
    "Fevral",
    "Mart",
    "Aprel",
    "May",
    "Iyun",
    "Iyul",
    "Avgust",
    "Sentabr",
    "Oktabr",
    "Noyabr",
    "Dekabr",
  ];
  const hozirgiOy = oyNomlari[new Date().getMonth()];

  return (
    <div className="grid-one-item grid-common grid-c1">
      <div className="grid-c1-content">
        <div className="balansBox">
          <div className="balansTitle">
            <p>Balans</p>
            <div className="bedrom">
              <div>
                <TbUsersGroup /> - {newData?.todayClientsLength}
              </div>
              <div>
                <MdOutlineBedroomChild /> - {newData?.roomClientsLength}{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="balansBox">
          <div className="lg-value1">
            <div className="payCaounting">
              <div>
                <GiMoneyStack /> Bugun:
              </div>
              <div className="">
                <Row gutter={16}>
                  <Col span={12} className="lg-value1">
                    <Statistic
                      value={(newData?.todayBalance * blackBalance) / 100}
                      formatter={formatter}
                    />{" "}
                    so'm
                  </Col>
                </Row>
              </div>
            </div>
          </div>
          <div className="lg-value1">
            <div className="payCaounting">
              <div>
                <GiMoneyStack /> {hozirgiOy}:
              </div>
              <Row gutter={16}>
                <Col span={12} className="lg-value1">
                  <Statistic
                    value={(newData?.monthlyBenifest * blackBalance) / 100}
                    formatter={formatter}
                  />{" "}
                  so'm
                </Col>
              </Row>
            </div>
          </div>
          <div className="lg-bl">
            <div>
              <GiMoneyStack /> Umumiy:
            </div>
            <div className="lg-Bal">
              <Statistic
                value={(newData?.mainBalans * blackBalance) / 100}
                formatter={formatter}
              />{" "}
              so'm
            </div>
          </div>
          <div className="lg-bl" style={{ color: "yellow", fontSize: "18px" }}>
            <div>
              <GiMoneyStack />
              Xarajatlar:
            </div>
            <div className="lg-Bal">
              <Statistic
                className="xarajat"
                value={newData?.monthlyCost}
                formatter={formatter}
              />{" "}
              so'm
            </div>
          </div>
        </div>
        <div className="card-logo-wrapper">
          <div>
            <div className="changeDate">
              <p className="text text-sm text-white  text-date">
                {selectedDate.format("DD.MM.YYYY")}
              </p>
              <span>
                <button onClick={back}>
                  <IoChevronUpOutline />
                </button>

                <button
                  onClick={forward}
                  disabled={
                    moment().format("DD") ===
                    selectedDate?.format("DD.MM.YYYY").split(".")[0]
                  }
                >
                  <IoChevronDownOutline />
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Balans;
