import React from "react";
import { PiSealQuestionBold } from "react-icons/pi";
import { Layout, Menu, theme } from "antd";
import { FaHeartbeat } from "react-icons/fa";
import { GiBrainLeak } from "react-icons/gi";
import { MdBloodtype } from "react-icons/md";
import { GiBrainFreeze } from "react-icons/gi";
import { PiAddressBook } from "react-icons/pi";
import LayoutWrapper from "../layout/Layout";
import ABCD2Calculator from "../CardiovascularRiskCalculatot/abcd2/ABCD2Calculator";
import ASCVDCalculator from "../CardiovascularRiskCalculatot/ascvdc/Ascvdc";
import CHA2DS2VAScCalculator from "../CardiovascularRiskCalculatot/cha2ds2/VASC";
import HASBLEDCalculator from "../CardiovascularRiskCalculatot/has-bled/Has-bled";
import RankinScaleForm from "../CardiovascularRiskCalculatot/rankinScaleForm/RankinScaleForm";
import ScoreScaleFormWrapper from "../CardiovascularRiskCalculatot/score/Score";
import StrokeForm from "../CardiovascularRiskCalculatot/strokeForm/StrokeForm";
import MIDASScale from "../medicalCalculators/boshOgrigi/MIDASScale";
import FunctionalIndependenceForm from "../medicalCalculators/ichkiMiyaQonquyilishi/functionalIndependence/FunctionalIndependenceForm";
import HemorrhageVolumeCalculator from "../medicalCalculators/ichkiMiyaQonquyilishi/hemorrhageVolumeCalculator/HemorrhageVolumeCalculator";
import RasseyannyySkleroz from "../medicalCalculators/rasseyannyySkleroz/RasseyannyySkleroz";
import HuntHessScale from "../medicalCalculators/subaraknoidQonKetishi/HessShkalasi";
import FOURComaScale from "../medicalCalculators/subaraknoidQonKetishi/FOURComaScale";
import GlasgowComaScale from "../medicalCalculators/subaraknoidQonKetishi/GlasgowComaScale";
import RASSScale from "../medicalCalculators/subaraknoidQonKetishi/rassCale";
import NIHSSForm from "../CardiovascularRiskCalculatot/insultShkalasi/InsultShkalasi";
import CreatinineClearanceCalculator from "../CardiovascularRiskCalculatot/clearance/Clearance";
// import CardioRiskCalculator from '../CardiovascularRiskCalculatot/scoree2/SCORE2';

const { Content, Sider } = Layout;

const items2 = [
  {
    key: "sub1",
    icon: <FaHeartbeat />,
    label: "Ishemik insult / yurak-qon tomir kasalliklarining oldini olish",
    children: [
      { key: "1", label: "Qon tomir shkalasi", component: <NIHSSForm /> },
      {
        key: "2",
        label: "CHA2DS2-VASC shkalasi",
        component: <CHA2DS2VAScCalculator />,
      },
      {
        key: "3",
        label: "HAS-BLED shkalasi",
        component: <HASBLEDCalculator />,
      },
      { key: "4", label: "ABCD2 shkalasi", component: <ABCD2Calculator /> },
      {
        key: "5",
        label: "GFR va kreatinin klirensi",
        component: <CreatinineClearanceCalculator />,
      },
      {
        key: "6",
        label: "Rt-PA dozasini hisoblash",
        component: <StrokeForm />,
      },
      { key: "7", label: "Rankin shkalasi", component: <RankinScaleForm /> },
      {
        key: "8",
        label: "SCORE shkalasi",
        component: <ScoreScaleFormWrapper />,
      },
      // { key: '8.5', label: 'SCORE 2-OP shkalasi', component: <CardioRiskCalculator /> },
    ],
  },
  {
    key: "sub2",
    icon: <GiBrainLeak />,
    label: "Miyaga qon ketishi",
    children: [
      {
        key: "9",
        label: "Ichki miya qon ketishdan",
        component: <FunctionalIndependenceForm />,
      },
      {
        key: "10",
        label: "Ichki Parenximatoz",
        component: <HemorrhageVolumeCalculator />,
      },
    ],
  },
  {
    key: "sub3",
    icon: <MdBloodtype />,
    label: "Subaraknoid qon ketishi",
    children: [
      { key: "11", label: "Hant-Hess Shkalasi ", component: <HuntHessScale /> },
    ],
  },
  {
    key: "sub4",
    icon: <GiBrainFreeze />,
    label: "Ong darajasi",
    children: [
      {
        key: "12",
        label: "Glazgo koma shkalasi",
        component: <GlasgowComaScale />,
      },
      { key: "13", label: "FOUR koma shkalasi", component: <FOURComaScale /> },
      { key: "14", label: "RASS shkalasi ", component: <RASSScale /> },
    ],
  },
  {
    key: "sub5",
    icon: <PiAddressBook />,
    label: "Bosh og'rig'i",
    children: [
      { key: "15", label: "MIDASS shkalasi", component: <MIDASScale /> },
    ],
  },
  {
    key: "sub6",
    icon: <PiSealQuestionBold />,
    label: "Ko'p skleroz",
    children: [
      { key: "16", label: "EDSS shkalasi", component: <RasseyannyySkleroz /> },
      { key: "114", label: "Component B5", component: <ASCVDCalculator /> },
    ],
  },
];

const defaultSelectedKey = "1"; // Default selected key
const defaultOpenKey = "sub1"; // Default open key

const MedicalCalculators = ({ children }) => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [selectedComponent, setSelectedComponent] = React.useState(
    items2
      .flatMap((item) => item.children)
      .find((child) => child.key === defaultSelectedKey)?.component
  );

  const handleMenuClick = (e) => {
    const component = items2
      .flatMap((item) => item.children)
      .find((child) => child.key === e.key)?.component;
    setSelectedComponent(component);
  };

  return (
    <LayoutWrapper>
      <Layout
        style={{
          padding: "10px 0",
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Sider
          style={{
            background: colorBgContainer,
            padding: "0 0 0 1px",
          }}
          width={200}
        >
          <Menu
            mode="inline"
            defaultSelectedKeys={[defaultSelectedKey]}
            defaultOpenKeys={[defaultOpenKey]}
            style={{
              height: "100%",
              padding: "0",
              margin: "0",
            }}
            items={items2.map((item) => ({
              key: item.key,
              label: (
                <div style={{ padding: "0", margin: "0" }}>
                  {item.icon} {item.label}
                </div>
              ),
              children: item.children.map((child) => ({
                key: child.key,
                label: <div style={{ padding: "0" }}>{child.label}</div>,
              })),
            }))}
            onClick={handleMenuClick}
          />
        </Sider>
        <Content
          style={{
            minHeight: 280,
          }}
        >
          {selectedComponent || children}
        </Content>
      </Layout>
    </LayoutWrapper>
  );
};

export default MedicalCalculators;
