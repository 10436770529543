import React, { useState } from 'react';
import { Select, Table, Progress } from "antd";
import { EnvironmentOutlined } from '@ant-design/icons';
import './style.css';

const { Option } = Select;

const OfAddress = ({ year, month, manthByNomber, twoColors1, data }) => {
    const [selectedRegion, setSelectedRegion] = useState("Namangan viloyati");
    const [selectedQuarter, setSelectedQuarter] = useState("");
    const [filteredQuarters, setFilteredQuarters] = useState([]);

    const regions = [...new Set(data.map(item => item.address.region))];

    const handleChange = (value) => {
        setSelectedRegion(value);
        const filteredQuarters = [...new Set(data.filter(item => item.address.region === value)
            .map(item => item.address.district))];
        setFilteredQuarters(filteredQuarters);
    };

    const handleChangeQuarter = (value) => {
        setSelectedQuarter(value);
    };

    const countVisitsByDistrict = (selectedRegion) => {
        return data.reduce((districtCounts, item) => {
            if (item.address.region === selectedRegion) {
                const district = item.address.district;
                districtCounts[district] = (districtCounts[district] || 0) + 1;
            }
            return districtCounts;
        }, {});
    };

    const countVisitsByQuarter = (selectedQuarter) => {
        return data.reduce((quarterCounts, item) => {
            if (item.address.district === selectedQuarter) {
                const quarter = item.address.quarter;
                quarterCounts[quarter] = (quarterCounts[quarter] || 0) + 1;
            }
            return quarterCounts;
        }, {});
    };

    const visitsByDistrict = countVisitsByDistrict(selectedRegion);
    const visitsByQuarter = countVisitsByQuarter(selectedQuarter);

    const dataSource = Object.keys(visitsByDistrict).map(district => ({
        key: district,
        district: district,
        visits: visitsByDistrict[district],
        totalVisits: Object.values(visitsByDistrict).reduce((a, b) => a + b, 0),
        quarters: data.filter(item => item.address.district === district).map(item => item.address.quarter)
    }));

    const dataSourceQuarter = Object.keys(visitsByQuarter).map(quarter => ({
        key: quarter,
        quarter: quarter,
        visits: visitsByQuarter[quarter],
        totalVisits: Object.values(visitsByQuarter).reduce((a, b) => a + b, 0),
    }));

    const columns = [
        {
            title: 'Tumanlar',
            dataIndex: 'district',
            key: 'district',
        },
        {
            title: 'Tashriflar',
            dataIndex: 'visits',
            key: 'visits',
            render: (visits, record) => (
                <div style={{ width: "450px" }}>
                    <Progress size={[300, 20]} width={300} percent={Math.floor((visits / record.totalVisits) * 100)} />
                </div>
            ),
        },
    ];

    const columnsQuarter = [
        {
            title: 'MFY',
            dataIndex: 'quarter',
            key: 'quarter',
        },
        {
            title: 'Tashriflar',
            dataIndex: 'visits',
            key: 'visits',
            render: (visits, record) => (
                <div style={{ width: "300px" }}>
                    <Progress size={[300, 20]} width={300} percent={Math.floor((visits / record.totalVisits) * 100)} />
                </div>
            ),
        },
    ];

    return (
        <div className="selectedRegion">
            <h4 style={{ textAlign: "center", color: twoColors1 }}>Bemorlarning yashash hududlari bo'yicha</h4>
            <p style={{ textAlign: "center", color: twoColors1, lineHeight: '5px' }}>{`${year}-yil ${manthByNomber}-${month}`} holatiga ko‘ra</p>
            <div className="selectsBox">
                <Select value={selectedRegion} style={{ width: 200 }} onChange={handleChange} suffixIcon={<EnvironmentOutlined />}>
                    <Option key="all" value="all">Barcha viloyatlar</Option>
                    {regions.map(region => (
                        <Option key={region} value={region}>{region}</Option>
                    ))}
                </Select>
                {filteredQuarters.length === 0 ? "" :
                    <Select placeholder="Tumanlar" style={{ width: 200 }} onChange={handleChangeQuarter} suffixIcon={<EnvironmentOutlined />}>
                        {filteredQuarters.map(district => (
                            <Option key={district} value={district}>{district}</Option>
                        ))}
                    </Select>
                }
            </div>
            <div className="Table_filteredQuarters">
                <Table size="small" columns={columns} dataSource={dataSource} pagination={false} />
                {filteredQuarters?.length === 0 ? <></> :
                    <Table size="small" columns={columnsQuarter} dataSource={dataSourceQuarter} pagination={false} />
                }
            </div>
        </div>
    );
};

export default OfAddress;
