import { configureStore } from "@reduxjs/toolkit";
import { alertSlice } from "./features/indexSlice";
import { userSlice } from "./features/userSlice";
import { lineSlice } from "./features/lineIoad";
import { reloadSlice } from "./features/reload";
import { searchSlice } from "./features/searchNav";
import { blackBalance } from "./features/blackBalanc";
import setInfo from "./recordList/recordList";
import setClinicData from "./recordList/clinicData";
import setLength from "./recordList/ClientLength";
import audioReducer from "./recordList/audioSlice";
import { api } from "./api";

export default configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    alerts: alertSlice.reducer,
    user: userSlice.reducer,
    lines: lineSlice.reducer,
    reload: reloadSlice.reducer,
    search: searchSlice.reducer,
    recordList: setInfo,
    clinicData: setClinicData,
    clientLength: setLength,
    blackBalance: blackBalance.reducer,
    audio: audioReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});
