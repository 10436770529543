import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { PhoneInput } from "react-international-phone";
import OtpInput from "react-otp-input";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import {
  Form,
  Input,
  message,
  Row,
  Col,
  TimePicker,
  Button,
  Radio,
} from "antd";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { useCreateDoctorMutation } from "../../../../redux/doctorApi";
import axios from "../../../../api";

const Reception = () => {
  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [createDoctor] = useCreateDoctorMutation();
  dayjs.extend(customParseFormat);
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState({
    region: "",
    district: "",
    quarter: "",
  });
  const [idNumber, setIdNumber] = useState("");

  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [quarters, setQuarters] = useState([]);

  const [loading, setLoading] = useState(false);

  const [extraClass, setExtraClass] = useState(false);
  const [extraClass2, setExtraClass2] = useState(false);
  const [extraClass3, setExtraClass3] = useState(false);
  const [administrator, setAdministrator] = useState("");

  const onChangeStart = (time, timeString) => setStartTime(timeString);
  const onChangeEnd = (time, timeString) => setEndTime(timeString);

  const onSubmit = async (data) => {
    setLoading(true); // Start loading
    function createAllData(
      data,
      administrator,
      phone,
      idNumber,
      address,
      startTime,
      endTime
    ) {
      let specialization;
      if (administrator === "reception") {
        specialization = "Administrator";
      } else if (administrator === "calCenter") {
        specialization = "calCenter";
      } else {
        specialization = "buhgalter";
      }

      return {
        phone,
        idNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        docORrecep: administrator,
        userType: administrator,
        specialization,
        roomNumber: "0",
        salary: +data.salary,
        login: data.login,
        password: data.password,
        address,
        dateOfBirth: data.dateOfBirth,
        workTime: { startTime, endTime },
      };
    }

    // Funksiyani chaqirish misoli:
    const allData = createAllData(
      data,
      administrator,
      phone,
      idNumber,
      address,
      startTime,
      endTime
    );
    try {
      if (!allData.workTime.startTime || !allData.workTime.endTime) {
        return message.error("ish vaqtini kiriting!");
      }
      const res = await createDoctor(allData);
      if (res?.data?.success) {
        message.success(res?.data?.message);
        navigate("/xodimlar");
        reset();
        return;
      }
      message.warning(res?.data?.message);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  const handleDateChange = (event) => {
    const inputValue = event.target.value;
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (dateRegex.test(inputValue)) {
      setValue("dateOfBirth", inputValue); // Update form value only for valid dates
    } else {
      console.log("Invalid date!");
    }
  };

  let headers = {
    headers: {
      authentication: `Bearer ${localStorage.token}`,
    },
  };

  useEffect(() => {
    axios
      .get("/regions/getRegions", headers)
      .then((res) => setRegions(res?.data))
      .catch((err) => console.log(err));
  }, []);

  const getDistricts = (regionID) => {
    axios
      .get(`/regions/getDistricts/${regionID}`, headers)
      .then((res) => setDistricts(res.data))
      .catch((err) => console.log(err));
  };

  const getQuarters = (districtID) => {
    axios
      .get(`/regions/getQuarters/${districtID}`, headers)
      .then((res) => setQuarters(res.data))
      .catch((err) => console.log(err));
  };

  return (
    <Form
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
      className="FormApply"
    >
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Ism"
            required
            validateStatus={errors.firstName ? "error" : ""}
            help={errors.firstName ? errors.firstName.message : ""}
          >
            <Controller
              name="firstName"
              control={control}
              rules={{ required: "Ism kiritish shart" }}
              render={({ field }) => <Input {...field} placeholder="Ism" />}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Familiya"
            required
            validateStatus={errors.lastName ? "error" : ""}
            help={errors.lastName ? errors.lastName.message : ""}
          >
            <Controller
              name="lastName"
              control={control}
              rules={{ required: "Familiya kiritish shart" }}
              render={({ field }) => (
                <Input {...field} placeholder="Familiya" />
              )}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Tug'ilgan sana"
            required
            validateStatus={errors.dateOfBirth ? "error" : ""}
            help={errors.dateOfBirth ? errors.dateOfBirth.message : ""}
          >
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{
                required: "tug'ulgan sanani kiriting",
              }}
              render={({ field }) => (
                <input
                  type="date"
                  style={{ width: "100%" }}
                  placeholder="yil/oy/kun"
                  className="DatePicer"
                  onChange={handleDateChange}
                  {...field}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Telefon raqami"
            name="phone"
            required
            rules={[{ required: true }]}
          >
            <PhoneInput
              defaultCountry="uz"
              value={phone}
              onChange={(e) =>
                e.length === 13 && setPhone(e.replace("+998", ""))
              }
              inputStyle={{ width: "100%" }}
            />
          </Form.Item>
        </Col>

        <div className="Col-Form_Box">
          <Col className={extraClass ? "Col-Form col11" : "Col-Form"}>
            <Form.Item label="Viloyat" name={["address", "region"]}>
              <Select
                onMenuOpen={() => setExtraClass(true)}
                onMenuClose={() => setExtraClass(false)}
                onChange={(e) => {
                  getDistricts(e.regionID);
                  setAddress((address) => ({
                    ...address,
                    region: e.value,
                  }));
                }}
                options={regions}
              />
            </Form.Item>
          </Col>
          <Col className={extraClass2 ? "Col-Form col11" : "Col-Form"}>
            <Form.Item label="Tuman" name={["address", "district"]}>
              <Select
                onMenuOpen={() => setExtraClass2(true)}
                onMenuClose={() => setExtraClass2(false)}
                onChange={(e) => {
                  getQuarters(e.districtID);
                  setAddress((address) => ({
                    ...address,
                    district: e.value,
                  }));
                }}
                options={districts}
              />
            </Form.Item>
          </Col>
          <Col className={extraClass3 ? "Col-Form col11" : "Col-Form"}>
            <Form.Item label="MFY" name={["address", "quarter"]}>
              <Select
                onMenuOpen={() => setExtraClass3(true)}
                onMenuClose={() => setExtraClass3(false)}
                options={quarters}
                onChange={(e) =>
                  setAddress((address) => ({
                    ...address,
                    quarter: e.value,
                  }))
                }
              />
            </Form.Item>
          </Col>
        </div>

        <Col span={8}>
          <Form.Item
            label="ID raqami"
            required
            validateStatus={errors.idNumber ? "error" : ""}
            help={errors.idNumber ? errors.idNumber.message : ""}
          >
            <OtpInput
              inputStyle={{
                border: "1px solid #ddd",
                margin: "2px",
                borderRadius: "5px",
                width: "32px",
                height: "32px",
              }}
              value={idNumber}
              onChange={setIdNumber}
              numInputs={9}
              renderSeparator={<span></span>}
              renderInput={(props) => <input {...props} />}
              placeholder="ab1234567"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            label="Login"
            required
            validateStatus={errors.login ? "error" : ""}
            help={errors.login ? errors.login.message : ""}
          >
            <Controller
              name="login"
              control={control}
              rules={{ required: "Login kiritish shart" }}
              render={({ field }) => <Input {...field} placeholder="Login" />}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Parol"
            required
            validateStatus={errors.password ? "error" : ""}
            help={errors.password ? errors.password.message : ""}
          >
            <Controller
              name="password"
              control={control}
              rules={{ required: "Parol kiriting" }}
              render={({ field }) => (
                <Input.Password {...field} placeholder="Password" />
              )}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Maosh"
            required
            validateStatus={errors.salary ? "error" : ""}
            help={errors.salary ? errors.salary.message : ""}
          >
            <Controller
              name="salary"
              control={control}
              rules={{ required: "Maosh kiritish shart" }}
              render={({ field }) => <Input {...field} placeholder="Maosh" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <Form.Item
            label="Ish vaqti"
            required
            validateStatus={errors.workTime ? "error" : ""}
            help={errors.workTime ? errors.workTime.message : ""}
          >
            <div style={{ display: "flex", gap: "10px" }}>
              <TimePicker
                placeholder="Boshlanishi"
                onChange={onChangeStart}
                // defaultValue={dayjs("08:00", "HH:mm")}
              />
              <TimePicker
                placeholder="Tugashi"
                onChange={onChangeEnd}
                // defaultValue={dayjs("17:00", "HH:mm")}
              />
            </div>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label="Yo'nalish"
            name="docORrecep"
            rules={[{ required: true, message: "Yo‘nalishni tanlang" }]}
          >
            <Radio.Group
              onChange={(e) => setAdministrator(e.target.value)}
              buttonStyle="solid"
            >
              <Radio.Button value="reception">Administrator</Radio.Button>
              <Radio.Button value="buhgalter">Buhgalter</Radio.Button>
              <Radio.Button value="calCenter">Aloqa markazi</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24} className="Row">
        <Col span={24}>
          <Form.Item>
            <Button
              className="btn btn-primary"
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Yuborish
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default Reception;
